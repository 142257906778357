import moment from 'moment';

export function HospitalAdminCylinderEmptyToReturnReport($rootScope, $scope, ReportService, hospitalReport, toastr, gettextCatalog) {
    $rootScope.navbarType = 'HOSPITALADMIN';

    this.rootScope = $rootScope;
    this.scope = $scope;
    this.ReportService = ReportService;
    this.selectedLocation = _.first(this.locations);
    this.report = hospitalReport;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.timeIntervals = this.ReportService.reportPeriods;
    this.scope.timeInterval = {
        name: this.gettextCatalog.getString('Last 30 Days'),
        id: '3',
        key: 'last30Days',
        text: this.gettextCatalog.getString('30 days')
    };
    this.reportName = this.gettextCatalog.getString('Hospital');

    const self = this;
    this.reportForHispital = {};
    this.reportsForLocations = [];

    this.processData = function () {
        self.reportForHispital = {};
        self.reportsForLocations = [];
        _.forEach(self.report.collectionTimeReport, function (report) {
            if (report.location) {
                self.reportsForLocations.push(report);
            } else {
                self.reportForHispital = report;
            }
        });
    };

    self.processData();
}

HospitalAdminCylinderEmptyToReturnReport.prototype.refresh = function () {
    const self = this;

    let from,
        to;

    if (self.scope.timeInterval.key === 'yesterday') {
        from = moment().subtract(1, 'day').startOf('day').toDate();
        to = moment().subtract(1, 'day').endOf('day').toDate();
    } else if (self.scope.timeInterval.key === 'last7Days') {
        from = moment().subtract(7, 'day').startOf('day').toDate();
        to = moment().subtract(1, 'day').endOf('day').toDate();
    } else if (self.scope.timeInterval.key === 'last30Days') {
        from = moment().subtract(30, 'day').startOf('day').toDate();
        to = moment().subtract(1, 'day').endOf('day').toDate();
    } else if (self.scope.timeInterval.key === 'last3Months') {
        from = moment().subtract(3, 'months').startOf('months').toDate();
        to = moment().subtract(1, 'months').endOf('months').toDate();
    } else if (self.scope.timeInterval.key === 'last12Months') {
        from = moment().subtract(12, 'months').startOf('months').toDate();
        to = moment().subtract(1, 'months').endOf('months').toDate();
    } else if (self.scope.timeInterval.key === 'last24Months') {
        from = moment().subtract(24, 'months').startOf('months').toDate();
        to = moment().subtract(1, 'months').endOf('months').toDate();
    }

    self.ReportService.cachedHospitalReport(self.scope.timeInterval.key)
        .then(function (report) {
            self.report = report;
            self.processData();
        });
};

HospitalAdminCylinderEmptyToReturnReport.$inject = ["$rootScope", "$scope", "ReportService", "hospitalReport", "toastr", "gettextCatalog"];