import {stAutoRefresh} from "./st-auto-refresh";

export function stResetSearch(gettextCatalog) {
    return {
        restrict: 'EA',
        require: '^stTable',
        link: function (scope, element, attrs, ctrl) {
            var tableState = ctrl.tableState();

            element.attr('title', gettextCatalog.getString('Clear all filters'));
            element.addClass('st-reset-search');

            // scope.$watch(function() {
            //    return tableState.search.predicateObject;
            // }, function(newVal, oldVal) {
            //
            //    if (_.isEmpty(newVal)) {
            //        element.hide();
            //    } else {
            //        element.show();
            //    }
            //
            //    console.log('new', newVal);
            //    console.log('old', oldVal);
            // }, true);

            return element.bind('click', function () {
                return scope.$apply(function () {
                    tableState.search.predicateObject = {};
                    tableState.pagination.start = 0;
                    return ctrl.pipe();
                });
            });
        },
        template: '<span class="icon-stack" uib-tooltip="Clear all filters" uib-tooltip-placement="left"><i class="fa fa-filter icon-stack-main"></i><i class="fa fa-remove icon-stack-bottom-right"></i></span>'
    };
}

stResetSearch.$inject = ["gettextCatalog"];