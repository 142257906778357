const DEFAULT_MAX_PRESSURE = 20300;

export function FillPressurePlanService($rootScope) {
    this.rootScope = $rootScope;

    // ATTENTION: We'll leave the bar values not localised, since probably they don't change in languages we need to translate to.
    // If it needs to be localised regardless, then bear in mind that you can't use gettextcatalog.getString directly here:
    // it would be called before the actual translations are loaded, so needs to listen to the TRANSLATIONS_LOADED EVENT and
    // reassign all dependent variables, for example this.currentFillPressurePlan.
    this.fillPressurePlans = [
        {
            pressureLevels: [600, 1300, 2000, 2700, 3400, 4300, 5200, 6100, 7000, 7900, 8800, 9700, 10700, 11700, 12700, 13700],
            max: 13700,
            name: '137 Bar'
        },
        {
            pressureLevels: [500, 1500, 2500, 3500, 4900, 6300, 7700, 9100, 10500, 11900, 13300, 14700, 16100, 17500, 18900, 20300],
            max: 20300,
            name: '203 Bar'
        },
        {
            pressureLevels: [500, 1800, 3100, 4400, 5700, 7200, 8700, 10200, 11800, 13400, 15000, 16600, 18200, 19800, 21400, 23000],
            max: 23000,
            name: '230 Bar'
        },
        {
            pressureLevels: [500, 1900, 3300, 4800, 6300, 8000, 9700, 11400, 13100, 14800, 16500, 18200, 19900, 21600, 23300, 25000],
            max: 25000,
            name: '250 Bar'
        },
        {
            pressureLevels: [500, 2200, 3900, 5600, 7400, 9400, 11400, 13400, 15400, 17400, 19500, 21600, 23700, 25800, 27900, 30000],
            max: 30000,
            name: '300 Bar'
        }
    ];
    this.defaultMaxPressurePerCountry = {
        US: 13700
    };
    this.currentHospital = this.rootScope.hospital;
    if (this.currentHospital && this.currentHospital.defaultFillPressureCB) {
        this.currentFillPressurePlan = _.find(this.fillPressurePlans, function (plan) {
            return plan.max === $rootScope.hospital.defaultFillPressureCB;
        });
    }
}

FillPressurePlanService.prototype.loadCurrentHospitalAndFillPressurePlan = function () {
    var self = this;
    self.currentHospital = self.rootScope.hospital;
    self.currentFillPressurePlan = _.find(self.fillPressurePlans, function (plan) {
        return plan.max === self.currentHospital.defaultFillPressureCB;
    });
};

FillPressurePlanService.prototype.defaultFillPressureForPlan = function (planName) {
    return _.find(this.fillPressurePlans, function (plan) {
        return plan.name === planName;
    }).max;
};

FillPressurePlanService.prototype.defaultFillPressureForCountry = function (country) {
    var defaultMaxPressureForCountry = this.defaultMaxPressurePerCountry[country.countryCode] || DEFAULT_MAX_PRESSURE;
    return _.find(this.fillPressurePlans, function (plan) {
        return plan.max === defaultMaxPressureForCountry;
    });
};

FillPressurePlanService.prototype.planForDefaultFillPressureCB = function (defaultFillPressureCB) {
    return _.find(this.fillPressurePlans, function (plan) {
        return plan.max === defaultFillPressureCB;
    });
};

FillPressurePlanService.prototype.pressureCBForLevelForPlan = function (planName, level) {
    if (typeof level === 'undefined' || level === null) {
        return;
    }
    return _.find(this.fillPressurePlans, function (plan) {
        return plan.name === planName;
    }).pressureLevels[level];
};

FillPressurePlanService.prototype.fullPressureCBInCurrentHospital = function () {
    return this.currentFillPressurePlan.max;
};

FillPressurePlanService.prototype.pressureCBForLevelInCurrentHospital = function (level) {
    if (typeof level === 'undefined' || level === null) {
        return;
    }
    return this.currentFillPressurePlan.pressureLevels[level];
};

FillPressurePlanService.prototype.levelForPressureCBInCurrentHospital = function (pressureCB) {
    var self = this;
    var level = 0;
    if (typeof self.currentFillPressurePlan === 'undefined') {
        self.loadCurrentHospitalAndFillPressurePlan();
    }
    _.forEachRight(self.currentFillPressurePlan.pressureLevels, function (levelPressureCB, index) {
        if (pressureCB >= levelPressureCB) {
            level = index + 1;
            return false;
        }
    });
    return level;
};

FillPressurePlanService.prototype.levelForPressureCBForTagInCurrentHospital = function (pressureCB) {
    var currentDefaultPressureCB = this.currentHospital.defaultFillPressureCB;
    var percentage = pressureCB / currentDefaultPressureCB;
    if (percentage < 0.25) {
        return 0;
    } else if (percentage >= 0.25 && percentage < 0.5) {
        return 1;
    } else if (percentage >= 0.5 && percentage < 0.75) {
        return 2;
    } else if (percentage >= 0.75 && percentage < 1.0) {
        return 3;
    } else {
        return 4;
    }
};

FillPressurePlanService.$inject = ['$rootScope'];