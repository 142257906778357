export function ForceChangePassword($rootScope, $scope, $q, $location, AuthService, SessionService, UserListService) {
    var self = this;

    $rootScope.navbarType = 'NONE';

    self.rootScope = $rootScope;
    self.scope = $scope;
    self.q = $q;
    self.location = $location;
    self.AuthService = AuthService;
    self.SessionService = SessionService;
    self.UserListService = UserListService;

    self.scope.firstJourney = self.rootScope.currentUser.needsFirstPasswordReset;
}

ForceChangePassword.prototype.enableResetButton = function () {
    return this.scope.newPassword &&
        this.scope.newPassword.length >= 8 &&
        this.scope.confirmNewPassword &&
        (this.scope.newPassword === this.scope.confirmNewPassword);
};

ForceChangePassword.prototype.resetPassword = function () {
    var self = this;
    return self.UserListService.forceResetPassword(self.scope.newPassword)
        .then(function () {
            self.rootScope.currentUser.forceResetPassword = false;
            self.rootScope.currentUser.needsFirstPasswordReset = false;
            self.SessionService.redirectAfterLogin();
        })
        .catch(function (err) {
            self.SessionService.logout();
        });
};

ForceChangePassword.prototype.reject = function () {
    var self = this;
    self.SessionService.logout();
};

ForceChangePassword.$inject = ["$rootScope", "$scope", "$q", "$location", "AuthService", "SessionService", "UserListService"];