export function HospitalsDirective() {
    return {
        restrict: 'E',
        scope: {
            hospitals: '=',
            selectHospital: '&',
            enterHospital: '&',
            addHospital: '&',
            title: '@'
        },
        link: function (scope, element, attrs, controller) {
            scope.allowSelection = !!attrs.selectHospital;
            scope.allowEnter = !!attrs.enterHospital;

            scope.tags = function () {
                return _.sortBy(_.reject(_.uniq(_.map(scope.hospitals, 'tag')), function (tag) {
                    return tag === undefined || tag === null || tag.length === 0;
                }));
            };
            scope.hospitalsWithTag = function (tag) {
                return _.filter(scope.hospitals, function (h) {
                    return h.tag === tag;
                });
            };
            scope.hospitalsWithoutTag = function (tag) {
                return _.filter(scope.hospitals, function (h) {
                    return !h.tag;
                });
            };
        },

        template: require('./liv-iq-hospitals.tpl.html')
    };
}