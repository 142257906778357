export function cookiePolicyConfig($routeProvider) {
    $routeProvider.when('/cookiePolicy', {
        controller: 'CookiePolicyController',
        controllerAs: 'cookiePolicyCtrl',
        template: require('./cookiePolicy.tpl.html'),
        resolve: {
            regionStats: ["RegionStat", function (RegionStat) {
                return RegionStat.find().$promise;
            }]
        }
    });
}

cookiePolicyConfig.$inject = ['$routeProvider'];