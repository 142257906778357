export function PorterDashboard($rootScope, $scope, cylinderRefreshIntervalMillis, SessionService, CylinderListService, LocationListService, ButtonsFeatureService, ButtonsOrdersListService, ButtonOrdersSummaryService, RefreshService, SmartTableServerAdapter, Roles, LocationTypes, gettextCatalog, NotificationsService) {
    if (!SessionService.isCurrentRoleAnAdminRole() && !SessionService.verifyCurrentRole([Roles.PORTER])) {
        return;
    }
    $rootScope.navbarType = 'PORTER';
    var self = this;
    self.rootScope = $rootScope;

    self.NEW_BUTTON_ORDERS_TAB_ID = '0';
    self.ACCEPTED_BUTTON_ORDERS_TAB_ID = '1';
    self.CYLINDERS_TAB_ID = '2';
    self.HISTORY_BUTTON_ORDERS_TAB_ID = '3';

    self.tabActiveStates = {};
    self.tabActiveStates[self.NEW_BUTTON_ORDERS_TAB_ID] = true;
    self.tabActiveStates[self.ACCEPTED_BUTTON_ORDERS_TAB_ID] = false;
    self.tabActiveStates[self.CYLINDERS_TAB_ID] = false;
    self.tabActiveStates[self.HISTORY_TAB_ID] = false;

    self.gettextCatalog = gettextCatalog;
    self.cylinderRefreshIntervalMillis = cylinderRefreshIntervalMillis;
    self.locationGrid = new SmartTableServerAdapter(LocationListService);

    self.cylinderGrid = new SmartTableServerAdapter(CylinderListService, function () {
        return {isLost: false};
    }, {
        skipFirstServerCall: true // a hack to avoid calling the server twice when there is a default sort, see: https://github.com/lorenzofox3/Smart-Table/issues/329
    });


    self.CylinderListService = CylinderListService;
    self.RefreshService = RefreshService;
    self.LocationTypes = LocationTypes;

    self.buttonsEnabled = ButtonsFeatureService.isEnabled;

    self.NotificationsService = NotificationsService;

    var butonListOptions = {
        hardLocationFiltering: false,
        sortOldestTop: true,
        additionalRefreshAction: refreshButtonOrdersSummaryStates
    };
    self.acceptedOrdersCtrl = ButtonsOrdersListService.createAcceptedOrdersController(butonListOptions);
    self.newOrdersCtrl = ButtonsOrdersListService.createNewOrdersController(self.acceptedOrdersCtrl, butonListOptions);

    self.totalNumberOfCylinders = 0;
    self.refreshTotalCylinderCount();

    self.selectedLocation = null;
    this.selectedLocationName = undefined;
    var buttonOrdersSummaryStates = {};
    self.getButtonOrdersSummaryState = function (location) {
        if (!buttonOrdersSummaryStates[location.name]) {
            buttonOrdersSummaryStates[location.name] = ButtonOrdersSummaryService.createSummaryState(location.name);
        }

        return buttonOrdersSummaryStates[location.name];
    };

    $(document).ready(function () {
        self._setupTooltips();
    });

    function refresh() {
        self.refreshTotalCylinderCount();
        self.refreshButtonsOrders();
    }

    function refreshButtonOrdersSummaryStates() {
        _.forEach(buttonOrdersSummaryStates, function (state) {
            state.refresh();
        });
    }

    self.refreshButtonsOrders = function () {
        self.newOrdersCtrl.refresh();
        self.acceptedOrdersCtrl.refresh();

        refreshButtonOrdersSummaryStates();
    };

    self.buttonNotificationsLabel = gettextCatalog.getString('REFRESH Button notifications');
    self.buttonNotificationDeniedLabel = gettextCatalog.getString('REFRESH Button notifications denied - check browser settings');
    refresh();
    self.RefreshService.start(self.cylinderRefreshIntervalMillis, [refresh]);
    $scope.$on('$destroy', function () {
        self.RefreshService.stop();
    });


}

PorterDashboard.prototype.areCylindersEnabled = function () {
    return !!this.rootScope.hospital && this.rootScope.hospital.features.cylinder;
};

PorterDashboard.prototype.refreshTotalCylinderCount = function () {
    var self = this;
    return this.CylinderListService.count().then(function (count) {
        self.totalNumberOfCylinders = count;
    });
};

PorterDashboard.prototype.refresh = function () {
    this.refreshTotalCylinderCount();
    this.cylinderGrid.refresh();
    this.locationGrid.refresh();
    this.refreshButtonsOrders();
};


PorterDashboard.prototype.selectLocation = function (location, onlyIfButtonsDisabled) {
    if (onlyIfButtonsDisabled && this.buttonsEnabled()) {
        return;
    }

    this.cylinderGrid.tableState.pagination.start = 0;
    this.cylinderGrid.tableState.search.predicateObject = this.cylinderGrid.tableState.search.predicateObject || {};

    if (this.isSelectedLocation(location)) {
        // Deselect the location
        this.selectedLocation = null;
        delete this.cylinderGrid.tableState.search.predicateObject.lastKnownLocation;
        this.selectedLocationName = undefined;
    } else {
        // Select the location
        this.selectedLocation = location;
        this.cylinderGrid.tableState.search.predicateObject.lastKnownLocation = location.name;
        this.selectedLocationName = location.name;
    }

    this.newOrdersCtrl.selectLocation(this.selectedLocation);
    this.acceptedOrdersCtrl.selectLocation(this.selectedLocation);

    this.refresh();
};

PorterDashboard.prototype.isSelectedLocation = function (location) {
    return this.selectedLocation && this.selectedLocation.name === location.name;
};

PorterDashboard.prototype.selectTab = function (location, selectedTabId, $event) {
    var shouldToggleLocation = true;
    for (var tabId in this.tabActiveStates) {
        if (tabId === selectedTabId) {
            if (!this.tabActiveStates[tabId] && this.isSelectedLocation(location)) {
                // If we are just selecting a different tab for the currently selected location
                // then stop the toggling of the location off
                shouldToggleLocation = false;
            }

            this.tabActiveStates[tabId] = true;
        } else {
            this.tabActiveStates[tabId] = false;
        }
    }

    $event.stopPropagation();
    if (shouldToggleLocation) {
        this.selectLocation(location);
    }
};

PorterDashboard.prototype.isSelectedTab = function (location, tabId) {
    if (!this.buttonsEnabled() || !this.isSelectedLocation(location)) {
        return false;
    }

    return this.tabActiveStates[tabId];
};

PorterDashboard.prototype.commaSeparatedAnd = function (array) {
    if (_.isArray(array)) {
        return [array.slice(0, -1).join(', '), array.slice(-1)[0]].join(array.length < 2 ? '' : ' and ');
    }

    return '';
};

PorterDashboard.prototype.getConnectivityIssuesMessage = function (location) {
    return this.gettextCatalog.getString('Hubs with connectivity issues: {{hubsCount}}', {
        hubsCount: this.commaSeparatedAnd(location.hubsLosingConnectivity)
    });
};

PorterDashboard.prototype.outstandingDeliveriesAndOrders = function (location) {
    var result = location.outstandingDeliveries;
    if (this.buttonsEnabled()) {
        result += this.getButtonOrdersSummaryState(location).openOrdersCount();
    }
    return result;
};

PorterDashboard.prototype.getOutstandingDeliveriesAndOrdersMessage = function (location) {
    return this.gettextCatalog.getString('There are {{deliveriesCount}} outstanding deliveries', {
        deliveriesCount: this.outstandingDeliveriesAndOrders(location)
    });
};

PorterDashboard.prototype.totalEmptyCylindersCount = function (location) {
    console.log('location', location);
    if (!location.cylinderSummaries || !location.cylinderSummaries.length) {
        return 0;
    }

    return _
        .map(location.cylinderSummaries, function (summary) {
            return summary.emptyCylindersCount ? summary.emptyCylindersCount : 0;
        })
        .reduce(function (acc, count) {
            return acc + count;
        });
};

PorterDashboard.prototype.cylinderSummaryLabel = function (cylinderSummary) {
    return 'IQ ' + cylinderSummary.cylinderSize;
};

PorterDashboard.prototype.checkIfActionRequestEnabled = function () {
    var self = this;
    return self.NotificationsService.areHospitalActionRequestGenerationNotificationsEnabled();
};

PorterDashboard.prototype.checkIfOverstockNotificationsEnabled = function () {
    var self = this;
    return self.NotificationsService.areOverStockNotificationsEnabled();
};

PorterDashboard.prototype._setupTooltips = function () {
    $('[data-toggle="tooltip"]').tooltip('destroy');
    $('[data-toggle="tooltip"]').tooltip();
};

PorterDashboard.$inject = ["$rootScope", "$scope", "cylinderRefreshIntervalMillis", "SessionService", "CylinderListService", "LocationListService", "ButtonsFeatureService", "ButtonsOrdersListService", "ButtonOrdersSummaryService", "RefreshService", "SmartTableServerAdapter", "Roles", "LocationTypes", "gettextCatalog", "NotificationsService"];