export function HospitalAdminButtonHistory($rootScope, $scope, $routeParams, $interval, gettextCatalog, ButtonHistoryService, buttonsRefreshIntervalMillis, SmartTableClientAdapter, SessionService) {
    if (!SessionService.isCurrentRoleAnAdminRole()) {
        return;
    }

    $rootScope.navbarType = 'HOSPITALADMIN';

    var self = this;
    self.mac = $routeParams.mac;

    self.buttonsRefreshIntervalMillis = buttonsRefreshIntervalMillis;

    ButtonHistoryService.mac = self.mac;
    self.buttonsGrid = new SmartTableClientAdapter([], function (item) {
        return item;
    });

    self.refresh = function () {
        ButtonHistoryService.history().then(
            function (result) {
                self.buttonsGrid.refresh(result.items);
            }
        );
    };

    var autoRefreshTask = $interval(function () {
        self.refresh();
        if ($scope.onRefresh) {
            $scope.onRefresh();
        }
    }, self.buttonsRefreshIntervalMillis);
    $scope.$on('$destroy', function () {
        $interval.cancel(autoRefreshTask);
    });

    self.refresh();

    self.translate = function (key) {
        return gettextCatalog.getString(key);
    };
}

HospitalAdminButtonHistory.$inject = ["$rootScope", "$scope", "$routeParams", "$interval", "gettextCatalog", "ButtonHistoryService", "buttonsRefreshIntervalMillis", "SmartTableClientAdapter", "SessionService"];