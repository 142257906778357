import {CYLINDERS_MODULE} from "../../../services/cylinders/cylinders.module";
import * as smartTable from 'angular-smart-table';

import {HospitalAdminCylinderUsage} from "./cylinderUsage";

export const HOSPITAL_ADMIN_CYLINDER_USAGE_MODULE = 'hospital.admin.cylinderUsage';

angular.module(HOSPITAL_ADMIN_CYLINDER_USAGE_MODULE, [CYLINDERS_MODULE, smartTable]);

angular.module(HOSPITAL_ADMIN_CYLINDER_USAGE_MODULE)
    .controller('HospitalAdminCylinderUsage', HospitalAdminCylinderUsage);