self.addEventListener('push', function (event) {
    var data = event.data ? event.data.json() : {};

    var title = data.title ? data.title : '<No Title>';
    var options = {
        body: data.message ? data.message : '<No Message>',
        // icon: 'Some image file',
        // data: {arbitrary: 'data'}, // Available as event.notification.data in events
        tag: data.tag ? data.tag : '<No Tag>',
        requireInteraction: true
    };

    var notificationPromise = self.registration.showNotification(title, options);
    event.waitUntil(notificationPromise);
});

self.addEventListener('notificationclick', function (event) {
    event.notification.close();
});
