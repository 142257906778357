export function ConfigParametersController($scope, $uibModalInstance, ConfigParametersHelpersService, ConfigParametersListService, configParameters, hospitalConfigParameters, LocationListService, location, readOnly, toastr, gettextCatalog) {
    this.type = 'LocationConfigController';
    $scope.readOnly = readOnly;
    this.modalInstance = $uibModalInstance;
    this.ConfigParametersListService = ConfigParametersListService;
    this.ConfigParametersHelpersService = ConfigParametersHelpersService;

    this.hospitalConfigParameters = hospitalConfigParameters;
    this.configParameters = configParameters;

    const self = this;

    fillMissingProperties(self.configParameters, self.hospitalConfigParameters);

    this.LocationListService = LocationListService;
    this.location = location;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
}

function fillMissingProperties(configParameters, hospitalConfigParameters) {
    // For any properties not overridden at this location get the values from the
    // hospital settings
    _.forEach(hospitalConfigParameters, function (value, key) {
        if (configParameters[key] === undefined || configParameters[key] === null) {
            configParameters[key] = value;
        }
    });
}

ConfigParametersController.prototype.cleanupParameter = function () {
    const self = this;
    _.forEach(self.configParameters, function (value, key) {
        if (value === self.hospitalConfigParameters[key] && key !== 'hospitalId' && key !== 'deleted' && key !== 'actionRequestGenerationHour') {
            self.configParameters[key] = null;
        }
    });
};

ConfigParametersController.prototype.save = function () {
    const self = this;
    if (self.ConfigParametersHelpersService.validateConfigParameters(self.configParameters)) {
        self.ConfigParametersHelpersService.updatePressures(self.configParameters);
        // Remove any settings which are the same as the hospital settings - we aren't overriding these so we
        // leave them as undefined
        self.cleanupParameter();
        return self.saveConfigParameters()
            .then(function () {
                self.toastr.success(
                    self.gettextCatalog.getString('Configuration has been saved'),
                    self.gettextCatalog.getString('Saved')
                );
                return self.modalInstance.close({
                    msg: self.gettextCatalog.getString('Configuration parameters saved'),
                    newValue: self.configParameters
                });
            })
            .catch(function (error) {
                console.error('Error saving', error);
                // ATTENTION: The message may come from the ConfigParametersListService.save(), which we'll leave not localised (the messages would be generated by loopback)
                const errMsg = typeof error === 'string' ?
                    error :
                    error.message ? error.message : self.gettextCatalog.getString('Unknown Error');

                self.toastr.error(
                    errMsg,
                    self.gettextCatalog.getString('Location save failure')
                );

                // Because we are carrying on editing we need to put back the non-overridden values again
                fillMissingProperties(self.configParameters, self.hospitalConfigParameters);
            });
    } else {
        return;
    }
};

ConfigParametersController.prototype.saveConfigParameters = function () {
    const self = this;
    if (self.configParameters && self.configParameters.hospitalId) {
        return self.ConfigParametersListService.save(self.configParameters)
            .then(function (savedConfigParameters) {
                self.configParameters = savedConfigParameters;
                if (self.location && self.configParameters) {
                    self.location.configParameters = self.configParameters;
                    if ((self.configParameters.id !== self.location.configParametersId) && (self.location.hospitalId)) {
                        return self.LocationListService.save(self.LocationListService.add(self.location))
                            .then(function (savedLocation) {
                                savedLocation.configParameters = self.configParameters;
                                self.location = savedLocation;
                            });
                    }
                }
                // Else we don't have a location, or the object we have is not a location
                return true;
            });
    }
};

ConfigParametersController.prototype.cancel = function () {
    const self = this;
    return self.modalInstance.close({msg: self.gettextCatalog.getString('Configuration parameters saved')});
};

ConfigParametersController.prototype.del = function () {
    const self = this;
    if (self.location && self.location.hospitalId) {
        self.location.configParametersId = null;
        delete self.location.configParameters;
        return self.LocationListService.save(self.LocationListService.add(self.location))
            .then(function (savedLocation) {
                self.location = savedLocation;
                self.toastr.success(
                    self.gettextCatalog.getString('Configuration has been reset'),
                    self.gettextCatalog.getString('Reset')
                );
                return self.modalInstance.close({
                    msg: self.gettextCatalog.getString('Configuration parameters reset'),
                    newValue: null
                });
            }).catch(function (err) {
                return window.alert(self.gettextCatalog.getString('Location save failure ({{errorMessage}})', {errorMessage: err}));
            }).finally(function () {
                if (self.configParameters) {
                    return self.ConfigParametersListService.del(self.configParameters);
                }
            });
    }
    // Else we don't have a location, or the object we have is not a location
};

ConfigParametersController.$inject = ["$scope", "$uibModalInstance", "ConfigParametersHelpersService", "ConfigParametersListService", "configParameters", "hospitalConfigParameters", "LocationListService", "location", "readOnly", "toastr", "gettextCatalog"];