import {CONFIG_PARAMETERS_MODULE} from "../../../services/configParameters/configParameters.module";
import {NOTIFICATIONS_MODULE} from "../../../services/notifications/notifications.module";

import {HospitalAdminNotifications} from "./notifications";
import {HospitalAdminConfiguration} from "./configuration";
import {NotifyeeListController} from "../../../directives/liv-iq-notfiyee-list.controller";
import {focusOnShow, NotifyeeListDirective} from "../../../directives/liv-iq-notifyee-list.directive";

export const HOSPITAL_ADMIN_CONFIGURATION_MODULE = 'hospital.admin.configuration';

angular.module(HOSPITAL_ADMIN_CONFIGURATION_MODULE, [CONFIG_PARAMETERS_MODULE, NOTIFICATIONS_MODULE]);

angular.module(HOSPITAL_ADMIN_CONFIGURATION_MODULE)
    .controller('HospitalAdminNotifications', HospitalAdminNotifications)
    .controller('HospitalAdminConfiguration', HospitalAdminConfiguration)
    .controller('livIqNotifyeeList', NotifyeeListController);

angular
    .module(HOSPITAL_ADMIN_CONFIGURATION_MODULE)
    .directive('livIqNotifyeeList', NotifyeeListDirective)
    .directive('focusOnShow', focusOnShow);