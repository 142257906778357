export function ColourMapService($rootScope) {
    this.rootScope = $rootScope;
    this.colours = [
        {index: 0, rgb: [0, 80, 123]},
        {index: 0.333, rgb: [62, 101, 124]},
        {index: 0.667, rgb: [0, 164, 216]},
        {index: 1, rgb: [196, 217, 223]}
    ];
}

function colourString(colour, alpha) {
    if (alpha) {
        return 'rgba(' + colour.join(',') + ',' + alpha + ')';
    }
    return 'rgb(' + colour.join(',') + ')';
}

ColourMapService.prototype.paint = function (objArray, lightAlpha, mediumAlpha) {
    var self = this;
    return _.map(objArray, function (obj, index) {
        var indexFraction = index ? index / (objArray.length - 1) : 0;
        var range = {};
        _.forEach(self.colours, function (colour, idx) {
            var nextColour = self.colours[idx + 1];
            if (indexFraction >= colour.index && indexFraction <= nextColour.index) {
                range.start = colour;
                range.end = nextColour;
                return false;
            }
        });

        obj.colour = {};
        var colour = _.map(range.start.rgb, function (component, index) {
            return Math.round(component + (range.end.rgb[index] - component) * (indexFraction - range.start.index) / (range.end.index - range.start.index));
        });
        obj.colour.light = colourString(colour, lightAlpha);
        obj.colour.medium = colourString(colour, mediumAlpha);
        obj.colour.solid = colourString(colour);
        obj.indexFraction = indexFraction;
        return obj;
    });
};

ColourMapService.prototype.mapFor = function (objArray, lightAlpha, mediumAlpha) {
    var self = this;
    var colourMap = {
        light: [],
        medium: [],
        solid: []
    };
    _.forEach(objArray, function (obj, index) {
        var indexFraction = index ? index / (objArray.length - 1) : 0;
        var range = {};
        _.forEach(self.colours, function (colour, idx) {
            var nextColour = self.colours[idx + 1];
            if (indexFraction >= colour.index && indexFraction <= nextColour.index) {
                range.start = colour;
                range.end = nextColour;
                return false;
            }
        });

        var colour = _.map(range.start.rgb, function (component, index) {
            return Math.round(component + (range.end.rgb[index] - component) * (indexFraction - range.start.index) / (range.end.index - range.start.index));
        });

        colourMap.light.push(colourString(colour, lightAlpha));
        colourMap.medium.push(colourString(colour, mediumAlpha));
        colourMap.solid.push(colourString(colour));
    });
    return colourMap;
};

ColourMapService.prototype.oneColour = function (lightAlpha, mediumAlpha) {
    return {
        light: colourString(this.colours[0].rgb, lightAlpha),
        medium: colourString(this.colours[0].rgb, mediumAlpha),
        solid: colourString(this.colours[0].rgb)
    };
};

ColourMapService.$inject = ["$rootScope"];