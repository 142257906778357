export function HospitalAdminConfiguration($rootScope, $scope, SessionService, ConfigParametersHelpersService, ConfigParametersListService, configParameters, toastr, gettextCatalog) {
    if (!SessionService.isCurrentRoleAnAdminRole()) {
        return;
    }

    $rootScope.navbarType = 'HOSPITALADMIN';

    this.type = 'HospitalConfigController';
    $scope.readOnly = !(_.includes($rootScope.currentRole.name, 'Service') || _.includes($rootScope.currentRole.name, 'Linde'));
    this.ConfigParametersListService = ConfigParametersListService;
    this.ConfigParametersHelpersService = ConfigParametersHelpersService;

    this.configParameters = configParameters;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
}

HospitalAdminConfiguration.prototype.save = function () {
    const self = this;

    if (!self.ConfigParametersHelpersService.validateConfigParameters(self.configParameters)) return;

    self.ConfigParametersHelpersService.updatePressures(self.configParameters);

    return self.ConfigParametersListService.save(self.configParameters)
        .then(function () {
            self.toastr.success(
                self.gettextCatalog.getString('Configuration has been saved'),
                self.gettextCatalog.getString('Saved')
            );
        }).catch(function (error) {
            console.error(error);
            self.toastr.error(
                //fake error
                self.gettextCatalog.getString('Internal server error - please try later'),
                self.gettextCatalog.getString('Error')
            );
        });
};

HospitalAdminConfiguration.$inject = ["$rootScope", "$scope", "SessionService", "ConfigParametersHelpersService", "ConfigParametersListService", "configParameters", "toastr", "gettextCatalog"];