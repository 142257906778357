export function FilteringAndPaginationApplicatorFactory() {

    var self = this;

    self.getApplicator = function (tableState, mainFilter) {
        var tableFilters = convertFilters(tableState.search.predicateObject);
        var queryClauses = _
            .flatten([tableFilters, getFilterWhereClauses(mainFilter)])
            .filter(function (f) {
                return !_.isEmpty(f);
            });

        return function applyFilteringAndPagination(filter) {

            var allClauses = _.flatten([queryClauses, getFilterWhereClauses(filter.where)]);

            if (allClauses.length > 1) {
                filter.where = {
                    and: allClauses
                };
            } else {
                filter.where = _.first(allClauses);
            }

            if (tableState.pagination.number) {
                filter.limit = tableState.pagination.number; // Number of entries showed per page.
            }

            if (tableState.pagination.start) {
                filter.offset = tableState.pagination.start;
            }

            if (tableState.sort.predicate) {
                filter.order = tableState.sort.predicate + ' ' + (tableState.sort.reverse ? 'DESC' : 'ASC');
            }
        };
    };
}

function convertFilters(predicateObject) {
    var queryObject = {};

    _.forOwn(predicateObject, function (propValue, propName) {
        if (_.isNumber(propValue) || _.isString(propValue)) {
            var pattern = new RegExp('.*' + escapeRegExp(propValue) + '.*', 'i');
            queryObject[propName] = {
                regexp: pattern.toString()
            };
        } else if (propName !== 'between') {
            queryObject[propName] = predicateObject[propName];
        } else {
            queryObject[propName] = convertFilters(predicateObject[propName]);
        }
    });

    return queryObject;
}

function escapeRegExp(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$]/g, '\\$&');
}

function getFilterWhereClauses(filterWhere) {
    // Get all the filter clauses that should be ANDed together
    // If we were relying on the caller to create the filter properly then
    // we could get away with just returning filterWhere.and if that exists,
    // or just filterWhere otherwise, since if an 'and' property is defined on the
    // filter it should be the only one
    var clauses = [];
    if (filterWhere && filterWhere.and) {
        clauses = filterWhere.and;
        delete filterWhere.and;
    }

    if (!_.isEmpty(filterWhere)) clauses.push(filterWhere);

    return clauses;
}