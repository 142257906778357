import moment from 'moment';

export function HospitalAdminOxygenUsedReport($rootScope, $scope, ReportService, hospitalReport, toastr, gettextCatalog) {
    $rootScope.navbarType = 'HOSPITALADMIN';

    this.rootScope = $rootScope;
    this.scope = $scope;
    this.ReportService = ReportService;
    this.hospitalReport = hospitalReport;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.timeInterval = this.ReportService.defaultInterval();
    this.reportName = this.gettextCatalog.getString('Hospital');

    let historyTimeFrom = [],
        oxygenUsages = [];
    const hospitalOxygenUsageReport = hospitalReport.hospitalOxygenUsageReport;
    if (hospitalOxygenUsageReport) {
        historyTimeFrom = hospitalOxygenUsageReport.history.time.from;
        oxygenUsages = hospitalOxygenUsageReport.history.hospitalOxygenUsage;
    }

    const timeLine = _.map(historyTimeFrom, function (time) {
        return moment(time).format('D MMM YY');
    });


    const oxygenUsageData = {
        labels: timeLine,
        datasets: [
            {
                label: this.gettextCatalog.getString('Oxygen Usage (Litres)'),
                backgroundColor: 'rgba(0,80,123,0.8)',
                borderColor: 'rgb(255,255,255)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(0,80,123,1)',
                hoverBorderColor: 'rgb(255,255,255)',
                data: oxygenUsages
            }
        ]
    };

    const oxygenUsageReportBarCTX = document.getElementById('o2-usage-report-bar');

    this.stockLocBarChart = new Chart(oxygenUsageReportBarCTX, {
        type: 'bar',
        data: oxygenUsageData,
        options: {
            legend: {
                position: 'bottom'
            },
            scales: {
                xAxes: [{
                    stacked: true
                }],
                yAxes: [{
                    stacked: true
                }]
            }
        }
    });
}

HospitalAdminOxygenUsedReport.$inject = ["$rootScope", "$scope", "ReportService", "hospitalReport", "toastr", "gettextCatalog"];