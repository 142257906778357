import {CONFIG_PARAMETERS_MODULE} from "../../services/configParameters/configParameters.module";
import {HOSPITALS_MODULE} from "../../services/hospitals/hospitals.module";
import {USERS_MODULE} from "../../services/users/users.module";
import {AdminHospitals} from "./hospitals";

export const ADMIN_HOSPITALS_MODULE = 'admin.hospitals';

angular
    .module(ADMIN_HOSPITALS_MODULE, [CONFIG_PARAMETERS_MODULE, HOSPITALS_MODULE, USERS_MODULE]);

angular.module(ADMIN_HOSPITALS_MODULE)
    .controller('AdminHospitals', AdminHospitals);