export function stSortIcon($provide) {
    $provide.decorator('stSortDirective', stSortDirective);

    function stSortDirective($delegate, $compile) {
        var directive = $delegate[0];

        var link = directive.link;

        directive.compile = function () {
            return function (scope, element, attrs) {
                if (element[0].tagName !== 'I') {
                    var predicate = attrs.stSort;
                    var sortIconElement = document.createElement('i');
                    sortIconElement.setAttribute('st-sort', predicate);
                    sortIconElement.setAttribute('st-class-ascent', 'fa-sort-asc');
                    sortIconElement.setAttribute('st-class-descent', 'fa-sort-desc');
                    sortIconElement.setAttribute('class', 'fa fa-sort');
                    element.append(' ');
                    element.append(sortIconElement);
                    $compile(sortIconElement)(scope);
                }

                link.apply(this, arguments);
            };
        };

        return $delegate;
    }

    stSortDirective.$inject = ["$delegate", "$compile"];
}

stSortIcon.$inject = ["$provide"];