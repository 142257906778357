export function HospitalHelperService($rootScope, HospitalListService, gettextCatalog,
                                      ConfigParametersListService, HospitalFeaturesService, UserListService,
                                      RoleListService, Roles, ServiceLevelListService) {
    this.rootScope = $rootScope;
    this.HospitalListService = HospitalListService;
    this.gettextCatalog = gettextCatalog;
    this.ConfigParametersListService = ConfigParametersListService;
    this.HospitalFeaturesService = HospitalFeaturesService;
    this.UserListService = UserListService;
    this.RoleListService = RoleListService;
    this.Roles = Roles;
    this.ServiceLevelListService = ServiceLevelListService;
}

HospitalHelperService.prototype.save = function (selection, existingHospital, primaryAdmin) {
    console.log('HospitalHelperService#save called for hospital ', existingHospital);
    var self = this;
    var hospital;
    var carryResult = {};
    var e;
    carryResult.primaryAdmin = primaryAdmin;
    try {
        if (selection) {
            if (!existingHospital) {
                // Only save if the item has actually changed
                if (selection.fillPressurePlan && selection.fillPressurePlan.max) {
                    selection.defaultFillPressureCB = selection.fillPressurePlan.max;
                } else {
                    e = new Error(self.gettextCatalog.getString('Hospital Cannot be created without a Fill Pressure.'));
                    e.name = 'noFillPressure';
                    throw e;
                }
                if (selection.country) {
                    selection.countryCode = selection.country.countryCode;
                } else {
                    e = new Error(self.gettextCatalog.getString('Hospital Cannot be created without a Selecting Country.'));
                    e.name = 'noCountry';
                    throw e;
                }
                if (selection.defaultLanguage && selection.defaultLanguage.id) {
                    selection.defaultLang = selection.defaultLanguage.id;
                }
                // calculate here because when is needed the data are no more in the hospital object
                var isValidationNeeded = false;
                var features = self.HospitalFeaturesService.add(selection.features);
                return self.HospitalListService.save(selection)
                    .then(function (createdHospital) {
                        hospital = createdHospital;
                        features.hospitalId = hospital.id;
                        delete features.id;
                        // console.log('New Hospital: ', hospital);
                        return self.HospitalFeaturesService.save(features);
                    })
                    .then(function (hospitalFeatures) {
                        if (!_.isEmpty(hospitalFeatures)) {
                            hospital.features = hospitalFeatures;
                            hospital.features.hospitalId = hospital.id;
                            isValidationNeeded = hospital.features.userEmailAddressValidation;
                            return self.HospitalFeaturesService.add(hospital.features);
                        }
                        return self.ServiceLevelListService.getById(hospital.serviceLevelId || hospital.serviceLevel.id)
                            .then(function (serviceLevel) {
                                delete serviceLevel.id;
                                hospital.features = serviceLevel || {};
                                hospital.features.hospitalId = hospital.id;
                                isValidationNeeded = hospital.features.userEmailAddressValidation;
                                return self.HospitalFeaturesService.add(hospital.features);
                            });
                    })
                    .then(function (initFeatures) {
                        return self.HospitalFeaturesService.save(initFeatures);
                    })
                    .then(function (savedFeatures) {
                        hospital.features = savedFeatures;
                        hospital.configParameters = hospital.configParameters || {};
                        hospital.configParameters.hospitalId = hospital.id; // This fixes config Parameters creation
                        hospital.configParameters.emptyLevelForFillPressure = 5;
                        hospital.configParameters.emptyPressureCB = selection.fillPressurePlan.pressureLevels[hospital.configParameters.emptyLevelForFillPressure];
                        hospital.configParameters.usableFullLevelForFillPressure = 15;
                        hospital.configParameters.usableFullPressureCB = selection.fillPressurePlan.pressureLevels[hospital.configParameters.usableFullLevelForFillPressure];
                        hospital.configParameters.cylinderDwellTimeForOrder = 900000;
                        hospital.configParameters.lostCylinderDetectionTimeWindow = 3600000;
                        hospital.configParameters.cylinderDetectionTimeWindow = 600000;
                        hospital.configParameters.fromStoreTimeWindow = 7200000;
                        return self.ConfigParametersListService.add(hospital.configParameters);
                    })
                    .then(function (initConfigParameters) {
                        return self.ConfigParametersListService.save(initConfigParameters);
                    })
                    .then(function (savedConfigParameters) {
                        // console.log('Saved Config parameters: ', savedConfigParameters);
                        hospital.configParameters = savedConfigParameters;
                        hospital.configParametersId = savedConfigParameters.id;
                        //ugly workaround for HospitalListService Save that perform data manipulation.
                        return self.HospitalListService.save(hospital);
                    })
                    .then(function (savedHospital) {
                        hospital = savedHospital;
                        return self.RoleListService.findOneByName(self.Roles.HOSPITAL_SERVICE);
                    })
                    .then(function (role) {
                        hospital.primaryAdmin = carryResult.primaryAdmin;
                        hospital.primaryAdmin.roleId = role.id;
                        hospital.primaryAdmin.hospitalId = hospital.id;
                        hospital.primaryAdmin.langSelect = hospital.defaultLang;
                        if (!hospital.primaryAdmin.password ||
                            (isValidationNeeded && !primaryAdmin.disableEmailNotifications)) {
                            delete hospital.primaryAdmin.password;
                        }

                        return self.UserListService.add(hospital.primaryAdmin);
                    })
                    .then(function (primaryAdminUser) {
                        return self.UserListService.save(primaryAdminUser);
                    })
                    .then(function (savedUser) {
                        // console.log('Saved Admin User: ', savedUser);

                        hospital.primaryAdmin = savedUser;

                        return self.HospitalListService.save(hospital);
                    });

            } else if (selection !== existingHospital) {
                hospital = selection;
                if (hospital) {
                    if (hospital.fillPressurePlan && hospital.fillPressurePlan.max) {
                        hospital.defaultFillPressureCB = hospital.fillPressurePlan.max;
                    }
                    if (hospital.country) {
                        hospital.countryCode = selection.country.countryCode;
                    }
                    if (selection.defaultLanguage && selection.defaultLanguage.id) {
                        selection.defaultLang = selection.defaultLanguage.id;
                    }
                }
                return self.HospitalFeaturesService.getByHospitalId(hospital.id)
                    .then(function (hospitalFeatures) {
                        if (!_.isEmpty(hospitalFeatures) && hospital.serviceLevel && hospital.serviceLevel.id && hospital.serviceLevelId && hospital.serviceLevel.id === hospital.serviceLevelId) {
                            hospital.features = hospitalFeatures;
                            hospital.features.hospitalId = hospital.id;
                            return self.HospitalFeaturesService.add(hospital.features);
                        } else if (!hospital.serviceLevel.id) {
                            return self.HospitalFeaturesService.add(hospital.features);
                        }
                        return self.ServiceLevelListService.getById(hospital.serviceLevel.id || hospital.serviceLevelId)
                            .then(function (serviceLevel) {
                                delete serviceLevel.id;
                                hospital.features = serviceLevel || {};
                                hospital.features.hospitalId = hospital.id;
                                return self.HospitalFeaturesService.add(hospital.features);
                            });
                    })
                    .then(function (initFeatures) {
                        return self.HospitalFeaturesService.save(initFeatures);
                    })
                    .then(function (savedFeatures) {
                        hospital.features = savedFeatures;
                        return self.HospitalListService.save(hospital);
                    })
                    .then(function (updatedHospital) {
                        hospital = updatedHospital;
                        return self.RoleListService.findOneByName(self.Roles.HOSPITAL_SERVICE);
                    })
                    .then(function (role) {
                        carryResult.primaryAdmin.roleId = role.id;
                        carryResult.primaryAdmin.hospitalId = hospital.id;
                        return self.UserListService.findOneOnRoleInHospital(carryResult.primaryAdmin);
                    })
                    .then(function (existingServiceUser) {
                        if (existingServiceUser && existingServiceUser.id) {
                            hospital.primaryAdminId = existingServiceUser.id;
                            return false;
                        }
                        delete carryResult.primaryAdmin.id;
                        return self.UserListService.add(carryResult.primaryAdmin);
                    })
                    .then(function (primaryAdminUser) {
                        if (primaryAdminUser) {
                            return self.UserListService.save(primaryAdminUser);
                        }
                        return false;
                    })
                    .then(function (savedUser) {
                        if (savedUser && savedUser.id) {
                            hospital.primaryAdminId = savedUser.id;
                        }
                        return self.HospitalListService.save(hospital);
                    });
            }
        }
    } catch (error) {
        if (error.name === 'noFillPressure') {
            window.alert(error.message); // ATTENTION: It's already localised above, see: new Error(self.gettextCatalog.getString('Hospital Cannot be created without a Fill Pressure.'))
        }
        console.error(error);
    }

    // If we get to here return a dummy promise
    return $q(function (resolve) {
        resolve();
    });
};

HospitalHelperService.$inject = ['$rootScope', 'HospitalListService', 'gettextCatalog', 'ConfigParametersListService', 'HospitalFeaturesService', 'UserListService', 'RoleListService', 'Roles', 'ServiceLevelListService'];