export function focusMe($timeout, $parse) {
    return {
        link: function (scope, element, attrs) {
            var field = element[0];
            var model = $parse(attrs.focusMe);

            scope.$watch(model, function (value) {
                if (value === field.id) {
                    $timeout(function () {
                        field.focus();
                    });
                }
            });
            element.bind('blur', function () {
                scope.$apply(model.assign(scope, false));
            });
        }
    };
}

focusMe.$inject = ["$timeout", "$parse"];