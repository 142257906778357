export function ServiceLevelListService($rootScope, $q, ServiceLevel) {
    this.rootScope = $rootScope;
    this.Q = $q;
    this.ServiceLevel = ServiceLevel;
}

ServiceLevelListService.prototype.getFeatureDefinitions = function () {
    return this.ServiceLevel.getFeatureDefinitions().$promise
        .then(function (result) {
            return result.featureDefinitions;
        });
};

ServiceLevelListService.prototype.getById = function (serviceLevelId) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.ServiceLevel.find({
            filter: {
                where: {
                    id: serviceLevelId,
                    deleted: 0
                }
            }
        }).$promise
            .then(function (result) {
                return result[0] || new self.ServiceLevel();
            });
    });
};

ServiceLevelListService.prototype.findAll = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.ServiceLevel.find({
            filter: {
                where: {
                    deleted: 0
                }
            }
        }).$promise;
    });
};

ServiceLevelListService.prototype.save = function (serviceLevels) {
    function saveServicelevel(serviceLevel) {
        serviceLevel.deleted = 0;
        return serviceLevel.$save();
    }

    if (serviceLevels) {
        if (_.isArray(serviceLevels)) {
            return this.Q.all(_.map(serviceLevels, saveServicelevel));
        }
        return saveServicelevel(serviceLevels);
    }
    return false;
};

ServiceLevelListService.prototype.add = function (serviceLevel) {
    var newServiceLevel = new this.ServiceLevel(serviceLevel);
    // We don't have to wait for appReady because the "add" button appears after the app is ready
    return newServiceLevel;
};

ServiceLevelListService.prototype.del = function (serviceLevel) {
    if (serviceLevel) {
        serviceLevel.deleted = new Date().getTime();
        return serviceLevel.$save();
    }
    return false;
};

ServiceLevelListService.$inject = ["$rootScope", "$q", "ServiceLevel"];