export function HospitalAdminUsageLogs($scope, $rootScope, UsageLogsService, OrderListService, SessionService, usageStats, orderStats, LocaleService, moment) {
    if (!SessionService.isCurrentRoleAnAdminRole()) {
        return;
    }
    $rootScope.navbarType = 'HOSPITALADMIN';

    var vm = this;

    vm.UsageLogsService = UsageLogsService;
    vm.OrderListService = OrderListService;
    vm.usageStats = usageStats;
    vm.orderStats = orderStats;

    $('.datepicker').datepicker();
    applyLocaleToAllDatepickers();
    $scope.$on(LocaleService.EVENT_LOCALE_CHANGED, applyLocaleToAllDatepickers);

    vm.getUsageStatsForDate = function () {
        var dateAsLocalisedString = $('#session-stats-date-picker').val();
        if (dateAsLocalisedString) {
            var currentLocaleDateFormat = $('.datepicker').datepicker('option', 'dateFormat');
            var nativeDate = $.datepicker.parseDate(currentLocaleDateFormat, dateAsLocalisedString);
            var dateAsISO8601String = moment(nativeDate).format('YYYY-MM-DD');
            vm.UsageLogsService.find(dateAsISO8601String).then(function (result) {
                vm.usageStats = result;
            });
        }
    };

    vm.getOrderStatsForDate = function () {
        var dateAsLocalisedString = $('#order-stats-date-picker').val();
        if (dateAsLocalisedString) {
            var currentLocaleDateFormat = $('.datepicker').datepicker('option', 'dateFormat');
            var nativeDate = $.datepicker.parseDate(currentLocaleDateFormat, dateAsLocalisedString);
            var dateAsISO8601String = moment(nativeDate).format('YYYY-MM-DD');
            vm.OrderListService.getOrderStats(dateAsISO8601String).then(function (result) {
                console.warn('result', result);
                vm.orderStats = result;
            });
        }
    };

    function applyLocaleToAllDatepickers() {
        $('.datepicker').datepicker('option', getJQueryUITranslations(LocaleService.getCurrentLocale()));
    }
}

function getJQueryUITranslations(fullLocale) {
    var localeMap = {
        en_GB: 'en-GB',
        en_US: '',
        de_DE: 'de',
        es_ES: 'es',
        sv_SE: 'sv',
        nl_NL: 'nl',
        fr_FR: 'fr',
        eo: 'eo'
    };
    return $.datepicker.regional[localeMap[fullLocale]];
}

HospitalAdminUsageLogs.$inject = ["$scope", "$rootScope", "UsageLogsService", "OrderListService", "SessionService", "usageStats", "orderStats", "LocaleService", "moment"];