export function RoleListService($rootScope, Role, Roles) {
    this.$rootScope = $rootScope;
    this.Role = Role;
    this.Roles = Roles;
}

RoleListService.prototype.add = function (role) {
    return new this.Role(role);
};

RoleListService.prototype.find = function (name) {
    return this.Role.find({filter: {where: {name: name}}}).$promise;
};

RoleListService.prototype.findOneByName = function (name) {
    return this.Role.findOne({filter: {where: {name: name}}}).$promise;
};

RoleListService.prototype.findById = function (id) {
    return this.Role.findById({id: id}).$promise;
};

RoleListService.prototype.findAll = function (includeOnlyEnabledRoles) {
    var self = this;
    includeOnlyEnabledRoles = !!includeOnlyEnabledRoles;

    return self.Role.find({}).$promise
        .then(function (roles) {
            return self.$rootScope.appReady.promise
                .then(function (hospital) {
                    return {
                        roles: roles,
                        hospital: hospital
                    };
                });
        })
        .then(function (rolesAndHospital) {
            if (!includeOnlyEnabledRoles) {
                return rolesAndHospital.roles;
            }

            return _.filter(rolesAndHospital.roles, function (r) {
                if (r.name === self.Roles.PORTER && !rolesAndHospital.hospital.features.porterRole) return false;
                if (r.name === self.Roles.NURSE && !rolesAndHospital.hospital.features.nurseRole) return false;

                return true;
            });
        });
};

RoleListService.prototype.save = function (role) {
    return role && role.$save();
};

RoleListService.prototype.del = function (role) {
    return role && this.Role.deleteById({id: role.id}).$promise;
};

RoleListService.$inject = ["$rootScope", "Role", "Roles"];