export function LocaleRun(gettextCatalog, LocaleService, moment) {
    useEsperantoLocaleInMomentForStarLanguage();

    gettextCatalog.debug = false; // ATTENTION: Set it to true to find out missing translations
    LocaleService.applyApplicationDefaultLocale();

    /**
     * TODO: Temporary, just for the star language
     */
    function useEsperantoLocaleInMomentForStarLanguage() {
        moment.updateLocale('eo', {
            months: '*******_********_*****_******_****_*****_*****_*******_*********_*******_********_********'.split('_'),
            monthsShort: '***_***_***_***_***_***_***_***_***_***_***_***'.split('_'),
            weekdays: '*******_*****_*****_********_*****_********_******'.split('_'),
            weekdaysShort: '***_***_****_****_***_***_***'.split('_'),
            weekdaysMin: '**_**_**_**_**_**_**'.split('_'),
            longDateFormat: {
                LT: 'HH:mm',
                LTS: 'HH:mm:ss',
                L: 'YYYY-MM-DD',
                LL: 'D[-** **] MMMM, YYYY',
                LLL: 'D[-** **] MMMM, YYYY HH:mm',
                LLLL: 'dddd, [**] D[-** **] MMMM, YYYY HH:mm'
            },
            meridiemParse: /[ap]\.t\.m/i,
            isPM: function (input) {
                return input.charAt(0).toLowerCase() === 'p';
            },
            meridiem: function (hours, minutes, isLower) {
                if (hours > 11) {
                    return isLower ? '*.*.*.' : '*.*.*.';
                }
                return isLower ? '*.*.*.' : '*.*.*.';
            },
            calendar: {
                sameDay: '[****** **] LT',
                nextDay: '[****** **] LT',
                nextWeek: 'dddd [**] LT',
                lastDay: '[****** **] LT',
                lastWeek: '[*******] dddd [**] LT',
                sameElse: 'L'
            },
            relativeTime: {
                future: '** %s',
                past: '***** %s',
                s: '********',
                m: '******',
                mm: '%d *******',
                h: '****',
                hh: '%d *****',
                d: '****',
                dd: '%d *****',
                M: '******',
                MM: '%d *******',
                y: '****',
                yy: '%d *****'
            },
            ordinalParse: /\d{1,2}a/,
            ordinal: '%d*',
            week: {
                dow: 1, // Monday is the first day of the week.
                doy: 7  // The week that contains Jan 1st is the first week of the year.
            }
        });

        var relativeTimeFormats = {
            en: {
                future: 'in %s',
                past: '%s ago',
                s: 'a few secs',
                ss: '%d secs',
                m: 'a min',
                mm: '%d mins',
                h: 'an hr',
                hh: '%d hrs',
                d: 'a day',
                dd: '%d days',
                M: 'a month',
                MM: '%d months',
                y: 'a year',
                yy: '%d years'
            }
        };
        moment.updateLocale('en', {
            relativeTime: relativeTimeFormats.en
        });
        moment.updateLocale('en-gb', {
            relativeTime: relativeTimeFormats.en
        });
    }
}

LocaleRun.$inject = ['gettextCatalog', 'LocaleService', 'moment'];