export function ButtonStatsDashboardPanel() {
    return {
        restrict: 'E',
        scope: {
            timeIntervalKey: '=',
            closePanel: '&'
        },
        controller: 'livIqButtonStatsDashboardPanel',
        controllerAs: 'ctrl',
        bindToController: true,
        template: require('./liv-iq-button-stats-dashboard-panel.tpl.html')
    };
}