export function LocationTypeListService($rootScope, $q, LocationType) {
    this.rootScope = $rootScope;
    this.LocationType = LocationType;
    this.$q = $q;
}

LocationTypeListService.prototype.findAll = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function () {
        return self.LocationType.find({
            filter: {
                where: {
                    deleted: 0
                }
            }
        }).$promise;
    });
};

LocationTypeListService.$inject = ["$rootScope", "$q", "LocationType"];