export function ButtonsOrderTypesService($rootScope, ButtonOrderType, ButtonsFeatureService) {
    var self = this;

    function getHospital() {
        return $rootScope.appReady.promise;
    }

    function getBaseFilter(hospital) {
        return {
            filter: {
                where: {
                    hospitalId: hospital ? hospital.id : null
                }
            }
        };
    }

    self.getAllOrderTypes = function (applyFiltering) {
        if (!ButtonsFeatureService.isEnabled()) {
            return $(function (resolve) {
                resolve([]);
            });
        }

        return getHospital()
            .then(getBaseFilter)
            .then(function (filter) {
                if (applyFiltering) applyFiltering(filter.filter);
                return ButtonOrderType.find(filter).$promise;
            });
    };
}

ButtonsOrderTypesService.$inject = ["$rootScope", "ButtonOrderType", "ButtonsFeatureService"];