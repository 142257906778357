export function CountryService($rootScope, $q, $http, RegionStat) {
    this.rootScope = $rootScope;
    this.q = $q;
    this.http = $http;
    this.RegionStat = RegionStat;
    this.countriesList = [];
    var self = this;
    this.http.get('json/country.json')
        .then(function (countries) {
            self.countriesList = countries.data;
        });
}

CountryService.prototype.countries = function () {
    var self = this;
    if (self.countriesList.length) {
        return self.q.resolve(self.countriesList);
    }
    return self.http.get('json/country.json')
        .then(function (countries) {
            self.countriesList = countries.data;
            return self.countriesList;
        });
};

CountryService.prototype.currency = function (countryCode) {
    var self = this;
    return self.http.get('json/country.json')
        .then(function (countries) {
            self.countriesList = countries.data;
            var country = _.find(self.countriesList, ['countryCode', countryCode]);
            return country ? country.currency : undefined;
        });
};

CountryService.prototype.currentCountry = function (countryCode, countries) {
    return _.find(countries, function (country) {
        return country.countryCode === countryCode;
    });
};

CountryService.$inject = ["$rootScope", "$q", "$http", "RegionStat"];