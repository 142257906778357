export function PushNotificationsService($q, $rootScope, NotificationSubscription, LocaleService) {
    var swRegistration = null;
    var self = this;
    var dummyPromise = $q(function () {
    });

    var userDenied = false;

    self.notificationsSupported = 'serviceWorker' in navigator && 'PushManager' in window;

    self.areNotificationsDenied = function () {
        if (self.notificationsSupported) {
            // If the user only selects 'Deny for now' then the Notification.permission stays at default, hence why we need to track the userDenied flag
            return Notification.permission === 'denied' || userDenied;
        } else {
            return true;
        }
    };

    self.subscribed = false;

    self.setupAction = doSetup();

    self.unsubscribe = function () {
        if (!self.notificationsSupported) return dummyPromise;

        return self.setupAction
            .then(getSubscription)
            .then(function (subscription) {
                if (subscription) {
                    return removeSubscriptionOnServer(subscription)
                        .then(function () {
                            return subscription.unsubscribe();
                        });
                }
            })
            .then(function () {
                self.subscribed = false;
            })
            .catch(function (err) {
                console.error('Error unsubscribing', err);
            });
    };

    self.subscribe = function () {
        if (!self.notificationsSupported) return dummyPromise;

        return self.setupAction
            .then(getSubscription)
            .then(function (subscription) {
                if (!subscription) {
                    return getHospital()
                        .then(function (hospital) {
                            return NotificationSubscription.publickey({hospitalId: hospital.id}).$promise;
                        })
                        .then(function (result) {
                            return swRegistration.pushManager.subscribe({
                                userVisibleOnly: true, // Must be true
                                applicationServerKey: urlB64ToUint8Array(result.publicKey)
                            })
                                .catch(function (err) {
                                    if (err.name === "NotAllowedError") { // User denied notifications
                                        userDenied = true;
                                    } else {
                                        throw err;
                                    }
                                });
                        });
                } else {
                    return subscription;
                }
            })
            .then(function (subscription) {
                if (subscription) {
                    return updateSubscriptionOnServer(subscription).then(function () {
                        return true;
                    });
                } else {
                    return false;
                }
            })
            .then(function (result) {
                self.subscribed = result;
            })
            .catch(function (error) {
                console.error('Error subscribing', error);
            });
    };

    function doSetup() {
        if (!self.notificationsSupported) {
            self.subscribed = false;
            self.ready = true;
            return dummyPromise;
        }

        return initPushNotificationsIfNeeded()
            .then(getSubscription)
            .then(function (subscription) {
                if (subscription) {
                    // Just in case the subscription information has been removed from the server
                    return updateSubscriptionOnServer(subscription).then(function () {
                        return true;
                    });
                } else {
                    return false;
                }
            })
            .then(function (subscribed) {
                self.subscribed = subscribed;
                self.ready = true;
            });
    }

    function getHospital() {
        return $rootScope.appReady.promise;
    }

    function initPushNotificationsIfNeeded() {
        if (!swRegistration) {
            return navigator.serviceWorker.register('js/serviceWorkers/serviceWorker.js')
                .then(function (swReg) {
                    swRegistration = swReg;
                });
        } else {
            return dummyPromise;
        }
    }

    function updateSubscriptionOnServer(subscription) {
        var locale = LocaleService.getCurrentLocale();

        return getHospital()
            .then(function (hospital) {
                if (!hospital) return;
                return NotificationSubscription.subscribe({
                    subscription: subscription,
                    locale: locale,
                    hospitalId: hospital.id
                }).$promise;
            })
            .catch(function (err) {
                console.error('Error updating subscription on server', err);
            });
    }

    function removeSubscriptionOnServer(subscription) {
        return getHospital()
            .then(function (hospital) {
                if (!hospital) return;
                return NotificationSubscription.unsubscribe({
                    endpoint: subscription.endpoint,
                    hospitalId: hospital.id
                }).$promise;
            });
    }

    function getSubscription() {
        return swRegistration.pushManager.getSubscription();
    }

    function urlB64ToUint8Array(base64String) {
        var padding = '='.repeat((4 - base64String.length % 4) % 4);
        var base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        var rawData = window.atob(base64);
        var outputArray = new Uint8Array(rawData.length);

        for (var i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }
}

PushNotificationsService.$inject = ["$q", "$rootScope", "NotificationSubscription", "LocaleService"];