import { reloadWithDebugInfo } from "angular";

export function CookiePolicyController($rootScope, $routeParams, $scope, $q, Common, SessionService, toastr) {
  var self = this;

  self.rootScope = $rootScope;
  self.routeParams = $routeParams;
  self.scope = $scope;
  self.q = $q;
  self.Common = Common;
  self.SessionService = SessionService;
  self.toastr = toastr;
  window.reloadOTBanner();
}

CookiePolicyController.prototype.gotoSignInPage = function () {
  return this.SessionService.logout();
};

CookiePolicyController.$inject = ['$rootScope', '$routeParams', '$scope', '$q', 'Common', 'SessionService', 'toastr'];