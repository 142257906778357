export function SigninController($rootScope, $scope, $location, $uibModal, Common, AuthService, UserListService, SessionService, LoopBackAuth, LoginPhotoService, LocaleService, regionStats, toastr, gettextCatalog) {
    this.rootScope = $rootScope;
    this.LocaleService = LocaleService;
    this.UserListService = UserListService;
    this.gettextCatalog = gettextCatalog;
    if (AuthService.user && LoopBackAuth.rememberMe) {
        SessionService.redirectAfterLogin();
    } else {
        AuthService.logout().then(function () {
            SessionService.restoreSession();
        });
    }

    var self = this;

    this.errorList = {
        emailAddressRequired: self.gettextCatalog.getString('Email address is required')
    };

    LoginPhotoService.setRegionStats(regionStats);

    self.login = login;
    self.url = $location.url();
    self.scope = $scope;

    self.cylinderStats = LoginPhotoService.getCurrentStats();

    if (self.cylinderStats.hasStats) {
        self.globalCylindersCount = (1 * self.cylinderStats.globalStats.cylindersCount).toLocaleString();
        self.regionCylindersCount = (1 * self.cylinderStats.countryStats.cylindersCount).toLocaleString();
    }


    self.rememberMe = LoopBackAuth.rememberMe;
    self.modal = $uibModal;
    self.toastr = toastr;
    self.showForgotPassword = false;
    delete $rootScope.hospitalName;
    self.scope.emailValidationEnabled = false;

    function login() {
        if (!self.rememberMe) {
            self.rememberMe = false;
        }
        AuthService.login(self.user, self.password, self.rememberMe)
            .then(function (session) {
                return self.LocaleService.loadListOfLocale(session);
            })
            .then(function () {
                SessionService.redirectAfterLogin();
            })
            .catch(function (err) {
                self.toastr.error(Common.interpretHttpStatus(err.status));
                self.showForgotPassword = true;
            });
    }

    self.scope.openForgetPasswordModal = function () {
        self.scope.showForgotPassword = true;
    };

    self.scope.closeForgetPasswordModal = function () {
        self.scope.showForgotPassword = false;
        self.scope.requestSubmitted = false;
        self.scope.showError = false;
        self.scope.email = '';
    };

    self.scope.submitRecoverPasswordRequest = function () {
        self.scope.requestSubmitted = false;
        self.scope.showError = false;
        if (!self.scope.email) {
            self.scope.forgotPasswordError = self.errorList.emailAddressRequired;
            self.scope.showError = true;
            return;
        }
        self.UserListService.requestPasswordRecovery(self.scope.email)
            .then(function (response) {
                self.scope.emailValidationEnabled = response.emailValidationEnabled;
                self.scope.requestSubmitted = true;
            });
    };
    
  window.reloadOTBanner();
}

SigninController.$inject = ['$rootScope', '$scope', '$location', '$uibModal', 'Common', 'AuthService', 'UserListService', 'SessionService', 'LoopBackAuth', 'LoginPhotoService', 'LocaleService', 'regionStats', 'toastr', 'gettextCatalog'];