export function TermsAndConditions($rootScope, $scope, $q, $location, AuthService, SessionService, UserListService) {
    var self = this;

    $rootScope.navbarType = 'NONE';

    self.rootScope = $rootScope;
    self.scope = $scope;
    self.q = $q;
    self.location = $location;
    self.AuthService = AuthService;
    self.SessionService = SessionService;
    self.UserListService = UserListService;
}

TermsAndConditions.prototype.accept = function () {
    var self = this;
    return self.UserListService.acceptTCs(self.rootScope.currentUser.id)
        .then(function (result) {
            if (result.acceptedTCsOn) {
                self.rootScope.currentUser.acceptedTCsOn = result.acceptedTCsOn;
                self.SessionService.redirectAfterLogin();
            } else {
                self.SessionService.logout();
            }
        });
};

TermsAndConditions.prototype.reject = function () {
    var self = this;
    self.SessionService.logout();
};

TermsAndConditions.$inject = ["$rootScope", "$scope", "$q", "$location", "AuthService", "SessionService", "UserListService"];