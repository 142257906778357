import _ from "lodash";

export class HospitalAdminCylinderAlarmReport {

    constructor($rootScope, ReportService, locations, gettextCatalog) {
        $rootScope.navbarType = 'HOSPITALADMIN';

        this.ReportService = ReportService;
        this.setAlarms({}, this);
        this.locations = locations;
        this.locations.unshift({name: gettextCatalog.getString('Hospital'), id: null});
        this.selectedLocation = _.first(this.locations);
        this.timeIntervals = this.ReportService.reportPeriods;
        this.timeInterval = _.find(this.timeIntervals, interval => interval.key === ReportService.defaultInterval().key);
        this.reportName = '';

        this.getReport();
    }

    findAlarmTypes(report) {
        const alarmTypes = [];
        _.forEach(report, alarms =>
            _.forEach(alarms, (count, type) => {
                if (!_.includes(alarmTypes, type)) {
                    alarmTypes.push(type);
                }
            }));
        return alarmTypes;
    }

    refresh() {
        this.getReport();
    }

    setAlarms(alarms, context) {
        context.cylinderAlarm = alarms;
        context.alarmTypes = context.findAlarmTypes(context.cylinderAlarm);
    }

    getLocationReport() {
        const context = this;
        // Note that this is not using the cached reports
        // However until something gets built into the UI that allows locations
        // to be selected this isn't being hit
        this.ReportService
            .locationCylinderAlarm(this.selectedLocation.id, this.timeInterval.from, this.timeInterval.to)
            .then(a => this.setAlarms(a, context));
    }

    getHospitalReport() {
        const context = this;
        this.ReportService
            .hospitalCylinderAlarm(this.timeInterval.key)
            .then(a => this.setAlarms(a, context));
    }

    getReport() {
        this.reportName = this.selectedLocation.name;
        if (this.selectedLocation.id) {
            this.getLocationReport();
        } else {
            this.getHospitalReport();
        }
    };
}

HospitalAdminCylinderAlarmReport.$inject = ["$rootScope", "ReportService", "locations", "gettextCatalog"];