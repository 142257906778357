export function EventLogService($rootScope, $q, Logs, LoopBackAuth) {
    this.rootScope = $rootScope;
    this.Logs = Logs;
    this.$q = $q;
    this.LoopBackAuth = LoopBackAuth;
}

EventLogService.prototype.listOrArchivedFiles = function () {
    var self = this;
    return this.Logs.archivedEventLogList().$promise.then(function (filenames) {
        return _.map(filenames, function (filename) {
            return {name: filename, token: self.LoopBackAuth.accessTokenId};
        });
    });
};

EventLogService.prototype.getArchivedFile = function (filename) {
    return this.Logs.getArchivedEventLogFile(filename).$promise;
};

EventLogService.$inject = ["$rootScope", "$q", "Logs", "LoopBackAuth"];