import {REPORTS_MODULE} from "../../../../services/reports/reports.module";
import {COLOURMAP_MODULE} from "../../../../services/colourmap/colourmap.module";
import {SHARED_MODULE} from "../../../../shared/shared.module";

import {HospitalAdminButtonsResponsesReport, ReportButtonResponses} from "./report-buttons-responses";
import {HospitalAdminButtonsStatsReport, ReportButtonStats} from "./report-buttons-stats";
import {ReportButtonsStatsLocationCtrl} from "../../../../directives/liv-iq-report-buttons-stats-location.controller";
import {LivIqReportButtonStatsLocation} from "../../../../directives/liv-iq-report-buttons-stats-location.directive";

export const HOSPITAL_ADMIN_REPORTS_BUTTONS_MODULE = 'hospital.admin.reports.buttons';

angular.module(HOSPITAL_ADMIN_REPORTS_BUTTONS_MODULE, [REPORTS_MODULE, COLOURMAP_MODULE, SHARED_MODULE]);

angular.module(HOSPITAL_ADMIN_REPORTS_BUTTONS_MODULE)
    .controller('HospitalAdminButtonsResponsesReport', HospitalAdminButtonsResponsesReport)
    .controller('HospitalAdminButtonsStatsReport', HospitalAdminButtonsStatsReport)
    .controller('livIqReportButtonsStatsLocation', ReportButtonsStatsLocationCtrl);

angular.module(HOSPITAL_ADMIN_REPORTS_BUTTONS_MODULE)
    .directive('reportButtonsResponses', ReportButtonResponses)
    .directive('reportButtonStats', ReportButtonStats)
    .directive('livIqReportButtonsStatsLocation', LivIqReportButtonStatsLocation);