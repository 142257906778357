import {PORTER_DASHBOARD_MODULE} from "./dashboard/dashboard.module";
import {PORTER_HOSPITAL_ACTION_REQUEST_MODULE} from "./hospitalActionRequest/hospitalActionRequest.module";
import {PORTER_ORDER_HISTORY_MODULE} from "./orderHistory/orderHistory.module";

import {porterConfig} from "./config.route";

export const PORTER_MODULE = 'porter';

angular.module(PORTER_MODULE, [PORTER_HOSPITAL_ACTION_REQUEST_MODULE, PORTER_DASHBOARD_MODULE, PORTER_ORDER_HISTORY_MODULE]);

angular.module(PORTER_MODULE)
    .config(porterConfig);