export function layoutRouting($routeProvider, $logProvider) {
    $routeProvider.otherwise({
        redirectTo: '/signin'
    });

    /* @if debug != true */
    $logProvider.debugEnabled(true);
    /* @endif */
}

layoutRouting.$inject = ['$routeProvider', '$logProvider'];