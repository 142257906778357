import lbServices from '../../../api-client/js/services/lb-services.js';

import {Roles} from "./Roles";
import {RoleListService} from "./RoleListService";

export const ROLES_MODULE = 'services.roles';

angular.module(ROLES_MODULE, [lbServices]);

angular.module(ROLES_MODULE)
    .constant('Roles', Roles);

angular.module(ROLES_MODULE)
    .service('RoleListService', RoleListService);