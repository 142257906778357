import {stAutoRefresh} from "./st-auto-refresh";

export function stPersist($location) {
    function getNamespace(namespace) {
        return namespace === 'auto' ? 'stPersist' + $location.url() : namespace;
    }

    return {
        require: '^stTable',
        link: function (scope, element, attr, ctrl) {
            var namespace = getNamespace(attr.stPersist);

            // save the table state every time it changes
            scope.$watch(function () {
                return ctrl.tableState();
            }, function (newValue, oldValue) {
                if (newValue !== oldValue) {
                    localStorage.setItem(namespace, JSON.stringify(newValue));
                }
            }, true);

            // fetch the table state when the directive is loaded
            if (localStorage.getItem(namespace)) {
                var savedState = JSON.parse(localStorage.getItem(namespace));
                var tableState = ctrl.tableState();

                angular.extend(tableState, savedState);
                ctrl.pipe();
            }
        }
    };
}

stPersist.$inject = ["$location"];