import {CylinderListService} from "./CylinderListService";
import {CylinderOperatingModes} from "./CylinderOperationalModes";
import {CylinderTypeService} from "./CylinderTypeService";
import {CylinderTypeListService} from "./cylinderTypesList.service";
import {CylinderUsageService} from "./CylinderUsageService";
import {GasListService} from "./gasList.service";
import {SizeListService} from "./sizeList.service";
import {ValveListService} from "./valveList.service";

export const CYLINDERS_MODULE = 'services.cylinders';

angular.module(CYLINDERS_MODULE, []);

angular.module(CYLINDERS_MODULE)
    .constant('CylinderOperatingModes', CylinderOperatingModes);

angular.module(CYLINDERS_MODULE)
    .service('CylinderListService', CylinderListService)
    .service('CylinderTypeService', CylinderTypeService)
    .service('CylinderTypeListService', CylinderTypeListService)
    .service('CylinderUsageService', CylinderUsageService)
    .service('GasListService', GasListService)
    .service('SizeListService', SizeListService)
    .service('ValveListService', ValveListService);
