import * as smartTable from 'angular-smart-table';
import {CYLINDERS_MODULE} from "../../../services/cylinders/cylinders.module";

import {HospitalAdminLostCylinders} from "./lost-cylinders";

export const HOSPITAL_ADMIN_LOST_CYLINDERS_MODULE = 'hospital.admin.lostCylinders';

angular.module(HOSPITAL_ADMIN_LOST_CYLINDERS_MODULE, [CYLINDERS_MODULE, smartTable]);

angular.module(HOSPITAL_ADMIN_LOST_CYLINDERS_MODULE)
    .controller('HospitalAdminLostCylinders', HospitalAdminLostCylinders);