import {AuditLogsService} from "./auditLogs.service";
import {AuditLogEntities} from "./AuditLogEntities";

export const AUDITLOGS_MODULE = 'services.auditLogs';

angular.module(AUDITLOGS_MODULE, []);

angular.module(AUDITLOGS_MODULE)
    .service('AuditLogsService', AuditLogsService);

angular.module(AUDITLOGS_MODULE)
    .constant('AuditLogEntities', AuditLogEntities);