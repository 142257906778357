export function ButtonsListService($rootScope, FlatHwButton, ButtonsFeatureService, ListHelpersService) {
    var self = this;

    function getHospital() {
        return $rootScope.appReady.promise;
    }

    function getBaseFilter(hospital) {
        return {
            filter: {
                where: {
                    hospitalId: hospital ? hospital.id : null
                }
            }
        };
    }

    function applyDefaultOrdering(filter) {
        ListHelpersService.applyDefaultOrdering(filter, [
            {property: 'location'},
            {property: 'buttonTaskType'}
        ]);
    }

    self.getPage = function (applyFilteringAndPagination) {
        if (!ButtonsFeatureService.isEnabled()) {
            return ButtonsFeatureService.getEmptyPageResults();
        }

        return getHospital()
            .then(getBaseFilter)
            .then(function (filter) {
                applyFilteringAndPagination(filter.filter);
                applyDefaultOrdering(filter.filter);
                return FlatHwButton.getPage(filter).$promise;
            })
            .then(function (data) {
                return {
                    items: _.map(data.items, function (hwButton) {
                        return new FlatHwButton(hwButton);
                    }),
                    totalCount: data.totalCount
                };
            });
    };

    self.getButtonsWithLowBattery = function () {
        if (!ButtonsFeatureService.isEnabled()) {
            return ButtonsFeatureService.getEmptyResults();
        }

        return getHospital()
            .then(getBaseFilter)
            .then(function (filter) {
                filter.filter.where.battLow = true;
                applyDefaultOrdering(filter.filter);
                return FlatHwButton.find(filter).$promise;
            })
            .then(function (data) {
                return _.map(data, function (hwButton) {
                    return new FlatHwButton(hwButton);
                });
            });
    };

    self.count = function () {
        return getHospital()
            .then(getBaseFilter)
            .then(function (filter) {
                return FlatHwButton.count(filter.filter).$promise;
            })
            .then(function (result) {
                return result.count;
            });
    };
}

ButtonsListService.$inject = ["$rootScope", "FlatHwButton", "ButtonsFeatureService", "ListHelpersService"];