import {nurseConfig} from "../nurse/config.route";

const routeBase = 'hospital/porter';

export function porterConfig($routeProvider) {
    $routeProvider.when('/' + routeBase + '/accessDisabled', {
        template: require('../../shared/accessDisabled/accessDisabled.tpl.html'),
        controller: 'AccessDisabled',
        controllerAs: 'accessDisabledCtrl',
        resolve: {
            disabledFor: ['Roles', function (Roles) {
                return Roles.PORTER;
            }],
        }
    }).when('/' + routeBase + '/hospitalActionRequest', {
        template: require('./hospitalActionRequest/hospitalActionRequest.tpl.html'),
        controller: 'ActionRequest',
        controllerAs: 'actionRequestCtrl',
        resolve: {
            faultyCylinderSummary: ['CylinderListService', function (CylinderListService) {
                return CylinderListService.faultyCylinderSummary();
            }],
            porterHospitalActionRequest: ['ReportService', function (ReportService) {
                return ReportService.porterHospitalActionRequest();
            }],
            regenHospitalActionRequest: ['ReportService', function (ReportService) {
                return ReportService.porterHospitalActionRequest();
            }]
        }
    }).when('/' + routeBase + '/dashboard', {
        template: require('./dashboard/dashboard.tpl.html'),
        controller: 'PorterDashboard',
        controllerAs: 'porterDashboardCtrl'
    }).when('/' + routeBase + '/orderHistory', {
        template: require('./orderHistory/orderHistory.tpl.html'),
        controller: 'PorterOrderHistory',
        controllerAs: 'orderHistoryCtrl'
    }).when('/' + routeBase + '/settings', {
        template: require('./settings/settings.tpl.html'),
        controller: 'PorterSettings',
        controllerAs: 'porterSettingsCtrl',
        resolve: {
            locationList: ['LocationListService', function (LocationListService) {
                return LocationListService.findAllWithCylinderSize();
            }]
        }
    }).when('/' + routeBase + '/map', {
        templateUrl: routeBase + '/webgl/webgl.tpl.html',  // TODO - this template doesn't exist!!
        controller: 'WebGlController',
        controllerAs: 'webglCtrl',
        resolve: {
            locationsWithHubsAndCylinders: ['LocationListService', function (LocationListService) {
                return LocationListService.findWithCylindersAndHubs();
            }]
        }
    });
}

porterConfig.$inject = ["$routeProvider"];
