import moment from "moment";

export function stSearchDate($provide) {
  $provide.decorator('stSearchDirective', stSearchDirective);

  function stSearchDirective($delegate, gettextCatalog, LocaleService, formatForDateRangePickerInput) {
    var directive = $delegate[0];

    var link = directive.link;

    var defaultOptions = {
      autoApply: false,
      autoUpdateInput: false,
      locale: null,
      ranges: null
    };

    directive.compile = function () {
      return function ($scope, element, attrs, ctrl) {
        var isDateRange = element[0].attributes.type && element[0].attributes.type.nodeValue === 'daterange';

        addScopeEventListeners();

        if (isDateRange) {
          updateView();
        } else {
          link.apply(this, arguments);
        }

        function addScopeEventListeners() {
          $scope.$on(LocaleService.EVENT_LOCALE_CHANGED, updateView);
        }

        function updateView() {
          translateDefaultOptions();
          setupDateRangePicker(attrs.stSearch);
        }

        function setupDateRangePicker(predicate) {
          element.daterangepicker(defaultOptions, function (start, end/* , label*/) {
            element.val(formatForDateRangePickerInput(start, end));
            ctrl.search({between: [start, end]}, predicate);
          });

          element.on('cancel.daterangepicker', function (/* ev, picker*/) {
            ctrl.search('', predicate);
            element.val('');
          });
        }
      };
    };

    function translateDefaultOptions() {
      defaultOptions.locale = {
        applyLabel: gettextCatalog.getString('Apply'),
        cancelLabel: gettextCatalog.getString('Clear'),
        weekLabel: gettextCatalog.getString('W', null, 'One character abbreviation for week, used in the bootstrap-daterangepicker-component'),
        customRangeLabel: gettextCatalog.getString('Custom Range')
      };

      var ranges = {};
      ranges[gettextCatalog.getString('Today')] = [
        moment().startOf('day'),
        moment().endOf('day')
      ];

      ranges[gettextCatalog.getString('Yesterday')] = [
        moment().startOf('day').subtract(1, 'day'),
        moment().endOf('day').subtract(1, 'day')
      ];

      ranges[gettextCatalog.getString('Last 7 Days')] = [
        moment().startOf('day').subtract(1, 'week'),
        moment().endOf('day')
      ];

      ranges[gettextCatalog.getString('Last 30 Days')] = [
        moment().startOf('day').subtract(30, 'days'),
        moment().endOf('day')
      ];

      ranges[gettextCatalog.getString('This Month')] = [
        moment().startOf('month'),
        moment().endOf('month')
      ];

      ranges[gettextCatalog.getString('Last Month')] = [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month')
      ];

      defaultOptions.ranges = ranges;
    }

    return $delegate;
  }

  stSearchDirective.$inject = ["$delegate", "gettextCatalog", "LocaleService", "formatForDateRangePickerInput"];
}

stSearchDate.$inject = ['$provide'];