import * as moment from "moment";

const LABELS_LOCALIZED = 'ReportService.LABELS_LOCALIZED';

export function ReportService($rootScope, $q, StockHistory, FlatCylinder, FlatCylinderHistory, FlatCylinderOrder, LocationReport, DashboardReport, HospitalReport, ButtonsReport, gettextCatalog, LocaleService, DATE_FORMAT_FOR_DATE_RANGE_PICKER_INPUT) {
    this.LABELS_LOCALIZED = LABELS_LOCALIZED;
    this.rootScope = $rootScope;
    this.q = $q;
    this.StockHistory = StockHistory;
    this.FlatCylinder = FlatCylinder;
    this.FlatCylinderHistory = FlatCylinderHistory;
    this.FlatCylinderOrder = FlatCylinderOrder;
    this.LocationReport = LocationReport;
    this.DashboardReport = DashboardReport;
    this.HospitalReport = HospitalReport;
    this.ButtonsReport = ButtonsReport;
    this.gettextCatalog = gettextCatalog;
    this.LocaleService = LocaleService;

    this.defaultTimeOptions = {
        format: DATE_FORMAT_FOR_DATE_RANGE_PICKER_INPUT,
        autoApply: false,
        autoUpdateInput: false,
        locale: null,
        ranges: null
    };

    this._addScopeEventListeners();
    this._updateDefaultTimeOptionLabels();
    this._updateReportPeriodLabels();
}

ReportService.prototype._addScopeEventListeners = function () {
    this.rootScope.$on(this.LocaleService.EVENT_LOCALE_CHANGED, (function () {
        this._updateDefaultTimeOptionLabels();
        this._updateReportPeriodLabels();
        this.rootScope.$broadcast(LABELS_LOCALIZED);
    }).bind(this));
};

/**
 * TODO: These needs to be invoked when the language selection is changed
 */
ReportService.prototype._updateDefaultTimeOptionLabels = function () {
    var gettextCatalog = this.gettextCatalog;

    this.defaultTimeOptions.locale = {
        applyLabel: gettextCatalog.getString('Apply'),
        cancelLabel: gettextCatalog.getString('Clear'),
        weekLabel: gettextCatalog.getString('W', null, 'One character abbreviation for week, used in the bootstrap-daterangepicker-component'),
        customRangeLabel: gettextCatalog.getString('Custom Range')
    };

    var ranges = {};
    ranges[gettextCatalog.getString('Last Hour')] = [
        moment(),
        moment().subtract(1, 'hour')
    ];
    ranges[gettextCatalog.getString('Last 6 Hours')] = [
        moment(),
        moment().subtract(6, 'hour')
    ];
    ranges[gettextCatalog.getString('Today')] = [
        moment().startOf('day'),
        moment().endOf('day')
    ];
    ranges[gettextCatalog.getString('Yesterday')] = [
        moment().startOf('day').subtract(1, 'day'),
        moment().endOf('day').subtract(1, 'day')
    ];
    ranges[gettextCatalog.getString('Last 7 Days')] = [
        moment().startOf('day').subtract(1, 'week'),
        moment().endOf('day')
    ];
    ranges[gettextCatalog.getString('Last 30 Days')] = [
        moment().startOf('day').subtract(30, 'days'),
        moment().endOf('day')
    ];
    ranges[gettextCatalog.getString('This Month')] = [
        moment().startOf('month'),
        moment().endOf('month')
    ];
    ranges[gettextCatalog.getString('Last Month')] = [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month')
    ];
    this.defaultTimeOptions.ranges = ranges;
};

/**
 * TODO: These needs to be invoked when the language selection is changed
 */
ReportService.prototype._updateReportPeriodLabels = function () {
    var gettextCatalog = this.gettextCatalog;
    this.reportPeriods = [
        {
            name: gettextCatalog.getString('Yesterday'),
            id: '1',
            key: 'yesterday',
            text: gettextCatalog.getString('A day'),
            duration: moment.duration(1, 'day')
        },
        {
            name: gettextCatalog.getString('Last 7 Days'),
            id: '2',
            key: 'last7Days',
            text: gettextCatalog.getString('7 days'),
            duration: moment.duration(7, 'days')
        },
        {
            name: gettextCatalog.getString('Last 30 Days'),
            id: '3',
            key: 'last30Days',
            text: gettextCatalog.getString('30 days'),
            duration: moment.duration(30, 'days')
        },
        {
            name: gettextCatalog.getString('Last 3 Months'),
            id: '4',
            key: 'last3Months',
            text: gettextCatalog.getString('3 months'),
            duration: moment.duration(3, 'months')
        },
        {
            name: gettextCatalog.getString('Last 12 Months'),
            id: '5',
            key: 'last12Months',
            text: gettextCatalog.getString('12 months'),
            duration: moment.duration(1, 'year')
        },
        {
            name: gettextCatalog.getString('Last 24 Months'),
            id: '6',
            key: 'last24Months',
            text: gettextCatalog.getString('24 months'),
            duration: moment.duration(2, 'years')
        }
    ];
};

ReportService.prototype.defaultInterval = function () {
    return {
        from: moment().subtract(30, 'days').startOf('day').toDate(),
        to: moment().subtract(1, 'days').endOf('day').toDate(),
        key: 'last30Days'
    };
};

ReportService.prototype.defaultActionRequest = function () {
    var timeInterval = this.defaultInterval();

    return this.hospitalActionRequest(timeInterval.from, timeInterval.to);
};

ReportService.prototype.defaultEmptyPressureReport = function () {
    var timeInterval = this.defaultInterval();

    return this.cylinderAverageReturnPressure(timeInterval.from, timeInterval.to);
};

ReportService.prototype.defaultEmptyToReturnReport = function () {
    var timeInterval = this.defaultInterval();

    return this.cylinderEmptyToReturnInHospital(timeInterval.from, timeInterval.to);
};

ReportService.prototype.defaultEmptyReplacementTimeReport = function () {
    var timeInterval = this.defaultInterval();

    return this.cylinderEmptyReplacementTimeInHospital(timeInterval.from, timeInterval.to);
};

ReportService.prototype.defaultOxygenUsageReport = function () {
    var timeInterval = this.defaultInterval();
    return this.hospitalOxygenUsage(timeInterval.from, timeInterval.to);
};

ReportService.prototype.defaultHospitalReport = function () {
    return this.cachedHospitalReport('oxygenUsagePeriod');
};

ReportService.prototype.defaultCylinderFirstUseReport = function () {
    var timeInterval = this.defaultInterval();

    return this.hospitalCylinderFirstUse(timeInterval.from, timeInterval.to);
};

ReportService.prototype.defaultCylinderTreatmentReport = function () {
    var timeInterval = this.defaultInterval();

    return this.hospitalCylinderTreatment(timeInterval.from, timeInterval.to);
};

ReportService.prototype.hospitalActionRequest = function (from, to) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.StockHistory.hospitalSummary({
            hospitalId: hospital.id,
            from: from,
            to: to
        }).$promise;
    });
};


ReportService.prototype.locationActionRequest = function (locationId, from, to) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.StockHistory.locationSummary({
            hospitalId: hospital.id,
            locationId: locationId,
            from: from,
            to: to
        }).$promise;
    });
};

ReportService.prototype.cylinderDwellTimeReport = function (from, to) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.FlatCylinder.dwellTimeSummary({
            hospitalId: hospital.id,
            from: from,
            to: to
        }).$promise;
    });
};

ReportService.prototype.cylinderAverageReturnPressure = function (from, to) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.FlatCylinder.averageReturnPressure({
            hospitalId: hospital.id,
            from: from,
            to: to
        }).$promise;
    });
};

ReportService.prototype.cylinderAverageEmptyPressureAtLocation = function (locationId, from, to) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.FlatCylinder.averageEmptyPressureAtLocation({
            hospitalId: hospital.id,
            locationId: locationId,
            from: from,
            to: to
        }).$promise;
    });
};

ReportService.prototype.cylinderEmptyToReturnInHospital = function (from, to) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.FlatCylinder.emptyToReturnInHospital({
            hospitalId: hospital.id,
            from: from,
            to: to
        }).$promise;
    });
};

ReportService.prototype.cylinderEmptyToReturnFromLocation = function (locationId, from, to) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.FlatCylinder.emptyToReturnFromLocation({
            hospitalId: hospital.id,
            locationId: locationId,
            from: from,
            to: to
        }).$promise;
    });
};

ReportService.prototype.cylinderEmptyReplacementTimeInHospital = function (from, to) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.FlatCylinderOrder.emptyCylinderReplacementTimeInHospital({
            hospitalId: hospital.id,
            from: from,
            to: to
        }).$promise;
    });
};

ReportService.prototype.cylinderEmptyReplacementTimeAtLocation = function (locationId, from, to) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.FlatCylinderOrder.emptyCylinderReplacementTimeAtLocation({
            hospitalId: hospital.id,
            locationId: locationId,
            from: from,
            to: to
        }).$promise;
    });
};

ReportService.prototype.hospitalOxygenUsage = function (from, to) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.FlatCylinder.hospitalOxygenUsage({
            hospitalId: hospital.id,
            from: from,
            to: to
        }).$promise;
    });
};

ReportService.prototype.hospitalCylinderFirstUse = function (from, to) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.FlatCylinder.hospitalCylinderFirstUsed({
            hospitalId: hospital.id,
            from: from,
            to: to
        }).$promise;
    });
};

ReportService.prototype.locationCylinderFirstUse = function (locationId, from, to) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.FlatCylinder.locationCylinderFirstUsed({
            hospitalId: hospital.id,
            locationId: locationId,
            from: from,
            to: to
        }).$promise;
    });
};

function processCylinderAlarmReport(rawCylinderAlarms) {
    var processed = {};
    _.forEach(rawCylinderAlarms, function (rawAlarm) {
        if (!processed[rawAlarm.location]) {
            processed[rawAlarm.location] = {};
        }
        processed[rawAlarm.location][rawAlarm.alarmtype] = rawAlarm.count;
    });
    return processed;
}

ReportService.prototype.hospitalCylinderAlarm = function (timePeriodKey) {
    var self = this;

    return this.cachedHospitalReport(timePeriodKey)
        .then(function (hospitalReports) {
            return hospitalReports.cylinderAlarmsReport || [];
        })
        .then(processCylinderAlarmReport);
};

ReportService.prototype.locationCylinderAlarm = function (locationId, from, to) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.FlatCylinder.locationCylinderAlarm({
            hospitalId: hospital.id,
            locationId: locationId,
            from: from,
            to: to
        }).$promise
            .then(processCylinderAlarmReport);
    });
};

ReportService.prototype.hospitalCylinderTreatment = function (from, to) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.FlatCylinder.hospitalCylinderTreatment({
            hospitalId: hospital.id,
            from: from,
            to: to
        }).$promise;
    });
};

ReportService.prototype.locationCylinderTreatment = function (locationId, from, to) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.FlatCylinder.locationCylinderTreatment({
            hospitalId: hospital.id,
            locationId: locationId,
            from: from,
            to: to
        }).$promise;
    });
};

ReportService.prototype.cachedLocationReport = function (timePeriodKey, locationId) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var reportFn = self.LocationReport[timePeriodKey];
        return reportFn({hospitalId: hospital.id, locationId: locationId}).$promise;
    });
};

ReportService.prototype.defaultCachedDashboardHospitalReport = function () {
    return this.cachedDashboardHospitalReport('last30Days');
};

ReportService.prototype.defaultCachedHospitalReport = function () {
    return this.cachedHospitalReport('last30Days');
};

ReportService.prototype.porterHospitalActionRequest = function () {
    return this.cachedHospitalReport('hospitalActionRequest');
};

ReportService.prototype.cachedDashboardHospitalReport = function (timePeriodKey) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var reportFn = self.DashboardReport[timePeriodKey];
        return reportFn({hospitalId: hospital.id}).$promise;
    });
};

ReportService.prototype.cachedHospitalReport = function (timePeriodKey) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var reportFn = self.HospitalReport[timePeriodKey];
        return reportFn({hospitalId: hospital.id}).$promise;
    });
};

ReportService.prototype.buttonStatsReport = function (timePeriodKey, type) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var reportFn = self.ButtonsReport[timePeriodKey];
        return reportFn({hospitalId: hospital.id, type: type}).$promise;
    });
};

ReportService.prototype.cylinderFillPlantReport = function () {
    var self = this;
    return self.FlatCylinderHistory.fillPlantReport().$promise;
};

ReportService.$inject = ["$rootScope", "$q", "StockHistory", "FlatCylinder", "FlatCylinderHistory", "FlatCylinderOrder", "LocationReport", "DashboardReport", "HospitalReport", "ButtonsReport", "gettextCatalog", "LocaleService", "DATE_FORMAT_FOR_DATE_RANGE_PICKER_INPUT"];