import * as _ from "lodash";

export function MainController($timeout, $rootScope, $location, $localStorage, $http, $window, $interval, $scope, SessionService,
                        AuthService, LocaleService, HospitalListService, Roles, ButtonsFeatureService,
                        UserListService, NotificationsService) {
    var self = this;
    self.timeout = $timeout;
    self.interval = $interval;
    self.rootScope = $rootScope;
    self.scope = $scope;
    self.AuthService = AuthService;
    self.SessionService = SessionService;
    self.Roles = Roles;
    self.location = $location;
    self.localStorage = $localStorage;
    self.http = $http;
    self.window = $window;
    self.LocaleService = LocaleService;
    self.HospitalListService = HospitalListService;
    self.UserListService = UserListService;
    self.isProd = false;
    self.ButtonsFeatureService = ButtonsFeatureService;
    self.NotificationsService = NotificationsService;
    self.rootScope.currentLang = {id: 'en_GB', name: 'English (United Kingdom)'};
    self.scope.userRequestPending = false;

    self.scope.showJiraTicket = function (e) {
        $window.showJiraTicket(e, self.localStorage.user.username, self.localStorage.user.email);
    };

    $http.get('/version.json?t=' + Date.now())
        .then(function (result) {
            self.rootScope.version = result.data.npm_version;
            self.rootScope.build_date = result.data.datestring;
        })
        .catch(function (err) {
            console.debug('unable to retrieve version', err.status, err.statusText);
        });

    self.HospitalListService.getPlatform()
        .then(function (platform) {
            self.isProd = platform.env === 'prod';
            return self.http.get('json/lang.json');
        })
        .catch(function (error) {
            console.debug('Error getting platform:', error.status, error.statusText);
        });

    SessionService.restoreSession();
    self.navbarCollapsed = true;

    self.checkPendingUserRequests();

    function pathNeedsLogin(path) {
        return !_.includes(['/recoverPassword', '/activateUser', '/singin', '/cookiePolicy'], path);
    }

    $rootScope.$on('$routeChangeStart', function (event, next, current) {
        var newPath = next ? next.originalPath : undefined;
        if (!pathNeedsLogin(newPath)) {
            redirect(newPath, self);
            return;
        }
        if (!SessionService.isValid()) {
            // Want to redirect the user back to where they were trying to get to once they have logged in again
            return SessionService.logout(newPath);
        }
        self.navbarCollapsed = true;

        if (newPath && redirectIfPathIsNotAllowedForNonLindeAdmins(newPath, self)) {
            return;
        }

        if (newPath && enterHospitalForPath(newPath, event.preventDefault, self)) {
            return;
        }

        if (newPath && $rootScope.hospital &&
            redirectIfPathIsNotAllowedByFeatures(newPath, $rootScope.hospital.features, event.preventDefault, self)) {
            return;
        }

        if (self.shouldAcceptTCs()) {
            self.location.path('/terms');
            return;
        } else if (self.rootScope.currentUser.forceResetPassword) {
            self.location.path('/forceChangePassword');
            return;
        }

        if (newPath && (self.isLindeAdminPath(newPath) || self.isHospitalPath(newPath))) {
            // Once we have finally arrived at our destination we can clear any query parameters
            self.location.search({});
        }
    });

    $rootScope.$on('$routeChangeSuccess', function (event) {
        $window.ga('send', 'pageview', {page: $location.path()});
    });

    $rootScope.$on('$viewContentLoaded', function () {
        adjustContentPadding();
    });

    $rootScope.$on('$includeContentLoaded', function () {
        adjustContentPadding();
    });

    $(window)
        .resize(function () {
            adjustContentPadding();
        });

    $(window)
        .load(function () {
            adjustContentPadding();
        });
}

MainController.prototype.shouldAcceptTCs = function () {
    var user = this.rootScope.currentUser;

    return user && (user.acceptedTCsOn * 1) === 0;
};

function stringEndsWith(str, suffix) {
    return str.substr(-suffix.length) === suffix;
}

function redirect(path, self) {
    self.timeout(function () {
        self.location.path(path);
    }, 0);
}

function redirectToSignin(self) {
    redirect('/signin', self);
}

MainController.prototype.isLindeAdminPath = function (path) {
    return _.startsWith(path, '/admin') || _.startsWith(path, '/superadmin');
};

MainController.prototype.isHospitalPath = function (path) {
    return _.startsWith(path, '/hospital');
};

function redirectIfPathIsNotAllowedForNonLindeAdmins(path, self) {
    if (self.isLindeAdminPath(path) && !self.SessionService.isCurrentRoleALindeAdminRole()) {
        redirectToSignin(self);
        return true;
    }

    return false;
}

function redirectIfPathIsNotAllowedByFeatures(path, features, preventDefault, self) {
    function redirectToNurseAccessDisabled() {
        redirect('/hospital/nurse/accessDisabled', self);
    }

    function redirectToPorterAccessDisabled() {
        redirect('/hospital/porter/accessDisabled', self);
    }

    function AccessControlByPathAndFeature(path, featureName, action) {
        this.path = path;
        this.feature = featureName;
        this.action = action;
    }

    var accessControlByFeature = [
        new AccessControlByPathAndFeature('/porter/dashboard', 'porterRole', redirectToPorterAccessDisabled),
        new AccessControlByPathAndFeature('/porter/orderHistory', 'porterRole', redirectToPorterAccessDisabled),
        new AccessControlByPathAndFeature('/porter/settings', 'porterRole', redirectToPorterAccessDisabled),

        new AccessControlByPathAndFeature('/nurse/dashboard', 'nurseRole', redirectToNurseAccessDisabled),
        new AccessControlByPathAndFeature('/nurse/orderHistory', 'nurseRole', redirectToNurseAccessDisabled),
        new AccessControlByPathAndFeature('/nurse/settings', 'nurseRole', redirectToNurseAccessDisabled),

        new AccessControlByPathAndFeature('/porter/orderHistory', 'porterOrderHistory', redirectToSignin),
        new AccessControlByPathAndFeature('/nurse/orderHistory', 'nurseOrderHistory', redirectToSignin),
        new AccessControlByPathAndFeature('/porter/map', '3DMap', redirectToSignin),

        new AccessControlByPathAndFeature('/admin/buttons/list', 'buttons', redirectToSignin),
        new AccessControlByPathAndFeature('/admin/buttons/tasks', 'buttons', redirectToSignin),
        new AccessControlByPathAndFeature('/admin/button/:mac/history', 'buttons', redirectToSignin)
    ];

    for (var i = 0; i < accessControlByFeature.length; i++) {
        if (stringEndsWith(path, accessControlByFeature[i].path) && !features[accessControlByFeature[i].feature]) {
            preventDefault();
            accessControlByFeature[i].action(self);
            return true;
        }
    }

    return false;
}

function enterHospitalForPath(newPath, preventDefault, self) {
    if (!self.SessionService.isCurrentRoleALindeAdminRole()) {
        // Only fiddle with the user's hospital Id if they aren't tied to a hospital
        return;
    }

    if (self.isHospitalPath(newPath)) {
        var pathHospitalId = self.location.search().hospitalid;

        // If the desired hospital is already set in the query string override the user's current hospital if different
        // Otherwise, only if the user doesn't already have a hospital set, set it based on either what
        // is in the query string or the first hospital we find
        var shouldSetHospital = (pathHospitalId && pathHospitalId !== self.rootScope.currentUser.hospitalId + '') ||
            !self.rootScope.currentUser.hospitalId;
        if (shouldSetHospital) {

            // Stop propagation of the event so we don't end up navigating
            // before we have found the hospital
            preventDefault();

            var getHospital;
            if (pathHospitalId) {
                getHospital = self.HospitalListService.findById(pathHospitalId, ['configParameters', 'features']);
            } else {
                getHospital = self.HospitalListService.findAny(['configParameters', 'features']);
            }

            return getHospital
                .then(function (hospital) {
                    self.SessionService.enterHospital(hospital);
                })
                .then(function () {
                    // Now we can navigate where we want to
                    self.timeout(function () {
                        self.location.path(newPath);
                    }, 0);
                });
        }
    } else if (self.isLindeAdminPath(newPath)) {
        self.SessionService.enterHospital(null);
    }
}

function adjustContentPadding() {
    // In the event where the nav-bar wraps around to multiple lines we don't want
    // it to overlap the main content
    var navbarHeight = parseInt($('#main-navbar-menu .navbar-nav')
        .css('height'));
    if (!isNaN(navbarHeight)) {
        $('.main-content')
            .css('padding-top', Math.max(0, navbarHeight - 35));
    }
}

MainController.prototype.isPorterRoleEnabled = function () {
    return this.rootScope.hospital === null || this.rootScope.hospital.features.porterRole;
};

MainController.prototype.isNurseRoleEnabled = function () {
    return this.rootScope.hospital === null || this.rootScope.hospital.features.nurseRole;
};

MainController.prototype.isNurseOrderHistoryEnabled = function () {
    return (this.rootScope.hospital === null || this.rootScope.hospital.features.nurseOrderHistory);
};

MainController.prototype.isPorterOrderHistoryEnabled = function () {
    return (this.rootScope.hospital === null || this.rootScope.hospital.features.porterOrderHistory);
};

MainController.prototype.isPorterHospitalActionRequestGenerationEnabled = function () {
    return this.rootScope.hospital === null || this.rootScope.hospital.features.actionRequestGeneration;
};

MainController.prototype.isHospitalAdminDashboardEnabled = function () {
    return (this.rootScope.hospital === null || this.rootScope.hospital.features.dashboard) && this.areCylindersEnabled();
};

MainController.prototype.isHospitalAdminButtonDashboardEnabled = function () {
    return (this.rootScope.hospital === null || this.rootScope.hospital.features.dashboard) && !this.areCylindersEnabled();
};

MainController.prototype.areReportsEnabled = function () {
    return this.rootScope.hospital === null || this.rootScope.hospital.features.reports;
};

MainController.prototype.is3DMapEnabled = function () {
    return this.rootScope.hospital === null || this.rootScope.hospital.features['3DMap'];
};

MainController.prototype.areButtonsEnabled = function () {
    return this.ButtonsFeatureService.isEnabled();
};

MainController.prototype.areEmailNotificationsEnabled = function () {
    return this.NotificationsService.areAnyNotificationsEnabled();
};

MainController.prototype.areCylindersEnabled = function () {
    return this.rootScope.hospital === null || this.rootScope.hospital.features.cylinder;
};

MainController.prototype.getNavbarTemplateUrl = function () {
    var navbarTemplateMappping = {
        SUPERADMIN: 'superadmin',
        ADMIN: 'admin',
        HOSPITALADMIN: 'hospitaladmin',
        PORTER: 'porter',
        NURSE: 'nurse'
    };
    var template = navbarTemplateMappping[this.rootScope.navbarType] || 'empty';
    return 'layout/navbar/' + template + '.tpl-lazy.html';
};

MainController.prototype.isActiveMenuItem = function (menuItemRoute) {
    var currentRoute = this.location.path();
    if (Array.isArray(menuItemRoute)) {
        return _.some(menuItemRoute, function (item) {
            return item === currentRoute;
        });
    }
    return currentRoute === menuItemRoute;
};

MainController.prototype.isActiveAdmin = function () {
    return this.SessionService.isCurrentRoleAnAdminRole() && !this.shouldAcceptTCs();
};

MainController.prototype.showChangeUser = function () {
    // Only bother to show the change user menu if there is another type of user to switch to
    return this.isActiveAdmin() && (this.isPorterRoleEnabled() || this.isNurseRoleEnabled());
};

MainController.prototype.isActiveLindeAdmin = function () {
    return this.SessionService.isCurrentRoleALindeAdminRole() && !this.shouldAcceptTCs();
};

MainController.prototype.isUserLoggedIn = function () {
    return (!(!this.rootScope.currentUser));
};

MainController.prototype.logout = function () {
    var vm = this;
    vm.SessionService.logout();
};

MainController.prototype.backToAdmin = function () {
    this.SessionService.enterHospital(null);
};

MainController.prototype.setLocale = function (locale) {
    var self = this;
    self.rootScope.currentLang = _.find(self.rootScope.languages, function (lang) {
        return lang.id === locale;
    });
    self.LocaleService.persistAndApplyLocale(locale)
        .then(function () {
            self.window.location.reload(); // TODO: Looks very weird, needs to be addressed later
        })
        .catch(function (err) {
            console.log('Error setting locale', locale, err);
        });
};

MainController.prototype.checkPendingUserRequests = function () {
    var self = this;
    if (self.rootScope && self.rootScope.currentUser && self.rootScope.hospital && self.SessionService.isCurrentRoleAnAdminRole()) {
        self.UserListService.userRequestPending(self.rootScope.hospital.id)
            .then(function (response) {
                self.scope.userRequestPending = response.requestCount !== 0;
            });
    }
};

MainController.prototype.checkIfActionRequestEnabled = function () {
    var self = this;
    return self.NotificationsService.areHospitalActionRequestGenerationNotificationsEnabled() && self.areCylindersEnabled();
};

MainController.prototype.shouldShowBackToAdmin = function () {
    var self = this;
    var isLindeAdmin = !!self.localStorage.role && self.localStorage.role.name === 'LindeAdministrator';
    var isAdminPage = self.location.path() === '/admin/hospitals';
    return isLindeAdmin && !isAdminPage;
};

MainController.$inject = ['$timeout', '$rootScope', '$location', '$localStorage', '$http', '$window', '$interval', '$scope', 'SessionService',
    'AuthService', 'LocaleService', 'HospitalListService', 'Roles', 'ButtonsFeatureService',
    'UserListService', 'NotificationsService'];
