export function ConfigParametersDirective() {
    return {
        restrict: 'E',
        scope: {
            configParameters: '=',
            readOnly: '=',
            showHospitalFields: '='
        },
        controller: 'livIqConfigParameters',
        template: require('./liv-iq-config-parameters.tpl.html')
    };
}