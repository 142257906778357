import moment from 'moment';

export function setupDateRangePickerBySelectorFactory($window, ReportService, formatForDateRangePickerInput) {
    function setupDateRangePickerBySelector(querySelector, timeInterval, refresh) {
        var daterangepicker = angular.element($window.document).find(querySelector);
        daterangepicker.val(formatForDateRangePickerInput(
            moment(timeInterval.from),
            moment(timeInterval.to)
        ));
        daterangepicker.daterangepicker(ReportService.defaultTimeOptions, function (start, end) {
            daterangepicker.val(formatForDateRangePickerInput(start, end));
            timeInterval.from = start.toDate();
            timeInterval.to = end.toDate();
            refresh();
        });
        daterangepicker.on('cancel.daterangepicker', function (/* ev, picker*/) {
            daterangepicker.val('');
        });
    }

    return setupDateRangePickerBySelector;
}

setupDateRangePickerBySelectorFactory.$inject = ["$window", "ReportService", "formatForDateRangePickerInput"];
