export function HospitalAdminButtonsTasksList($rootScope, ButtonsTasksListService, buttonsTasksRefreshIntervalMillis, SmartTableServerAdapter, SessionService, toastr, gettextCatalog) {

    if (!SessionService.isCurrentRoleAnAdminRole()) {
        return;
    }
    $rootScope.navbarType = 'HOSPITALADMIN';

    var self = this;
    self.rootScope = $rootScope;

    self.selectedTask = {};

    self.buttonsTasksRefreshIntervalMillis = buttonsTasksRefreshIntervalMillis;

    self.buttonsTasksGrid = new SmartTableServerAdapter(ButtonsTasksListService);

    self.showPaginationControl = function () {
        return self.buttonsTasksGrid.totalItems > 5;
    };

    self.select = function (task) {
        if (task) {
            beginEdit(task, task);
        } else {
            reset();
        }
    };

    self.current = function () {
        return self.selectedTask.editing;
    };

    self.save = function () {
        if (!self.isEditing()) return;

        if (!anyEditsMade()) return;

        var errorMsg = validateSelectedTask();
        if (!errorMsg.length) {
            // Only save if the item has actually changed
            ButtonsTasksListService.save(self.selectedTask.editing)
                .then(function () {
                    refresh();
                    toastr.success(
                        gettextCatalog.getString('Task has been saved'),
                        gettextCatalog.getString('Saved')
                    );
                })
                .catch(function (err) {
                    console.error(err);
                    alert(gettextCatalog.getString(err.data.error.code));
                });
            reset();
        } else {
            alert(errorMsg);
        }
    };

    self.delete = function () {
        if (isSelectionAnExistingTask()) {
            ButtonsTasksListService.delete(self.selectedTask.editing)
                .then(function () {
                    refresh();
                    alert(
                        gettextCatalog.getString('Task has been deleted'),
                        gettextCatalog.getString('Deleted'));
                })
                .catch(function (err) {
                    console.error(err);
                    alert(gettextCatalog.getString(err.data.error.code));
                });
        }

        reset();
    };

    self.add = function () {
        beginEdit(undefined, {hospitalId: $rootScope.hospital.id});
    };

    self.isEditing = function () {
        return self.selectedTask.editing !== undefined;
    };

    function beginEdit(original, editing) {
        self.selectedTask = {
            original: original,
            editing: ButtonsTasksListService.makeEditableCopy(editing)
        };
    }

    function isSelectionAnExistingTask() {
        return self.selectedTask.original !== undefined;
    }

    function anyEditsMade() {
        if (!self.isEditing()) return false;

        if (isSelectionAnExistingTask()) {
            return self.selectedTask.editing.name !== self.selectedTask.original.name ||
                self.selectedTask.editing.shortname !== self.selectedTask.original.shortname;
        } else {
            return true;
        }
    }

    function refresh() {
        self.buttonsTasksGrid.refresh();
    }

    function reset() {
        self.selectedTask = {};
    }

    function validateSelectedTask() {
        var errorMessage = '';

        if (!self.selectedTask.editing.name) {
            errorMessage += gettextCatalog.getString('Please enter a name.') + '</br>';
        }

        if (!self.selectedTask.editing.shortname) {
            errorMessage += gettextCatalog.getString('Please enter a short name.') + '</br>';
        }

        return errorMessage;
    }

    function alert(message, title) {
        toastr.error(
            message,
            title ? title : gettextCatalog.getString('Error'),
            {allowHtml: true}
        );
    }

    reset();
}

HospitalAdminButtonsTasksList.prototype.areCylindersEnabled = function () {
    return this.rootScope.hospital === null || this.rootScope.hospital.features.cylinder;
};

HospitalAdminButtonsTasksList.$inject = ["$rootScope", "ButtonsTasksListService", "buttonsTasksRefreshIntervalMillis", "SmartTableServerAdapter", "SessionService", "toastr", "gettextCatalog"];