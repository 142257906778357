export function ButtonsFeatureService($q, $rootScope) {
    this.isEnabled = function () {
        return $rootScope.hospital === null || $rootScope.hospital.features.buttons;
    };

    this.getEmptyPageResults = function () {
        return $q(function (resolve) {
            resolve({
                items: [],
                totalCount: 0
            });
        });
    };

    this.getEmptyResults = function () {
        return $q(function (resolve) {
            resolve([]);
        });
    };
}

ButtonsFeatureService.$inject = ['$q', '$rootScope'];