export function LivIqButtonTypeSelector() {
    return {
        restrict: 'E',
        scope: {
            onSelect: '&'
        },
        template: require('./liv-iq-button-type-selector.tpl.html'),
        controller: 'livIqButtonTypeSelector',
        controllerAs: 'ctrl',
        bindToController: true
    };
}