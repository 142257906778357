export function StockListService($rootScope, $q, Stock, FlatCylinderActionRequest, FlatStock) {
    this.rootScope = $rootScope;
    this.Q = $q;
    this.Stock = Stock;
    this.FlatCylinderActionRequest = FlatCylinderActionRequest;
    this.FlatStock = FlatStock;
}

StockListService.prototype.getFlatCylinderActionRequestByLocation = function (locationId) {
    var self = this;

    return this.rootScope.appReady.promise.then(function (hospital) {
        if (locationId) {
            return self.FlatCylinderActionRequest.find({
                filter: {
                    where: {
                        locationId: locationId,
                        hospitalId: hospital.id
                    }
                }
            });
        }
        return self.FlatCylinderActionRequest.find({
            filter: {
                where: {
                    hospitalId: hospital.id
                }
            }
        });
    });
};

StockListService.prototype.stockHospitalDashboardReport = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.FlatStock.find({
            filter: {
                where: {
                    hospitalId: hospital.id
                }
            }
        }).$promise
            .then(function (hospitalStocks) {
                var cylinderTypeNames = [];
                var report = {
                    locationsForCylinderCount: [],
                    locationsForOxygenLeft: [],
                    cylinderTypes: [],
                    countByCylinderType: {},
                    oxygenUsageByLocation: [],
                    emptyCylinderCount: 0,
                    locationTypes: [],
                    locationTypesSortedByCylinderCount: {},
                    locationTypesSortedByOxygenLeft: {},
                    countAtLocationByLocationTypeAndCylinderType: {},
                    oxygenLeftAtLocationByLocationType: {}
                };

                var unsortedLocation = [];
                var unsortedLocationTypes = {};

                _.forEach(hospitalStocks, function (stock) {
                    var locationsOfType = unsortedLocationTypes[stock.locationType] || [];
                    if (!_.find(unsortedLocation, function (location) {
                        return location.name === stock.location;
                    })) {
                        unsortedLocation.push({
                            name: stock.location,
                            cylinderCount: Number(stock.totalAtLocation),
                            oxygenLeft: stock.oxygenLeftAtLocation
                        });
                        locationsOfType.push({
                            name: stock.location,
                            cylinderCount: Number(stock.totalAtLocation),
                            oxygenLeft: stock.oxygenLeftAtLocation
                        });
                    }
                    unsortedLocationTypes[stock.locationType] = locationsOfType;
                });

                _.forEach(unsortedLocationTypes, function (unsortedLocationType, index) {
                    report.locationTypesSortedByCylinderCount[index] = _.sortBy(_.cloneDeep(unsortedLocationType), 'cylinderCount').reverse().slice(0, 12);
                });

                _.forEach(unsortedLocationTypes, function (unsortedLocationType, index) {
                    report.locationTypesSortedByOxygenLeft[index] = _.sortBy(_.cloneDeep(unsortedLocationType), 'oxygenLeft').reverse().slice(0, 12);
                });

                report.locationTypes = _.map(_.keys(unsortedLocationTypes).sort(), function (locationName) {
                    return {name: locationName};
                });

                report.locationsForCylinderCount = _.sortBy(_.cloneDeep(unsortedLocation), 'cylinderCount').reverse().slice(0, 12);
                report.locationsForOxygenLeft = _.sortBy(_.cloneDeep(unsortedLocation), 'oxygenLeft').reverse().slice(0, 12);

                _.forEach(hospitalStocks, function (stock) {
                    report.emptyCylinderCount += Number(stock.empty);
                    if (!_.includes(cylinderTypeNames, stock.cylinderType)) {
                        cylinderTypeNames.push(stock.cylinderType);
                        report.cylinderTypes.push({name: stock.cylinderType});
                    }
                    var countByCylinderType = report.countAtLocationByLocationTypeAndCylinderType[stock.locationType] || {};
                    if (!countByCylinderType[stock.cylinderType]) {
                        countByCylinderType[stock.cylinderType] = [];
                    }
                    var locationsForCylinderCount = report.locationTypesSortedByCylinderCount[stock.locationType];
                    var locationForCylinderCountIndex = _.findIndex(locationsForCylinderCount, function (location) {
                        return location.name === stock.location;
                    });
                    if (locationForCylinderCountIndex >= 0) {
                        countByCylinderType[stock.cylinderType][locationForCylinderCountIndex] = Number(stock.current) - Number(stock.empty);
                    }
                    report.countAtLocationByLocationTypeAndCylinderType[stock.locationType] = countByCylinderType;

                    var oxygenUsageByLocation = report.oxygenLeftAtLocationByLocationType[stock.locationType] || [];
                    var locationForOxygenUsageIndex = _.findIndex(report.locationTypesSortedByOxygenLeft[stock.locationType], function (location) {
                        return location.name === stock.location;
                    });
                    if (locationForOxygenUsageIndex >= 0) {
                        if (!oxygenUsageByLocation[locationForOxygenUsageIndex]) {
                            oxygenUsageByLocation[locationForOxygenUsageIndex] = 0;
                        }
                        oxygenUsageByLocation[locationForOxygenUsageIndex] += stock.fullOxygenLeft + stock.partialOxygenLeft + stock.emptyOxygenLeft;
                    }
                    report.oxygenLeftAtLocationByLocationType[stock.locationType] = oxygenUsageByLocation;
                });

                _.forEach(hospitalStocks, function (stock) {
                    if (!report.countByCylinderType[stock.cylinderType]) {
                        report.countByCylinderType[stock.cylinderType] = [];
                    }
                    var locationForCylinderCountIndex = _.findIndex(report.locationsForCylinderCount, function (location) {
                        return location.name === stock.location;
                    });
                    var locationForOxygenUsageIndex = _.findIndex(report.locationsForOxygenLeft, function (location) {
                        return location.name === stock.location;
                    });
                    if (locationForCylinderCountIndex >= 0) {
                        report.countByCylinderType[stock.cylinderType][locationForCylinderCountIndex] = Number(stock.current) - Number(stock.empty);
                    }
                    if (locationForOxygenUsageIndex >= 0) {
                        if (!report.oxygenUsageByLocation[locationForOxygenUsageIndex]) {
                            report.oxygenUsageByLocation[locationForOxygenUsageIndex] = 0;
                        }
                        report.oxygenUsageByLocation[locationForOxygenUsageIndex] += stock.fullOxygenLeft + stock.partialOxygenLeft + stock.emptyOxygenLeft;
                    }
                });
                return report;
            });
    });
};

StockListService.prototype.save = function (stocks) {
    function saveStockSettings(stock) {
        console.log("Entered 'saveStockSettings' function. 'stock' is:")
        console.log(stock);
        stock.deleted = 0;
        console.log("'stock' has become:")
        console.log(stock);
        return stock.$save();
    }

    if (stocks) {
        if (_.isArray(stocks)) {
            console.log("'stocks' are an array");
            console.log(stocks);
            return this.Q.all(_.map(stocks, saveStockSettings));
        }
        return saveStockSettings(stocks);
    }
    console.log("'stocks' not defined");
    return false;
};

StockListService.prototype.add = function (stock) {
    var newStock = new this.Stock(stock);
    // We don't have to wait for appReady because the "add" button appears after the app is ready
    return newStock;
};

StockListService.$inject = ["$rootScope", "$q", "Stock", "FlatCylinderActionRequest", "FlatStock"];