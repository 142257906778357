const jQuerySliderConfig = {
    start: function (event, ui) {
    },
    stop: function (event, ui) {
    },
    min: 0,
    max: 15,
    step: 1
};

export function livIqConfigParameters($rootScope, $scope, gettextCatalog) {
    $scope.CYLINDER_GAUGE_DESCRIPTION = gettextCatalog.getString('Move slider left or right to set when the platform recognises a cylinder as empty. ' +
        'Default setting is 25% full to match the first content alert on LIV IQ. Remember changing this setting does not ' +
        'affect the alarm levels on LIV IQ.');

    $scope.CYLINDER_USABLE_GAUGE_DESCRIPTION = gettextCatalog.getString('Move slider left or right to set when the platform recognises a cylinder as full with respect to stock levels and ordering. ' +
        'Default setting is 100% full. This setting needs to be greater than the empty level.');

    $scope.CYLINDER_DETECTION_TIME_WINDOW_DESCRIPTION = gettextCatalog.getString('This is the time in minutes before a cylinder arrives in a location and ' +
        'is displayed by the platform. Recommendation is to have a minimum 11 minutes (2 LIV IQ advertisements) to avoid cylinders jumping to different locations ' +
        'while in transit - either being moved by a porter or accompanying a patient.');

    $scope.LOST_CYLINDER_DETECTION_TIME_WINDOW_DESCRIPTION = gettextCatalog.getString('This is the time in minutes before a cylinder disappears from a location. ' +
        'It is recommended to set this time to 16 minutes (3 LIV IQ advertisements) to avoid cylinders disappearing when an advertisement gets blocked or missed by a ' +
        'LIV IQ Hub.');

    $scope.FROM_STORE_TIME_WINDOW_DESCRIPTION = gettextCatalog.getString('This is the time window in minutes for cylinder coming from the central or departmental store ' +
        'to a delivery location. If the cylinder arrives at a delivery location within this time window of leaving the store, then it is considered to be from the store to ' +
        'fulfill an order. It is recommended to set this at least 120 minutes to allow the porter moving through other delivery locations. If a cylinder is delivered ' +
        'from the cylinder store after this time then it will not complete an outstanding order.');

    $scope.DWELL_TIME_FOR_ORDER_DESCRIPTION = gettextCatalog.getString('Time in minutes for a cylinder to be stationary at a location before raising an empty pick up ' +
        'request or confirming a delivery. It is recommended to set this to 16 minutes (3 LIV IQ advertisements) to ensure cylinders in transit do not raise or complete orders.');

    $scope.ALLOW_NON_STORE_CYLINDERS_TO_FULFILL_ORDERS_DESCRIPTION = gettextCatalog.getString('If enabled then any sufficiently full cylinder can fulfill an order. ' +
        'If disabled then only cylinders that have passed through a Central Store may fulfill orders.');

    $scope.AUTO_ACTION_REQUEST_GENERATION_TIME_OF_DAY_DESCRIPTION = gettextCatalog.getString('Set the hour of the day (0-23) to auto generate action request at that hour.');

    $scope.emptySlider = {
        options: jQuerySliderConfig,
        value: $scope.configParameters.emptyLevelForFillPressure
    };

    $scope.fullSlider = {
        options: jQuerySliderConfig,
        value: $scope.configParameters.usableFullLevelForFillPressure
    };

    $scope.timesInMinutes = _.clone({
        cylinderDetectionTimeWindow: $scope.configParameters.cylinderDetectionTimeWindow / 60000,
        lostCylinderDetectionTimeWindow: $scope.configParameters.lostCylinderDetectionTimeWindow / 60000,
        fromStoreTimeWindow: $scope.configParameters.fromStoreTimeWindow / 60000,
        cylinderDwellTimeForOrder: $scope.configParameters.cylinderDwellTimeForOrder / 60000
    });

    $scope.showFromStoreTimeWindow = function () {
        return $scope.showHospitalFields || !$scope.configParameters.allowFulfillOrdersFromNonStore;
    };

    $scope.readOnlyFromStoreTimeWindow = function () {
        // The 'true' clause is because we are temporarily disabling the effect of the fromStoreTimeWindow setting
        return $scope.readOnly || $scope.configParameters.allowFulfillOrdersFromNonStore || true;
    };

    $scope.times = 24;

    $scope.getTimes = function (times) {
        return new Array(times);
    };

    $scope.setHoursOfDayLabel = function (time) {
        return time === null || typeof time === 'undefined' ?
            'Set time' :
            $scope.configureIntToTime(time);
    };

    $scope.configureIntToTime = function (integer) {
        if (integer === null || typeof integer === 'undefined') {
            return '';
        } else if (integer === 0) {
            return '00:00 (default)';
        } else if (integer < 10) {
            return '0' + integer + ':00';
        } else {
            return integer + ':00';
        }
    };

    $scope.setReportGenerationTime = function (time) {
        $scope.configParameters.actionRequestGenerationHour = time;
    };

    $scope.areCylindersEnabled = function () {
        return $rootScope.hospital === null || $rootScope.hospital.features.cylinder;
    };

    function watchTimeSetting(settingName) {
        $scope.$watch(function () {
            return $scope.timesInMinutes[settingName];
        }, function (newValue, oldValue) {
            if (newValue !== oldValue && typeof (newValue) !== 'undefined') {
                $scope.configParameters[settingName] = newValue * 60000;
            }
        }, true);
    }

    function watchSliderValue(slider, settingName) {
        $scope.$watch(function () {
            return slider.value;
        }, function (newValue, oldValue) {
            if (newValue !== oldValue) {
                $scope.configParameters[settingName] = newValue;
            }
        }, true);
    }

    function watchConfigParamIntValue(settingName) {
        $scope.$watch($scope.configParameters[settingName], function (newValue, oldValue) {
            if (newValue !== oldValue && typeof (newValue) !== 'undefined') {
                $scope.configParameters[settingName] = Math.floor(Math.abs(newValue));
            }
        }, true);
    }

    watchTimeSetting('cylinderDetectionTimeWindow');
    watchTimeSetting('lostCylinderDetectionTimeWindow');
    watchTimeSetting('fromStoreTimeWindow');
    watchTimeSetting('cylinderDwellTimeForOrder');
    watchConfigParamIntValue('actionRequestGenerationHour');
    watchSliderValue($scope.emptySlider, 'emptyLevelForFillPressure');
    watchSliderValue($scope.fullSlider, 'usableFullLevelForFillPressure');
}

livIqConfigParameters.$inject = ['$rootScope', '$scope', 'gettextCatalog'];