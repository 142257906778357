export function firstJourneyConfig($routeProvider) {
    $routeProvider.when('/forceChangePassword', {
        template: require('./forceChangePassword/forceChangePassword.tpl.html'),
        controller: 'ForceChangePassword',
        controllerAs: 'forceChangePasswordCtrl'
    }).when('/terms', {
        template: require('./termsAndConditions/termsAndConditions.tpl.html'),
        controller: 'TermsAndConditions',
        controllerAs: 'termsAndConditionsCtrl'
    });
}

firstJourneyConfig.$inject = ['$routeProvider'];