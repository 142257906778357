export function ValveListService($rootScope, $q, Valve) {
    this.rootScope = $rootScope;
    this.Valve = Valve;
    this.q = $q;
}

ValveListService.prototype.addValve = function (valve) {
    var newValve = new this.Valve(valve);
    // We don't have to wait for appReady because the "add" button appears after the app is ready
    newValve.hospitalId = this.rootScope.hospital.id;
    return newValve;
};

ValveListService.prototype.findAll = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        // console.log ('ValveListService.findAll (); hospital:', hospital);
        return self.Valve.find({
            filter: {
                where: {
                    hospitalId: hospital.id,
                    deleted: 0
                }
            }
        }).$promise;
    });
};

ValveListService.prototype.saveValve = function (valve) {
    if (valve) {
        valve.deleted = 0;
        return valve.$save();
    }
    return this.q.when(false);
};

ValveListService.prototype.del = function (valve) {
    if (valve) {
        valve.deleted = new Date().getTime();
        return valve.$save();
    }
    return this.q.when(false);
};

ValveListService.$inject = ["$rootScope", "$q", "Valve"];