export function HospitalAdminHubs($rootScope, $routeParams, $scope, $timeout, cylinderRefreshIntervalMillis, SessionService, HubListService, HubConnectivityListService, locations, SmartTableServerAdapter, RefreshService, toastr, gettextCatalog) {
    if (!SessionService.isCurrentRoleAnAdminRole()) {
        return;
    }
    $rootScope.navbarType = 'HOSPITALADMIN';
    this.$routeParams = $routeParams;
    this.cylinderRefreshIntervalMillis = cylinderRefreshIntervalMillis;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.scope = $scope;
    this.scope.readOnly = !(_.includes($rootScope.currentRole.name, 'Service') || _.includes($rootScope.currentRole.name, 'Linde'));
    this.timeout = $timeout;
    this.HubListService = HubListService;
    this.HubConnectivityListService = HubConnectivityListService;
    this.RefreshService = RefreshService;
    this.allLocations = locations; // needed by
    var self = this;

    this.hubGrid = new SmartTableServerAdapter(HubListService, function () {
        if (self.$routeParams.location) {
            return {
                location: self.$routeParams.location
            };
        }
    });
    this.reset();

    function refresh() {
        return self.hubGrid.refresh();
    }

    this.RefreshService.start(this.cylinderRefreshIntervalMillis, refresh);
    $scope.$on('$destroy', function () {
        self.RefreshService.stop();
    });
}

HospitalAdminHubs.prototype.reset = function () {
    this.scope.initialFocus = false;
    this.selection = null;
};

HospitalAdminHubs.prototype.openEditPane = function (focusOnFieldId, existingHub) {
    var self = this;
    self.scope.initialFocus = false;
    self.existingHub = existingHub;
    self.HubListService.findById(self.existingHub.id).then(function (hub) {
        self.scope.initialFocus = focusOnFieldId;
        self.selection = self.HubListService.add(hub);
    });
};

HospitalAdminHubs.prototype.select = function (hub) {
    if (hub) {
        this.openEditPane('inputHubName', hub);
    } else {
        this.reset();
    }
};

HospitalAdminHubs.prototype.current = function () {
    return this.selection;
};

HospitalAdminHubs.prototype.missedStatForPeriod = function (hub, periodInHours) {
    return _.find(hub.missedStat, {period: periodInHours});
};

HospitalAdminHubs.prototype.refreshHubs = function () {
    var self = this;
    var hubs;
    return self.hubGrid.refresh();
};

HospitalAdminHubs.prototype.save = function () {
    var self = this;
    var errorMsg = this.validateHub();
    if (!errorMsg.length) {
        if (self.selection) {
            if (self.selection !== self.existingHub) {
                // Only save if the item has actually changed
                self.HubListService.save(self.selection)
                    .then(function () {
                        self.toastr.success(self.gettextCatalog.getString('IQ Hub has been saved'), self.gettextCatalog.getString('Saved'));

                        self.refreshHubs();
                    });
            }
        }
        self.reset();
    } else {
        window.alert(errorMsg);
    }
};

HospitalAdminHubs.prototype.del = function () {
    var self = this;
    if (self.selection) {
        if (self.existingHub) {
            self.HubListService.del(self.existingHub).then(function () {
                self.refreshHubs();
                self.toastr.error(self.gettextCatalog.getString('IQ Hub has been deleted'), self.gettextCatalog.getString('Deleted'), {timeOut: 0});
            });
        }
    }
    self.reset();
};

HospitalAdminHubs.prototype.validateHub = function () {
    var self = this;
    var errorMsg = '';

    if (!self.selection.macAddress) {
        errorMsg += self.gettextCatalog.getString('Please enter a valid MAC address') + '\r\n';
    }
    if (!self.selection.name) {
        errorMsg += self.gettextCatalog.getString('Please enter a valid IQ Hub name') + '\r\n';
    }
    if (!self.selection.location) {
        errorMsg += self.gettextCatalog.getString('Please select a location') + '\r\n';
    }

    if (errorMsg.length > 0) {
        return errorMsg;
    }
    return true;
};

HospitalAdminHubs.$inject = ["$rootScope", "$routeParams", "$scope", "$timeout", "cylinderRefreshIntervalMillis", "SessionService", "HubListService", "HubConnectivityListService", "locations", "SmartTableServerAdapter", "RefreshService", "toastr", "gettextCatalog"];