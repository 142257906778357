export function HospitalAdminBillSplit($rootScope, $scope, $routeParams, CountryService, DashboardReportService, gettextCatalog) {
    $rootScope.navbarType = 'HOSPITALADMIN';
    this.scope = $scope;
    this.routeParams = $routeParams;
    this.DashboardReportService = DashboardReportService;
    this.CountryService = CountryService;
    this.gettextCatalog = gettextCatalog;
    this.billSplitReport = [];
    this.billingCurrency = undefined;
    var self = this;

    self.CountryService.currency($rootScope.hospital.countryCode)
        .then(function (currency) {
            self.billingCurrency = currency;
        });

    this.timeInterval = this.DashboardReportService.getTimePeriodForKey(this.routeParams.interval ? this.routeParams.interval : 'last30Days');
    this.totalBill = this.routeParams.totalBill ? this.routeParams.totalBill : 0;

    this.DashboardReportService.dashboardReport(this.timeInterval.key)
        .then(function (dashboardReport) {
            if (dashboardReport && dashboardReport.cylinderUsedReport) {
                self.billSplitReport = _.sortBy(_.flatten(_.map(dashboardReport.cylinderUsedReport, function (locationTypeRawReports) {
                    var locationReports = [];
                    _.forEach(locationTypeRawReports.locations, function (location, index) {
                        locationReports.push({
                            location: location,
                            percentage: Number((locationTypeRawReports.percentage[index]).toFixed(2)),
                            bill: Number((locationTypeRawReports.percentage[index] / 100 * self.totalBill).toFixed(2))
                        });
                    });
                    return locationReports;
                })), 'bill').reverse();
            }
        });
}

HospitalAdminBillSplit.$inject = ["$rootScope", "$scope", "$routeParams", "CountryService", "DashboardReportService", "gettextCatalog"];