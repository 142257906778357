import Chart from 'chart.js';
import moment from 'moment';

export function HospitalAdminDashboard($rootScope, $scope, $timeout, $interval, $location, SessionService, ColourMapService, StockListService, CylinderListService, DashboardReportService, CountryService, FillPressurePlanService, ButtonsFeatureService, cylinderRefreshIntervalMillis, stockHospitalDashboardReport, untrackedCylinderCount, totalCylinderCount, locationSummary, dashboardReport, displaySettings, toastr, gettextCatalog, gettext, LocationTypes, CommonTranslations, trackedEmpty, trackedPartial, trackedFull, untrackedUnderThree, untrackedOverThree, untrackedInTransit) {
    if (!SessionService.isCurrentRoleAnAdminRole()) {
        return;
    }
    $rootScope.navbarType = 'HOSPITALADMIN';

    var self = this;
    self.gettextCatalog = gettextCatalog;
    self.gettext = gettext;
    self.cylinderRefreshIntervalMillis = cylinderRefreshIntervalMillis;
    self.ColourMapService = ColourMapService;
    self.StockListService = StockListService;
    self.CylinderListService = CylinderListService;
    self.DashboardReportService = DashboardReportService;
    self.CountryService = CountryService;
    self.FillPressurePlanService = FillPressurePlanService;
    self.LocationTypes = LocationTypes;
    self.CommonTranslations = CommonTranslations;
    self.stockHospitalDashboardReport = stockHospitalDashboardReport;
    self.untrackedCylinderCount = untrackedCylinderCount;
    self.locationSummary = locationSummary;
    self.toastr = toastr;
    self.scope = $scope;
    self.timeout = $timeout;
    self.interval = $interval;
    self.location = $location;
    self.emptyCount = stockHospitalDashboardReport.emptyCylinderCount;
    self.untrackedCount = untrackedCylinderCount.count;
    self.dashboardReport = dashboardReport;
    self.displaySettings = displaySettings;
    self.billingCurrency = undefined;
    self.CountryService.currency($rootScope.hospital.countryCode)
        .then(function (currency) {
            self.billingCurrency = currency;
        });

    self.totalCylinderCount = totalCylinderCount;
    self.totalCylinders = totalCylinderCount.count;

    self.trackedEmpty = trackedEmpty;
    self.trackedEmptyCount = trackedEmpty.count;
    self.trackedPartial = trackedPartial;
    self.trackedPartialCount = trackedPartial.count;
    self.trackedFull = trackedFull;
    self.trackedFullCount = trackedFull.count;
    self.untrackedUnderThree = untrackedUnderThree;
    self.untrackedUnderThreeCount = untrackedUnderThree.count;
    self.untrackedOverThree = untrackedOverThree;
    self.untrackedOverThreeCount = untrackedOverThree.count;
    self.untrackedInTransit = untrackedInTransit;
    self.untrackedInTransitCount = untrackedInTransit.count;

    self.totalTracked = self.trackedEmptyCount + self.trackedPartialCount + self.trackedFullCount;
    self.totalUntracked = self.untrackedUnderThreeCount + self.untrackedOverThreeCount;
    self.totalCylinders = self.totalTracked + self.totalUntracked;

    self.liveReports = [
        {id: 'select', name: self.gettextCatalog.getString('--Select--')},
        // { id: 'emptyCylinder', name: self.gettextCatalog.getString('Empty Cylinder') },
        {id: 'totalCylinder', name: self.gettextCatalog.getString('Total Cylinders')},
        {id: 'trackedCylinder', name: self.gettextCatalog.getString('Tracked Cylinders')},
        {id: 'untrackedCylinder', name: self.gettextCatalog.getString('Untracked Cylinders')},
        {id: 'stockByLocation', name: self.gettextCatalog.getString('Stock By Location')},
        {id: 'o2StorageByLocation', name: self.gettextCatalog.getString('Oxygen Storage By Location')}
    ];

    self.statisticsReports = [
        {id: 'select', name: self.gettextCatalog.getString('--Select--')},
        {id: 'o2Usage', name: self.gettextCatalog.getString('Oxygen Usage')},
        {id: 'avgReturnPressure', name: self.gettextCatalog.getString('Avg. Return Pressure')},
        {id: 'avgCollectionTime', name: self.gettextCatalog.getString('Avg. Collection Time')},
        {id: 'avgTimeBeforeFirstUser', name: self.gettextCatalog.getString('Avg. Time Before First User')},
        {id: 'noTreatmentByLocation', name: self.gettextCatalog.getString('No. Treatment By Location')},
        {id: 'billSplitByLocation', name: self.gettextCatalog.getString('Cylinder Usage (%) by Location')},
        {id: 'alarm', name: self.gettextCatalog.getString('Alarm')},
        {id: 'avgStockHistory', name: self.gettextCatalog.getString('Avg. Stock History')}
    ];

    if (ButtonsFeatureService.isEnabled()) {
        self.statisticsReports.push({
            id: 'buttonStats',
            name: self.gettextCatalog.getString('REFRESH Button Statistics')
        });
    }
    self.showButtonStats = function () {
        return ButtonsFeatureService.isEnabled() && self.displaySettings.buttonStats;
    };

    self.scope.panelsSelection = {id: 'select', name: self.gettextCatalog.getString('--Select--')};

    self.scope.updateWithTick = function (option) {
        if (option.id === 'select') {
            return option.name;
        }
        return (self.displaySettings[option.id] ? '✓ ' : '– ') + option.name;
    };

    self.scope.closePanel = function (panelId) {
        self.displaySettings[panelId] = false;
        var update = JSON.parse('{"' + panelId + '": ' + self.displaySettings[panelId] + '}');
        self.DashboardReportService.setDisplaySettings(update)
            .then(function () {
                return self.DashboardReportService.getDisplaySettings();
            })
            .then(function (updatedSettings) {
                self.displaySettings = updatedSettings;
            });
    };

    self.togglePanelOption = function () {
        var option = self.scope.panelsSelection.id;

        if (self.displaySettings[option] !== undefined) {
            self.displaySettings[option] = !self.displaySettings[option];
            var update = JSON.parse('{"' + option + '": ' + self.displaySettings[option] + '}');
            self.DashboardReportService.setDisplaySettings(update)
                .then(function () {
                    return self.DashboardReportService.getDisplaySettings();
                })
                .then(function (updatedSettings) {
                    self.displaySettings = updatedSettings;
                    overrideDisplaySettingsForEmptyReports(option);
                    self.scope.panelsSelection = {id: 'select', name: self.gettextCatalog.getString('--Select--')};
                });
        }
    };

    function overrideDisplaySettingsForEmptyReports(toggledOption) {
        function overrideCore(reportForOption, optionToOverride) {
            // Don't fudge the display setting if the user has explicitly changed the setting
            if (toggledOption === optionToOverride) return;

            if (!reportForOption || (!reportForOption.current && !reportForOption.previous)) {
                self.displaySettings[optionToOverride] = false;
            }
        }

        overrideCore(self.averageEmptyPressure, 'avgReturnPressure');
        overrideCore(self.averageCollectionTime, 'avgCollectionTime');
        overrideCore(self.averageTimeBeforeFirstUse, 'avgTimeBeforeFirstUser');
    }

    self.averageEmptyPressure = dashboardReport.avgReturnPressureReport;
    self.averageCollectionTime = dashboardReport.avgCollectionTimeReport;
    self.averageTimeBeforeFirstUse = dashboardReport.avgTimeBeforeFirstUseReport;
    overrideDisplaySettingsForEmptyReports();

    self.oxygenUsage = dashboardReport.oxygenUsage;
    self.alarms = dashboardReport.alarmReport;
    self.cylinderUsage = dashboardReport.cylinderUsage;
    if (self.alarms) {
        self.totalAlarmCount = _.reduce(self.alarms.alarmCountByType, function (total, alarm) {
            return total + Number(alarm.count);
        }, 0);
    }
    self._addScopeEventListeners();
    self._setTimeIntervals();
    self.scope.timeInterval = {
        name: self.gettextCatalog.getString('Last 30 Days'),
        id: '3',
        key: 'last30Days',
        text: self.gettextCatalog.getString('30 days')
    };

    self.collectionTimeUnit = {
        current: self.gettext('mins'),
        previous: 'mins'
    };
    self.firstUsedTimeUnit = {
        current: 'mins',
        previous: 'mins'
    };

    $(document).ready(function () {
        self._setupTooltips();
    });

    var cylinderTypes = self.stockHospitalDashboardReport.cylinderTypes;
    cylinderTypes = ColourMapService.paint(cylinderTypes, 0.8, 1);

    // Live Displays

    self.locationTypes = self.stockHospitalDashboardReport.locationTypes;
    self.scope.selectedCylinderCountLocationType = _.find(self.stockHospitalDashboardReport.locationTypes, function (locationType) {
        return locationType === self.LocationTypes.STANDARD_LOCATION;
    }) || self.stockHospitalDashboardReport.locationTypes[0];

    var locationForCylinderCount = [];
    if (self.scope.selectedCylinderCountLocationType) {
        locationForCylinderCount = _.map(self.stockHospitalDashboardReport.locationTypesSortedByCylinderCount[self.scope.selectedCylinderCountLocationType.name], function (location) {
            return location.name;
        });
    }

    var colourMapForLocations = ColourMapService.oneColour(0.8, 1);

    // Stock By Location Bar Chart
    var stockLocBarCTX = document.getElementById('stock-loc-bar');
    var stockLocBarData = {
        labels: locationForCylinderCount
    };

    stockLocBarData.datasets = _.map(cylinderTypes, function (cylinderType) {
        var count = (
            self.scope.selectedCylinderCountLocationType && cylinderType &&
            self.stockHospitalDashboardReport.countAtLocationByLocationTypeAndCylinderType[self.scope.selectedCylinderCountLocationType.name][cylinderType.name]
        ) || [];
        return {
            label: cylinderType.name,
            backgroundColor: cylinderType.colour.light,
            borderColor: 'rgb(255,255,255)',
            borderWidth: 1,
            hoverBackgroundColor: cylinderType.colour.medium,
            hoverBorderColor: 'rgb(255,255,255)',
            data: count
        };
    });
    self.stockLocBarChart = new Chart(stockLocBarCTX, {
        type: 'bar',
        data: stockLocBarData,
        options: {
            legend: {
                position: 'bottom'
            },
            scales: {
                xAxes: [{
                    stacked: true
                }],
                yAxes: [{
                    stacked: true
                }]
            }
        }
    });

    // Oxygen Storage by Location Bar Chart

    self.scope.selectedOxygenLeftLocationType = _.find(self.stockHospitalDashboardReport.locationTypes, function (locationType) {
        return locationType === self.LocationTypes.STANDARD_LOCATION;
    }) || self.stockHospitalDashboardReport.locationTypes[0];

    var locationForOxygenLeft = [];
    if (self.scope.selectedOxygenLeftLocationType) {
        locationForOxygenLeft = _.map(self.stockHospitalDashboardReport.locationTypesSortedByOxygenLeft[self.scope.selectedOxygenLeftLocationType.name], function (location) {
            return location.name;
        });
    }
    var oxygenStoragePieCTX = document.getElementById('oxygen-stored-pi');
    var oxygenLeft = (
        self.scope.selectedOxygenLeftLocationType &&
        self.stockHospitalDashboardReport.oxygenLeftAtLocationByLocationType[self.scope.selectedOxygenLeftLocationType.name]
    ) || [];
    var oxygenStoragePieData = {
        labels: locationForOxygenLeft,
        datasets: [
            {
                label: self.gettextCatalog.getString('Oxygen'),
                backgroundColor: colourMapForLocations.light,
                borderColor: 'rgb(255,255,255)',
                borderWidth: 1,
                hoverBackgroundColor: colourMapForLocations.medium,
                data: oxygenLeft
            }
        ]
    };
    self.oxygenStoragePieChart = new Chart(oxygenStoragePieCTX, {
        type: 'bar',
        data: oxygenStoragePieData,
        options: {
            scale: {
                ticks: {
                    min: 0
                }
            },
            legend: {
                position: 'bottom'
            }
        }
    });

    // Statistics Displays

    self.timeInterval = (function () {
        var latest = moment().toDate();
        var earliest = moment().subtract(1, 'month').toDate();
        return {
            from: earliest,
            to: latest
        };
    }());

    if (self.averageEmptyPressure) {
        self.avgReturnPressure = {
            fillLevel: FillPressurePlanService.levelForPressureCBInCurrentHospital(self.averageEmptyPressure.current * 100),
            percentage: {
                current: Math.round(
                    ((self.averageEmptyPressure.current * 100) / self.FillPressurePlanService.fullPressureCBInCurrentHospital()
                    ) * 100
                ),
                previous: Math.round(
                    ((self.averageEmptyPressure.previous * 100) / self.FillPressurePlanService.fullPressureCBInCurrentHospital()
                    ) * 100
                )
            },
            trend: self.averageEmptyPressure.trend
        };

        if (!self.averageEmptyPressure.current || isNaN(self.averageEmptyPressure.current)) {
            self.avgReturnPressure.fillLevel = 0;
        }
    }

    if (self.averageCollectionTime) {
        if (self.averageCollectionTime.current > 120) {
            self.averageCollectionTime.current = self.averageCollectionTime.current / 60;
            self.collectionTimeUnit.current = self.gettext('hours');
            if (self.averageCollectionTime.current > 48) {
                self.averageCollectionTime.current = self.averageCollectionTime.current / 24;
                self.collectionTimeUnit.current = self.gettext('days');
            }
        }
        self.averageCollectionTime.current = Math.round(self.averageCollectionTime.current);

        if (self.averageCollectionTime.previous > 120) {
            self.averageCollectionTime.previous = self.averageCollectionTime.previous / 60;
            self.collectionTimeUnit.previous = 'hours';
            if (self.averageCollectionTime.previous > 48) {
                self.averageCollectionTime.previous = self.averageCollectionTime.previous / 24;
                self.collectionTimeUnit.previous = 'days';
            }
        }
        self.averageCollectionTime.previous = Math.round(self.averageCollectionTime.previous);
    }

    if (self.averageTimeBeforeFirstUse) {
        if (self.averageTimeBeforeFirstUse.current > 120) {
            self.averageTimeBeforeFirstUse.current = self.averageTimeBeforeFirstUse.current / 60;
            self.firstUsedTimeUnit.current = 'hours';
            if (self.averageTimeBeforeFirstUse.current > 48) {
                self.averageTimeBeforeFirstUse.current = self.averageTimeBeforeFirstUse.current / 24;
                self.firstUsedTimeUnit.current = 'days';
            }
        }
        self.averageTimeBeforeFirstUse.current = Math.round(self.averageTimeBeforeFirstUse.current);

        if (self.averageTimeBeforeFirstUse.previous > 120) {
            self.averageTimeBeforeFirstUse.previous = self.averageTimeBeforeFirstUse.previous / 60;
            self.firstUsedTimeUnit.previous = 'hours';
            if (self.averageTimeBeforeFirstUse.previous > 48) {
                self.averageTimeBeforeFirstUse.previous = self.averageTimeBeforeFirstUse.previous / 24;
                self.firstUsedTimeUnit.previous = 'days';
            }
        }
        self.averageTimeBeforeFirstUse.previous = Math.round(self.averageTimeBeforeFirstUse.previous);
    }

    self.oxygenUsage = Math.round(self.oxygenUsage);

    var treatmentPieCTX = document.getElementById('treatment-pi');

    self.locationTypesForTreatmentReport = _.map(_.keys(self.dashboardReport.treatmentReport), function (name) {
        return {name: name};
    });

    self.scope.selectedCylinderTreatmentLocationType = _.find(self.locationTypesForTreatmentReport, function (locationType) {
        return locationType === self.LocationTypes.STANDARD_LOCATION;
    }) || self.locationTypesForTreatmentReport[0];
    var locations = [];

    if (self.dashboardReport.treatmentReport) {
        var rawCylinderTreatmentReportForLocationType = self.scope.selectedCylinderTreatmentLocationType &&
        self.dashboardReport.treatmentReport[self.scope.selectedCylinderTreatmentLocationType.name] ?
            self.dashboardReport.treatmentReport[self.scope.selectedCylinderTreatmentLocationType.name]
            : [];
        var sortedTreatmentCount = [];

        if (rawCylinderTreatmentReportForLocationType && rawCylinderTreatmentReportForLocationType.locations) {
            var treatmentCountByLocation = _.map(rawCylinderTreatmentReportForLocationType.locations, function (location, index) {
                return {
                    location: location,
                    count: rawCylinderTreatmentReportForLocationType.treatmentCount[index]
                };
            });
            var sortedTreatmentCountByLocation = _.sortBy(treatmentCountByLocation, 'count').reverse().slice(0, 12);
            locations = _.map(sortedTreatmentCountByLocation, function (treatment, index) {
                sortedTreatmentCount[index] = treatment.count;
                return treatment.location;
            });
        }

        var treatmentPieData = {
            labels: locations,
            datasets: [
                {
                    label: self.gettextCatalog.getString('Treatments'),
                    backgroundColor: colourMapForLocations.light,
                    borderColor: 'rgb(255,255,255)',
                    borderWidth: 1,
                    hoverBackgroundColor: colourMapForLocations.medium,
                    data: sortedTreatmentCount
                }
            ]
        };
        self.treatmentPieChart = new Chart(treatmentPieCTX, {
            type: 'bar',
            data: treatmentPieData,
            options: {
                legend: {
                    position: 'bottom'
                }
            }
        });
    }

    var cylinderUsedCTX = document.getElementById('cylinder-usage');

    self.scope.selectedCylinderUsedLocationType = _.find(self.stockHospitalDashboardReport.locationTypes, function (locationType) {
        return locationType === self.LocationTypes.STANDARD_LOCATION;
    }) || self.stockHospitalDashboardReport.locationTypes[0];

    if (self.dashboardReport && self.dashboardReport.cylinderUsedReport) {
        var cylinderUsedReport = [];
        _.forEach(dashboardReport.cylinderUsedReport, function (rawData) {
            _.forEach(rawData.locations, function (location, index) {
                cylinderUsedReport.push({
                    location: location,
                    percentage: parseFloat(rawData.percentage && rawData.percentage[index] ? rawData.percentage[index].toFixed(2) : 0)
                });
            });
        });

        var sortedCylinderUsedReport = _.sortBy(cylinderUsedReport, 'percentage').reverse();
        var sortedCylinderUsedPercentages = [];
        locations = _.map(sortedCylinderUsedReport, function (report, index) {
            sortedCylinderUsedPercentages[index] = report.percentage;
            return report.location;
        });

        var colourMapForCylinderUsed = self.ColourMapService.mapFor(locations, 0.8, 1);

        var cylinderUsedData = {
            labels: locations,
            datasets: [
                {
                    label: self.gettextCatalog.getString('Cylinder Usage (%)'),
                    backgroundColor: colourMapForCylinderUsed.light,
                    borderColor: 'rgb(255,255,255)',
                    borderWidth: 1,
                    hoverBackgroundColor: colourMapForCylinderUsed.medium,
                    data: sortedCylinderUsedPercentages
                }
            ]
        };

        self.billSplitLegendOff = true;
        self.cylinderUsedChart = new Chart(cylinderUsedCTX, {
            type: 'doughnut',
            data: cylinderUsedData,
            options: {
                legend: {
                    display: !self.billSplitLegendOff,
                    position: 'bottom'
                }
            }
        });
    }

    if (self.dashboardReport.actionRequest) {
        cylinderTypes = self.dashboardReport.actionRequest.history.cylinderTypes;
        cylinderTypes = ColourMapService.paint(cylinderTypes, 0.8, 1);
        var timeLine = _.map(self.dashboardReport.actionRequest.history.time.from, function (time) {
            return moment(time).format('D MMM YY');
        });
        var stockHistoryDataSets = _.map(cylinderTypes, function (cylinderType) {
            return {
                label: cylinderType.name,
                backgroundColor: cylinderType.colour.light,
                borderColor: 'rgb(255,255,255)',
                borderWidth: 1,
                hoverBackgroundColor: cylinderType.colour.medium,
                hoverBorderColor: 'rgb(255,255,255)',
                data: self.dashboardReport.actionRequest.history.avgCountByCylinderType[cylinderType.name]
            };
        });

        var stockHistoryLocBarCTX = document.getElementById('stock-history-loc-bar');
        var stockHistoryLocBarData = {
            labels: timeLine,
            datasets: stockHistoryDataSets
        };

        self.stockHistoryLocBarChart = new Chart(stockHistoryLocBarCTX, {
            type: 'bar',
            data: stockHistoryLocBarData,
            options: {
                legend: {
                    position: 'bottom'
                },
                scales: {
                    xAxes: [{
                        stacked: true
                    }],
                    yAxes: [{
                        stacked: true
                    }]
                }
            }
        });
    }
}

HospitalAdminDashboard.prototype._addScopeEventListeners = function () {
    this.scope.$on(this.DashboardReportService.LABELS_LOCALIZED, this._setTimeIntervals.bind(this));
};

HospitalAdminDashboard.prototype._setTimeIntervals = function () {
    this.timeIntervals = this.DashboardReportService.reportPeriods;
};

HospitalAdminDashboard.prototype._setupTooltips = function () {
    //TODO Enable tooltips
    // $('[data-toggle="tooltip"]').tooltip('destroy');
    $('[data-toggle="tooltip"]').tooltip();
};

HospitalAdminDashboard.prototype.getStockForLocationType = function (selectedLocationType) {
    var self = this;
    var cylinderTypes = self.stockHospitalDashboardReport.cylinderTypes;
    self.stockLocBarChart.data.labels = _.map(self.stockHospitalDashboardReport.locationTypesSortedByCylinderCount[selectedLocationType.name], function (location) {
        return location.name;
    });
    self.stockLocBarChart.data.datasets = _.map(cylinderTypes, function (cylinderType) {
        var count = (
            selectedLocationType && cylinderType &&
            self.stockHospitalDashboardReport.countAtLocationByLocationTypeAndCylinderType[selectedLocationType.name][cylinderType.name]
        ) || [];
        return {
            label: cylinderType.name,
            backgroundColor: cylinderType.colour.light,
            borderColor: 'rgb(255,255,255)',
            borderWidth: 1,
            hoverBackgroundColor: cylinderType.colour.medium,
            hoverBorderColor: 'rgb(255,255,255)',
            data: count
        };
    });
    self.stockLocBarChart.update();
};

HospitalAdminDashboard.prototype.getOxygenStorageForLocationType = function (selectedLocationType) {
    var self = this;
    var colourMapForLocations = self.ColourMapService.oneColour(0.8, 1);
    self.oxygenStoragePieChart.data.datasets = [
        {
            label: self.gettextCatalog.getString('Oxygen'),
            backgroundColor: colourMapForLocations.light,
            borderColor: 'rgb(255,255,255)',
            borderWidth: 1,
            hoverBackgroundColor: colourMapForLocations.medium,
            data: (selectedLocationType && self.stockHospitalDashboardReport.oxygenLeftAtLocationByLocationType[selectedLocationType.name]) || []
        }
    ];
    self.oxygenStoragePieChart.data.labels = _.map(self.stockHospitalDashboardReport.locationTypesSortedByOxygenLeft[selectedLocationType.name], function (location) {
        return location.name;
    });
    self.oxygenStoragePieChart.update();
};

HospitalAdminDashboard.prototype.commaSeparatedAnd = function (array) {
    if (_.isArray(array)) {
        return [array.slice(0, -1).join(', '), array.slice(-1)[0]].join(array.length < 2 ? '' : ' and ');
    }

    return '';
};

HospitalAdminDashboard.prototype.locationsWithHubConnectivityIssues = function () {
    return _.uniqBy(_.filter(this.locationSummary, function (location) {
        return location.hubsLosingConnectivity && location.hubsLosingConnectivity.length;
    }), 'id');
};

HospitalAdminDashboard.prototype.hasHubConnectivityIssues = function () {
    return _.some(this.locationSummary, function (item) {
        return item.hubsLosingConnectivity && item.hubsLosingConnectivity.length;
    });
};

HospitalAdminDashboard.prototype.getCylinderTreatmentReportForLocationType = function (selectedLocationType) {
    var self = this;
    if (self.dashboardReport.treatmentReport) {
        var rawCylinderTreatmentReportForLocationType = self.dashboardReport.treatmentReport[selectedLocationType.name];
        var sortedTreatmentCount = [];
        var locations = [];

        if (rawCylinderTreatmentReportForLocationType && rawCylinderTreatmentReportForLocationType.locations) {
            var treatmentCountByLocation = _.map(rawCylinderTreatmentReportForLocationType.locations, function (location, index) {
                return {
                    location: location,
                    count: rawCylinderTreatmentReportForLocationType.treatmentCount[index]
                };
            });
            var sortedTreatmentCountByLocation = _.sortBy(treatmentCountByLocation, 'count').reverse().slice(0, 12);
            locations = _.map(sortedTreatmentCountByLocation, function (treatment, index) {
                sortedTreatmentCount[index] = treatment.count;
                return treatment.location;
            });
        }

        var colourMapForLocations = self.ColourMapService.oneColour(0.8, 1);

        var treatmentPieDataSets = [
            {
                label: self.gettextCatalog.getString('Treatments'),
                backgroundColor: colourMapForLocations.light,
                borderColor: 'rgb(255,255,255)',
                borderWidth: 1,
                hoverBackgroundColor: colourMapForLocations.medium,
                data: sortedTreatmentCount
            }
        ];
        self.treatmentPieChart.data.labels = locations;
        self.treatmentPieChart.data.datasets = treatmentPieDataSets;
        self.treatmentPieChart.update();
    }
};

HospitalAdminDashboard.prototype.getDashboardReport = function () {
    var self = this;

    var from,
        to;

    console.log(self.scope.timeInterval);

    if (self.scope.timeInterval.key === 'yesterday') {
        from = moment().subtract(1, 'day').startOf('day').toDate();
        to = moment().subtract(1, 'day').endOf('day').toDate();
    } else if (self.scope.timeInterval.key === 'last7Days') {
        from = moment().subtract(7, 'day').startOf('day').toDate();
        to = moment().subtract(1, 'day').endOf('day').toDate();
    } else if (self.scope.timeInterval.key === 'last30Days') {
        from = moment().subtract(30, 'day').startOf('day').toDate();
        to = moment().subtract(1, 'day').endOf('day').toDate();
    } else if (self.scope.timeInterval.key === 'last3Months') {
        from = moment().subtract(3, 'months').startOf('months').toDate();
        to = moment().subtract(1, 'months').endOf('months').toDate();
    } else if (self.scope.timeInterval.key === 'last12Months') {
        from = moment().subtract(12, 'months').startOf('months').toDate();
        to = moment().subtract(1, 'months').endOf('months').toDate();
    } else if (self.scope.timeInterval.key === 'last24Months') {
        from = moment().subtract(24, 'months').startOf('months').toDate();
        to = moment().subtract(1, 'months').endOf('months').toDate();
    }

    self.DashboardReportService.dashboardReport(self.scope.timeInterval.key)
        .then(function (report) {
            self.dashboardReport = report;

            if (self.dashboardReport.actionRequest) {
                var cylinderTypes = self.dashboardReport.actionRequest.history.cylinderTypes;
                cylinderTypes = self.ColourMapService.paint(cylinderTypes, 0.8, 1);
                var timeLine = _.map(self.dashboardReport.actionRequest.history.time.from, function (time) {
                    if (self.dashboardReport.intervalType === 'hour') {
                        return moment(time).format('D MMM YY HH:mm');
                    }
                    return moment(time).format('D MMM YY');
                });
                var stockHistoryDataSets = _.map(cylinderTypes, function (cylinderType) {
                    return {
                        label: cylinderType.name,
                        backgroundColor: cylinderType.colour.light,
                        borderColor: 'rgb(255,255,255)',
                        borderWidth: 1,
                        hoverBackgroundColor: cylinderType.colour.medium,
                        hoverBorderColor: 'rgb(255,255,255)',
                        data: self.dashboardReport.actionRequest.history.avgCountByCylinderType[cylinderType.name]
                    };
                });

                self.stockHistoryLocBarChart.data.labels = timeLine;
                self.stockHistoryLocBarChart.data.datasets = stockHistoryDataSets;
                self.stockHistoryLocBarChart.update();
            }

            self.locationTypesForTreatmentReport = _.map(_.keys(self.dashboardReport.treatmentReport), function (name) {
                return {name: name};
            });

            self.scope.selectedCylinderTreatmentLocationType = _.find(self.locationTypesForTreatmentReport, function (locationType) {
                return locationType === self.LocationTypes.STANDARD_LOCATION;
            }) || self.locationTypesForTreatmentReport[0];

            var colourMapForLocations = self.ColourMapService.oneColour(0.8, 1);

            var treatmentPieDataSets = [
                {
                    label: self.gettextCatalog.getString('Treatments'),
                    backgroundColor: colourMapForLocations.light,
                    borderColor: 'rgb(255,255,255)',
                    borderWidth: 1,
                    hoverBackgroundColor: colourMapForLocations.medium,
                    data: []
                }
            ];

            if (self.dashboardReport.treatmentReport && self.scope.selectedCylinderTreatmentLocationType) {
                var rawCylinderTreatmentReportForLocationType = self.dashboardReport.treatmentReport[self.scope.selectedCylinderTreatmentLocationType.name];
                var sortedTreatmentCount = [];
                var locations = [];

                if (rawCylinderTreatmentReportForLocationType && rawCylinderTreatmentReportForLocationType.locations) {
                    var treatmentCountByLocation = _.map(rawCylinderTreatmentReportForLocationType.locations, function (location, index) {
                        return {
                            location: location,
                            count: rawCylinderTreatmentReportForLocationType.treatmentCount[index]
                        };
                    });
                    var sortedTreatmentCountByLocation = _.sortBy(treatmentCountByLocation, 'count').reverse().slice(0, 12);
                    locations = _.map(sortedTreatmentCountByLocation, function (treatment, index) {
                        sortedTreatmentCount[index] = treatment.count;
                        return treatment.location;
                    });
                }
                treatmentPieDataSets[0].data = sortedTreatmentCount;
                self.treatmentPieChart.data.labels = locations;
            }
            if (self.treatmentPieChart) {
                self.treatmentPieChart.data.datasets = treatmentPieDataSets;
                self.treatmentPieChart.update();
            }

            self.drawBillSplitReport();

            if (self.dashboardReport.avgReturnPressureReport) {
                self.averageEmptyPressure = self.dashboardReport.avgReturnPressureReport;
                self.avgReturnPressure = {
                    fillLevel: self.FillPressurePlanService.levelForPressureCBInCurrentHospital(self.averageEmptyPressure.current * 100),
                    percentage: {
                        current: Math.round(((self.averageEmptyPressure.current * 100) / self.FillPressurePlanService.fullPressureCBInCurrentHospital()) * 100),
                        previous: Math.round(((self.averageEmptyPressure.previous * 100) / self.FillPressurePlanService.fullPressureCBInCurrentHospital()) * 100)
                    },
                    trend: self.averageEmptyPressure.trend
                };

                if (!self.averageEmptyPressure.current || isNaN(self.averageEmptyPressure.current)) {
                    self.avgReturnPressure.fillLevel = 0;
                }
            }

            self.collectionTimeUnit = {
                current: 'mins',
                previous: 'mins'
            };
            self.firstUsedTimeUnit = {
                current: 'mins',
                previous: 'mins'
            };

            if (self.dashboardReport.avgCollectionTimeReport) {
                self.averageCollectionTime = self.dashboardReport.avgCollectionTimeReport;
                if (self.averageCollectionTime.current > 120) {
                    self.averageCollectionTime.current = self.averageCollectionTime.current / 60;
                    self.collectionTimeUnit.current = 'hours';
                    if (self.averageCollectionTime.current > 48) {
                        self.averageCollectionTime.current = self.averageCollectionTime.current / 24;
                        self.collectionTimeUnit.current = 'days';
                    }
                }
                self.averageCollectionTime.current = Math.round(self.averageCollectionTime.current);

                if (self.averageCollectionTime.previous > 120) {
                    self.averageCollectionTime.previous = self.averageCollectionTime.previous / 60;
                    self.collectionTimeUnit.previous = 'hours';
                    if (self.averageCollectionTime.previous > 48) {
                        self.averageCollectionTime.previous = self.averageCollectionTime.previous / 24;
                        self.collectionTimeUnit.previous = 'days';
                    }
                }
                self.averageCollectionTime.previous = Math.round(self.averageCollectionTime.previous);
            }

            if (self.dashboardReport.avgTimeBeforeFirstUseReport) {
                self.averageTimeBeforeFirstUse = self.dashboardReport.avgTimeBeforeFirstUseReport;
                if (self.averageTimeBeforeFirstUse.current > 120) {
                    self.averageTimeBeforeFirstUse.current = self.averageTimeBeforeFirstUse.current / 60;
                    self.firstUsedTimeUnit.current = 'hours';
                    if (self.averageTimeBeforeFirstUse.current > 48) {
                        self.averageTimeBeforeFirstUse.current = self.averageTimeBeforeFirstUse.current / 24;
                        self.firstUsedTimeUnit.current = 'days';
                    }
                }
                self.averageTimeBeforeFirstUse.current = Math.round(self.averageTimeBeforeFirstUse.current);

                if (self.averageTimeBeforeFirstUse.previous > 120) {
                    self.averageTimeBeforeFirstUse.previous = self.averageTimeBeforeFirstUse.previous / 60;
                    self.firstUsedTimeUnit.previous = 'hours';
                    if (self.averageTimeBeforeFirstUse.previous > 48) {
                        self.averageTimeBeforeFirstUse.previous = self.averageTimeBeforeFirstUse.previous / 24;
                        self.firstUsedTimeUnit.previous = 'days';
                    }
                }
                self.averageTimeBeforeFirstUse.previous = Math.round(self.averageTimeBeforeFirstUse.previous);
            }

            self.oxygenUsage = self.dashboardReport.oxygenUsage;
            self.oxygenUsage = Math.round(self.oxygenUsage);

            if (self.dashboardReport.alarmReport) {
                self.alarms = self.dashboardReport.alarmReport;
                self.totalAlarmCount = _.reduce(self.alarms.alarmCountByType, function (total, alarm) {
                    return total + Number(alarm.count);
                }, 0);
            }
        });
};

HospitalAdminDashboard.prototype.toggleBillSplitLegend = function () {
    var self = this;
    self.billSplitLegendOff = !self.billSplitLegendOff;
    self.drawBillSplitReport();
};

HospitalAdminDashboard.prototype.navigateTo = function (path) {
    this.location.path(path + this.scope.timeInterval.key + '/' + (this.totalBill ? this.totalBill : 0));
};

HospitalAdminDashboard.prototype.drawBillSplitReport = function () {
    var self = this;
    if (self.dashboardReport && self.dashboardReport.cylinderUsedReport) {
        var cylinderUsedReport = [];
        _.forEach(self.dashboardReport.cylinderUsedReport, function (rawData) {
            _.forEach(rawData.locations, function (location, index) {
                cylinderUsedReport.push({
                    location: location,
                    percentage: parseFloat(rawData.percentage && rawData.percentage && rawData.percentage[index] ? rawData.percentage[index].toFixed(2) : 0)
                });
            });
        });

        var sortedCylinderUsedReport = _.sortBy(cylinderUsedReport, 'percentage').reverse();
        var sortedCylinderUsedPercentages = [];
        var locations = _.map(sortedCylinderUsedReport, function (sortedReport, index) {
            sortedCylinderUsedPercentages[index] = sortedReport.percentage;
            return sortedReport.location;
        });

        var colourMapForCylinderUsed = self.ColourMapService.mapFor(locations, 0.8, 1);

        var cylinderUsedDataSets = [
            {
                label: self.gettextCatalog.getString('Cylinder Usage (%)'),
                backgroundColor: colourMapForCylinderUsed.light,
                borderColor: 'rgb(255,255,255)',
                borderWidth: 1,
                hoverBackgroundColor: colourMapForCylinderUsed.medium,
                data: sortedCylinderUsedPercentages
            }
        ];

        self.cylinderUsedChart.data.labels = locations;
        self.cylinderUsedChart.data.datasets = cylinderUsedDataSets;
        self.cylinderUsedChart.options.legend.display = !self.billSplitLegendOff;
        self.cylinderUsedChart.update();
    }
};

HospitalAdminDashboard.$inject = ["$rootScope", "$scope", "$timeout", "$interval", "$location", "SessionService", "ColourMapService", "StockListService", "CylinderListService", "DashboardReportService", "CountryService", "FillPressurePlanService", "ButtonsFeatureService", "cylinderRefreshIntervalMillis", "stockHospitalDashboardReport", "untrackedCylinderCount", "totalCylinderCount", "locationSummary", "dashboardReport", "displaySettings", "toastr", "gettextCatalog", "gettext", "LocationTypes", "CommonTranslations", "trackedEmpty", "trackedPartial", "trackedFull", "untrackedUnderThree", "untrackedOverThree", "untrackedInTransit"];