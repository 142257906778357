export function adminConfig($routeProvider) {
    $routeProvider.when('/admin/hospitals', {
        template: require('./hospitals/hospitals.tpl.html'),
        controller: 'AdminHospitals',
        controllerAs: 'hospitalListCtrl',
        resolve: {
            hospitals: ['HospitalListService', function (HospitalListService) {
                return HospitalListService.findAll();
            }],
            serviceLevels: ['ServiceLevelListService', function (ServiceLevelListService) {
                return ServiceLevelListService.findAll();
            }],
            fillPressurePlans: ['FillPressurePlanService', function (FillPressurePlanService) {
                return FillPressurePlanService.fillPressurePlans;
            }],
            countries: ['CountryService', function (CountryService) {
                return CountryService.countries();
            }]
        }
    }).when('/admin/users', {
        template: require('./users/users.tpl.html'),
        controller: 'AdminUsers',
        controllerAs: 'userListCtrl'
    }).when('/admin/reports/fill-plant', {
        template: require('./reports/report-fill-plant/report-fill-plant.tpl.html'),
        controller: 'LindeAdminFillPlantReport',
        controllerAs: 'fillPlantReportCtrl',
        resolve: {
            report: ['ReportService', function (ReportService) {
                return ReportService.cylinderFillPlantReport();
            }]
        }
    }).when('/admin/settings', {
        template: require('./settings/settings.tpl.html'),
        controller: 'LindeAdminSettings',
        controllerAs: 'lindeAdminSettingsCtrl'
    });
}

adminConfig.$inject = ['$routeProvider'];
