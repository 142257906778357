import {CYLINDERS_MODULE} from "../../../services/cylinders/cylinders.module";
import * as smartTable from 'angular-smart-table';

import {HospitalAdminCylinders} from "./cylinders";

export const HOSPITAL_ADMIN_CYLINDERS_MODULE = 'hospital.admin.cylinders';

angular.module(HOSPITAL_ADMIN_CYLINDERS_MODULE, [CYLINDERS_MODULE, smartTable]);

angular.module(HOSPITAL_ADMIN_CYLINDERS_MODULE)
    .controller('HospitalAdminCylinders', HospitalAdminCylinders);