export function LivIqReportButtonStatsLocation() {
    return {
        restrict: 'E',
        scope: {
            location: '=',
            timeInterval: '=',
            stats: '='
        },
        controller: 'livIqReportButtonsStatsLocation',
        controllerAs: 'ctrl',
        bindToController: true,
        template: require('./liv-iq-report-buttons-stats-location.tpl.html')
    };
}