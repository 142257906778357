export function HospitalEditDirective(gettextCatalog, gettext, FillPressurePlanService) {
    return {
        restrict: 'E',
        scope: {
            hospital: '=',
            hospitals: '=',
            primaryAdmin: '=',
            fillPressurePlans: '=',
            serviceLevels: '=',
            countries: '=',
            timezones: '=',
            languages: '=',
            openHospitalFeatures: '&',
            save: '&',
            cancel: '&'
        },
        link: function (scope) {
            var originallySelectedFillPressurePlan = scope.hospital.fillPressurePlan;
            var fillPressurePlanHasBeenChangedByUserSelection = false;
            scope.TAG_DESCRIPTION = gettext('Allows grouping of the hospital using the text in this field');
            scope.isNewPasswordRequired = function () {
                return !scope.primaryAdminExists();
            };
            var defaultStr = _.template('-- <%= active %> <%= hospitals %> --')(
                {
                    active: gettext('Active'),
                    hospitals: gettext('Hospitals')
                });

            Object.defineProperty(scope, 'tag', {
                get: function () {
                    var hospital = scope.hospital;
                    return hospital.tag === undefined || hospital.tag === null || hospital.tag.length === 0 ? defaultStr : hospital.tag;
                },
                set: function (value) {
                    var hospital = scope.hospital;
                    if (!value || value === defaultStr) {
                        hospital.tag = null;
                    } else {
                        hospital.tag = value;
                    }
                }
            });

            scope.tags = function () {
                var tagList = _.map(scope.hospitals, 'tag');
                if (scope.search) {
                    tagList.push(scope.search);
                }
                tagList = _.sortBy(_.uniq(tagList));
                tagList = _.filter(tagList, function (tag) {
                    return tag !== undefined && tag !== null && tag.length !== 0;
                });
                tagList.unshift(defaultStr);
                return tagList;
            };

            scope.addSearch = function ($select) {
                var search = $select.search;
                if (!!search) {
                    scope.search = search;
                }
            };

            scope.adminPassword = function () {
                return !!scope.primaryAdmin && !!scope.primaryAdmin.password ? scope.primaryAdmin.password : '';
            };

            scope.requireEmailValidation = function () {
                return scope.hospital &&
                    (
                        (!!scope.hospital.features &&
                            !(!!scope.hospital.serviceLevel && !!scope.hospital.serviceLevel.id) &&
                            scope.hospital.features.userEmailAddressValidation) ||
                        (!!scope.hospital.serviceLevel &&
                            !!scope.hospital.serviceLevel.id &&
                            !!scope.hospital.serviceLevel.userEmailAddressValidation)
                    );
            };

            scope.isPasswordFieldVisible = function () {
                return scope.hospital ?
                    !scope.requireEmailValidation() ||
                    (scope.primaryAdmin.disableEmailNotifications)
                    : true;
            };

            scope.primaryAdminExists = function () {
                return !!(scope.hospital && scope.hospital.primaryAdmin);
            };

            scope.getCurrentAction = function () {
                return scope.hospital && scope.hospital.id ? gettextCatalog.getString('Edit') : gettextCatalog.getString('Add');
            };

            scope.selectDefaultPressureLevelForCountryIfUnset = function (country) {
                if (originallySelectedFillPressurePlan || fillPressurePlanHasBeenChangedByUserSelection) {
                    return;
                }
                scope.hospital.countryCode = country.countryCode;
                var defaultFillPressureForSelectedCountry = FillPressurePlanService.defaultFillPressureForCountry(country);
                scope.hospital.fillPressurePlan = defaultFillPressureForSelectedCountry;
            };

            scope.stopCountrySelectFromChangingCylinderFillPressure = function () {
                fillPressurePlanHasBeenChangedByUserSelection = true;
            };
        },
        template: require('./liv-iq-hospital-edit.tpl.html')
    };
}

HospitalEditDirective.$inject = ['gettextCatalog', 'gettext', 'FillPressurePlanService'];