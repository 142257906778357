export function AdminHospitals($rootScope, $scope, $uibModal, $q, $timeout, $location, $http, SessionService, AuthService,
                        ConfigParametersListService, HospitalListService, UserListService, RoleListService,
                        HospitalFeaturesService, LocationTypeListService, ServiceLevelListService, FillPressurePlanService,
                        CountryService, hospitals, serviceLevels, fillPressurePlans, countries, toastr, gettextCatalog, Roles,
                        HospitalHelperService) {
    if (!SessionService.isCurrentRoleIn([Roles.LINDE_ADMINISTRATOR])) {
        return;
    }
    $rootScope.navbarType = 'ADMIN';

    var self = this;

    this.scope = $scope;
    this.rootScope = $rootScope;
    this.modal = $uibModal;
    this.http = $http;
    this.toastr = toastr;
    this.gettextCatalog = gettextCatalog;
    this.q = $q;
    this.timeout = $timeout;
    this.location = $location;
    this.ConfigParametersListService = ConfigParametersListService;
    this.HospitalListService = HospitalListService;
    this.UserListService = UserListService;
    this.RoleListService = RoleListService;
    this.Roles = Roles;
    this.SessionService = SessionService;
    this.AuthService = AuthService;
    this.HospitalFeaturesService = HospitalFeaturesService;
    this.LocationTypeListService = LocationTypeListService;
    this.ServiceLevelListService = ServiceLevelListService;
    this.FillPressurePlanService = FillPressurePlanService;
    this.fillPressurePlans = fillPressurePlans;
    this.hospitalHelperService = HospitalHelperService;
    this.hospitals = _.map(hospitals, function (hospital) {
        if (!hospital.serviceLevel && !hospital.serviceLevelId) {
            hospital.serviceLevel = {
                name: self.gettextCatalog.getString('Custom'),
                id: null
            };
        }
        return hospital;
    });
    this.serviceLevels = serviceLevels;
    this.serviceLevels.push({id: null, name: this.gettextCatalog.getString('Custom')}); // ATTENTION: This will show up in the "Service Level" dropdown menu of the Add/Edit Hospital form
    this.countries = countries;
    this.timezones = [];
    var ctrl = this;
    this.http.get('json/timezones.json')
        .then(function (res) {
            ctrl.timezones = res.data;
        });

    this.languages = [{id: 'en_GB', name: 'English (United Kingdom)'}];
    this.http.get('json/lang.json')
        .then(function (res) {
            ctrl.languages = _.filter(res.data, function (l) {
                return l.debug !== true;
            });
        });

    this.CountryService = CountryService;
    this.reset();
}

AdminHospitals.prototype.enter = function (hospital) {
    var self = this;

    self.SessionService.enterHospital(hospital);

    self.rootScope.appReady.promise.then(function (hospital) {
        if (hospital.features.dashboard && hospital.features.cylinder) {
            self.location.path('/hospital/admin/dashboard');
        } else if (hospital.features.dashboard) {
            self.location.path('/hospital/admin/button-dashboard');
        } else {
            self.location.path('/hospital/admin/users');
        }
    });
};

AdminHospitals.prototype.openHospitalFeatures = function () {
    var self = this;
    var modalInstance = self.modal.open({
        animation: self.animationsEnabled,
        template: require('../../hospitalFeaturesModal/hospital-features-modal.tpl.html'),
        controller: 'HospitalFeaturesController',
        controllerAs: 'hospitalFeaturesCtrl',
        size: 'lg',
        resolve: {
            serviceLevels: function () {
                return self.serviceLevels;
            },
            selectedServiceLevel: self.selection.serviceLevel,
            hospitalFeatures: ['HospitalFeaturesService', function (HospitalFeaturesService) {
                if (!self.selection.serviceLevel.id) {
                    console.log('Cloning service level: %s', JSON.stringify(self.selection.features));
                    return _.cloneDeep(self.selection.features);
                }
                return HospitalFeaturesService.getByHospitalId(self.selection.id);
            }],
            featureDefinitions: ['ServiceLevelListService', function (ServiceLevelListService) {
                return ServiceLevelListService.getFeatureDefinitions();
            }],
            hospital: function () {
                return self.selection;
            }
        }
    });

    return modalInstance.result.then(function (updatedHospital) {
        self.selection = updatedHospital;
        return true;
    });
};

AdminHospitals.prototype.reset = function () {
    this.scope.initialFocus = false;
    this.selection = null;
    this.primaryAdmin = null;
};

AdminHospitals.prototype.openEditPane = function (focusOnFieldId, existingHospital) {
    var self = this;
    self.scope.initialFocus = false;
    self.existingHospital = existingHospital;
    self.timeout(function () {
        self.scope.initialFocus = focusOnFieldId;
        self.selection = self.HospitalListService.add(existingHospital);
        self.selection.fillPressurePlan = existingHospital ? self.FillPressurePlanService.planForDefaultFillPressureCB(self.selection.defaultFillPressureCB) : null;
        self.selection.country = existingHospital ? self.CountryService.currentCountry(self.selection.countryCode, self.countries) : null;
        self.selection.defaultLanguage = existingHospital ? _.find(self.languages, function (language) {
            return language.id === self.selection.defaultLang;
        }) : null;
        self.primaryAdmin = self.UserListService.add(self.selection.primaryAdmin);
    });
};

/** @function Adds a new hospital to the user list. */
AdminHospitals.prototype.add = function () {
    this.openEditPane('inputHospitalName');
};

AdminHospitals.prototype.select = function (hospital) {
    if (hospital) {
        if (!hospital.serviceLevel && !hospital.serviceLevelId) {
            hospital.serviceLevel = {
                name: this.gettextCatalog.getString('Custom'),
                id: null
            };
        }
        this.openEditPane('inputHospitalName', hospital);
    } else {
        this.reset();
    }
};

AdminHospitals.prototype.refreshHospitals = function () {
    var self = this;
    return self.HospitalListService.findAll().then(function (hospitals) {
        self.hospitals = hospitals;
        return hospitals;
    });
};

// this method is duplicated in SuperAdminHospitals; it needs to be refactored, extracted and reused
AdminHospitals.prototype.save = function () {
    var self = this;
    return this.hospitalHelperService.save(this.selection, this.existingHospital, this.primaryAdmin)
        .then(function (updatedHospital) {
            self.toastr.success(self.gettextCatalog.getString('Hospital has been saved'), self.gettextCatalog.getString('Saved'));

            self.refreshHospitals();
            self.scope.showInitUserDetails = true;

            self.reset();
        })
        .catch(function (err) {
            console.error(err);
            self.toastr.error(self.gettextCatalog.getString('Error saving the hospital'), err.data.error.message, {timeOut: 0});
            self.reset();
            self.refreshHospitals();
        });
};

AdminHospitals.prototype.del = function () {
    var self = this;
    if (self.selection) {
        if (self.existingHospital) {
            self.HospitalListService.del(self.existingHospital).then(function () {
                self.refreshHospitals();
            });
        }
    }
    self.reset();
};

AdminHospitals.$inject = ["$rootScope", "$scope", "$uibModal", "$q", "$timeout", "$location", "$http", "SessionService", "AuthService",
    "ConfigParametersListService", "HospitalListService", "UserListService", "RoleListService",
    "HospitalFeaturesService", "LocationTypeListService", "ServiceLevelListService", "FillPressurePlanService",
    "CountryService", "hospitals", "serviceLevels", "fillPressurePlans", "countries", "toastr", "gettextCatalog", "Roles",
    "HospitalHelperService"];
