export function ButtonHistoryService($rootScope, FlatHwButton, ButtonsFeatureService) {
    var self = this;
    self.mac = undefined;

    function getHospital() {
        return $rootScope.appReady.promise;
    }

    self.flatHwButtonHistory = function (hospitalId) {
        return FlatHwButton.history({hospitalId: hospitalId, mac: self.mac}).$promise;
    };


    self.history = function () {
        if (!ButtonsFeatureService.isEnabled()) {
            return ButtonsFeatureService.getEmptyPageResults();
        }

        return getHospital()
            .then(function (hospital) {
                return self.flatHwButtonHistory(hospital.id);
            })
            .then(function (data) {
                return {
                    items: _.map(data, function (hwButton) {
                        return hwButton;
                    }),
                    totalCount: data.length
                };
            });
    };
}

ButtonHistoryService.$inject = ['$rootScope', 'FlatHwButton', 'ButtonsFeatureService'];