import {HospitalHelperService} from "./HospitalHelperService";
import {HospitalListService} from "./HospitalListService";

export const HOSPITALS_MODULE = 'services.hospitals';

angular.module(HOSPITALS_MODULE, []);

angular.module(HOSPITALS_MODULE)
    .service('HospitalHelperService', HospitalHelperService)
    .service('HospitalListService', HospitalListService);
