export function LindeAdminFillPlantReport($rootScope, $scope, report, SessionService, Roles) {
    if (!SessionService.isCurrentRoleIn([Roles.LINDE_ADMINISTRATOR])) {
        return;
    }
    $rootScope.navbarType = 'ADMIN';

    this.rootScope = $rootScope;
    this.scope = $scope;
    this.period = report.period;
    this.report = report.data;
    this.hospitals = _.map(_.keys(this.report), function (hospitalName, index) {
        return {index: index, name: hospitalName};
    });
    this.scope.selectedHospital = this.hospitals[0];

    this.selectedReport = this.scope.selectedHospital ? this.report[this.scope.selectedHospital.name] : [];
}

LindeAdminFillPlantReport.prototype.refresh = function () {
    this.selectedReport = this.report[this.scope.selectedHospital.name];
};

LindeAdminFillPlantReport.$inject = ["$rootScope", "$scope", "report", "SessionService", "Roles"];