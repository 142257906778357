export const Roles = {
    // ATTENTION: Keep it in synch with the liv-iq-api/common/models/user-roles.js or make it one
    LINDE_SUPER_ADMINISTRATOR: gettext('LindeSuperAdministrator'),
    LINDE_ADMINISTRATOR: gettext('LindeAdministrator'),
    HOSPITAL_SERVICE: gettext('HospitalService'),
    HOSPITAL_ADMINISTRATOR: gettext('HospitalAdministrator'),
    LINDE_INSIGHTS: gettext('LindeInsights'), // ATTENTION: Don't remove it, even if nobody is using it: needed for translation extraction
    PORTER: gettext('Porter'),
    NURSE: gettext('Nurse')
};

/**
 * Does nothing, it's only needed to extract strings to localise
 *
 * @param text
 * @returns {*}
 */
function gettext(text) {
    return text;
}