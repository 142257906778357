export function HospitalListService($rootScope, Hospital) {
    this.rootScope = $rootScope;
    this.Hospital = Hospital;
}

HospitalListService.prototype.add = function (hospital) {
    var newHospital = new this.Hospital(hospital);
    if (newHospital.tag === '') {
        delete newHospital.tag;
    }
    // We don't have to wait for appReady because the "add" button appears after the app is ready
    return newHospital;
};

HospitalListService.prototype.find = function (name) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.Hospital.find({
            filter: {
                where: {
                    name: name,
                    id: hospital.id, // Will have to remove this parameter for superusers
                    deleted: 0
                },
                include: [
                    'configParameters',
                    'primaryAdmin',
                    'serviceLevel'
                ]
            }
        }).$promise;
    });
};

HospitalListService.prototype.findAny = function (propertiesToInclude) {
    var filter = {where: {deleted: 0}};

    if (propertiesToInclude && propertiesToInclude.length) {
        filter.include = propertiesToInclude;
    }

    return this.Hospital.findOne({filter: filter}).$promise;
};

HospitalListService.prototype.findById = function (hospitalId, propertiesToInclude) {
    var params = {
        id: hospitalId
    };

    if (propertiesToInclude && propertiesToInclude.length) {
        params.filter = {
            include: propertiesToInclude
        };
    }

    return this.Hospital.findById(params).$promise;
};

HospitalListService.prototype.findAll = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        // console.log ('HospitalListService.findAll (); hospital:', hospital);
        return self.Hospital.find({
            filter: {
                where: {
                    deleted: 0
                },
                include: [
                    'configParameters',
                    'primaryAdmin',
                    'serviceLevel',
                    'features'
                ]
            }
        }).$promise;
    });
};


HospitalListService.prototype.save = function (hospital) {
    if (hospital) {
        if (hospital.configParameters) {
            hospital.configParametersId = hospital.configParameters.id;
            delete hospital.configParameters;
        }
        if (hospital.primaryAdmin) {
            hospital.primaryAdminId = hospital.primaryAdmin.id;
            delete hospital.primaryAdmin;
        }
        if (hospital.serviceLevel) {
            hospital.serviceLevelId = hospital.serviceLevel.id;
            delete hospital.serviceLevel;
        }
        hospital.deleted = 0;
        return hospital.$save();
    }
    return false;
};

HospitalListService.prototype.del = function (hospital) {
    if (hospital) {
        hospital.deleted = new Date().getTime();
        return hospital.$save();
    }
    return false;
};

HospitalListService.prototype.allTimeZones = function () {
    return this.Hospital.getAllTimeZones().$promise;
};

HospitalListService.prototype.getPlatform = function () {
    return this.Hospital.getPlatform().$promise;
};

HospitalListService.$inject = ['$rootScope', 'Hospital'];