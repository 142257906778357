export function OrderRejectionModalController($uibModalInstance) {
    var vm = this;

    vm.cancel = cancelModal;
    vm.ok = closeModal;
    vm.message = '';

    function cancelModal() {
        $uibModalInstance.dismiss('cancel');
    }

    function closeModal() {
        /* Pass data back through close here */
        $uibModalInstance.close(vm.message);
    }
}

OrderRejectionModalController.$inject = ["$uibModalInstance"];