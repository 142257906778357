export function ListHelpersService() {
    var self = this;

    self.applyDefaultOrdering = function (filter, defaultOrderings) {
        if (filter.order) {
            if (!(filter.order instanceof Array)) {
                filter.order = [filter.order];
            }
        } else {
            filter.order = [];
        }

        _.forEach(defaultOrderings, function (ordering) {
            if (!_.some(filter.order, function (ord) {
                return _.startsWith(ord, ordering.property);
            })) {
                var direction = ordering.direction ? ordering.direction : 'ASC';
                filter.order.push(ordering.property + ' ' + direction);
            }
        });
    };
}
