export function LocationFactory(Model, HubModel, CylinderOperatingModes) {
    var superclass = Model;

    function LocationModel(locationJSON) {
        var self = this;
        superclass.call(self, locationJSON);


        if (_.isArray(this.hubs)) {
            self.hubs = _.map(self.hubs, function (hub) {
                return new HubModel(hub, self);
            });
        }
    }

    LocationModel.prototype.outstaandingOrders = function () {
        return _.filter(this.orders, function (o) {
            return o.isOutstanding();
        });
    };

    LocationModel.prototype.getAllCylinders = function () {
        return _.flatten(_.map(this.hubs, function (peg) {
            return _.filter(peg.cylinders, {deleted: 0});
        }));
    };

    LocationModel.prototype.getHealthyCylinders = function () {
        return _.filter(this.getAllCylinders(), function (cyl) {
            if (cyl.operatingMode === CylinderOperatingModes.FAULT) {
                return false;
            }
            return !cyl.isEmpty;
        });
    };

    LocationModel.prototype.numberOfHealthyCylinders = function () {
        let healthyCylinders = this.getHealthyCylinders();
        return healthyCylinders? healthyCylinders.length : 0;
    };

    LocationModel.prototype.hasHealthyCylinders = function () {
        return this.numberOfHealthyCylinders() > 0;
    };

    LocationModel.prototype.hasNoPendingOrdersOrBadCylinders = function () {
        return !this.hasPendingOrders() && !this.hasFaultyCylinders() && !this.hasEmptyCylinders();
    };

    LocationModel.prototype.getPendingOrders = function () {
        return _.filter(this.orders, function (order) {
            return (order.status === 'new' || order.status === 'acknowledged') ? 1 : 0;
        });
    };

    LocationModel.prototype.numberOfPendingOrders = function () {
        let pendingOrders = this.getPendingOrders();
        return pendingOrders? pendingOrders.length : 0;
    };

    LocationModel.prototype.hasPendingOrders = function () {
        return this.numberOfPendingOrders() > 0;
    };

    LocationModel.prototype.getFaultyCylinders = function () {
        return _.filter(this.getAllCylinders(), function (cyl) {
            return cyl.operatingMode === CylinderOperatingModes.FAULT;
        });
    };

    LocationModel.prototype.numberOfFaultyCylinders = function () {
        let faultyCylinders = this.getFaultyCylinders();
        return faultyCylinders? faultyCylinders.length : 0;
    };

    LocationModel.prototype.hasFaultyCylinders = function () {
        return this.numberOfFaultyCylinders() > 0;
    };

    LocationModel.prototype.getEmptyCylindersInUse = function () {
        return _.filter(this.getAllCylinders(), function (cyl) {
            return (cyl.operatingMode === CylinderOperatingModes.GAS_DELIVERY && cyl.isEmpty) ? 1 : 0;
        });
    };

    LocationModel.prototype.numberOfEmptyCylindersInUse = function () {
        let emptyCylindersInUse = this.getEmptyCylindersInUse();
        return emptyCylindersInUse? emptyCylindersInUse.length : 0;
    };

    LocationModel.prototype.hasEmptyCylindersInUse = function () {
        return this.numberOfEmptyCylindersInUse() > 0;
    };

    LocationModel.prototype.getEmptyCylinders = function () {
        return _.filter(this.getAllCylinders(), function (cyl) {
            return cyl.isEmpty;
        });
    };

    LocationModel.prototype.numberOfEmptyCylinders = function () {
        let emptyCylinders = this.getEmptyCylinders();
        return emptyCylinders? emptyCylinders.length : 0;
    };

    LocationModel.prototype.hasEmptyCylinders = function () {
        return this.numberOfEmptyCylinders() > 0;
    };

    LocationModel.prototype.allOk = function () {
        return !this.hasEmptyCylinders() && !this.hasFaultyCylinders() && !this.hasPendingOrders();
    };

    LocationModel.prototype.getEmptyCylindersNotInUse = function () {
        return _.filter(this.getAllCylinders(), function (cyl) {
            return cyl.isEmpty && cyl.operatingMode !== CylinderOperatingModes.GAS_DELIVERY && cyl.operatingMode !== CylinderOperatingModes.FAULT;
        });
    };

    LocationModel.prototype.numberOfEmptyCylindersNotInUse = function () {
        let emptyCylindersNotInUse = this.getEmptyCylindersNotInUse();
        return emptyCylindersNotInUse? emptyCylindersNotInUse.length : 0;
    };

    LocationModel.prototype.hasEmptyCylindersNotInUse = function () {
        return this.numberOfEmptyCylindersNotInUse() > 0;
    };

    LocationModel.prototype.matchesColladaId = function (colladaId) {
        var colladaIdNoPrefix = colladaId.split('location___')[1];
        if (colladaIdNoPrefix) {
            return this.idFor3DModelLocationName === colladaIdNoPrefix.replace(/_/g, ' ');
        }
        return false;
    };

    return LocationModel;
}

LocationFactory.$inject = ["Model", "HubModel", "CylinderOperatingModes"];