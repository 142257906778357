export function HospitalAdminNotifications($rootScope, $scope, SessionService, NotificationsService, LocationTypeListService, LocationTypesNoLocale, gettextCatalog, gettext, locations) {
    if (!SessionService.isCurrentRoleAnAdminRole()) {
        return;
    }

    $rootScope.navbarType = 'HOSPITALADMIN';

    var self = this;

    self.locations = locations;
    self.storeOnlyLocations = [];
    setStoreOnlyLocations();
    self.areCylindersEnabled = function () {
        return $rootScope.hospital === null || $rootScope.hospital.features.cylinder;
    };

    self.showLowStockNotifyees = function () {
        return self.storeOnlyLocations.length > 0 && NotificationsService.areLowStockNotificationsEnabled() && self.areCylindersEnabled();
    };

    self.lowStockNotificationType = NotificationsService.notificationTypes.lowStock;
    self.lowStockNotificationTitle = gettext('Low Stock Notification Recipients');

    self.showOverStockNotifyees = function () {
        return self.storeOnlyLocations.length > 0 && NotificationsService.areOverStockNotificationsEnabled() && self.areCylindersEnabled();
    };

    self.overStockNotificationType = NotificationsService.notificationTypes.overStock;
    self.overStockNotificationTitle = gettext('Overstock Notification Recipients');

    self.showLostHubNotifyees = NotificationsService.areLostHubNotificationsEnabled() && self.areCylindersEnabled();
    self.lostHubNotificationType = NotificationsService.notificationTypes.lostHubs;
    self.lostHubNotificationsTitle = gettext('Lost Hub Notification Recipients');

    self.showButtonPushNotifyees = NotificationsService.areButtonNotificationsEnabled();
    self.buttonPushNotificationType = NotificationsService.notificationTypes.buttonPush;
    self.buttonPushNotificationsTitle = gettext('REFRESH Button Press Notification Recipients');

    self.showButtonLowBatteryNotifyees = NotificationsService.areButtonNotificationsEnabled();
    self.buttonLowBatteryNotificationType = NotificationsService.notificationTypes.buttonLowBattery;
    self.buttonLowBatteryNotificationsTitle = gettext('REFRESH Button Low Battery Notification Recipients');

    self.showHospitalActionRequestGenerationNotifyees = NotificationsService.areHospitalActionRequestGenerationNotificationsEnabled() && self.areCylindersEnabled();
    self.actionRequestEmailNotificationType = NotificationsService.notificationTypes.actionRequest;
    self.actionRequestEmailNotificationTitle = gettext('Action Request Notification Recipients');

    function setStoreOnlyLocations() {
        // Filter the list of locations to be only those that are store types
        if (!self.locations) return;

        LocationTypeListService.findAll()
            .then(function (locationTypes) {
                var indexedStoreLocationTypes = {};
                _.forEach(locationTypes, function (locType) {
                    if (locType.name === LocationTypesNoLocale.CENTRAL_STORE || locType.name === LocationTypesNoLocale.DEPARTMENTAL_STORE) {
                        indexedStoreLocationTypes[locType.id] = locType;
                    }
                });
                return indexedStoreLocationTypes;
            })
            .then(function (indexedStoreLocationTypes) {
                _.forEach(locations, function (loc) {
                    if (indexedStoreLocationTypes[loc.locationTypeId]) {
                        self.storeOnlyLocations.push(loc);
                    }
                });
            });
    }
}

HospitalAdminNotifications.$inject = ["$rootScope", "$scope", "SessionService", "NotificationsService", "LocationTypeListService", "LocationTypesNoLocale", "gettextCatalog", "gettext", "locations"];