export function SuperadminUsers($rootScope, $scope, $timeout, SessionService, UserListService, SmartTableServerAdapter, roles, toastr, gettextCatalog, Roles) {
    if (!SessionService.isCurrentRoleIn([Roles.LINDE_SUPER_ADMINISTRATOR])) {
        return;
    }

    $rootScope.navbarType = 'SUPERADMIN';

    this.currentUser = $rootScope.currentUser;
    this.toastr = toastr;
    this.scope = $scope;
    this.timeout = $timeout;
    this.roles = roles;
    this.UserListService = UserListService;
    this.usersGrid = new SmartTableServerAdapter(UserListService);
    this.gettextCatalog = gettextCatalog;

    this.reset();
}

/**
 * @function Checks if the selected user is the same as the current user.  */
SuperadminUsers.prototype.isSelectedUserTheSameAsTheCurrentUser = function () {
    return this.currentUser.id === this.selection.id;
};

/**
 * @function Resets the user list. */
SuperadminUsers.prototype.reset = function () {
    this.scope.initialFocus = false;
    this.selection = null;
};

/**
 * @function Opens the edit pane for a user.
 */
SuperadminUsers.prototype.openEditPane = function (focusOnFieldId, existingUser) {
    var self = this;
    self.scope.initialFocus = false;
    self.existingUser = existingUser;
    self.timeout(function () {
        self.scope.initialFocus = focusOnFieldId;
        self.selection = self.UserListService.add(existingUser);
    });
};

/** @function Adds a new user to the user list. */
SuperadminUsers.prototype.add = function () {
    this.openEditPane('inputUserName');
};

SuperadminUsers.prototype.select = function (user) {
    var self = this;
    if (user) {
        self.UserListService.findOneByEmail(user.email).then(function (user) {
            self.openEditPane('inputEmail', user);
        });
    } else {
        this.reset();
    }
};

SuperadminUsers.prototype.current = function () {
    return this.selection;
};

SuperadminUsers.prototype.refreshUsers = function () {
    return this.usersGrid.refresh();
};

SuperadminUsers.prototype.save = function () {
    var self = this;
    var errroMsg = this.validateUser();
    if (!errroMsg.length) {
        if (self.selection) {
            if (self.selection !== self.existingUser) {
                // Only save if the item has actually changed
                self.UserListService.save(self.selection)
                    .then(function () {
                        self.refreshUsers();
                        self.toastr.success(
                            self.gettextCatalog.getString('User has been saved'),
                            self.gettextCatalog.getString('Saved')
                        );
                    });
            }
        }
        self.reset();
    } else {
        window.alert(errroMsg);
    }
};

SuperadminUsers.prototype.del = function () {
    var self = this;
    var confirmText = self.gettextCatalog.getString('Are you sure to delete the user {{username}}?', {username: self.existingUser.username});
    if (self.selection && confirm(confirmText)) {
        if (self.existingUser && !self.isSelectedUserTheSameAsTheCurrentUser()) {
            self.UserListService.del(self.existingUser).then(function () {
                self.refreshUsers();
                self.toastr.error(
                    self.gettextCatalog.getString('User has been deleted'),
                    self.gettextCatalog.getString('Deleted')
                );
            }).catch(function (error) {
                self.toastr.error(
                    self.gettextCatalog.getString("Error delete user"),
                    error
                );
            });
        }
    }
    self.reset();
};

SuperadminUsers.prototype.validateUser = function () {
    var self = this;
    var errorMsg = '';

    if (!self.selection.username || !(/^.+$/.test(self.selection.username))) {
        errorMsg += self.gettextCatalog.getString('Please enter a valid name') + '\r\n';
    }

    if (!(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(self.selection.email))) {
        errorMsg += self.gettextCatalog.getString('Please enter a valid email') + '\r\n';
    }
    if (!self.selection.role) {
        errorMsg += self.gettextCatalog.getString('Please select a role') + '\r\n';
    }

    if (!self.selection.password && !self.selection.id) {
        errorMsg += self.gettextCatalog.getString('Please enter a valid password') + '\n';
    }

    if (errorMsg.length > 0) {
        return errorMsg;
    }
    return true;
};

SuperadminUsers.$inject = ["$rootScope", "$scope", "$timeout", "SessionService", "UserListService", "SmartTableServerAdapter", "roles", "toastr", "gettextCatalog", "Roles"];