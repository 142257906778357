export function NurseOrderHistory($rootScope, $scope, $localStorage, orderRefreshIntervalMillis, SessionService, CylinderListService, OrderListService, SmartTableServerAdapter, locationList, gettext, Roles, ButtonsOrdersService, ButtonsFeatureService) {
    if (!SessionService.isCurrentRoleAnAdminRole() && !SessionService.verifyCurrentRole([Roles.NURSE])) {
        return;
    }
    $rootScope.navbarType = 'NURSE';

    var vm = this;

    if (locationList.length > 0) {
        vm.hasLocations = true;
    } else {
        return;
    }

    var persistedLocation = _.find(locationList, ['id', $localStorage.nurseLocation]);
    var usersDefaultLocation = _.find(locationList, ['id', $scope.currentUser.defaultLocationId]);

    vm.orderRefreshIntervalMillis = orderRefreshIntervalMillis;
    vm.updateOrderState = updateOrderState;
    vm.cancel = cancel;
    vm.cancelAll = cancelAll;
    vm.updateLocation = updateLocation;

    vm.CylinderListService = CylinderListService;
    vm.OrderListService = OrderListService;
    vm.locations = locationList;
    vm.location = persistedLocation || usersDefaultLocation || vm.locations[0];
    vm.showMultiLocations = $rootScope.hospital.features.nurseViewMultiLocations;
    vm.gettext = gettext;
    vm.buttonsEnabled = ButtonsFeatureService.isEnabled;
    vm.cylinderFeatureEnabled = $rootScope.hospital.features.cylinder;
    vm.buttonsOrdersService = ButtonsOrdersService;

    vm.createFilter = function (taskTypeFilter) {
        var filter = {
            buttonTaskTypeId: {
                regexp: taskTypeFilter
            }
        };
        if (!!vm.location) {
            filter.location = vm.location.name;
        }
        return filter;
    };

    vm.buttonsOtherOrdersGrid = new SmartTableServerAdapter(vm.buttonsOrdersService, function () {
        return vm.createFilter('/OT.*/i');
    });
    vm.buttonsCylinderOrdersGrid = new SmartTableServerAdapter(vm.buttonsOrdersService, function () {
        return vm.createFilter('/CT.*/i');
    });


    function getOrdersByLocationAndStatusFilter(status) {
        return function filterOrdersByLocationAndStatus() {
            var filter = {
                locationId: vm.location.id
            };
            if (status === 'new') {
                filter.acknowledgedTime = null;
                filter.fulfilledTime = null;
                filter.cancelledTime = null;
            } else if (status === 'acknowledged') {
                filter.acknowledgedTime = {neq: null};
                filter.fulfilledTime = null;
                filter.cancelledTime = null;
            } else {
                filter.acknowledgedTime = {neq: null};
                filter = {
                    and: [
                        filter,
                        {
                            or: [{fulfilledTime: {neq: null}}, {cancelledTime: {neq: null}}]
                        }
                    ]
                };
            }

            return filter;
        };
    }

    vm.newOrdersGrid = new SmartTableServerAdapter(OrderListService, getOrdersByLocationAndStatusFilter('new'));
    vm.acknowledgedOrdersGrid = new SmartTableServerAdapter(OrderListService, getOrdersByLocationAndStatusFilter('acknowledged'));
    vm.orderHistoryGrid = new SmartTableServerAdapter(OrderListService, getOrdersByLocationAndStatusFilter());


    updateLocationId($scope.currentUser.defaultLocationId);


    function updateLocationId(locationId) {
        var location = _.find(vm.locations, ['id', locationId]);
        if (location) {
            updateLocation(location);
        }
    }

    function updateLocation(location) {
        vm.location = location;
        $localStorage.nurseLocation = location.id;
        if (vm.buttonsEnabled()) {
            vm.buttonsOtherOrdersGrid.refresh();
            vm.buttonsCylinderOrdersGrid.refresh();
        } else {
            vm.newOrdersGrid.refresh();
            vm.acknowledgedOrdersGrid.refresh();
            vm.orderHistoryGrid.refresh();
        }
    }

    function updateOrderState(order, newState, msg) {
        var self = this;

        if (newState === 'cancelled') {
            order.cancelledTime = new Date();
            order.message = msg || self.gettext('cancelled by requester'); // ATTENTION: It's only stored into the database and it's never displayed, no need to localise
        } else if (newState === 'acknowledged' && !order.acknowledgedTime) {
            order.acknowledgedTime = new Date();
        } else if (newState === 'acknowledged' && !order.fulfilledTime) {
            order.fulfilledTime = new Date();
        }

        return OrderListService.save(order).then(function () {
            self.newOrdersGrid.refresh();
            self.acknowledgedOrdersGrid.refresh();
            self.orderHistoryGrid.refresh();
        });
    }

    function cancel(order, msg) {
        var self = this;
        order.cancelledTime = new Date();
        order.message = msg || self.gettext('cancelled by requester'); // ATTENTION: It's only stored into the database and it's never displayed, no need to localise
        return OrderListService.save(order).then(function () {
            self.newOrdersGrid.refresh();
            self.acknowledgedOrdersGrid.refresh();
            self.orderHistoryGrid.refresh();
        });
    }

    function cancelAll(currentState, msg) {
        var self = this;
        return OrderListService.cancelAllOrders(currentState, self.location.id, msg || self.gettext('cancelled by requester')) // ATTENTION: It's only stored into the database and it's never displayed, no need to localise
            .then(function () {
                self.newOrdersGrid.refresh();
                self.acknowledgedOrdersGrid.refresh();
                self.orderHistoryGrid.refresh();
            });
    }
}

NurseOrderHistory.$inject = ["$rootScope", "$scope", "$localStorage", "orderRefreshIntervalMillis", "SessionService", "CylinderListService", "OrderListService", "SmartTableServerAdapter", "locationList", "gettext", "Roles", "ButtonsOrdersService", "ButtonsFeatureService"];