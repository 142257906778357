export function CylindersDirective() {
    return {
        restrict: 'E',
        scope: {
            tableAdapter: '=',
            hideLocationColumn: '@',
            selectedLocationName: '=',
            defaultSort: '@',
            autoRefreshInterval: '@'
        },
        link: function (scope, element, attrs, controller) {
        },
        template: require('./liv-iq-cylinders.tpl.html')
    };
}