export function ButtonsOrdersSummary() {

    var self = this;
    var currentState;

    self.init = function (state) {
        currentState = state;
    };

    self.newOrdersCount = function () {
        return currentState ? currentState.newOrdersCount : 0;
    };

    self.hasAnyNewOrders = function () {
        return self.newOrdersCount() > 0;
    };
}