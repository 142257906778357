export function ButtonsOrdersDirective() {
    return {
        restrict: 'E',
        scope: {
            ctrl: '=',
            acceptedOrders: '=',
            allowActions: '=',
            showLocation: '='
        },
        template: require('./liv-iq-buttons-orders.tpl.html')
    };
}