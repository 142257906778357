export function ButtonsOrderHistoryDirective() {
    return {
        restrict: 'E',
        scope: {
            tableAdapter: '=',
            openOrderRejection: '&',
            cancelAllOrders: '&',
            cancelOrder: '&',
            hideLocationColumn: '@',
            hideAcknowledgedTimeColumn: '@',
            hideFulfilledAndCancelledTimeColumn: '@',
            hideRaisedAndFulfilledColumns: '@',
            hideNurseColumns: '@',
            selectedLocationName: '=',
            title: '@',
            autoRefreshInterval: '@'
        },
        link: function (scope, element, attrs, controller) {
            scope.allowOrderRejection = !!attrs.openOrderRejection;
            scope.allowOrderCancellation = !!attrs.cancelAllOrders;
            scope.allowAllOrdersCancellation = !!attrs.cancelOrder;
        },
        template: require('./liv-iq-buttons-order-history.tpl.html')
    };
}