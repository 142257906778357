import {HospitalAdminLostCylinders} from "../lostCylinders/lost-cylinders";

export function HospitalAdminCylinders($rootScope, $routeParams, $scope, $timeout, $interval, SessionService, cylinderRefreshIntervalMillis, CylinderListService, SmartTableServerAdapter, toastr, gettextCatalog) {
    if (!SessionService.isCurrentRoleAnAdminRole()) {
        return;
    }
    $rootScope.navbarType = 'HOSPITALADMIN';

    var self = this;
    self.rootScope = $rootScope;
    self.routeParams = $routeParams;
    self.cylinderRefreshIntervalMillis = cylinderRefreshIntervalMillis;
    self.toastr = toastr;
    self.scope = $scope;
    self.scope.readOnly = !(_.includes($rootScope.currentRole.name, 'Service') || _.includes($rootScope.currentRole.name, 'Linde'));
    self.timeout = $timeout;
    self.interval = $interval;
    self.CylinderListService = CylinderListService;
    self.gettextCatalog = gettextCatalog;
    self.reset();
    self.cylinderGrid = new SmartTableServerAdapter(self.CylinderListService, function () {
        if (self.routeParams.empty) {
            return {
                isLost: false,
                isEmpty: true
            };
        }
        return {};
    });
}

HospitalAdminCylinders.prototype.reset = function () {
    this.scope.initialFocus = false;
    this.selection = null;
};

HospitalAdminCylinders.prototype.openEditPane = function (focusOnFieldId, existingCylinder) {
    var self = this;
    self.scope.initialFocus = false;
    self.existingCylinder = existingCylinder;
    self.timeout(function () {
        self.scope.initialFocus = focusOnFieldId;
        self.selection = self.CylinderListService.add(existingCylinder);
    });
};

HospitalAdminCylinders.prototype.add = function () {
    this.openEditPane('inputMACAddress');
};

HospitalAdminCylinders.prototype.forceInsightsUpdate = function () {
    var self = this;
    self.CylinderListService.forceInsightsUpdate()
        .then(function (updateCount) {
            console.log('Forced Insights Update', updateCount.updatedCylinderCount);
        })
        .catch(function (err) {
            console.log('Insights update failed');
            if (process.env.NODE_ENV !== 'prod') {
                console.log(JSON.stringify(err));
            }
        });
};

HospitalAdminCylinders.prototype.select = function (cylinder) {
    if (cylinder) {
        this.openEditPane('inputCylinderName', cylinder);
    } else {
        this.reset();
    }
};

HospitalAdminCylinders.prototype.current = function () {
    return this.selection;
};

HospitalAdminCylinders.prototype.refreshCylinders = function () {
    var self = this;
    return self.cylinderGrid.refresh();
};

HospitalAdminCylinders.prototype.save = function () {
    var self = this;
    var errorMsg = this.validateCylinder();
    if (!errorMsg.length) {
        if (self.selection) {
            if (self.selection !== self.existingCylinder) {
                // Only save if the item has actually changed
                self.CylinderListService.save(self.selection)
                    .then(function () {
                        self.refreshCylinders();
                        self.toastr.success(
                            self.gettextCatalog.getString('Cylinder has been saved'),
                            self.gettextCatalog.getString('Saved')
                        );
                    });
            }
        }
        self.reset();
    } else {
        window.alert(errorMsg);
    }
};

HospitalAdminCylinders.prototype.del = function () {
    var self = this;
    if (self.selection) {
        if (self.existingCylinder) {
            self.CylinderListService.del(self.existingCylinder)
                .then(function () {
                    self.refreshCylinders();
                    self.toastr.error(
                        self.gettextCatalog.getString('Cylinder has been deleted'),
                        self.gettextCatalog.getString('Deleted')
                    );
                });
        }
    }
    self.reset();
};

HospitalAdminCylinders.prototype.validateCylinder = function () {
    var self = this;
    var errorMsg = '';

    if (!self.selection.valveMACAddress) {
        errorMsg += self.gettextCatalog.getString('Please enter a valid MAC address') + '\r\n';
    }
    if (!self.selection.name) {
        errorMsg += self.gettextCatalog.getString('Please enter a valid cylinder name') + '\r\n';
    }
    if (!self.selection.cylinderType) {
        errorMsg += self.gettextCatalog.getString('Please select a valid cylinder type') + '\r\n';
    }
    if (errorMsg.length > 0) {
        return errorMsg;
    }
    return true;
};

HospitalAdminCylinders.prototype.getCsvHeader = function () {
    var self = this;
    return [self.gettextCatalog.getString("Name"), self.gettextCatalog.getString("Description"), self.gettextCatalog.getString("Type"),
        self.gettextCatalog.getString("Last Known location"), self.gettextCatalog.getString("Last Seen")];
};

HospitalAdminCylinders.prototype.getCsvData = function () {
    return this.CylinderListService.allCylinders().then(function (cylinders) {
        return _.map(cylinders, function (cylinder) {
            return {
                name: cylinder.name,
                desc: cylinder.desc,
                label: cylinder.cylinderTypeLabel,
                location: cylinder.lastKnownLocation,
                lastSeen: cylinder.lastSeen
            };
        });
    });
};

HospitalAdminCylinders.$inject = ["$rootScope", "$routeParams", "$scope", "$timeout", "$interval", "SessionService", "cylinderRefreshIntervalMillis", "CylinderListService", "SmartTableServerAdapter", "toastr", "gettextCatalog"];