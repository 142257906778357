export function SwButtonsService($q, $rootScope, FlatSwButton, SwButton, ButtonsFeatureService) {
    var self = this;

    var dummyPromise = $q(function (resolve) {
        resolve();
    });

    function getHospital() {
        return $rootScope.appReady.promise;
    }

    function getBaseFilter(hospital) {
        return {
            filter: {
                where: {
                    hospitalId: hospital ? hospital.id : null
                },
                order: ['lastModified ASC']
            }
        };
    }

    self.getByLocation = function (locationId) {
        if (!ButtonsFeatureService.isEnabled()) return dummyPromise;

        return getHospital()
            .then(getBaseFilter)
            .then(function (filter) {
                filter.filter.where.locationId = locationId;
                return FlatSwButton.find(filter).$promise;
            });
    };

    self.create = function (button) {
        if (!ButtonsFeatureService.isEnabled()) return dummyPromise;

        return new SwButton(button).$save();
    };

    self.delete = function (button) {
        if (!ButtonsFeatureService.isEnabled()) return dummyPromise;

        return new SwButton(button).$prototype$removeButton();
    };
}

SwButtonsService.$inject = ["$q", "$rootScope", "FlatSwButton", "SwButton", "ButtonsFeatureService"];