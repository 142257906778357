export function HospitalFeaturesController($scope, $q, $uibModalInstance, HospitalFeaturesService, serviceLevels, selectedServiceLevel, hospitalFeatures, featureDefinitions, hospital, gettextCatalog) {
    var self = this;
    self.scope = $scope;
    self.q = $q;
    self.modalInstance = $uibModalInstance;
    self.HospitalFeaturesService = HospitalFeaturesService;
    self.serviceLevels = serviceLevels;
    self.selectedServiceLevel = selectedServiceLevel;
    self.featureDefinitions = featureDefinitions;
    self.hospital = hospital;
    self.hospitalFeatures = hospitalFeatures;
    self.gettextCatalog = gettextCatalog;
}

HospitalFeaturesController.prototype.selectServiceLevel = function (serviceLevel) {
    var self = this;
    console.log(serviceLevel);
    for (var i = 0; i < self.featureDefinitions.length; i++) {
        var propertyName = self.featureDefinitions[i].propertyName;
        if (self.hospitalFeatures.hasOwnProperty(propertyName) && serviceLevel.hasOwnProperty(propertyName)) {
            self.hospitalFeatures[propertyName] = serviceLevel[propertyName];
        }
    }
};

HospitalFeaturesController.prototype.save = function () {
    var self = this;
    self.hospital.features = self.hospitalFeatures;
    console.log('Hospital: %s', JSON.stringify(self.hospital));
    //don't know, but looks to me that should be the only things done, all the other are useles.
    //self.hospital.features = self.hospitalFeatures;
    self.hospital.serviceLevelId = null;
    return self.modalInstance.close(self.hospital);
};

HospitalFeaturesController.prototype.cancel = function () {
    this.modalInstance.dismiss('cancel');
};

HospitalFeaturesController.$inject = ["$scope", "$q", "$uibModalInstance", "HospitalFeaturesService", "serviceLevels", "selectedServiceLevel", "hospitalFeatures", "featureDefinitions", "hospital", "gettextCatalog"];