export function NurseDashboard($rootScope, $scope, $localStorage, Common, cylinderRefreshIntervalMillis, SessionService, CylinderListService, CylinderTypeListService, OrderListService, SmartTableServerAdapter, cylinderTypes, LocationListService, ButtonsFeatureService, ButtonsOrdersListService, RefreshService, gettextCatalog, Roles) {
    if (!SessionService.isCurrentRoleAnAdminRole() && !SessionService.verifyCurrentRole([Roles.NURSE])) {
        return;
    }
    $rootScope.navbarType = 'NURSE';

    var self = this;
    self.rootScope = $rootScope;
    self.gettextCatalog = gettextCatalog;
    self.scope = $scope;
    self.orderForm = [];
    self.cylinderRefreshIntervalMillis = cylinderRefreshIntervalMillis;
    self.cylinderTypes = Common.sortCylinderTypesAsPerOrderForm(cylinderTypes);
    self.animationsEnabled = true;
    self.alert = {
        acknowledged: {type: 'info', msg: ' ' + self.gettextCatalog.getString('new cylinder order(s) acknowledged!')},
        cancelled: {type: 'danger', msg: ' ' + self.gettextCatalog.getString('cylinder order(s) cancelled!')},
        completed: {type: 'success', msg: ' ' + self.gettextCatalog.getString('cylinder order(s) completed!')}
    };
    self.alertCounts = {};
    self.CylinderListService = CylinderListService;
    self.LocationListService = LocationListService;
    self.RefreshService = RefreshService;

    self.buttonsEnabled = ButtonsFeatureService.isEnabled;

    var buttonListOptions = {
        hardLocationFiltering: true,
        sortOldestTop: false,
        additionalRefreshAction: updateButtonGridsWithLocation
    };
    self.acceptedOrdersCtrl = ButtonsOrdersListService.createAcceptedOrdersController(buttonListOptions);
    self.newOrdersCtrl = ButtonsOrdersListService.createNewOrdersController(self.acceptedOrdersCtrl, buttonListOptions);


    self.cylinderGrid = new SmartTableServerAdapter(self.CylinderListService, function () {
        return {hubLocationId: self.location.id, isLost: false};
    }, {
        skipFirstServerCall: true // a hack to avoid calling the server twice when there is a default sort, see: https://github.com/lorenzofox3/Smart-Table/issues/329
    });

    self.totalNumberOfCylindersForLocation = function () {
        return self.cylinderGrid.totalItems || 0;
    };

    function updateButtonGridsWithLocation() {
        self.newOrdersCtrl.selectLocation(self.location);
        self.newOrdersCtrl.refresh();
        self.acceptedOrdersCtrl.selectLocation(self.location);
        self.acceptedOrdersCtrl.refresh();
    }

    function loadDashboardInfo() {
        self.LocationListService.findAll().then(function (locations) {
            self.hasLocations = locations.length > 0;
            self.locations = locations;

            var persistedLocation = _.find(self.locations, ['id', $localStorage.nurseLocation]);
            var usersDefaultLocation = _.find(self.locations, ['id', $scope.currentUser.defaultLocationId]);
            self.location = persistedLocation || usersDefaultLocation || self.locations[0];

            updateButtonGridsWithLocation();

            self.CylinderTypeListService.getSummaryByLocation(self.location.id).then(function (result) {
                self.cylinderSummary = result;
            });
        });
    }

    loadDashboardInfo();
    self.RefreshService.start(self.cylinderRefreshIntervalMillis, [loadDashboardInfo]);
    $scope.$on('$destroy', function () {
        self.RefreshService.stop();
    });

    self.closeAlert = function (status, locationName) {
        self.alertCounts[locationName][status] = 0;
    };
    self.OrderListService = OrderListService;
    self.CylinderTypeListService = CylinderTypeListService;
    self.inUseTable = {sortBy: 'displayPriority', reverse: false};

    self.showMultiLocations = $rootScope.hospital.features.nurseViewMultiLocations;
    self.showOrderForm = $rootScope.hospital.features.manualOrdering;

    self.limit = 5;
    self.orderTypes = [];
    self.currentTab = 'overview';
    self.updateLocation = function updateLocation(location) {
        self.location = location;
        $localStorage.nurseLocation = location.id;

        self.cylinderGrid.refresh();

        updateButtonGridsWithLocation();

        self.LocationListService.findAll().then(function (locations) {
            self.locations = locations;

            self.location = _.find(self.locations, ['id', location.id]);

            updateButtonGridsWithLocation();

            self.CylinderTypeListService.getSummaryByLocation(self.location.id).then(function (result) {
                self.cylinderSummary = result;
            });
        });
    };
}

NurseDashboard.prototype.toggleDetails = function () {
    this.showDetails = !this.showDetails;
};

NurseDashboard.prototype.commaSeparatedAnd = function (array) {
    if (_.isArray(array)) {
        return [array.slice(0, -1).join(', '), array.slice(-1)[0]].join(array.length < 2 ? '' : ' and ');
    }
    return '';
};

NurseDashboard.prototype.submitOrder = function submitOrder() {
    var self = this;

    self.orderError = false;
    self.orderSuccess = false;

    _.forEach(self.orderForm, function (cylinder) {
        if (typeof cylinder.orderQuantity !== 'undefined' && (isNaN(cylinder.orderQuantity) || cylinder.orderQuantity < 0)) {
            self.orderError = true;
        }
    });

    if (!self.orderError) {
        _.forEach(self.orderForm, function (cylinderType) {
            if (cylinderType.orderQuantity) {
                var orderParams = {
                    locationId: self.location.id,
                    cylinderTypeId: cylinderType.id,
                    orderType: 'User',
                    // username: $scope.currentUser.name,
                    raisedTime: new Date()
                };

                if (cylinderType.orderQuantity !== 0) {
                    for (var i = 0; i < cylinderType.orderQuantity; i++) {
                        var order = self.OrderListService.add(orderParams);
                        self.OrderListService.save(order);
                    }
                    self.orderSuccess = true;
                }
            }
            // reset form
            cylinderType.orderQuantity = '';
        });
        self.orderForm = [];
    }
};

NurseDashboard.prototype.refreshCylinders = function () {
    var self = this;

    self.CylinderTypeListService.getSummaryByLocation(self.location.id).then(function (result) {
        self.cylinderSummary = result;
    });
    return self.cylinderGrid.refresh();
};

NurseDashboard.prototype.refreshAlerts = function (oldOrders, newOrders, status) {
    var self = this;
    var oldIds = [],
        newIds = [];

    if (oldOrders[self.location.name] && oldOrders[self.location.name][status]) {
        oldIds = _.map(oldOrders[self.location.name][status], 'id');
    }

    if (newOrders[self.location.name] && newOrders[self.location.name][status]) {
        newIds = _.difference(_.map(self.orders[self.location.name][status], 'id'), oldIds);

        if (newIds.length > 0) {
            if (!self.alertCounts[self.location.name]) {
                self.alertCounts[self.location.name] = {};
            }
            if (!self.alertCounts[self.location.name][status]) {
                self.alertCounts[self.location.name][status] = 0;
            }

            self.alertCounts[self.location.name][status]++;
        }
    }
};

NurseDashboard.prototype.cylinderTypeDescription = function (cylType) {
    return cylType.name;
};

NurseDashboard.prototype.addToOrderForm = function (cylType) {
    if (cylType) {
        var matchingCylinder = _.filter(this.orderForm, function (order) {
            return order.id === cylType.id;
        })[0];
        if (matchingCylinder) {
            matchingCylinder.orderQuantity++;
        } else {
            cylType.orderQuantity = 1;
            this.orderForm.push(cylType);
        }
    }
};

NurseDashboard.prototype.removeFromOrderForm = function (cylinderTypeToRemove) {
    this.orderForm = _.remove(this.orderForm, function (cylinderTypeOrder) {
        return cylinderTypeOrder.id !== cylinderTypeToRemove.id;
    });
};

NurseDashboard.prototype.areCylindersEnabled = function () {
    //need to be evaluated here, because in the controller could be too early
    return !!this.rootScope.hospital && this.rootScope.hospital.features.cylinder;
};

NurseDashboard.$inject = ["$rootScope", "$scope", "$localStorage", "Common", "cylinderRefreshIntervalMillis", "SessionService", "CylinderListService", "CylinderTypeListService", "OrderListService", "SmartTableServerAdapter", "cylinderTypes", "LocationListService", "ButtonsFeatureService", "ButtonsOrdersListService", "RefreshService", "gettextCatalog", "Roles"];