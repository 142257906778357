import {CYLINDERS_MODULE} from "../../../services/cylinders/cylinders.module";

import {HospitalAdminCylinderVariables} from "./cylinderVariables";

export const HOSPITAL_ADMIN_CYLINDER_VARIABLES_MODULE = 'hospital.admin.cylinderVariables';

angular.module(HOSPITAL_ADMIN_CYLINDER_VARIABLES_MODULE, [CYLINDERS_MODULE]);

angular.module(HOSPITAL_ADMIN_CYLINDER_VARIABLES_MODULE)
    .controller('HospitalAdminCylinderVariables', HospitalAdminCylinderVariables);
