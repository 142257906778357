export function livIqWhenFalsyOrNaNFactory() {
    function livIqWhenFalsyOrNaN(value, placeholderToUseWhenFalsyOrNaN) {
        placeholderToUseWhenFalsyOrNaN = placeholderToUseWhenFalsyOrNaN === undefined ? '' : placeholderToUseWhenFalsyOrNaN;
        if (!value || isNaN(value)) {
            return placeholderToUseWhenFalsyOrNaN;
        }
        return value;
    }

    return livIqWhenFalsyOrNaN;
}