export function CommonTranslations($rootScope, LocaleService, gettextCatalog) {
    this.$rootScope = $rootScope;
    this.LocaleService = LocaleService;
    this.gettextCatalog = gettextCatalog;
    this._addScopeEventListeners();
    this._updateTranslations();
}

CommonTranslations.prototype._addScopeEventListeners = function () {
    this.$rootScope.$on(this.LocaleService.EVENT_LOCALE_CHANGED, (function () {
        this._updateTranslations();
    }).bind(this));
};

CommonTranslations.prototype._updateTranslations = function () {
    this.NOT_AVAILABLE_ABBREVIATION = this.gettextCatalog.getString('N/A');
};

CommonTranslations.$inject = ["$rootScope", "LocaleService", "gettextCatalog"];