export function HospitalAdminCylinderTypes($window, $rootScope, $scope, SessionService, CylinderListService, CylinderTypeListService, SmartTableClientAdapter, cylinderTypes, gases, valves, sizes) {
    if (!SessionService.isCurrentRoleAnAdminRole()) {
        return;
    }
    $rootScope.navbarType = 'HOSPITALADMIN';

    var vm = this;
    vm.scope = $scope;
    vm.window = $window;
    vm.scope.readOnly = !(_.includes($rootScope.currentRole.name, 'Service') || _.includes($rootScope.currentRole.name, 'Linde'));
    vm.CylinderTypeListService = CylinderTypeListService;
    vm.CylinderListService = CylinderListService;
    vm.cylinderTypes = cylinderTypes;
    // console.log (cylinderTypes);
    vm.gases = gases;
    vm.valves = valves;
    vm.sizes = sizes;
    vm.typeForm = {
        gas: gases[0],
        valve: valves[0],
        size: sizes[0]
    };

    vm.cylinderTypeGrid = new SmartTableClientAdapter(vm.cylinderTypes);
}

HospitalAdminCylinderTypes.prototype.refresh = function () {
    var self = this;
    return self.CylinderTypeListService.findAll().then(function (cylinderTypes) {
        self.cylinderTypes = cylinderTypes;
        self.cylinderTypeGrid.refresh(self.cylinderTypes);
        return cylinderTypes;
    });
};

HospitalAdminCylinderTypes.prototype.add = function (cylinderTypeParams) {
    const self = this;
    // console.log ('cylinderTypeParams:', cylinderTypeParams);
    let cylinderType = self.CylinderTypeListService.add(cylinderTypeParams);
    self.CylinderTypeListService.save(cylinderType).then(function () {
        self.updateCylinderTypesOnAdd(cylinderType);
        self.refresh();
    });
};

HospitalAdminCylinderTypes.prototype.updateCylinderTypesOnAdd = function (addedCylinderType) {
    const self = this;
    self.CylinderListService.findBySize(addedCylinderType).then(toBeUpdatedCylinders => {
        _.forEach(toBeUpdatedCylinders, cylinderToUpdate => {
            cylinderToUpdate.cylinderTypeId = addedCylinderType.id;
            self.CylinderListService.save(cylinderToUpdate);
        });
    });
};

HospitalAdminCylinderTypes.prototype.del = function (cylinderType) {
    var self = this;
    // console.log (cylinderType);
    self.CylinderTypeListService.del(cylinderType).then(() => {
        self.updateCylinderTypesOnDelete(cylinderType);
        self.refresh();
    });
};

HospitalAdminCylinderTypes.prototype.updateCylinderTypesOnDelete = function (deletedCylinderType) {
    const self = this;
    self.CylinderTypeListService.findByType(deletedCylinderType).then(newCylinderTypes => {
        let newCylinderType = _.find(newCylinderTypes, ['sizeml', deletedCylinderType.sizeml]);
        if (newCylinderType) {
            self.CylinderListService.findByType(deletedCylinderType.id)
                .then(cylinders => {
                    _.forEach(cylinders, cylinder => {
                        cylinder.cylinderTypeId = newCylinderType.id;
                        self.CylinderListService.save(cylinder);
                    });
                });
        }
    });
};

HospitalAdminCylinderTypes.$inject = ["$window", "$rootScope", "$scope", "SessionService", "CylinderListService", "CylinderTypeListService", "SmartTableClientAdapter", "cylinderTypes", "gases", "valves", "sizes"];