import jQuery from "jquery";

import 'jquery-ui/datepicker';

jQuery(function ($) {
  $.datepicker.regional.eo = {
    closeText         : '*****',
    prevText          : '&#x3C;****',
    nextText          : '****&#x3E;',
    currentText       : '****',
    monthNames        : ['*******', '********', '*****', '******', '****', '*****',
      '*****', '*******', '*********', '*******', '********', '********'],
    monthNamesShort   : ['***', '***', '***', '***', '***', '***',
      '***', '***', '***', '***', '***', '***'],
    dayNames          : ['*******', '*****', '*****', '********', '*****', '********', '******'],
    dayNamesShort     : ['***', '***', '***', '***', '***', '***', '***'],
    dayNamesMin       : ['**', '**', '**', '**', '**', '**', '**'],
    weekHeader        : '**',
    dateFormat        : 'dd/mm/yy',
    firstDay          : 0,
    isRTL             : false,
    showMonthAfterYear: false,
    yearSuffix        : ''
  };
  $.datepicker.setDefaults($.datepicker.regional.eo);
});
