import THREE from "threejs";
import moment from 'moment';

export function CylinderListService($rootScope, $q, Cylinder, FlatCylinder, FlatFaultyCylinderSummary, FlatEmptyCylinderSummary, FillPressurePlanService, LocationTypes, CylinderTypeService, CylinderTypeListService, SizeListService) {
    var self = this;
    self.rootScope = $rootScope;
    self.Cylinder = Cylinder;
    self.FlatCylinder = FlatCylinder;
    self.FlatFaultyCylinderSummary = FlatFaultyCylinderSummary;
    self.FlatEmptyCylinderSummary = FlatEmptyCylinderSummary;
    self.FillPressurePlanService = FillPressurePlanService;
    self.LocationTypes = LocationTypes;
    self.q = $q;
    self.CylinderTypeService = CylinderTypeService;
    self.CylinderTypeListService = CylinderTypeListService;
    self.SizeListService = SizeListService;

    FlatCylinder.STEPS = 16;
    FlatCylinder.FAULT_COLOR = new THREE.Color(0xFF8400);
    FlatCylinder.OK_COLOR = new THREE.Color(0x7fcc3f);
    FlatCylinder.EMPTY_COLOR = new THREE.Color(0xff173b);
    FlatCylinder.DELIVERY_COLOR = new THREE.Color(0x00ACFF);


    Object.defineProperty(Cylinder.prototype, 'cylinderTypeLabel', {
        get: function () {
            return self.CylinderTypeService.translateCylinderType(this.cylinderType);
        }
    });

    Object.defineProperties(FlatCylinder.prototype, {
        cylinderTypeLabel: {
            get: function () {
                return self.CylinderTypeService.translateCylinderType(this.cylinderType);
            }
        },
        scaledFillLevel: {
            get: function () {
                if (this.pressureCB === null) {
                    this.pressureCB = 0;
                }
                if (this.tag) {
                    return self.FillPressurePlanService.levelForPressureCBForTagInCurrentHospital(this.pressureCB);
                }
                return self.FillPressurePlanService.levelForPressureCBInCurrentHospital(this.pressureCB);
            }
        },
        statusColor: {
            get: function () {
                return FlatCylinder[this.status + '_COLOR'];
            }
        }
    });
}

CylinderListService.prototype.getBaseFilter = function (hospital) {
    return _.cloneDeep({
        filter: {
            where: {
                hospitalId: hospital.id,
                deleted: 0
            }
        }
    });
};

CylinderListService.prototype.getHospital = function () {
    return this.FillPressurePlanService.currentHospital;
};

CylinderListService.prototype.add = function (cylinder) {
    var newCylinder = new this.Cylinder(cylinder);
    // We don't have to wait for appReady because the "add" button appears after the app is ready
    newCylinder.hospitalId = this.rootScope.hospital.id;
    return newCylinder;
};

CylinderListService.prototype.find = function (name) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var filter = self.getBaseFilter(hospital);
        filter.filter.where.name = name;
        return self.Cylinder.find(filter).$promise;
    });
};

CylinderListService.prototype.findByLocation = function (locationId) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var filter = self.getBaseFilter(hospital);
        filter.filter.where.lastKnownLocationId = locationId;
        return self.Cylinder.find(filter).$promise;
    });
};

CylinderListService.prototype.findByType = function (typeId) {
    const self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        let filter = self.getBaseFilter(hospital);
        filter.filter.where.cylinderTypeId = typeId;
        return self.Cylinder.find(filter).$promise;
    });
};

CylinderListService.prototype.findCylindersWithTypes = function () {
    const self = this;
    const hospital = self.getHospital();
    const filter = _.cloneDeep({
        filter: {
            where: {
                hospitalId: hospital.id,
                deleted: 0,
                cylinderTypeId: {neq: null}
            }
        }
    });
    return self.Cylinder.find(filter).$promise;
};

CylinderListService.prototype.findCylindersWithGivenTypes = function (typesIds) {
    const self = this;
    const hospital = self.getHospital();
    const filter = _.cloneDeep({
        filter: {
            where: {
                hospitalId: hospital.id,
                deleted: 0,
                cylinderTypeId: {inq: typesIds}
            }
        }
    });
    return self.Cylinder.find(filter).$promise;
};

CylinderListService.prototype.findBySize = function (cylinderType) {
    const self = this;
    let hospital = self.getHospital();
    return self.CylinderTypeListService.findByTypeId(cylinderType.id).then(cylinderTypes => {
        return self.findCylindersWithTypes().then(cylindersWithTypes => {
            let cylinderTypeIds = _.map(cylindersWithTypes, cylinder => cylinder.cylinderTypeId);
            return self.CylinderTypeListService
                .findDeletedTypes(hospital, cylinderTypeIds).then(deletedTypes => {
                    let sizeIds = _.map(deletedTypes, type => type.sizeId);
                    return self.SizeListService
                        .findSizesWithGivenCapacity(hospital, sizeIds, cylinderTypes[0].sizeml).then(sizes => {
                            let sizeIdsForGivenCapacity = _.map(sizes, size => size.id);
                            return self.CylinderTypeListService
                                .findTypesWithGivenCapacity(hospital, sizeIdsForGivenCapacity).then(deletedTypesWithGivenCapacity => {
                                    let deletedTypesIds = _.map(deletedTypesWithGivenCapacity, deletedType => deletedType.id);
                                    return self.findCylindersWithGivenTypes(deletedTypesIds);
                                });
                        });
                });
        });
    });
};

CylinderListService.adjustFilter = function (filter) {
    let filterOrder = filter.filter.order;

    if (filterOrder) {
        if (filterOrder.includes('cylinderTypeLabel')) {
            filterOrder = filterOrder.replace('cylinderTypeLabel', 'cylinderType');
        }
        filterOrder = ['hasAlarm DESC', filterOrder, 'lastSeen DESC'];
    } else {
        filterOrder = ['hasAlarm DESC', 'lastSeen DESC'];
    }
    filter.filter.order = filterOrder;

    if (filter.filter.where.and) {
        let searchString = filter.filter.where.and[0].cylinderTypeLabel;
        if (searchString) {
            filter.filter.where.and = [
                {cylinderType: searchString},
                filter.filter.where.and[1]
            ];
        }
    }

    return filter;
};

CylinderListService.prototype.getPage = function (applyFilteringAndPagination) {
    let self = this;

    return this.rootScope.appReady.promise.then(function (hospital) {
        let filter = self.getBaseFilter(hospital);

        applyFilteringAndPagination(filter.filter);
        filter = CylinderListService.adjustFilter(filter);

        return self.FlatCylinder.getPage(filter)
            .$promise
            .then(function (data) {
                return self.q.resolve({
                    items: _.map(data.items, function (flatCylinder) {
                        flatCylinder = CylinderListService.mapOperatingModesForDisplaying(flatCylinder);
                        return new self.FlatCylinder(flatCylinder);
                    }),
                    totalCount: data.totalCount
                });
            });
    });
};

CylinderListService.mapOperatingModesForDisplaying = function (flatCylinder) {
    switch (flatCylinder.operatingMode) {
        case 'STANDBY':
            flatCylinder.operatingMode = 'OFF';
            flatCylinder.operatingModeTranslated = true;
            break;
        case 'LOW-FLOW':
            flatCylinder.operatingMode = 'STANDBY';
            flatCylinder.operatingModeTranslated = true;
            break;
        default:
    }
    return flatCylinder;
};

CylinderListService.mapOperatingModesForSaving = function (cylinder) {
    if (!!cylinder.operatingModeTranslated) {
        switch (cylinder.operatingMode) {
            case 'OFF':
                cylinder.operatingMode = 'STANDBY';
                break;
            case 'STANDBY':
                cylinder.operatingMode = 'LOW-FLOW';
                break;
            default:
        }
    }
    delete cylinder.operatingModeTranslated;
    return cylinder;
};

CylinderListService.prototype.count = function () {
    var self = this;

    return this.rootScope.appReady.promise.then(function (hospital) {
        var filter = self.getBaseFilter(hospital);
        filter.filter.where.isLost = false;

        return self.FlatCylinder.count(filter.filter)
            .$promise
            .then(function (result) {
                return self.q.resolve(result.count);
            });
    });
};

CylinderListService.prototype.findAll = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var filter = self.getBaseFilter(hospital);
        return self.Cylinder.find(filter).$promise;
    });
};

CylinderListService.prototype.allCylinders = function () {
    var self = this;

    return this.rootScope.appReady.promise.then(function (hospital) {
        var filter = {
            filter: {
                where: {
                    hospitalId: hospital.id,
                    deleted: 0
                }
            }
        };
        return self.FlatCylinder.getPage(filter)
            .$promise
            .then(function (cylinders) {
                return _.map(cylinders.items, function (cylinder) {
                    return new self.FlatCylinder(cylinder);
                });
            });
    });
};

CylinderListService.prototype.lostCylinder = function () {
    var self = this;

    return this.rootScope.appReady.promise.then(function (hospital) {
        var filter = {
            filter: {
                where: {
                    hospitalId: hospital.id,
                    isLost: true,
                    lostToInsights: true,
                    deleted: 0
                }
            }
        };
        return self.FlatCylinder.getPage(filter)
            .$promise
            .then(function (cylinders) {
                return _.map(cylinders.items, function (cylinder) {
                    return new self.FlatCylinder(cylinder);
                });
            });
    });
};

CylinderListService.prototype.save = function (cylinder) {
    if (cylinder) {
        if (cylinder.lastKnownLocation) {
            cylinder.lastKnownLocationId = cylinder.lastKnownLocation.id;
            delete cylinder.lastKnownLocation;
        }
        if (cylinder.hub) {
            if (cylinder.hubId !== cylinder.hub.id) {
                cylinder.hubId = cylinder.hub.id;
                cylinder.lastSeen = new Date();
                if (cylinder.lastKnownLocationId !== cylinder.hub.locationId) {
                    cylinder.lastKnownLocationId = cylinder.hub.locationId;
                    cylinder.lastLocationChange = cylinder.lastSeen;
                }
            }
            delete cylinder.hub;
        }
        cylinder.deleted = 0;
        cylinder = CylinderListService.mapOperatingModesForSaving(cylinder);
        return cylinder.$save();
    }
    return false;
};

CylinderListService.prototype.del = function (cylinder) {
    if (cylinder) {
        cylinder.deleted = new Date().getTime();
        return cylinder.$save();
    }
    return false;
};

CylinderListService.prototype.getNameById = function (id, cylinderTypes) {
    var cylinderType = _.find(cylinderTypes, ['id', id]);
    if (!cylinderType) {
        return '--';
    }
    return cylinderType.gas.gasType + ' ' + cylinderType.valve.valveType + ' ' + cylinderType.size.sizeCode;
};

CylinderListService.prototype.forceInsightsUpdate = function () {
    var self = this;
    return self.rootScope.appReady.promise.then(function (hospital) {
        return self.Cylinder.forceInsightsUpdate({hospitalId: hospital.id}).$promise;
    });
};

CylinderListService.prototype.groupByLocationGasTypeAndSize = function (rawSummaryData) {
    var toReturn = {
        summary: {},
        locations: [],
        cylinderTypes: [],
        countBySize: {},
        countByType: {},
        sizeCount: 0,
        typeCount: 0,
        totalCount: 0
    };
    _.forEach(rawSummaryData, function (data) {
        if (!toReturn.summary[data.location]) {
            toReturn.summary[data.location] = {};
            toReturn.summary[data.location].id = data.locationId;
            toReturn.locations.push(data.location);
        }
        if (!toReturn.summary[data.location][data.cylinderType]) {
            toReturn.summary[data.location][data.cylinderType] = Number(data.count);
            if (!_.includes(toReturn.cylinderTypes, data.cylinderType)) {
                toReturn.cylinderTypes.push(data.cylinderType);
            }
        }
        if (toReturn.countBySize[data.sizeCode]) {
            toReturn.countBySize[data.sizeCode] += Number(data.count);
        } else if (Number(data.count) > 0) {
            toReturn.countBySize[data.sizeCode] = Number(data.count);
            toReturn.sizeCount++;
        }
        if (toReturn.countByType[data.cylinderType]) {
            toReturn.countByType[data.cylinderType] += Number(data.count);
        } else if (Number(data.count) > 0) {
            toReturn.countByType[data.cylinderType] = Number(data.count);
            toReturn.typeCount++;
        }
        toReturn.totalCount += Number(data.count);
    });
    return toReturn;
};

CylinderListService.prototype.faultyCylinderSummary = function () {
    var self = this;
    return self.rootScope.appReady.promise.then(function (hospital) {
        var filter = _.cloneDeep({
            filter: {
                where: {
                    hospitalId: hospital.id,
                    deleted: 0
                }
            }
        });

        return self.FlatFaultyCylinderSummary.find(filter)
            .$promise
            .then(function (rawFaultyCylinderSummary) {
                return self.q(function (resolve) {
                    resolve(self.groupByLocationGasTypeAndSize(rawFaultyCylinderSummary));
                });
            });
    });
};

CylinderListService.prototype.untrackedCylinderCount = function () {
    var self = this;
    var since72Hours = moment()
        .subtract(72, 'hours')
        .toDate();
    return self.rootScope.appReady.promise.then(function (hospital) {
        var filter = {
            filter: {
                where: {
                    hospitalId: hospital.id,
                    isLost: true,
                    lostToInsights: true,
                    lastSeen: {
                        lt: since72Hours
                    },
                    deleted: 0
                }
            }
        };
        return self.FlatCylinder.count(filter.filter).$promise;
    });
};

CylinderListService.prototype.totalCylinderCount = function () {
    var self = this;
    return self.rootScope.appReady.promise.then(function (hospital) {
        var filter = {
            filter: {
                where: {
                    hospitalId: hospital.id,
                    deleted: 0
                }
            }
        };
        return self.FlatCylinder.count(filter.filter).$promise;
    });
};

CylinderListService.prototype.trackedEmpty = function () {
    var self = this;
    return self.rootScope.appReady.promise.then(function (hospital) {
        var filter = {
            filter: {
                where: {
                    hospitalId: hospital.id,
                    isLost: false,
                    lostToInsights: false,
                    isEmpty: true,
                    lastSeen: {
                        neq: null
                    },
                    deleted: 0
                }
            }
        };
        return self.FlatCylinder.count(filter.filter).$promise;
    });
};

CylinderListService.prototype.trackedPartial = function () {
    var self = this;
    return self.rootScope.appReady.promise.then(function (hospital) {
        var filter = {
            filter: {
                where: {
                    hospitalId: hospital.id,
                    isLost: false,
                    lostToInsights: false,
                    isFull: false,
                    isEmpty: false,
                    lastSeen: {
                        neq: null
                    },
                    deleted: 0
                }
            }
        };
        return self.FlatCylinder.count(filter.filter).$promise;
    });
};

CylinderListService.prototype.trackedFull = function () {
    var self = this;
    return self.rootScope.appReady.promise.then(function (hospital) {
        var filter = {
            filter: {
                where: {
                    hospitalId: hospital.id,
                    isLost: false,
                    lostToInsights: false,
                    isFull: true,
                    lastSeen: {
                        neq: null
                    },
                    deleted: 0
                }
            }
        };
        return self.FlatCylinder.count(filter.filter).$promise;
    });
};

// untracked: last seen in gas store (id == 1) or cylinder is lost and lostToInsights
CylinderListService.prototype.untrackedUnderThree = function () {
    var self = this;
    var since72Hours = moment()
        .subtract(72, 'hours')
        .toDate();
    return self.rootScope.appReady.promise.then(function (hospital) {
        var filter = {
            filter: {
                where: {
                    hospitalId: hospital.id,
                    or: [
                        {
                            hospitalId: hospital.id,
                            isLost: true,
                            lostToInsights: true,
                            lastSeen: {
                                neq: null,
                                gte: since72Hours
                            },
                            lastKnownLocationType: 'Central Store',
                            isUsablyFull: true,
                            deleted: 0
                        },
                        {
                            hospitalId: hospital.id,
                            isLost: true,
                            lostToInsights: true,
                            lastSeen: {
                                neq: null,
                                gte: since72Hours
                            },
                            lastKnownLocationType: {neq: 'Central Store'},
                            deleted: 0
                        }
                    ]
                }
            }
        };
        return self.FlatCylinder.count(filter.filter).$promise;
    });
};

CylinderListService.prototype.untrackedOverThree = function () {
    var self = this;
    var since72Hours = moment()
        .subtract(72, 'hours')
        .toDate();
    return self.rootScope.appReady.promise.then(function (hospital) {
        var filter = {
            filter: {
                where: {
                    hospitalId: hospital.id,
                    or: [
                        {
                            hospitalId: hospital.id,
                            isLost: true,
                            lostToInsights: true,
                            lastSeen: {
                                neq: null,
                                lt: since72Hours
                            },
                            lastKnownLocationType: 'Central Store',
                            isUsablyFull: true,
                            deleted: 0
                        },
                        {
                            hospitalId: hospital.id,
                            isLost: true,
                            lostToInsights: true,
                            lastSeen: {
                                neq: null,
                                lt: since72Hours
                            },
                            lastKnownLocationType: {neq: 'Central Store'},
                            deleted: 0
                        }
                    ]
                }
            }
        };
        return self.FlatCylinder.count(filter.filter).$promise;
    });
};

CylinderListService.prototype.untrackedInTransit = function () {
    var self = this;
    return self.rootScope.appReady.promise.then(function (hospital) {
        var filter = {
            filter: {
                where: { // last seen in gas store, not usably full, lost
                    hospitalId: hospital.id,
                    isLost: true,
                    lostToInsights: true,
                    lastKnownLocationType: 'Central Store',
                    isUsablyFull: false,
                    lastSeen: {
                        neq: null
                    },
                    deleted: 0
                }
            }
        };
        return self.FlatCylinder.count(filter.filter).$promise;
    });
};

CylinderListService.$inject = ["$rootScope", "$q", "Cylinder", "FlatCylinder", "FlatFaultyCylinderSummary", "FlatEmptyCylinderSummary", "FillPressurePlanService", "LocationTypes", "CylinderTypeService", "CylinderTypeListService", "SizeListService"];