const MAC_REGEXP = /^([0-9A-F]{2}[:]){5}([0-9A-F]{2})$/;

export function validMacAddress() {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ctrl) {
            var capitalize = function (inputValue) {
                var capitalised = inputValue ? inputValue.toUpperCase() : '';
                ctrl.$setViewValue(capitalised);
                ctrl.$render();
                return capitalised;
            };
            ctrl.$parsers.push(capitalize);

            ctrl.$validators.validMacAddress = function (modelValue, viewValue) {
                if (ctrl.$isEmpty(modelValue) || MAC_REGEXP.test(viewValue)) {
                    return true;
                }
                // it is invalid
                return false;
            };
        }
    };
}