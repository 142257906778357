import angularRoute from "angular-route";
import "ngstorage";

import {firstJourneyConfig} from "../firstJourney/config.route";
import {signInConfig} from "./config.route";
import {SigninController} from "./signin";
import {ActivateUserController} from "../activateUser/activateUser";
import {RecoverPasswordWithEmailController} from "../recoverPassword/recoverPassword";

export const SIGN_IN_MODULE = 'signin';

angular.module(SIGN_IN_MODULE, ['ngStorage', angularRoute]);

angular.module(SIGN_IN_MODULE)
    .config(firstJourneyConfig)
    .config(signInConfig);

angular.module(SIGN_IN_MODULE)
    .controller('SigninController', SigninController)
    .controller('ActivateUserController', ActivateUserController)
    .controller('RecoverPasswordWithEmailController', RecoverPasswordWithEmailController);