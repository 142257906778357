/* Use this to easily connect an angular-smart-table to a loopback api
 * */

export function SmartTableServerAdapterFactory($localStorage, $timeout, FilteringAndPaginationApplicatorFactory) {
    Object.defineProperties(SmartTableServerAdapter.prototype, {
        itemsPerPage: {
            get: function () {
                return $localStorage.stItemsPerPage || 25;
            },
            set: function (val) {
                $localStorage.stItemsPerPage = val;
            }
        }
    });

    function SmartTableServerAdapter(service, getFilter, options, mapping) {
        var self = this;
        this.isLoading = true;
        this.service = service;
        this.totalItems = 0;
        this.displayedCollection = [];
        this.getFilter = getFilter || function () {
            return {};
        };
        this.mapping = typeof mapping === 'function' ? mapping : function (items) {
            return items;
        };

        this.tableState = {
            pagination: {
                start: 0,
                number: this.itemsPerPage
            },
            sort: {},
            search: {}
        };

        this.isLoading = false;
        var skippedCallServerCount = 0;
        var callServerCount = 0;
        this.callServer = callServerExceptFirstTime;

        // a hack to avoid calling the server twice when there is a default sort, see: https://github.com/lorenzofox3/Smart-Table/issues/329
        function callServerExceptFirstTime(tableState) {
            if (options && options.skipFirstServerCall && (!skippedCallServerCount || skippedCallServerCount < 1)) {
                skippedCallServerCount = 1;
                return;
            }

            callServer(tableState);
            callServerCount++;
        }

        function callServer(tableState) {
            self.isLoading = true;

            tableState = tableState || self.tableState;
            self.tableState = tableState;

            var filteringAndPaginationApplicator = FilteringAndPaginationApplicatorFactory.getApplicator(tableState, self.getFilter());
            return self.service.getPage(filteringAndPaginationApplicator)
                .then(function (result) {
                    self.totalItems = result.totalCount;
                    tableState.pagination.totalItemCount = result.totalCount; // set the number of pages so the pagination can update
                    tableState.pagination.numberOfPages = Math.ceil(tableState.pagination.totalItemCount / tableState.pagination.number); // set the number of pages so the pagination can update
                    self.isLoading = false;

                    self.displayedCollection = self.mapping(result.items, self.displayedCollection);
                    return self.displayedCollection;
                });
        }
    }

    SmartTableServerAdapter.prototype.refresh = function () {
        return this.callServer();
    };

    return SmartTableServerAdapter;
}

SmartTableServerAdapterFactory.$inject = ["$localStorage", "$timeout", "FilteringAndPaginationApplicatorFactory"];