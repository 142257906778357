import gettext from "angular-gettext";

import {LocaleRun} from "./locale.run";
import {LocaleService} from "./locale.service";

export const I18N_MODULE = 'i18n.liv-iq-hospital';

angular.module(I18N_MODULE, [gettext]);

angular.module(I18N_MODULE)
    .factory('LocaleService', LocaleService);

angular.module(I18N_MODULE)
    .run(LocaleRun);