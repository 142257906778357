import {CYLINDERS_MODULE} from "../../../services/cylinders/cylinders.module";
import {ORDERS_MODULE} from "../../../services/orders/orders.module";
import {LOCATIONS_MODULE} from "../../../services/locations/locations.module";
import {BUTTONS_MODULE} from "../../../services/buttons/buttons.module";
import {LAYOUT_MODULE} from "../../../layout/layout.module";

import {PorterDashboard} from "./dashboard";
import {ButtonsOrdersSummary} from "./buttonOrdersSummary";

export const PORTER_DASHBOARD_MODULE = 'porter.dashboard';

angular.module(PORTER_DASHBOARD_MODULE, [CYLINDERS_MODULE, ORDERS_MODULE, LOCATIONS_MODULE, BUTTONS_MODULE, LAYOUT_MODULE]);

angular.module(PORTER_DASHBOARD_MODULE)
    .controller('PorterDashboard', PorterDashboard)
    .controller('ButtonsOrdersSummary', ButtonsOrdersSummary);