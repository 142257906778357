export function HospitalAdminCylinderVariables($rootScope, $scope, gettextCatalog, SessionService, GasListService, gases, ValveListService, valves, SizeListService, sizes) {
    if (!SessionService.isCurrentRoleAnAdminRole()) {
        return;
    }
    $rootScope.navbarType = 'HOSPITALADMIN';

    var vm = this;
    vm.gettextCatalog = gettextCatalog;
    vm.scope = $scope;
    vm.scope.readOnly = !(_.includes($rootScope.currentRole.name, 'Service') || _.includes($rootScope.currentRole.name, 'Linde'));
    vm.gases = gases;
    vm.GasListService = GasListService;
    vm.sizes = sizes;
    vm.SizeListService = SizeListService;
    vm.valves = valves;
    vm.ValveListService = ValveListService;
    vm.gasForm = {};
    vm.valveForm = {};
    vm.sizeForm = {};

    // console.log(sizes);
}

HospitalAdminCylinderVariables.prototype.gasTypes = function () {
    var self = this;
    return [{
        type: 'Oxygen',
        label: self.gettextCatalog.getString('Oxygen')
    }, {
        type: 'Unknown',
        label: self.gettextCatalog.getString('Unknown')
    }];
};

HospitalAdminCylinderVariables.prototype.refreshGases = function () {
    var self = this;
    return self.GasListService.findAll().then(function (gases) {
        self.gases = gases;
        return gases;
    });
};

HospitalAdminCylinderVariables.prototype.refreshValves = function () {
    var self = this;
    return self.ValveListService.findAll().then(function (valves) {
        self.valves = valves;
        return valves;
    });
};

HospitalAdminCylinderVariables.prototype.refreshSizes = function () {
    var self = this;
    return self.SizeListService.findAll().then(function (sizes) {
        self.sizes = sizes;
        return sizes;
    });
};

HospitalAdminCylinderVariables.prototype.addGas = function (gas) {
    var self = this;
    console.log(gas);
    var gasModel = self.GasListService.addGas({gasType: gas.gasType.type});
    self.GasListService.saveGas(gasModel).then(function () {
        return self.refreshGases();
    });
};

HospitalAdminCylinderVariables.prototype.addValve = function (valve) {
    var self = this;
    var valveModel = self.ValveListService.addValve(valve);
    self.ValveListService.saveValve(valveModel).then(function () {
        return self.refreshValves();
    });
};

HospitalAdminCylinderVariables.prototype.addSize = function (size) {
    var self = this;
    var sizeModel = self.SizeListService.addSize(size);
    self.SizeListService.saveSize(sizeModel).then(function () {
        return self.refreshSizes();
    });
};

HospitalAdminCylinderVariables.prototype.removeGas = function (gas) {
    var self = this;
    self.GasListService.del(gas).then(function () {
        return self.refreshGases();
    });
};

HospitalAdminCylinderVariables.prototype.removeValve = function (valve) {
    var self = this;
    self.ValveListService.del(valve).then(function () {
        return self.refreshValves();
    });
};

HospitalAdminCylinderVariables.prototype.removeSize = function (size) {
    var self = this;
    self.SizeListService.del(size).then(function () {
        return self.refreshSizes();
    });
};

HospitalAdminCylinderVariables.$inject = ["$rootScope", "$scope", "gettextCatalog", "SessionService", "GasListService", "gases", "ValveListService", "valves", "SizeListService", "sizes"];