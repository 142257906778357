export function HospitalAdminUsers($rootScope, $scope, $timeout, $http, SessionService, UserListService, SmartTableServerAdapter, locations, roles, toastr, gettextCatalog) {
    if (!SessionService.isCurrentRoleAnAdminRole()) {
        return;
    }
    $rootScope.navbarType = 'HOSPITALADMIN';

    this.http = $http;
    this.currentUser = $rootScope.currentUser;
    this.hospital = $rootScope.hospital;
    this.toastr = toastr;
    this.scope = $scope;
    this.scope.readOnly = false;
    this.timeout = $timeout;
    this.UserListService = UserListService;
    this.userGrid = new SmartTableServerAdapter(UserListService);
    this.locations = locations;
    this.roles = roles;
    this.gettextCatalog = gettextCatalog;

    var self = this;

    this.languages = [{id: 'en_GB', name: 'English (United Kingdom)'}];
    this.http.get('json/lang.json')
        .then(function (res) {
            self.languages = _.filter(res.data, function (l) {
                return l.debug !== true;
            });
        });

    this.reset();
}

/**
 * @function Checks if the selected user is the same as the current user.  */
HospitalAdminUsers.prototype.isSelectedUserTheSameAsTheCurrentUser = function () {
    return this.currentUser.id === this.selection.id;
};

/**
 * @function Resets the user list. */
HospitalAdminUsers.prototype.reset = function () {
    this.scope.initialFocus = false;
    this.selection = null;
};

/**
 * @function Opens the edit pane for a user.
 */
HospitalAdminUsers.prototype.openEditPane = function (focusOnFieldId, existingUser) {
    var self = this;
    self.scope.initialFocus = false;
    self.scope.readOnly = false;
    self.existingUser = existingUser;
    if (existingUser && !_.find(self.roles, function (role) {
        return role.name === existingUser.role.name;
    })) {
        self.scope.readOnly = true;
    }
    self.timeout(function () {
        self.scope.initialFocus = focusOnFieldId;
        self.selection = self.UserListService.add(existingUser);
        self.selection.langSelect = _.find(self.languages, function (language) {
            return language.id === self.selection.langSelect;
        });
    });
};

/** @function Adds a new user to the user list. */
HospitalAdminUsers.prototype.add = function () {
    this.openEditPane('inputUserName');
};

HospitalAdminUsers.prototype.select = function (user) {
    var self = this;
    if (user) {
        self.UserListService.findOneByEmail(user.email).then(function (user) {
            self.openEditPane('inputEmail', user);
        });
    } else {
        self.reset();
    }
};

HospitalAdminUsers.prototype.current = function () {
    return this.selection;
};

HospitalAdminUsers.prototype.refreshUsers = function () {
    console.log("HospitalAdminUsers refreshUsers")
    return this.userGrid.refresh();
};

HospitalAdminUsers.prototype.save = function () {
    console.log("HospitalAdminUsers save")
    var self = this;
    var errroMsg = this.validateUser();
    if (!errroMsg.length) {
        if (self.selection) {
            if (self.selection !== self.existingUser) {
                // Only save if the item has actually changed
                if (self.selection.langSelect && self.selection.langSelect.id) {
                    self.selection.langSelect = self.selection.langSelect.id;
                }
                self.UserListService.save(self.selection)
                    .then(function () {
                        self.refreshUsers();
                        self.toastr.success(
                            self.gettextCatalog.getString('User has been saved'),
                            self.gettextCatalog.getString('Saved')
                        );
                        self.reset();
                    })
                    .catch(function (err) {
                        console.log('Error:', err);
                    });
            }
        }
    } else {
        window.alert(errroMsg);
    }
};

HospitalAdminUsers.prototype.del = function () {
    var self = this;
    var confirmText = self.gettextCatalog.getString('Are you sure to delete the user {{username}}?', {username: self.existingUser.username});
    if (self.selection && window.confirm(confirmText)) {
        if (self.existingUser && !self.isSelectedUserTheSameAsTheCurrentUser()) {
            self.UserListService.del(self.existingUser).then(function () {
                self.refreshUsers();
                self.toastr.error(
                    self.gettextCatalog.getString('User has been deleted'),
                    self.gettextCatalog.getString('Deleted')
                );
            }).catch(function (error) {
                self.toastr.error(
                    self.gettextCatalog.getString("Error delete user"),
                    error
                );
            });
        }
    }
    self.reset();
};

HospitalAdminUsers.prototype.validateUser = function () {
    var self = this;
    var errorMsg = '';

    if (!self.selection.username || !(/^.+$/.test(self.selection.username))) {
        errorMsg += self.gettextCatalog.getString('Please enter a valid name') + '\r\n';
    }

    if (!(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(self.selection.email))) {
        errorMsg += self.gettextCatalog.getString('Please enter a valid email') + '\r\n';
    }
    if (!self.selection.role) {
        errorMsg += self.gettextCatalog.getString('Please select a role') + '\r\n';
    }

    if (!self.hospital.features.userEmailAddressValidation && !self.selection.password && !self.selection.id) {
        errorMsg += self.gettextCatalog.getString('Please enter a valid password') + '\n';
    }

    if (errorMsg.length > 0) {
        return errorMsg;
    }
    return true;
};

HospitalAdminUsers.$inject = ["$rootScope", "$scope", "$timeout", "$http", "SessionService", "UserListService", "SmartTableServerAdapter", "locations", "roles", "toastr", "gettextCatalog"];