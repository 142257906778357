import moment from 'moment';

const fontOptions = {
    color: '#333333',
    family: "'LindeDaxPowerPoint', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    style: 'normal',
    size: 14
};

//directive is needed in order to embed the report in the dashboard page
export function ReportButtonResponses() {
    return {
        restrict: 'E',
        scope: {},
        controller: HospitalAdminButtonsResponsesReport,
        controllerAs: 'ctrl',
        bindToController: true,
        template: require('./report-buttons-responses.tpl.html')
    };
}

export function HospitalAdminButtonsResponsesReport($rootScope, $q, ReportService, ColourMapService, CommonTranslations, gettextCatalog) {
    $rootScope.navbarType = 'HOSPITALADMIN';

    const self = this;
    self.dataAvailable = true; // Avoid showing the 'No data' indication initially
    self.chart = undefined;

    self.timeIntervals = ReportService.reportPeriods;
    self.selectedTimeInterval = _.find(self.timeIntervals, function (interval) {
        return interval.key === ReportService.defaultInterval().key;
    });

    self.selectedButtonType = undefined;

    self.timeIntervalChanged = function () {
        return getReport();
    };

    self.buttonTypeChanged = function (buttonType) {
        self.selectedButtonType = buttonType;
        getReport();
    };

    function getReport() {
        if (!self.selectedButtonType) {
            return $q(function (resolve) {
                return resolve(false);
            });
        }

        return ReportService.buttonStatsReport(self.selectedTimeInterval.key, self.selectedButtonType.key)
            .then(function (result) {
                const filteredStats = filterAndSortStats(result);
                self.dataAvailable = !!filteredStats.length;
                updateChart(filteredStats);
            })
            .catch(function (err) {
                console.error(err);
                self.dataAvailable = false;
            });
    }

    function filterAndSortStats(stats) {
        // Don't want task type specific stats, nor stats for locations where nothing has been acknowledged
        const filtered = _.filter(stats, function (s) {
            return !s.buttontasktypeid && !!s.avgacktime;
        })
            .map(function (s) {
                if (!s.location) {
                    s.location = gettextCatalog.getString('Hospital (Total)');
                    s.isWholeHospital = true;
                } else {
                    s.isWholeHospital = false;
                }
                return s;
            });

        return _.sortBy(filtered, function (stat) {
            return stat.isWholeHospital ? '' : stat.location;
        });
    }

    function updateChart(stats) {
        const labels = _.map(stats, function (s) {
            return s.location;
        });

        const ackData = _.map(stats, function (s) {
            return s.avgacktime ? moment.duration(s.avgacktime, 'seconds').asHours() : undefined;
        });

        const fulfillData = _.map(stats, function (s) {
            return s.avgfulfilltime ? moment.duration(s.avgfulfilltime, 'seconds').asHours() : undefined;
        });

        if (!self.chart) {
            createChart(labels, ackData, fulfillData);
        } else {
            const steps = calculateSteps(ackData, fulfillData);

            self.chart.data.labels = labels;
            self.chart.data.datasets[0].data = ackData;
            self.chart.data.datasets[1].data = fulfillData;
            self.chart.options.scales.yAxes[0].ticks.stepSize = steps;
            self.chart.options.scales.yAxes[0].ticks.suggestedMax = calculateSuggestedMax(steps);
            self.chart.update();
        }
    }

    function calculateSteps(ackData, fulfillData) {
        const maxValue = Math.max(_.max(ackData), _.max(fulfillData));
        if (maxValue < 1) {
            const inverse = 1 / maxValue;
            const numberOfDigit = Math.ceil(Math.log10(inverse));
            return Math.pow(10, -1 * numberOfDigit);
        } else {
            return (Math.ceil(maxValue / 10) * 10) / 10;
        }
    }

    function calculateSuggestedMax(steps) {
        let suggestMax = 1;
        suggestMax = steps * 12;
        return suggestMax;
    }

    function createChart(labels, ackData, fulfillData) {
        const datasets = createDatasets(ackData, fulfillData);

        const steps = calculateSteps(ackData, fulfillData);
        const chartCtx = document.getElementById('responses-chart');
        self.chart = new Chart(chartCtx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: datasets
            },
            options: {
                legend: {
                    position: 'bottom',
                    labels: addFontSettingsTo({})
                },
                scales: {
                    xAxes: [{
                        stacked: true,
                        ticks: addFontSettingsTo({})
                    }],
                    yAxes: [{
                        stacked: true,
                        ticks: addFontSettingsTo({
                            suggestedMax: calculateSuggestedMax(steps),
                            stepSize: steps
                        }),
                        scaleLabel: addFontSettingsTo({
                            display: true,
                            labelString: gettextCatalog.getString('Response Time (hours)')
                        })
                    }]
                },
                tooltips: {
                    callbacks: {
                        title: function (item, data) {
                            const dsLabel = data.datasets[item[0].datasetIndex].label;
                            const title = item[0].xLabel;
                            return title + ' ' + dsLabel;
                        },
                        label: function (item, data) {
                            const value = data.datasets[item.datasetIndex].data[item.index];
                            if (value) {
                                return moment.duration(value, 'hours').humanize();
                            } else {
                                return CommonTranslations.NOT_AVAILABLE_ABBREVIATION;
                            }
                        }
                    }
                }
            }
        });
    }

    function createDatasets(ackData, fulfillData) {
        const datasetProperties = [
            {
                label: gettextCatalog.getString('Avg. Acceptance Time'),
                data: ackData
            },
            {
                label: gettextCatalog.getString('Avg. Fulfill Time'),
                data: fulfillData
            }
        ];
        ColourMapService.paint(datasetProperties, 0.8, 1);

        return _.map(datasetProperties, function (dsp) {
            return {
                label: dsp.label,
                data: dsp.data,
                backgroundColor: dsp.colour.light,
                hoverBackgroundColor: dsp.colour.medium,
                borderColour: 'rgb(255,255,255)',
                hoverBorderColour: 'rgb(255,255,255)',
                borderWidth: 1
            };
        });
    }

    function addFontSettingsTo(obj) {
        obj.fontColor = fontOptions.color;
        obj.fontFamily = fontOptions.family;
        obj.fontStyle = fontOptions.style;
        obj.fontSize = fontOptions.size;
        return obj;
    }
}

HospitalAdminButtonsResponsesReport.$inject = ["$rootScope", "$q", "ReportService", "ColourMapService", "CommonTranslations", "gettextCatalog"];