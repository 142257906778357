import {HospitalAdminButtonsList} from "./buttonsList/buttons-list";
import {HospitalAdminButtonHistory} from "./history/button-history";
import {HospitalAdminButtonsTasksList} from "./buttonsTasksList/buttons-tasks-list";

export const HOSPITAL_ADMIN_BUTTONS_MODULE = 'hospital.admin.buttons';

angular.module(HOSPITAL_ADMIN_BUTTONS_MODULE, []);

angular.module(HOSPITAL_ADMIN_BUTTONS_MODULE)
    .controller('HospitalAdminButtonsList', HospitalAdminButtonsList)
    .controller('HospitalAdminButtonHistory', HospitalAdminButtonHistory)
    .controller('HospitalAdminButtonsTasksList', HospitalAdminButtonsTasksList);