import moment from 'moment';

export function HospitalAdminActionRequest($rootScope, $scope, ReportService, ColourMapService, locations, hospitalReport, toastr, gettextCatalog) {
    $rootScope.navbarType = 'HOSPITALADMIN';

    this.rootScope = $rootScope;
    this.scope = $scope;
    this.ReportService = ReportService;
    this.ColourMapService = ColourMapService;
    this.locations = locations;
    this.gettextCatalog = gettextCatalog;
    this.locations.unshift({
        name: this.gettextCatalog.getString('Hospital'),
        id: null
    });
    this.report = hospitalReport;
    this.actionRequest = this.report.actionRequest;
    this.selectedLocation = _.first(this.locations);
    this.toastr = toastr;
    this.timeIntervals = this.ReportService.reportPeriods;
    this.scope.timeInterval = {
        name: this.gettextCatalog.getString('Last 30 Days'),
        id: '3',
        key: 'last30Days',
        text: this.gettextCatalog.getString('30 days')
    };
    this.timeInterval = this.ReportService.defaultInterval();
    this.reportName = this.gettextCatalog.getString('Hospital');

    var ctrl = this;

    //this should be a function
    let cylinderTypes = ctrl.report.cylinderTypes;
    cylinderTypes = ColourMapService.paint(cylinderTypes, 0.8, 1);
    if (ctrl.report.actionRequest) {
        cylinderTypes = ctrl.report.actionRequest.history.cylinderTypes;
        cylinderTypes = ColourMapService.paint(cylinderTypes, 0.8, 1);
        const timeLine = _.map(ctrl.report.actionRequest.history.time.from, function (time) {
            return moment(time).format('D MMM YY');
        });
        const stockHistoryDataSets = _.map(cylinderTypes, function (cylinderType) {
            return {
                label: cylinderType.name,
                backgroundColor: cylinderType.colour.light,
                borderColor: 'rgb(255,255,255)',
                borderWidth: 1,
                hoverBackgroundColor: cylinderType.colour.medium,
                hoverBorderColor: 'rgb(255,255,255)',
                data: ctrl.report.actionRequest.history.avgCountByCylinderType[cylinderType.name]
            };
        });

        const stockHistoryLocBarCTX = document.getElementById('action-request-bar');
        const stockHistoryLocBarData = {
            labels: timeLine,
            datasets: stockHistoryDataSets
        };

        ctrl.stockHistoryBarChart = new Chart(stockHistoryLocBarCTX, {
            type: 'bar',
            data: stockHistoryLocBarData,
            options: {
                legend: {
                    position: 'bottom'
                },
                scales: {
                    xAxes: [{
                        stacked: true
                    }],
                    yAxes: [{
                        stacked: true
                    }]
                }
            }
        });
    }
}

HospitalAdminActionRequest.prototype.refresh = function () {
    const self = this;

    let from,
        to;

    if (self.scope.timeInterval.key === 'yesterday') {
        from = moment().subtract(1, 'day').startOf('day').toDate();
        to = moment().subtract(1, 'day').endOf('day').toDate();
    } else if (self.scope.timeInterval.key === 'last7Days') {
        from = moment().subtract(7, 'day').startOf('day').toDate();
        to = moment().subtract(1, 'day').endOf('day').toDate();
    } else if (self.scope.timeInterval.key === 'last30Days') {
        from = moment().subtract(30, 'day').startOf('day').toDate();
        to = moment().subtract(1, 'day').endOf('day').toDate();
    } else if (self.scope.timeInterval.key === 'last3Months') {
        from = moment().subtract(3, 'months').startOf('months').toDate();
        to = moment().subtract(1, 'months').endOf('months').toDate();
    } else if (self.scope.timeInterval.key === 'last12Months') {
        from = moment().subtract(12, 'months').startOf('months').toDate();
        to = moment().subtract(1, 'months').endOf('months').toDate();
    } else if (self.scope.timeInterval.key === 'last24Months') {
        from = moment().subtract(24, 'months').startOf('months').toDate();
        to = moment().subtract(1, 'months').endOf('months').toDate();
    }

    if (self.selectedLocation && self.selectedLocation.id) {
        self.reportName = self.selectedLocation.name;
        self.ReportService.cachedLocationReport(self.scope.timeInterval.key, self.selectedLocation.id)
            .then(function (report) {
                self.report = report;

                if (self.report.actionRequest) {
                    var cylinderTypes = self.report.actionRequest.history.cylinderTypes;
                    cylinderTypes = self.ColourMapService.paint(cylinderTypes, 0.8, 1);
                    var timeLine = _.map(self.report.actionRequest.history.time.from, function (time) {
                        if (self.report.intervalType === 'hour') {
                            return moment(time).format('D MMM YY HH:mm');
                        }
                        return moment(time).format('D MMM YY');
                    });
                    var stockHistoryDataSets = _.map(cylinderTypes, function (cylinderType) {
                        return {
                            label: cylinderType.name,
                            backgroundColor: cylinderType.colour.light,
                            borderColor: 'rgb(255,255,255)',
                            borderWidth: 1,
                            hoverBackgroundColor: cylinderType.colour.medium,
                            hoverBorderColor: 'rgb(255,255,255)',
                            data: self.report.actionRequest.history.avgCountByCylinderType[cylinderType.name]
                        };
                    });

                    self.stockHistoryBarChart.data.labels = timeLine;
                    self.stockHistoryBarChart.data.datasets = stockHistoryDataSets;
                    self.stockHistoryBarChart.update();
                }
            });
    } else {
        self.reportName = self.gettextCatalog.getString('Hospital');
        self.ReportService.cachedDashboardHospitalReport(self.scope.timeInterval.key)
            .then(function (report) {
                self.report = report;

                if (self.report.actionRequest) {
                    var cylinderTypes = self.report.actionRequest.history.cylinderTypes;
                    cylinderTypes = self.ColourMapService.paint(cylinderTypes, 0.8, 1);
                    var timeLine = _.map(self.report.actionRequest.history.time.from, function (time) {
                        if (self.report.intervalType === 'hour') {
                            return moment(time).format('D MMM YY HH:mm');
                        }
                        return moment(time).format('D MMM YY');
                    });
                    var stockHistoryDataSets = _.map(cylinderTypes, function (cylinderType) {
                        return {
                            label: cylinderType.name,
                            backgroundColor: cylinderType.colour.light,
                            borderColor: 'rgb(255,255,255)',
                            borderWidth: 1,
                            hoverBackgroundColor: cylinderType.colour.medium,
                            hoverBorderColor: 'rgb(255,255,255)',
                            data: self.report.actionRequest.history.avgCountByCylinderType[cylinderType.name]
                        };
                    });

                    self.stockHistoryBarChart.data.labels = timeLine;
                    self.stockHistoryBarChart.data.datasets = stockHistoryDataSets;
                    self.stockHistoryBarChart.update();
                }
            });
    }
};

HospitalAdminActionRequest.$inject = ["$rootScope", "$scope", "ReportService", "ColourMapService", "locations", "hospitalReport", "toastr", "gettextCatalog"];