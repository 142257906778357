export function HospitalAdminLostCylinders($rootScope, $scope, $timeout, $interval, $q, SessionService, cylinderRefreshIntervalMillis, CylinderListService, RefreshService, SmartTableServerAdapter, toastr, gettext, LocationTypes, gettextCatalog) {
    if (!SessionService.isCurrentRoleAnAdminRole()) {
        return;
    }
    $rootScope.navbarType = 'HOSPITALADMIN';

    var self = this;
    self.$q = $q;
    self.gettext = gettext;
    $scope.hospital = $rootScope.hospital;
    self.gettextCatalog = gettextCatalog;
    self.cylinderRefreshIntervalMillis = cylinderRefreshIntervalMillis;
    self.toastr = toastr;
    self.scope = $scope;
    self.scope.readOnly = !(_.includes($rootScope.currentRole.name, 'Service') || _.includes($rootScope.currentRole.name, 'Linde'));
    self.timeout = $timeout;
    self.interval = $interval;
    self.RefreshService = RefreshService;
    self.CylinderListService = CylinderListService;
    self.LocationTypes = LocationTypes;
    self.reset();
    self.lostCylinderGrid = new SmartTableServerAdapter(self.CylinderListService, function () {
        return {
            isLost: true,
            lostToInsights: true
        };
    });

    function refresh() {
        return self.lostCylinderGrid.refresh();
    }

    self.RefreshService.start(self.cylinderRefreshIntervalMillis, [refresh]);
    $scope.$on('$destroy', function () {
        self.RefreshService.stop();
    });
}

HospitalAdminLostCylinders.prototype.reset = function () {
    this.scope.initialFocus = false;
    this.selection = null;
};

HospitalAdminLostCylinders.prototype.openEditPane = function (focusOnFieldId, existingCylinder) {
    var self = this;
    self.scope.initialFocus = false;
    self.existingCylinder = existingCylinder;
    self.timeout(function () {
        self.scope.initialFocus = focusOnFieldId;
        self.selection = self.CylinderListService.add(existingCylinder);
    });
};

HospitalAdminLostCylinders.prototype.select = function (cylinder) {
    if (cylinder) {
        this.openEditPane('inputCylinderName', cylinder);
    } else {
        this.reset();
    }
};

HospitalAdminLostCylinders.prototype.current = function () {
    return this.selection;
};

HospitalAdminLostCylinders.prototype.refresh = function () {
    return this.lostCylinderGrid.refresh();
};


HospitalAdminLostCylinders.prototype.refresh = function () {
    return this.lostCylinderGrid.refresh();
};

HospitalAdminLostCylinders.prototype.getCsvHeader = function () {
    var self = this;
    return [self.gettextCatalog.getString("Name"), self.gettextCatalog.getString("Description"), self.gettextCatalog.getString("Type"),
        self.gettextCatalog.getString("Last Known location"), self.gettextCatalog.getString("Last Seen")];
};

HospitalAdminLostCylinders.prototype.getCsvData = function () {
    return this.CylinderListService.lostCylinder().then(function (cylinders) {
        return _.map(cylinders, function (cylinder) {
            return {
                name: cylinder.name,
                desc: cylinder.desc,
                label: cylinder.cylinderTypeLabel,
                location: cylinder.lastKnownLocation,
                lastSeen: cylinder.lastSeen
            };
        });
    });
};

HospitalAdminLostCylinders.$inject = ["$rootScope", "$scope", "$timeout", "$interval", "$q", "SessionService", "cylinderRefreshIntervalMillis", "CylinderListService", "RefreshService", "SmartTableServerAdapter", "toastr", "gettext", "LocationTypes", "gettextCatalog"];