const routeBase = 'hospital/admin';

export function config($routeProvider) {
    $routeProvider
        .when('/' + routeBase + '/auditLogs', {
            template: require('./auditLogs/auditLogs.tpl.html'),
            controller: 'HospitalAdminAuditLogs',
            controllerAs: 'auditLogsCtrl'
        })
        .when('/' + routeBase + '/configuration', {
            template: require('./configuration/configuration.tpl.html'),
            controller: 'HospitalAdminConfiguration',
            controllerAs: 'configurationCtrl',
            resolve: {
                configParameters: ['ConfigParametersListService', '$rootScope', function (ConfigParametersListService, $rootScope) {
                    return ConfigParametersListService.findById($rootScope.hospital.configParametersId);
                }]
            },
        })
        .when('/' + routeBase + '/notifications', {
            template: require('./configuration/notifications.tpl.html'),
            controller: 'HospitalAdminNotifications',
            controllerAs: 'notificationsCtrl',
            resolve: {
                locations: ['LocationListService', '$rootScope', function (LocationListService, $rootScope) {
                    return LocationListService.find();
                }]
            }
        })
        .when('/' + routeBase + '/cylinders', {
            template: require('./cylinders/cylinders.tpl.html'),
            controller: 'HospitalAdminCylinders',
            controllerAs: 'cylinderListCtrl'
        })
        .when('/' + routeBase + '/cylinderTypes', {
            template: require('./cylinderTypes/cylinderTypes.tpl.html'),
            controller: 'HospitalAdminCylinderTypes',
            controllerAs: 'cTypeCtrl',
            resolve: {
                gases: ['GasListService', function (GasListService) {
                    return GasListService.findAll();
                }],
                valves: ['ValveListService', function (ValveListService) {
                    return ValveListService.findAll();
                }],
                sizes: ['SizeListService', function (SizeListService) {
                    return SizeListService.findAll();
                }],
                cylinderTypes: ['CylinderTypeListService', function (CylinderTypeListService) {
                    return CylinderTypeListService.findAll();
                }]
            }
        })
        .when('/' + routeBase + '/cylinderUsage', {
            template: require('./cylinderUsage/cylinderUsage.tpl.html'),
            controller: 'HospitalAdminCylinderUsage',
            controllerAs: 'cylinderUsageCtrl',
            resolve: {
                UsageList: ['CylinderUsageService', function (CylinderUsageService) {
                    return CylinderUsageService.findClosedUsages();
                }],
            }

        })
        .when('/' + routeBase + '/cylinderVariables', {
            template: require('./cylinderVariables/cylinderVariables.tpl.html'),
            controller: 'HospitalAdminCylinderVariables',
            controllerAs: 'cVarCtrl',
            resolve: {
                gases: ['GasListService', function (GasListService) {
                    return GasListService.findAll();
                }],
                valves: ['ValveListService', function (ValveListService) {
                    return ValveListService.findAll();
                }],
                sizes: ['SizeListService', function (SizeListService) {
                    return SizeListService.findAll();
                }],
            }
        })
        .when('/' + routeBase + '/locations', {
            template: require('./locations/locations.tpl.html'),
            controller: 'HospitalAdminLocations',
            controllerAs: 'locationListCtrl',
            resolve: {
                locations: ['LocationListService', function (LocationListService) {
                    return LocationListService.findAll();
                }],
                cylinderTypes: ['CylinderTypeListService', function (CylinderTypeListService) {
                    return CylinderTypeListService.findAll();
                }],
                hubs: ['HubListService', function (HubListService) {
                    return HubListService.findAll();
                }],
                locationTypes: ['LocationTypeListService', function (LocationTypeListService) {
                    return LocationTypeListService.findAll();
                }]
            }
        })
        .when('/' + routeBase + '/hubs', {
            template: require('./hubs/hubs.tpl.html'),
            controller: 'HospitalAdminHubs',
            controllerAs: 'hubListCtrl',
            resolve: {
                cylinders: ['CylinderListService', function (CylinderListService) {
                    return CylinderListService.findAll();
                }],
                locations: ['LocationListService', function (LocationListService) {
                    return LocationListService.findAll();
                }],
            }
        })
        .when('/' + routeBase + '/usageLogs', {
            template: require('./usageLogs/usageLogs.tpl.html'),
            controller: 'HospitalAdminUsageLogs',
            controllerAs: 'usageLogsCtrl',
            resolve: {
                usageStats: ['UsageLogsService', function (UsageLogsService) {
                    return UsageLogsService.findAll();
                }],
                orderStats: ['OrderListService', function (OrderListService) {
                    return OrderListService.getOrderStats();
                }]
            }
        })
        .when('/' + routeBase + '/users', {
            template: require('./users/users.tpl.html'),
            controller: 'HospitalAdminUsers',
            controllerAs: 'userListCtrl',
            resolve: {
                users: ['UserListService', function (UserListService) {
                    return UserListService.findAll()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }],
                roles: ['RoleListService', function (RoleListService) {
                    return RoleListService.findAll(true)
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }],
                locations: ['LocationListService', function (LocationListService) {
                    return LocationListService.findAll()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }]
            }
        })
        .when('/' + routeBase + '/reports/stock', {
            template: require('./reports/stock/report-stock.tpl.html'),
            controller: 'HospitalAdminActionRequest',
            controllerAs: 'actionRequestCtrl',
            resolve: {
                locations: ['LocationListService', function (LocationListService) {
                    return LocationListService.findAll()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }],
                hospitalReport: ['ReportService',function (ReportService) {
                    return ReportService.defaultCachedDashboardHospitalReport()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }]
            }
        })
        .when('/' + routeBase + '/reports/oxygen/stored', {
            template: require('./reports/oxygen-stored/report-oxygen-stored.tpl.html'),
            controller: 'HospitalAdminOxygenStoredReport',
            controllerAs: 'oxygenStoredReportCtrl',
            resolve: {
                actionRequest: ['ReportService', function (ReportService) {
                    return ReportService.defaultActionRequest()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }],
                locations: ['LocationListService', function (LocationListService) {
                    return LocationListService.findAll()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }]
            }
        })
        .when('/' + routeBase + '/reports/cylinder/dwelltime', {
            template: require('./reports/cylinder-dwelltime/report-cylinder-dwelltime.tpl.html'),
            controller: 'HospitalAdminCylinderDwellTimeReport',
            controllerAs: 'cylinderDwellTimeReportCtrl',
            resolve: {
                hospitalReport: ['ReportService', function (ReportService) {
                    return ReportService.defaultCachedDashboardHospitalReport()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }]
            }
        })
        .when('/' + routeBase + '/reports/cylinder/emptyPressure', {
            template: require('./reports/cylinder-empty-pressure/report-cylinder-empty-pressure.tpl.html'),
            controller: 'HospitalAdminCylinderEmptyPressureReport',
            controllerAs: 'cylinderEmptyPressureCtrl',
            resolve: {
                hospitalReport: ['ReportService', function (ReportService) {
                    return ReportService.defaultCachedHospitalReport()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }]
            }
        })
        .when('/' + routeBase + '/reports/cylinder/emptyToReturn', {
            template: require('./reports/cylinder-empty-to-return/report-cylinder-empty-to-return.tpl.html'),
            controller: 'HospitalAdminCylinderEmptyToReturnReport',
            controllerAs: 'cylinderEmptyToReturnCtrl',
            resolve: {
                hospitalReport: ['ReportService', function (ReportService) {
                    return ReportService.defaultCachedHospitalReport()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }]
            }
        })
        .when('/' + routeBase + '/reports/cylinder/emptyReplacement', {
            template: require('./reports/cylinder-empty-replacement/report-cylinder-empty-replacement-time.tpl.html'),
            controller: 'HospitalAdminCylinderEmptyReplacementTimeReport',
            controllerAs: 'cylinderEmptyReplacementCtrl',
            resolve: {
                emptyReplacementReport: ['ReportService', function (ReportService) {
                    return ReportService.defaultEmptyReplacementTimeReport()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }],
                locations: ['LocationListService', function (LocationListService) {
                    return LocationListService.findAll()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }]
            }
        })
        .when('/' + routeBase + '/reports/oxygen/used', {
            template: require('./reports/oxygen-used/report-oxygen-used.tpl.html'),
            controller: 'HospitalAdminOxygenUsedReport',
            controllerAs: 'oxygenUsedCtrl',
            resolve: {
                hospitalReport: ['ReportService', function (ReportService) {
                    return ReportService.defaultHospitalReport()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }]
            }
        })
        .when('/' + routeBase + '/cylinders/lost', {
            template: require('./lostCylinders/lost-cylinders.tpl.html'),
            controller: 'HospitalAdminLostCylinders',
            controllerAs: 'lostCylinderListCtrl'
        })
        .when('/' + routeBase + '/reports/cylinder/firstUse', {
            template: require('./reports/cylinder-first-use/report-cylinder-first-use.tpl.html'),
            controller: 'HospitalAdminCylinderFirstUseReport',
            controllerAs: 'cylinderFirstUseCtrl',
            resolve: {
                cylinderFirstUse: ['ReportService', function (ReportService) {
                    return ReportService.defaultCachedHospitalReport()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }],
                locations: ['LocationListService', function (LocationListService) {
                    return LocationListService.findAll()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }],
                hospitalReport: ['ReportService', function (ReportService) {
                    return ReportService.defaultCachedDashboardHospitalReport()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }]
            }
        })
        .when('/' + routeBase + '/reports/cylinder/alarm', {
            template: require('./reports/cylinder-alarm/report-cylinder-alarm.tpl.html'),
            controller: 'HospitalAdminCylinderAlarmReport',
            controllerAs: 'cylinderAlarmCtrl',
            resolve: {
                locations: ['LocationListService', function (LocationListService) {
                    return LocationListService.findAll()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }]
            }
        })
        .when('/' + routeBase + '/reports/cylinder/treatment', {
            template: require('./reports/cylinder-treatment/report-cylinder-treatment.tpl.html'),
            controller: 'HospitalAdminCylinderTreatmentReport',
            controllerAs: 'cylinderTreatmentCtrl',
            resolve: {
                cylinderTreatment: ['ReportService', function (ReportService) {
                    return ReportService.defaultCylinderTreatmentReport()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }],
                locations: ['LocationListService', function (LocationListService) {
                    return LocationListService.findAll()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }]
            }
        })
        .when('/' + routeBase + '/reports/buttons/stats', {
            template: require('./reports/buttons/report-buttons-stats.tpl.html'),
            controller: 'HospitalAdminButtonsStatsReport',
            controllerAs: 'ctrl',
            resolve: {
                locations: ['LocationListService', function (LocationListService) {
                    return LocationListService.findAll()
                        .catch(function (err) {
                            console.error('Error getting locations for button stats report', err);
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }]
            }
        })
        .when('/' + routeBase + '/reports/buttons/responses', {
            template: require('./reports/buttons/report-buttons-responses.tpl.html'),
            controller: 'HospitalAdminButtonsResponsesReport',
            controllerAs: 'ctrl',
            resolve: {}
        })
        .when('/' + routeBase + '/button-dashboard', {
            template: require('./dashboard/button-dashboard.tpl.html'),
            controller: 'HospitalAdminButtonDashboard',
            controllerAs: 'ctrl',
            resolve: {}
        })
        .when('/' + routeBase + '/dashboard', {
            template: require('./dashboard/dashboard.tpl.html'),
            controller: 'HospitalAdminDashboard',
            controllerAs: 'adminDashboardCtrl',
            resolve: {
                stockHospitalDashboardReport: ['StockListService', function (StockListService) {
                    return StockListService.stockHospitalDashboardReport()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }],
                untrackedCylinderCount: ['CylinderListService', function (CylinderListService) {
                    return CylinderListService.untrackedCylinderCount()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }],

                totalCylinderCount: ['CylinderListService', function (CylinderListService) {
                    return CylinderListService.totalCylinderCount()
                        .catch(function (err) {
                            return [];
                        });
                }],

                trackedEmpty: ['CylinderListService', function (CylinderListService) {
                    'ngInjext';

                    return CylinderListService.trackedEmpty()
                        .catch(function (err) {
                            return [];
                        });
                }],
                trackedPartial: ['CylinderListService', function (CylinderListService) {
                    'ngInjext';

                    return CylinderListService.trackedPartial()
                        .catch(function (err) {
                            return [];
                        });
                }],
                trackedFull: ['CylinderListService', function (CylinderListService) {
                    'ngInjext';

                    return CylinderListService.trackedFull()
                        .catch(function (err) {
                            return [];
                        });
                }],
                untrackedUnderThree: ['CylinderListService', function (CylinderListService) {
                    'ngInjext';

                    return CylinderListService.untrackedUnderThree()
                        .catch(function (err) {
                            return [];
                        });
                }],
                untrackedOverThree: ['CylinderListService', function (CylinderListService) {
                    'ngInjext';

                    return CylinderListService.untrackedOverThree()
                        .catch(function (err) {
                            return [];
                        });
                }],
                untrackedInTransit: ['CylinderListService', function (CylinderListService) {
                    'ngInjext';

                    return CylinderListService.untrackedInTransit()
                        .catch(function (err) {
                            return [];
                        });
                }],


                locationSummary: ['LocationListService', function (LocationListService) {
                    return LocationListService.getSummary()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }],
                dashboardReport: ['DashboardReportService', function (DashboardReportService) {
                    return DashboardReportService.defaultDashboardReport()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }],
                displaySettings: ['DashboardReportService', function (DashboardReportService) {
                    return DashboardReportService.getDisplaySettings()
                        .catch(function (err) {
                            return []; // Ignore errors - might be due to start-up condition
                        });
                }]
            }
        })
        .when('/' + routeBase + '/reports/bill-split/:interval/:totalBill', {
            template: require('./reports/bill-split/bill-split.tpl.html'),
            controller: 'HospitalAdminBillSplit',
            controllerAs: 'billSplitCtrl'
        })
        .when('/' + routeBase + '/buttons/list', {
            template: require('./buttons/buttonsList/buttons-list.tpl.html'),
            controller: 'HospitalAdminButtonsList',
            controllerAs: 'buttonsListCtrl'
        })
        .when('/' + routeBase + '/button/:mac/history', {
            template: require('./buttons/history/button-history.tpl.html'),
            controller: 'HospitalAdminButtonHistory',
            controllerAs: 'buttonHistoryCtrl'
        })
        .when('/' + routeBase + '/buttons/tasks', {
            template: require('./buttons/buttonsTasksList/buttons-tasks-list.tpl.html'),
            controller: 'HospitalAdminButtonsTasksList',
            controllerAs: 'buttonsTasksCtrl'
        })
        .when('/' + routeBase + '/settings', {
            template: require('./settings/settings.tpl.html'),
            controller: 'HospitalAdminSettings',
            controllerAs: 'hospitalAdminSettingsCtrl'
        })
        .when('/' + routeBase + '/about', {
            template: require('./about/about.tpl.html'),
            controller: 'HospitalAdminAbout',
            controllerAs: 'HospitalAdminAboutCtrl'
        });
}

config.$inject = ['$routeProvider'];
