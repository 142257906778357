export function pageIdDirective() {
  return {
    restrict: 'E',
    scope: {
      pageId: '@'
    },
    link: function (scope, elem, attrs) {
      if (scope.pageId) {
        $('html').attr('id', scope.pageId);
      }

      scope.$on(
          '$destroy',
          function (event) {
            $('html').removeAttr('id');
          }
      );
    }
  };
}