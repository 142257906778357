export function AdminUsers($rootScope, SessionService, UserListService, SmartTableServerAdapter, Roles) {
    if (!SessionService.isCurrentRoleIn([Roles.LINDE_ADMINISTRATOR])) {
        return;
    }

    $rootScope.navbarType = 'ADMIN';

    this.UserListService = UserListService;
    this.usersGrid = new SmartTableServerAdapter(UserListService);
}

AdminUsers.$inject = ["$rootScope", "SessionService", "UserListService", "SmartTableServerAdapter", "Roles"];