export function Refresh($interval) {
    this.interval = $interval;
    this.runningTasks = [];
    this.allTasks = [];
    var self = this;
    document.addEventListener('visibilitychange', function () {
        if (document.hidden) {
            cancelAllTasks(self.interval, self.runningTasks);
        } else {
            _.forEach(self.allTasks, function (tasks) {
                self.start(tasks.interval, tasks.toRefresh);
            });
        }
    });
}

Refresh.prototype.start = function (interval, tasksToRefresh) {
    var self = this;
    self.tasks = [];
    cancelAllTasks(self.interval, self.runningTasks);
    self.allTasks.push({toRefresh: tasksToRefresh, interval: interval});


    // console.log('Starting Monitoring', tasksToRefresh.length, 'tasks');
    self.runningTasks = _.map(tasksToRefresh, function (task) {
        return self.interval(task, interval);
    });
};

Refresh.prototype.stop = function () {
    cancelAllTasks(this.interval, this.runningTasks);
};

function cancelAllTasks(interval, runningTasks) {
    _.forEach(runningTasks, function (task) {
        // console.log('Cancelling', runningTasks.length, 'tasks');
        interval.cancel(task);
    });
}

Refresh.$inject = ["$interval"];