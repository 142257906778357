import {CylinderListService} from "./CylinderListService";

export function CylinderTypeListService($rootScope, $q, CylinderType, FlatCylinderType, CylinderTypeSummary, gettextCatalog, CylinderTypeService) {
    // console.log ('in CylinderTypeListService (); CylinderType: ', CylinderType);
    var self = this;
    this.q = $q;
    this.rootScope = $rootScope;
    this.CylinderType = CylinderType;
    this.FlatCylinderType = FlatCylinderType;
    this.CylinderTypeSummary = CylinderTypeSummary;
    this.gettextCatalog = gettextCatalog;
    self.CylinderTypeService = CylinderTypeService;

    Object.defineProperties(this.CylinderType.prototype, {
        label: {
            get: function cylinderTypeLabel() {
                if (this.gas && this.valve && this.size) {
                    return self.gettextCatalog.getString(this.gas.gasType) + ', ' + this.valve.valveType + ' ' + this.size.sizeCode;
                }
                return '';
            }
        },
        gasTypeLabel: {
            get: function gasTypeLabel() {
                return this.gas ? this.gas.gasType : '';
            }
        },
        valveTypeLabel: {
            get: function valveTypeLabel() {
                return this.valve ? this.valve.valveType : '';
            }
        },
        sizeCodeLabel: {
            get: function sizeCodeLabel() {
                return this.size ? this.size.sizeCode : '';
            }
        },
        sizeMlLabel: {
            get: function sizeMlLabel() {
                return this.size ? this.size.sizeML : '';
            }
        }
    });
}

CylinderTypeListService.prototype.getSummaryByLocation = function (locationId) {
    var self = this;

    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.CylinderTypeSummary.find({
            filter: {
                where: {
                    locationId: locationId,
                    hospitalId: hospital.id
                }
            }
        }).$promise.then(
            function (ctsList) {
                return _.map(ctsList, function (item) {
                    return Object.defineProperty(item, 'description', {
                        get: function () {
                            return self.CylinderTypeService.translateCylinderType(this.name);
                        }
                    });
                });
            }
        );
    });
};

CylinderTypeListService.prototype.add = function (cylinderType) {
    var newCylinderType = new this.CylinderType(cylinderType);
    // We don't have to wait for appReady because the "add" button appears after the app is ready
    newCylinderType.hospitalId = this.rootScope.hospital.id;
    return newCylinderType;
};

CylinderTypeListService.prototype.find = function (sizeCode) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.CylinderType.find({
            filter: {
                where: {
                    hospitalId: hospital.id,
                    sizeCode: sizeCode,
                    deleted: 0
                },
                include: [
                    'gas',
                    'valve',
                    'size'
                ]
            }
        }).$promise;
    });
};

CylinderTypeListService.prototype.findAll = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        // console.log ('CylinderTypeListService.findAll (); hospital:', hospital);
        return self.FlatCylinderType.find({
            filter: {
                where: {
                    hospitalId: hospital.id,
                    deleted: 0
                }
            }
        }).$promise;
    });
};

CylinderTypeListService.prototype.findByType = function (type) {
    const self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.FlatCylinderType.find({
            filter: {
                where: {
                    hospitalId: hospital.id,
                    gasType: type.gasType,
                    valveType: type.valveType,
                    deleted: 0
                }
            }
        }).$promise;
    });
};

CylinderTypeListService.prototype.findDeletedTypes = function (hospital, cylinderTypeIds) {
    const self = this;
    const filter = _.cloneDeep({
        filter: {
            where: {
                id: {inq: cylinderTypeIds},
                hospitalId: hospital.id,
                deleted: {neq: 0}
            }
        }
    });
    return self.CylinderType.find(filter).$promise;
};

CylinderTypeListService.prototype.findTypesWithGivenCapacity = function (hospital, sizeIds) {
    const self = this;
    const filter = _.cloneDeep({
        filter: {
            where: {
                hospitalId: hospital.id,
                sizeId: {inq: sizeIds}
            }
        }
    });
    return self.CylinderType.find(filter).$promise;
};

CylinderTypeListService.prototype.findByTypeId = function (typeId) {
    const self = this;
    return self.FlatCylinderType.find({
        filter: {
            where: {
                id: typeId
            }
        }
    }).$promise;
};

CylinderTypeListService.prototype.save = function (cylinderType) {
    // console.log ('saving cylinderType:', cylinderType);
    if (cylinderType) {
        cylinderType.deleted = 0;
        return cylinderType.$save();
    }
    return this.q.when(false);
};

CylinderTypeListService.prototype.del = function (cylinderType) {
    var self = this;
    return self.CylinderType.findOne({
        filter: {
            where: {id: cylinderType.id, deleted: 0}
        }
    }).$promise
        .then(function (editableCylinderType) {
            if (editableCylinderType) {
                editableCylinderType.deleted = new Date().getTime();
                return editableCylinderType.$save();
            }
            return self.q.when(false);
        });
};

CylinderTypeListService.$inject = ["$rootScope", "$q", "CylinderType", "FlatCylinderType", "CylinderTypeSummary", "gettextCatalog", "CylinderTypeService"];