export function CylinderTypeService(gettextCatalog) {
    var self = this;
    self.gettextCatalog = gettextCatalog;
}

CylinderTypeService.prototype.translateCylinderType = function (cylinderType) {
    var self = this;
    if (!!cylinderType && cylinderType.length > 0) {
        var cylinderTypeSplitted = cylinderType.split(',');

        var result = self.gettextCatalog.getString(cylinderTypeSplitted[0]);
        if (cylinderTypeSplitted.length > 1) {
            result = result + ", " + cylinderTypeSplitted[1];
        }
        return result;
    } else {
        return cylinderType;
    }
};

CylinderTypeService.$inject = ["gettextCatalog"];