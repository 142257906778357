export function HospitalFeaturesService($rootScope, $q, HospitalFeatures) {
    this.rootScope = $rootScope;
    this.Q = $q;
    this.HospitalFeatures = HospitalFeatures;
}

HospitalFeaturesService.prototype.save = function (hospitalFeatures) {
    var self = this;
    if (hospitalFeatures) {
        return self.HospitalFeatures.find({
            filter: {
                where: {
                    hospitalId: !!hospitalFeatures.hospitalId ? hospitalFeatures.hospitalId : -9999
                }
            }
        }).$promise
            .then(function (features) { // Problematic part which could be the cause of the issue
                hospitalFeatures.id = features.length === 1 ? features[0].id : null;
                return self.HospitalFeatures.upsert(hospitalFeatures).$promise;
            });
    }
    return false;
};

HospitalFeaturesService.prototype.add = function (hospitalFeatures) {
    var newHospitalFeatures = new this.HospitalFeatures(hospitalFeatures);
    // We don't have to wait for appReady because the "add" button appears after the app is ready
    return newHospitalFeatures;
};

HospitalFeaturesService.prototype.getByHospitalId = function (hospitalId) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.HospitalFeatures.find({
            filter: {
                where: {
                    hospitalId: hospitalId || hospital.id
                }
            }
        }).$promise
            .then(function (result) {
                return result[0] || new self.HospitalFeatures();
            });
    });
};

HospitalFeaturesService.prototype.del = function (hospital) {
    if (hospital) {
        hospital.deleted = new Date().getTime();
        return hospital.$save();
    }
    return false;
};

HospitalFeaturesService.$inject = ["$rootScope", "$q", "HospitalFeatures"];