export function preventTemplateCacheConfig($httpProvider) {
    $httpProvider.interceptors.push('preventTemplateCache');
}

preventTemplateCacheConfig.$inject = ['$httpProvider'];

export function preventTemplateCacheFactory($injector, ENV) {
    return {
        request: function (config) {
            // if (
            //     ENV.preventTemplateCaching === 'true' &&
            //     config.url.indexOf('.html') !== -1 &&
            //     config.method === 'GET' &&
            //     config.url.indexOf('configParametersModal.tpl.html') === -1 &&
            //     config.url.indexOf('hospital-features-modal.tpl.html') === -1 &&
            //     (config.url.indexOf('hospital') !== -1 || config.url.indexOf('liv-iq') !== -1)) {
            //     var millis = (new Date()).getTime();
            //     config.url = config.url + '?t=' + millis;
            // }
            return config;
        }
    };
}

preventTemplateCacheFactory.$inject = ['$injector', 'ENV'];