export function ConfigParametersListService($rootScope, ConfigParameters) {
    this.rootScope = $rootScope;
    this.ConfigParameters = ConfigParameters;
}

ConfigParametersListService.prototype.add = function (configParameters) {
    var newConfigParameters = new this.ConfigParameters(configParameters);
    // We don't have to wait for appReady because the "configuration parameters" button appears after the app is ready
    newConfigParameters.hospitalId = newConfigParameters.hospitalId ? newConfigParameters.hospitalId : this.rootScope.hospital.id;
    return newConfigParameters;
};

ConfigParametersListService.prototype.findById = function (id) {
    var self = this;
    return this.rootScope.appReady.promise.then(function () {
        return self.ConfigParameters.findById({
            id: id,
            filter: {
                where: {
                    deleted: 0
                }
            }
        }).$promise;
    });
};

ConfigParametersListService.prototype.findAll = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.ConfigParameters.find({
            filter: {
                where: {
                    hospitalId: hospital.id,
                    deleted: 0
                }
            }
        }).$promise;
    });
};

ConfigParametersListService.prototype.save = function (configParameters) {
    if (configParameters) {
        configParameters.deleted = 0;

        return this.rootScope.appReady.promise.then(function (hospital) {
            if (hospital) {
                configParameters.hospitalId = hospital.id;
            }
            return configParameters.$save();
        });
    }
    return false;
};

ConfigParametersListService.prototype.del = function (configParameters) {
    if (configParameters) {
        configParameters.deleted = new Date().getTime();
        return configParameters.$save();
    }
    return false;
};

ConfigParametersListService.$inject = ["$rootScope", "ConfigParameters"];