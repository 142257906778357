import {NotificationToggleController} from "./notification-toggle.controller";

export function NotificationToggleDirective() {
    return {
        restrict: 'E',
        scope: {
            notificationsLabel: '=',
            notificationsDeniedLabel: '='
        },
        template: require('./notification-toggle.tpl.html'),
        controller: NotificationToggleController,
        controllerAs: 'ctrl',
        bindToController: true
    };
}