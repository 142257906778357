export function LocationListService($rootScope, $q, Location, FlatLocationSummary, LocationSummary) {
    this.rootScope = $rootScope;
    this.Location = Location;
    this.LocationSummary = LocationSummary;
    this.FlatLocationSummary = FlatLocationSummary;
    this.q = $q;
}

LocationListService.prototype.getBaseFilter = function (hospital) {
    return _.cloneDeep({
        filter: {
            where: {
                hospitalId: hospital ? hospital.id : null,
                deleted: 0
            },
            include: [
                'configParameters',
                'locationType',
                'hubs',
                'stocks'
            ]
        }
    });
};

LocationListService.prototype.add = function (location) {
    var newLocation = new this.Location(location);
    // We don't have to wait for appReady because the "add" button appears after the app is ready
    newLocation.hospitalId = this.rootScope.hospital.id;
    return newLocation;
};

LocationListService.prototype.regenHospitalActionRequest = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.LocationSummary.regenHospitalActionRequest({hospitalId: hospital.id}).$promise;
    });
};

LocationListService.prototype.find = function (name) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.Location.find({
            filter: {
                where: {
                    hospitalId: hospital.id,
                    name: name,
                    deleted: 0
                },
                include: [
                    'configParameters',
                    'hubs'
                ]
            }
        }).$promise;
    });
};

LocationListService.prototype.findById = function (id) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        // console.log ('LocationListService.findAll (); hospital:', hospital);
        var filter = self.getBaseFilter(hospital);
        filter.filter.where.id = id;
        return self.Location.findOne(filter).$promise;
    });
};

LocationListService.prototype.findWithCylindersAndHubs = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.Location.find({
            filter: {
                where: {
                    hospitalId: hospital.id,
                    deleted: 0
                },
                include: [
                    'configParameters',
                    {
                        hubs: {
                            relation: 'cylinders',
                            scope: {
                                include: {cylinderType: ['gas', 'valve', 'size']}
                            }
                        }
                    },
                    {
                        orders: {
                            relation: 'cylinderType',
                            scope: {
                                include: ['gas', 'valve', 'size']
                            }
                        }
                    }
                ]
            }
        }).$promise;
    });
};

LocationListService.prototype.getPage = function (applyFilteringAndPagination) {
    var self = this;

    return this.rootScope.appReady.promise.then(function (hospital) {
        var filter = self.getBaseFilter(hospital);
        delete filter.filter.include;
        delete filter.filter.where.deleted;

        applyFilteringAndPagination(filter.filter);

        return self.LocationSummary.getPage(filter)
            .$promise
            .then(function (result) {
                var rawLocationSummaries = result.items;
                var locationSummaries = {};
                _.forEach(rawLocationSummaries, function (rawLocationSummary) {
                    var locationSummary = locationSummaries[rawLocationSummary.name];
                    if (locationSummary) {
                        if (locationSummary.hubsLosingConnectivity) {
                            locationSummary.hubsLosingConnectivity = locationSummary.hubsLosingConnectivity.concat(rawLocationSummary.hubsLosingConnectivity);
                            locationSummary.hubsLosingConnectivity = _.uniq(locationSummary.hubsLosingConnectivity);
                        } else {
                            locationSummary.hubsLosingConnectivity = rawLocationSummary.hubsLosingConnectivity;
                        }
                        locationSummary.outstandingDeliveries += Number(rawLocationSummary.outstandingDeliveries);
                        locationSummary.cylinderSummaries.push({
                            cylinderSize: rawLocationSummary.cylinderSize,
                            emptyCylindersCount: Number(rawLocationSummary.emptyCylindersCount),
                            partialCylindersCount: Number(rawLocationSummary.partialCylindersCount),
                            fullCylindersCount: Number(rawLocationSummary.fullCylindersCount),
                            maxDelivery: Number(rawLocationSummary.maxDelivery),
                            minDelivery: Number(rawLocationSummary.minDelivery),
                            overstockExcess: Number(rawLocationSummary.overstockExcess)
                        });
                        locationSummary.cylinderSummaries = _.sortBy(locationSummary.cylinderSummaries, 'cylinderSize');
                        locationSummary.cylindersCount += Number(rawLocationSummary.cylindersCount);
                    } else {
                        locationSummary = {
                            name: rawLocationSummary.name,
                            type: rawLocationSummary.locationType,
                            hubsLosingConnectivity: rawLocationSummary.hubsLosingConnectivity,
                            outstandingDeliveries: Number(rawLocationSummary.outstandingDeliveries)
                        };
                        locationSummary.cylinderSummaries = [{
                            cylinderSize: rawLocationSummary.cylinderSize,
                            emptyCylindersCount: Number(rawLocationSummary.emptyCylindersCount),
                            partialCylindersCount: Number(rawLocationSummary.partialCylindersCount),
                            fullCylindersCount: Number(rawLocationSummary.fullCylindersCount),
                            maxDelivery: Number(rawLocationSummary.maxDelivery),
                            minDelivery: Number(rawLocationSummary.minDelivery),
                            overstockExcess: Number(rawLocationSummary.overstockExcess)
                        }];
                        locationSummary.cylindersCount = Number(rawLocationSummary.cylindersCount);
                    }
                    locationSummaries[rawLocationSummary.name] = locationSummary;
                });
                var locationSummaryList = _.map(locationSummaries, function (item) {
                    return item;
                });
                return self.q.resolve({
                    items: locationSummaryList,
                    totalCount: locationSummaryList.length
                });
            });
    });
};

LocationListService.prototype.getSummary = function () {
    var self = this;

    return this.rootScope.appReady.promise.then(function (hospital) {
        var filter = self.getBaseFilter(hospital);
        delete filter.filter.include;
        delete filter.filter.where.deleted;

        return self.LocationSummary.find(filter).$promise;
    });
};

LocationListService.prototype.findAllWithCylinderSize = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var filter = self.getBaseFilter(hospital);
        delete filter.filter.include;
        delete filter.filter.where.deleted;
        return self.LocationSummary.find(filter).$promise;
    });
};

LocationListService.prototype.findAll = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var filter = self.getBaseFilter(hospital);
        delete filter.filter.include;
        delete filter.filter.where.deleted;
        return self.FlatLocationSummary.find(filter).$promise;
    });
};

LocationListService.prototype.save = function (location) {
    if (location) {
        if (location.configParameters) {
            location.configParametersId = location.configParameters.id;
            delete location.configParameters;
        }
        if (location.locationType) {
            location.locationTypeId = location.locationType.id;
            delete location.locationType;
        }
        location.deleted = 0;
        return location.$save();
    }
    return false;
};

LocationListService.prototype.del = function (location) {
    if (location) {
        location.deleted = new Date().getTime();
        return location.$save();
    }
    return false;
};

LocationListService.$inject = ['$rootScope', '$q', 'Location', 'FlatLocationSummary', 'LocationSummary'];