export function AuthService($rootScope, $localStorage, $q, LoopBackAuth, Appuser, Role, HospitalListService) {
    function login(email, password, rememberMe) {
        return logout().then(function () {
            return Appuser.login({rememberMe: rememberMe}, {email: email.toLowerCase(), password: password}).$promise;
        }).then(function (response) {
            $localStorage.user = response.user;
            return Role.findById({id: $localStorage.user.roleId}).$promise;
        }).then(function (role) {
            $localStorage.role = role;
            if ($localStorage.user.hospitalId) {
                return HospitalListService.findById($localStorage.user.hospitalId, ['configParameters', 'features'])
                    .then(function (hospital) {
                        $localStorage.hospital = hospital;
                        $localStorage.configParameters = hospital.configParameters;
                        $localStorage.hospitalFeatures = hospital.features;
                        return getSessionVariables();
                    });
            }
            $rootScope.navbarType = 'SUPERADMIN';
            $localStorage.hospital = null;
            $localStorage.configParameters = null;
            return getSessionVariables();
        }).catch(function (err) {
            console.error('Login error:', err.stack || err);
            throw err;
        });
    }

    function getSessionVariables() {
        return {
            user: $localStorage.user,
            role: $localStorage.role,
            hospital: $localStorage.hospital,
            configParameters: $localStorage.configParameters
        };
    }

    function updateUser(user) {
        $localStorage.user = user;
    }

    function logout() {
        // SEE ALSO session.service.js - essentially clear the session variables
        $rootScope.appReady = $q.defer();
        if ($localStorage.user) {
            $localStorage.$reset(); // We don't actually care if logout fails
            return Appuser.logout()
                .$promise;
        }
        return $q.when(true);
    }

    return {
        login: login,
        getSessionVariables: getSessionVariables,
        logout: logout,
        updateUser: updateUser
    };
}

AuthService.$inject = ['$rootScope', '$localStorage', '$q', 'LoopBackAuth', 'Appuser', 'Role', 'HospitalListService'];