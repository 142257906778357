//directive is needed in order to embed the report in the dashboard page
export function ReportButtonStats() {
    return {
        restrict: 'E',
        scope: {},
        controller: HospitalAdminButtonsStatsReport,
        controllerAs: 'ctrl',
        bindToController: true,
        template: require('./report-buttons-stats.tpl.html')
    };
}

export function HospitalAdminButtonsStatsReport($rootScope, $q, ReportService, LocationListService) {
    $rootScope.navbarType = 'HOSPITALADMIN';

    var self = this;
    self.locations = [];
    LocationListService.findAll().catch(function (err) {
        console.error('Error getting locations for button stats report', err);
        return []; // Ignore errors - might be due to start-up condition
    }).then(function (locations) {
        self.locations = _.flatten([undefined, _.sortBy(locations, function (loc) {
            return loc.name;
        })]);
    });
    // all locations, including an initial 'whole hospital' location


    var stats = [];
    self.currentStats = function () {
        return stats;
    };

    self.timeIntervals = ReportService.reportPeriods;
    self.selectedTimeInterval = _.find(self.timeIntervals, function (interval) {
        return interval.key === ReportService.defaultInterval().key;
    });

    self.selectedButtonType = undefined;

    self.timeIntervalChanged = function () {
        return getReport();
    };

    self.buttonTypeChanged = function (buttonType) {
        console.debug('Reports - On change called:', buttonType);
        self.selectedButtonType = buttonType;
        getReport();
    };

    function getReport() {
        if (!self.selectedButtonType) {
            return $q(function (resolve) {
                return resolve(false);
            });
        }

        return ReportService.buttonStatsReport(self.selectedTimeInterval.key, self.selectedButtonType.key)
            .then(function (result) {
                stats = result;
            })
            .catch(function (err) {
                console.error(err);
                stats = [];
            });
    }
}

HospitalAdminButtonsStatsReport.$inject = ["$rootScope", "$q", "ReportService", "LocationListService"];