export function ButtonsOrdersService($rootScope, FlatButtonOrder, ButtonOrder, ButtonsFeatureService, CylinderTypeService) {
    var STATUS_NEW = 0;
    var STATUS_ACKNOWLEDGED = 1;
    var STATUS_FULFILLED = 2;
    var STATUS_CANCELLED = 3;

    var self = this;

    self.FlatButtonOrder = FlatButtonOrder;
    self.CylinderTypeService = CylinderTypeService;

    Object.defineProperty(FlatButtonOrder.prototype, 'buttonTaskTypeDesc', {
        get: function () {
            return self.CylinderTypeService.translateCylinderType(this.buttonTaskType);
        }
    });

    function getHospital() {
        return $rootScope.appReady.promise;
    }

    function getBaseFilter(hospital) {
        return {
            filter: {
                where: {
                    hospitalId: hospital ? hospital.id : null
                }
            }
        };
    }

    function countOrders(updateWhere) {
        return getHospital()
            .then(getBaseFilter)
            .then(function (filter) {
                updateWhere(filter.filter.where);
                return FlatButtonOrder.count(filter.filter).$promise;
            })
            .then(function (data) {
                return data.count;
            });
    }

    self.getPage = function (applyFilteringAndPagination) {
        if (!ButtonsFeatureService.isEnabled()) {
            return ButtonsFeatureService.getEmptyPageResults();
        }

        return getHospital()
            .then(getBaseFilter)
            .then(function (filter) {
                applyFilteringAndPagination(filter.filter);
                return FlatButtonOrder.getPage(filter).$promise;
            })
            .then(function (data) {
                return {
                    items: _.map(data.items, function (order) {
                        return new self.FlatButtonOrder(order);
                    }),
                    totalCount: data.totalCount
                };
            });
    };

    self.countOpenOrders = function () {
        return countOrders(function (where) {
            where.or = [{status: STATUS_NEW}, {status: STATUS_ACKNOWLEDGED}];
        });
    };

    self.countOrdersWithStateAndLocation = function (state, locationName) {
        return countOrders(function (where) {
            where.status = state;
            if (locationName) where.location = locationName;
        });
    };

    self.countOrdersByType = function (state, locationName) {
        return getHospital()
            .then(function (hospital) {
                return FlatButtonOrder.countByType({
                    location: locationName,
                    status: state,
                    hospitalId: hospital.id
                }).$promise;
            });
    };

    self.acknowledge = function (order) {
        return new ButtonOrder(order).$prototype$acknowledge();
    };

    self.fulfill = function (order) {
        return new ButtonOrder(order).$prototype$fulfill();
    };

    self.cancel = function (order) {
        order.cancelledTime = new Date();
        return new ButtonOrder(order).$prototype$cancel();
    };

    self.validStatuses = {
        new: STATUS_NEW,
        acknowledged: STATUS_ACKNOWLEDGED,
        fulfilled: STATUS_FULFILLED,
        cancelled: STATUS_CANCELLED
    };
}

ButtonsOrdersService.$inject = ["$rootScope", "FlatButtonOrder", "ButtonOrder", "ButtonsFeatureService", "CylinderTypeService"];