export function ButtonOrdersSummaryService($q, ButtonsOrdersService) {
    this.createSummaryState = function (locationName) {
        return new ButtonOrdersSummaryState(locationName);
    };

    function ButtonOrdersSummaryState(locationName) {
        var self = this;
        self.newOrdersCount = 0;
        self.acknowledgedOrdersCount = 0;

        self.refresh = function () {
            return $q.all(
                [
                    updateNewOrdersCount(),
                    updateAcknowledgedOrdersCount()
                ]
            );
        };

        self.openOrdersCount = function () {
            return self.newOrdersCount + self.acknowledgedOrdersCount;
        };

        self.refresh();

        function updateNewOrdersCount() {
            return ButtonsOrdersService.countOrdersWithStateAndLocation(ButtonsOrdersService.validStatuses.new, locationName)
                .then(function (count) {
                    self.newOrdersCount = count;
                });
        }

        function updateAcknowledgedOrdersCount() {
            return ButtonsOrdersService.countOrdersWithStateAndLocation(ButtonsOrdersService.validStatuses.acknowledged, locationName)
                .then(function (count) {
                    self.acknowledgedOrdersCount = count;
                });
        }
    }
}

ButtonOrdersSummaryService.$inject = ['$q', 'ButtonsOrdersService'];