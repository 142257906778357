export function HubFactory(Model, FlatCylinder) {
    var superclass = Model;

    function HubModel(cylinderJSON, location) {
        var self = this;
        superclass.call(self, cylinderJSON);
        self.location = location;

        if (_.isArray(self.cylinders)) {
            self.cylinders = _.map(self.cylinders, function (cylinder) {
                return new FlatCylinder(cylinder, self);
            });
        }
    }

    return HubModel;
}

HubFactory.$inject = ["Model", "FlatCylinder"];