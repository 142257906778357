import {VirtualButtonsController} from "./liv-iq-virtual-buttons.controller";

export function VirtualButtonsDirective() {
    return {
        restrict: 'E',
        scope: {
            location: '='
        },
        template: require('./liv-iq-virtual-buttons.tpl.html'),
        controller: VirtualButtonsController,
        controllerAs: 'ctrl',
        bindToController: true
    };
}