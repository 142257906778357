export function ActivateUserController($rootScope, $routeParams, $scope, $q, Common, UserListService, SessionService, toastr) {
  var self = this;

  self.rootScope = $rootScope;
  self.routeParams = $routeParams;
  self.scope = $scope;
  self.q = $q;
  self.Common = Common;
  self.UserListService = UserListService;
  self.SessionService = SessionService;
  self.toastr = toastr;
  self.activationAttempted = false;
  self.activationSuccessful = false;
  self.activationFailed = false;

  if (!self.routeParams.token || !self.routeParams.id) {
    self.SessionService.logout();
  }

  self.scope.showActivationForm = function () {
    return !self.activationAttempted;
  };

  self.scope.showActivationSuccess = function () {
    return self.activationAttempted && self.activationSuccessful;
  };

  self.scope.showActivationFailure = function () {
    return self.activationAttempted && self.activationFailed;
  };
}

ActivateUserController.prototype.enableActivationButton = function () {
  return this.scope.username &&
      this.scope.newPassword &&
      this.scope.newPassword.length >= 8 &&
      this.scope.confirmNewPassword &&
      (this.scope.newPassword === this.scope.confirmNewPassword);
};

ActivateUserController.prototype.activate = function () {
  var self = this;
  self.activationAttempted = true;
  return self.UserListService.activateNewUser(self.routeParams.id, self.scope.username, self.routeParams.token, self.scope.newPassword)
      .then(function () {
        self.activationSuccessful = true;
      })
      .catch(function (err) {
        console.log('Activation Error:', err);
        self.toastr.error(self.Common.interpretHttpStatus(err.status));
        self.activationFailed = true;
      });
};

ActivateUserController.prototype.gotoSignInPage = function () {
  return this.SessionService.logout();
};

ActivateUserController.$inject = ['$rootScope', '$routeParams', '$scope', '$q', 'Common', 'UserListService', 'SessionService', 'toastr'];