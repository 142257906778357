export function SuperAdminServiceLevels($rootScope, $scope, $uibModal, $q, $timeout, toastr, SessionService, ServiceLevelListService, serviceLevels, featureDefinitions, gettextCatalog) {
    if (!SessionService.isCurrentRoleAnAdminRole()) {
        return;
    }
    $rootScope.navbarType = 'SUPERADMIN';
    this.scope = $scope;
    this.modal = $uibModal;
    this.q = $q;
    this.timeout = $timeout;
    this.toastr = toastr;
    this.ServiceLevelListService = ServiceLevelListService;
    this.serviceLevels = serviceLevels;
    this.features = featureDefinitions;
    this.gettextCatalog = gettextCatalog;
    this.reset();
}

SuperAdminServiceLevels.prototype.save = function () {
    var self = this;

    if (self.hasChanges) {
        self.ServiceLevelListService.save(self.serviceLevels)
            .then(function (result) {
                self.toastr.success(
                    self.gettextCatalog.getString('Service level definitions have been saved'),
                    self.gettextCatalog.getString('Saved')
                );
            })
            .catch(function (err) {
                console.error(err);
            });
    }
};

SuperAdminServiceLevels.prototype.current = function () {
    return this.selection;
};

SuperAdminServiceLevels.prototype.reset = function () {
    this.hasChanges = true;
    this.scope.initialFocus = false;
    this.existingServiceLevel = null;
    this.selection = this.ServiceLevelListService.add(null);
};

SuperAdminServiceLevels.prototype.selectServiceLevel = function (serviceLevel) {
    if (serviceLevel) {
        this.editServiceLevel('inputServiceLevelName', serviceLevel);
    } else {
        this.reset();
    }
};

SuperAdminServiceLevels.prototype.addServiceLevel = function () {
    this.editServiceLevel('inputServiceLevelName');
};

SuperAdminServiceLevels.prototype.editServiceLevel = function (focusOnFieldId, existingServiceLevel) {
    var self = this;
    self.scope.initialFocus = false;
    self.existingServiceLevel = existingServiceLevel;
    self.timeout(function () {
        self.scope.initialFocus = focusOnFieldId;
        self.selection = self.ServiceLevelListService.add(existingServiceLevel);
    });
};

SuperAdminServiceLevels.prototype.saveServiceLevel = function () {
    var self = this;
    this.ServiceLevelListService.save(self.selection)
        .then(function () {
            self.ServiceLevelListService.findAll().then(function (serviceLevels) {
                self.serviceLevels = serviceLevels;
            });
        }).then(function () {
        self.reset();
    });
};

SuperAdminServiceLevels.prototype.deleteServiceLevel = function () {
    var self = this;
    this.ServiceLevelListService.del(self.selection)
        .then(function () {
            self.ServiceLevelListService.findAll().then(function (serviceLevels) {
                self.serviceLevels = serviceLevels;
            });
        }).then(function () {
        self.reset();
    });
};

SuperAdminServiceLevels.$inject = ["$rootScope", "$scope", "$uibModal", "$q", "$timeout", "toastr", "SessionService", "ServiceLevelListService", "serviceLevels", "featureDefinitions", "gettextCatalog"];