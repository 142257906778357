/* Use this to easily connect an angular-smart-table to a loopback api */

export function SmartTableClientAdapterFactory() {
    function SmartTableClientAdapter(items, mapItemToDisplayItem) {
        this.mapItemToDisplayItem = mapItemToDisplayItem;

        var self = this;

        self.pipe = function (tableState) {
            self.tableStateRef = tableState;
        };

        this.refresh(items);
        // this.displayedCollection = [].concat(this.safeCollection);
    }

    SmartTableClientAdapter.prototype.refresh = function (items) {
        this.safeCollection = this.mapItemToDisplayItem ? _.map(items, this.mapItemToDisplayItem) : items;

        if (this.tableStateRef) {
            this.displayedCollection = this.safeCollection.slice(this.tableStateRef.pagination.start, this.tableStateRef.pagination.start + this.tableStateRef.pagination.number);
            this.tableStateRef.pagination.numberOfPages = Math.ceil(items.length / this.tableStateRef.pagination.number);
        }
    };

    return SmartTableClientAdapter;
}