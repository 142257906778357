export function AuditLogsService($rootScope, $q, AuditEvent, AuditLogEntities, FlatAuditEvent, gettextCatalog) {
    this.rootScope = $rootScope;
    this.AuditEvent = AuditEvent;
    this.AuditLogEntities = AuditLogEntities;
    this.FlatAuditEvent = FlatAuditEvent;
    this.q = $q;
    this.gettextCatalog = gettextCatalog;
}

AuditLogsService.prototype.getPage = function (applyFilteringAndPagination) {
    const self = this;

    return this.rootScope.appReady.promise.then(function (hospital) {
      const filter = {
            filter: {
                where: {
                    hospitalId: hospital.id
                },
                order: 'timestamp DESC'
            }
        };

        applyFilteringAndPagination(filter.filter);

        return self.FlatAuditEvent.getPage(filter).$promise.then(function (data) {
            return self.q.resolve({
                items: _.map(data.items, function (flatItem) {
                    return self.formatEventDetails(new self.FlatAuditEvent(flatItem), self.gettextCatalog, self.AuditLogEntities);
                }),
                totalCount: data.totalCount
            });
        });
    });
};

  AuditLogsService.prototype.formatEventDetails = function(auditEvent, gettextCatalog, AuditLogEntities) {
    const value = auditEvent.value;
    auditEvent.event = [gettextCatalog.getString(auditEvent.entity), gettextCatalog.getString(auditEvent.operation)].join(' ');

    const auditEventParts = []; // Build the details string here
    switch (auditEvent.entity) {
        case AuditLogEntities.CONFIG_PARAMETERS:
            auditEventParts.push(gettextCatalog.getString('( Name: {{name}}, Applicability: {{applicability}} )', {
                name: value.name,
                applicability: value.applicability
            }));
            break;

        case AuditLogEntities.CYLINDER:
            auditEventParts.push(gettextCatalog.getString('( Name: {{name}}), Description: {{description}}, IQ Hub Id: {{hubId}} )', {
                name: value.name,
                description: value.desc,
                hubId: value.hubId
            }));
            break;

        case AuditLogEntities.CYLINDER_TYPE:
            auditEventParts.push([value.gas.gasType, value.valve.valveType, value.size.sizeCode].join('/'));
            break;

        case AuditLogEntities.HOSPITAL:
            auditEventParts.push(gettextCatalog.getString('( Name: {{name}} )', {
                name: value
            }));
            break;

        case AuditLogEntities.LOGIN:
        case AuditLogEntities.LOGOUT:
            auditEventParts.push(gettextCatalog.getString('( From: {{from}}, User-Agent: {{userAgent}} )', {
                from: value.remoteAddress,
                userAgent: value.userAgent
            }));
            break;

        case AuditLogEntities.LOCATION:
            auditEventParts.push(gettextCatalog.getString('( Name: {{name}}, Description: {{description}} )', {
                name: value.name,
                description: value.desc
            }));
            break;

        case AuditLogEntities.ORDER:
            auditEventParts.push(gettextCatalog.getString('( Location: {{location}}, Cylinder Type: {{gasType}}/{{valveType}}/{{sizeCode}}, Quantity: {{quantity}}, Status: {{status}} )', {
                location: value.locationName,
                gasType: value.cylinderType.gas.gasType,
                valveType: value.cylinderType.valve.valveType,
                sizeCode: value.cylinderType.size.sizeCode,
                quantity: value.quantity,
                status: value.status
            }));
            break;

        case AuditLogEntities.HUB:
            auditEventParts.push(gettextCatalog.getString('( Name: {{name}}, Description: {{description}}, Location: {{location}} )', {
                name: value.name,
                description: value.desc,
                location: value.locationName
            }));
            break;

        case AuditLogEntities.USER:
            auditEventParts.push(gettextCatalog.getString('( Username: {{username}}, Email: {{email}}, Role: {{role}}, Default Location: {{defaultLocation}} )', {
                username: value.username,
                email: value.email,
                role: value.role,
                defaultLocation: value.defaultLocationName
            }));
            break;

        default:
            auditEventParts.push(auditEvent.instance, value);
    }
    auditEvent.details = auditEventParts.join(' ');
    return auditEvent;
};

AuditLogsService.$inject = ['$rootScope', '$q', 'AuditEvent', 'AuditLogEntities', 'FlatAuditEvent', 'gettextCatalog'];