export function NotifyeeListDirective() {
    return {
        restrict: 'E',
        scope: {
            nlTitle: '=',
            notificationType: '=',
            locations: '@'
        },
        controller: 'livIqNotifyeeList',
        controllerAs: 'ctrl',
        bindToController: true,
        template: require('./liv-iq-notifyee-list.tpl.html')
    };
}

export function focusOnShow($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            $timeout(function () {
                // use a timout to focus outside this digest cycle!
                var ele = element[0];
                //Focus here which may need to be removed once update of jquery-ui has been pushed
                ele.focus();
                if (ele.setSelectionRange) {
                    ele.setSelectionRange(0, ele.value.length);
                }
            }, 0);
        }
    };
}