export function GasListService($rootScope, $q, Gas) {
    this.rootScope = $rootScope;
    this.Gas = Gas;
    this.q = $q;
}

GasListService.prototype.addGas = function (gas) {
    var newGas = new this.Gas(gas);
    newGas.hospitalId = this.rootScope.hospital.id;
    return newGas;
};

GasListService.prototype.findAll = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        // console.log ('GasListService.findAll (); hospital:', hospital);
        return self.Gas.find({
            filter: {
                where: {
                    hospitalId: hospital.id,
                    deleted: 0
                }
            }
        }).$promise;
    });
};

GasListService.prototype.saveGas = function (gas) {
    if (gas) {
        gas.deleted = 0;
        return gas.$save();
    }
    return this.q.when(false);
};

GasListService.prototype.del = function (gas) {
    if (gas) {
        gas.deleted = new Date().getTime();
        return gas.$save();
    }
    return this.q.when(false);
};

GasListService.$inject = ["$rootScope", "$q", "Gas"];