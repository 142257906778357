export function CommonFactory(CylinderOperatingModes, gettextCatalog) {
    var Common = {};

    Common.interpretHttpStatus = function interpretHttpStatus(status) {
        var message;
        switch (status) {
            case 200:
            case 201:
            case 202:
            case 203:
            case 204:
            case 205:
            case 206:
            case 207:
            case 208:
            case 226:
            case 304:
                message = gettextCatalog.getString('OK');
                break;
            case 400:
                message = gettextCatalog.getString('Client request error');
                break;
            case 401:
                message = gettextCatalog.getString('Invalid username or password');
                break;
            case 403:
                message = gettextCatalog.getString('Permission denied');
                break;
            case 404:
            case 405:
            case 406:
            case 410:
            case 501:
                message = gettextCatalog.getString('Requested resource not available on server - please contact Linde');
                break;
            case 407:
                message = gettextCatalog.getString('Proxy authentication failed - please contact your network administrator');
                break;
            case 409:
                message = gettextCatalog.getString('Requested resource in an inconsistent state - please refresh your browser and try again');
                break;
            case 500:
                message = gettextCatalog.getString('Internal server error - please try later');
                break;
            case 502:
                message = gettextCatalog.getString('Bad gateway - please try later');
                break;
            case 503:
                message = gettextCatalog.getString('Service currently unavailable - please try later');
                break;
            case 504:
                message = gettextCatalog.getString('Gateway timeout - please try later');
                break;
            case 505:
                message = gettextCatalog.getString('Requested protocol not supported by server - please contact Linde');
                break;
            case 508:
                message = gettextCatalog.getString('Redirection loop - please contact Linde');
                break;
            default:
                message = gettextCatalog.getString('Unknown error {{status}} - please refresh your browser and try again', {status: status});
                break;
        }
        return message;
    };

    Common.buildStockLevels = function (stocks, cylinderTypes) {
        var stockLevels = {};
        _.forEach(cylinderTypes, function (cylinderType) {
            if (!stockLevels[cylinderType.gas.id]) {
                stockLevels[cylinderType.gas.id] = {};
            }
            if (!stockLevels[cylinderType.gas.id][cylinderType.valve.id]) {
                stockLevels[cylinderType.gas.id][cylinderType.valve.id] = {};
            }
            var stockIndex = _.findIndex(stocks, _.matchesProperty('cylinderTypeId', cylinderType.id));
            if (stockIndex < 0) {
                var stock = {cylinderTypeId: cylinderType.id, restockTrigger: 0, restockLevel: 0};
                if (stocks) {
                    stockIndex = stocks.length;
                    stocks.push(stock);
                } else {
                    stockIndex = 0;
                    stocks = [stock];
                }
            }
            var selectedStock = stocks[stockIndex];
            var entry = {stockIndex: stockIndex, levels: [selectedStock.restockTrigger, selectedStock.restockLevel]};
            stockLevels[cylinderType.gas.id][cylinderType.valve.id][cylinderType.id] = entry;
        });

        return stockLevels;
    };

    function countCylindersByStatus(applicableCylinders, location) {
        var empty = 0;
        var available = 0;
        var assigned = 0;
        var fault = 0;
        var total = 0;
        if (applicableCylinders) {
            total = applicableCylinders.length;
            _.forEach(applicableCylinders, function (cylinder) {
                if (cylinder.lastKnownLocationId === location.id) {
                    if (cylinder.assignmentId) {
                        assigned++;
                    } else if (!cylinder.isEmpty) {
                        available++;
                    }
                    if (cylinder.isEmpty && !(cylinder.operatingMode === CylinderOperatingModes.GAS_DELIVERY && cylinder.assignmentId)) {
                        empty++;
                    }
                    if (cylinder.operatingMode === CylinderOperatingModes.FAULT) {
                        fault++;
                    }
                }
            });
        }
        return {assigned: assigned, available: available, empty: empty, total: total, fault: fault};
    }

    Common.countOrdersByLocationAndType = function (processedOrders, location, cylinderType) {
        var totals = {
            new: 0,
            acknowledged: 0,
            cancelled: 0,
            completed: 0
        };
        var orderLists = processedOrders[location.name];
        if (orderLists) {
            // console.log ('location:', location.name, '; orderLists:', orderLists);
            _.forEach(Object.keys(totals), function (status) {
                var orderList = orderLists[status];
                // var orderTotals = {};
                _.forEach(orderList, function (order) {
                    // console.log ('order:', order, 'cylinderType:', cylinderType);
                    if (order.cylinderTypeId === cylinderType.id) {
                        var outstanding = (order.quantity || 0) - (order.delivered || 0);
                        totals[status] += outstanding;
                    }
                });
            });
        }
        return totals;
    };


    Common.buildActionRequest = function (location, cylinderTypes, processedCylinders, processedOrders) {
        var cylindersByType = {};
        if (!processedOrders) {
            processedOrders = {};
        }
        var stockLevels = {};
        _.forEach(processedCylinders, function (cylinder) {
            if (cylinder.lastKnownLocationId === location.id) {
                var id = (cylinder.cylinderType) ? cylinder.cylinderType.id : '';
                if (!cylindersByType[id]) {
                    cylindersByType[id] = [];
                }
                cylindersByType[id].push(cylinder);
            }
        });

        if (!location.stocks) {
            location.stocks = [];
        }
        _.forEach(cylinderTypes, function (cylinderType) {
            // console.log ('cylinderType:', cylinderType);
            var applicableCylinders = cylindersByType[cylinderType.id];
            // console.log ('applicableCylinders:', applicableCylinders);
            var cylinderCounts = countCylindersByStatus(applicableCylinders, location);
            if (!stockLevels[cylinderType.gas.gasType]) {
                stockLevels[cylinderType.gas.gasType] = {};
            }

            if (!stockLevels[cylinderType.gas.gasType][cylinderType.valve.valveType]) {
                stockLevels[cylinderType.gas.gasType][cylinderType.valve.valveType] = {};
            }
            var stockIndex = _.findIndex(location.stocks, _.matchesProperty('cylinderTypeId', cylinderType.id));
            if (stockIndex < 0) {
                stockIndex = location.stocks.length;
                location.stocks.push({cylinderTypeId: cylinderType.id, restockTrigger: 0, restockLevel: 0});
            }
            var orders = Common.countOrdersByLocationAndType(processedOrders, location, cylinderType);
            var stock = location.stocks[stockIndex];
            var entry = {
                stockIndex: stockIndex,
                levels: [
                    cylinderCounts.empty,
                    cylinderCounts.assigned,
                    cylinderCounts.available,
                    stock.restockLevel,
                    // non empty cylinders
                    cylinderCounts.total - cylinderCounts.empty,
                    orders.new + orders.acknowledged,
                    cylinderCounts.fault
                ]
            };
            stockLevels[cylinderType.gas.gasType][cylinderType.valve.valveType][cylinderType.size.sizeCode] = entry;
        });

        return stockLevels;
    };

    // will generate an array of locations with cylinder types for each room with stock levels for each cylinder
    Common.generatePorterSummary = function (locations, cylinderTypes, cylinders, orders) {
        var porterSummary = [];
        _.forEach(locations, function (location) {
            var locationSummary = {name: location.name, stock: []};
            var locationActionRequest = Common.buildActionRequest(location, cylinderTypes, cylinders, orders);
            // console.log ('locationActionRequest for', location.name, ':', locationActionRequest);
            _.forEach(cylinderTypes, function (cylinder) {
                var cylinderLevels = {
                    name: cylinder.gas.gasType + ' ' + cylinder.valve.valveType + ' ' + cylinder.size.sizeCode,
                    levels: locationActionRequest[cylinder.gas.gasType][cylinder.valve.valveType][cylinder.size.sizeCode].levels
                };
                locationSummary.stock.push(cylinderLevels);
            });
            porterSummary.push(locationSummary);
        });

        return porterSummary;
    };

    Common.summariseExpectations = function (locations, processedCylinders) {
        var expectations = {};

        _.map(locations, function (location) {
            var cylinderCounts = countCylindersByStatus(processedCylinders, location);
            expectations[location.name] = {
                assigned: cylinderCounts.assigned,
                available: cylinderCounts.available,
                empty: cylinderCounts.empty,
                total: cylinderCounts.available + cylinderCounts.assigned + cylinderCounts.empty,
                expected: _.reduce(location.stocks, function (total, stock) {
                    return total + stock.restockLevel;
                }, 0)
            };
        });
        return expectations;
    };

    Common.summariseOverview = function (expectations) {
        var available = 0,
            assigned = 0,
            empty = 0,
            expected = 0;

        _.map(expectations, function (expectation) {
            assigned += expectation.assigned;
            available += expectation.available;
            empty += expectation.empty;
            expected += expectation.expected;
        });

        return {
            available: available,
            assigned: assigned,
            empty: empty,
            total: available + assigned + empty,
            expected: expected
        };
    };

    Common.enrichOrder = function (order, locationList, cylinderTypes) {
        var location = _.find(locationList, _.matchesProperty('id', order.locationId));
        var locationName;
        if (location) {
            locationName = location.name;
        }
        var cylinderType = _.find(cylinderTypes, _.matchesProperty('id', order.cylinderTypeId));
        if (cylinderType) {
            order.gas = cylinderType.gas;
            order.valve = cylinderType.valve;
            order.size = cylinderType.size;
        }
        return locationName;
    };

    /* Group orders by location */
    Common.processOrders = function (cylinderTypes, orders, locationList, oldOrders) {
        var locations = {};
        _.forEach(orders, function (order) {
            var locationName = Common.enrichOrder(order, locationList, cylinderTypes);
            if (locationName) {
                if (!locations[locationName]) {
                    locations[locationName] = {};
                }
                if (locations[locationName][order.status]) {
                    locations[locationName][order.status].push(order);
                } else {
                    locations[locationName][order.status] = [order];
                }
            }
        });

        for (var loc in locations) {
            var oldNewOrderIds = [];
            var oldNotifications = 0;
            if (oldOrders && oldOrders[loc] && oldOrders[loc].new) {
                oldNewOrderIds = _.map(oldOrders[loc].new, 'id');
                oldNotifications = oldOrders[loc].notifications;
            }
            var newOrders = _.difference(_.map(locations[loc].new, 'id'), oldNewOrderIds);

            locations[loc].notifications = oldNotifications + newOrders.length;
        }

        // console.log ('locations:', locations);
        return locations;
    };

    Common.countOrders = function (processedOrders, location, status) {
        if (processedOrders && location && processedOrders[location.name] && processedOrders[location.name][status]) {
            return processedOrders[location.name][status].length;
        }
        return 0;
    };

    /* Determine cylinder location and fill level */
    Common.processCylinders = function (cylinders, cylinderTypes, locationList, hubList) {
        var processedCylinders = _.map(cylinders, function (cylinder) {
            // console.log ('processCylinders; cylinder:', cylinder);
            // The code below to assign a cylinder type should be redundant if the
            // back-end is doing its work correctly - a cylinder type should always exist
            var gasType = (cylinder.cylinderType && cylinder.cylinderType.gas) ?
                cylinder.cylinderType.gas.gasType : 'Oxygen';
            var valveType = (cylinder.cylinderType && cylinder.cylinderType.valve) ?
                cylinder.cylinderType.valve.valveType : 'LIV-IQ';
            var sizeML = (cylinder.cylinderType && cylinder.cylinderType.size) ?
                parseInt(cylinder.cylinderType.size.sizeML) : 0;
            if (!cylinder.cylinderType) {
                cylinder.cylinderType = _.find(cylinderTypes, function (cylinderType) {
                    return ((cylinderType.gas.gasType === gasType) &&
                        (cylinderType.valve.valveType === valveType) &&
                        (cylinderType.size.sizeML === sizeML));
                });
            }
            if (!cylinder.cylinderType) {
                cylinder.cylinderType = {
                    gas: {gasType: gasType},
                    valve: {valveType: valveType},
                    size: {sizeML: sizeML, sizeCode: '' + sizeML / 1000 + 'L'}
                };
            }

            return cylinder;
        });

        return _.filter(processedCylinders, function (cylinder) {
            return !(!cylinder.hubId);
        });
    };

    Common.locateCylinder = function (cylinder, locationList) {
        var hub = cylinder.hub;
        if (hub && hub.locationId) {
            var location = _.find(locationList, _.matchesProperty('id', hub.locationId));
            if (location) {
                return location;
            }
        }

        return {name: null};
    };

    Common.sortCylinderTypesAsPerOrderForm = function (cylinderTypes) {
        /* Paper order form shows oxygen -> entonox -> Nitrous oxide -> carbon dioxide -> breathing air -> other gases ie industrial gases
         * each gas type is ordered by the size code ie for oxygen D -> E -> G -> R -> HFX
         */
        var sortedBySizeCode = _.sortBy(cylinderTypes, ['gas.gasType', 'size.sizeCode']);
        return sortedBySizeCode;
    };

    Common.getAllCylindersForLocation = function (location) {
        return _.flatten(_.map(location.hubs, function (peg) {
            return _.filter(peg.cylinders, {deleted: '0'});
        }));
    };

    return Common;
}

CommonFactory.$inject = ["CylinderOperatingModes", "gettextCatalog"];