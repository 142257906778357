export const CylinderOperatingModes = {
    // ATTENTION: Keep it in synch with the liv-iq-api/common/models/cylinder-operating-modes.js or make it one
    FAULT: gettext('FAULT'),
    GAS_DELIVERY: gettext('GAS-DELIVERY'),
    FILL_PLANT: gettext('FILL-PLANT'),
    STANDBY: gettext('STANDBY'),
    ANYSTATUS: gettext('ANYSTATUS'), // TODO: Is it a real status? It's only used in tests
    UNKNOWN: gettext('UNKNOWN') // TODO: Is it a real status? It's only used in tests
};

/**
 * Does nothing, it's only needed to extract strings to localise
 *
 * @param text
 * @returns {*}
 */
function gettext(text) {
    return text;
}