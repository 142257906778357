export function SizeListService($rootScope, $q, Size) {
    this.rootScope = $rootScope;
    this.Size = Size;
    this.q = $q;
}

SizeListService.prototype.addSize = function (size) {
    var newSize = new this.Size(size);
    // We don't have to wait for appReady because the "add" button appears after the app is ready
    newSize.hospitalId = this.rootScope.hospital.id;
    return newSize;
};

SizeListService.prototype.findAll = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        // console.log ('SizeListService.findAll (); hospital:', hospital);
        return self.Size.find({
            filter: {
                where: {
                    hospitalId: hospital.id,
                    deleted: 0
                }
            }
        }).$promise;
    });
};

SizeListService.prototype.findSizesWithGivenCapacity = function (hospital, sizeIds, sizeml) {
    const self = this;
    const filter = _.cloneDeep({
        filter: {
            where: {
                id: {inq: sizeIds},
                hospitalId: hospital.id,
                sizeML: sizeml
            }
        }
    });
    return self.Size.find(filter).$promise;
};

SizeListService.prototype.saveSize = function (size) {
    if (size) {
        size.deleted = 0;
        return size.$save();
    }
    return this.q.when(false);
};

SizeListService.prototype.del = function (size) {
    if (size) {
        size.deleted = new Date().getTime();
        return size.$save();
    }
    return this.q.when(false);
};

SizeListService.$inject = ["$rootScope", "$q", "Size"];