export function UsageLogsService($rootScope, AuditEvent) {
    this.rootScope = $rootScope;
    this.AuditEvent = AuditEvent;
}

UsageLogsService.prototype.findAll = function () {
    var self = this;

    return this.rootScope.appReady.promise.then(function () {
        return self.AuditEvent.usage().$promise;
    });
};

UsageLogsService.prototype.find = function (date) {
    var self = this;

    return this.rootScope.appReady.promise.then(function () {
        return self.AuditEvent.usage({
            date: date
        }).$promise;
    });
};

UsageLogsService.$inject = ["$rootScope", "AuditEvent"];