export function signInConfig($routeProvider) {
    $routeProvider.when('/signin', {
        controller: 'SigninController',
        controllerAs: 'signinCtrl',
        template: require('./signin.tpl.html'),
        resolve: {
            regionStats: ["RegionStat", function (RegionStat) {
                return RegionStat.find().$promise;
            }]
        }
    }).when('/activateUser', {
        controller: 'ActivateUserController',
        controllerAs: 'activateUserCtrl',
        template: require('../activateUser/activateUser.tpl.html')
    }).when('/recoverPassword', {
        controller: 'RecoverPasswordWithEmailController',
        controllerAs: 'recoverPasswordCtrl',
        template: require('../recoverPassword/recoverPassword.tpl.html')});
   
}

signInConfig.$inject = ['$routeProvider'];