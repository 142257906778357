export function HospitalAdminCylinderDwellTimeReport($rootScope, $scope, ReportService, hospitalReport, toastr, gettextCatalog, setupDateRangePickerBySelector) {
    $rootScope.navbarType = 'HOSPITALADMIN';

    this.gettextCatalog = gettextCatalog;
    this.setupDateRangePickerBySelector = setupDateRangePickerBySelector;
    this.rootScope = $rootScope;
    this.scope = $scope;
    this.ReportService = ReportService;
    this.report = hospitalReport;
    this.dwellTimeReport = null;
    // this.locations.unshift({name: 'Hospital', id: null});
    this.selectedLocation = _.first(this.locations);
    this.toastr = toastr;
    this.timeInterval = this.ReportService.defaultInterval();
    this.timeIntervals = this.ReportService.reportPeriods;
    this.scope.timeInterval = {
        name: this.gettextCatalog.getString('Last 30 Days'),
        id: '3',
        key: 'last30Days',
        text: this.gettextCatalog.getString('30 days')
    };
    this.reportName = this.gettextCatalog.getString('Hospital');

    this._addScopeEventListeners();
    this._setupDateRangePicker();

    this.getReport();
}

HospitalAdminCylinderDwellTimeReport.prototype._addScopeEventListeners = function () {
    this.scope.$on(this.ReportService.LABELS_LOCALIZED, this._setupDateRangePicker.bind(this));
};

HospitalAdminCylinderDwellTimeReport.prototype._setupDateRangePicker = function () {
    this.setupDateRangePickerBySelector(
        '#cylinder-dwelltime-report-time-interval',
        this.timeInterval,
        this.refresh.bind(this)
    );
};

HospitalAdminCylinderDwellTimeReport.prototype.refresh = function () {
    this.getReport();
};

HospitalAdminCylinderDwellTimeReport.prototype.getReport = function () {
    var self = this;

    self.ReportService.cachedHospitalReport(self.scope.timeInterval.key)
        .then((function (hospitalReports) {
            var rawReports = hospitalReports.dwellTimeReport;
            var processedReport = {};
            _.forEach(rawReports, function (rawReport) {
                var locReport = processedReport[rawReport.location];
                if (!locReport) {
                    locReport = {};
                }
                var cylTypeReport = locReport[rawReport.cylindertype];
                if (!cylTypeReport) {
                    cylTypeReport = {
                        minFullCylinder: 0,
                        avgFullCylinder: 0,
                        maxFullCylinder: 0,
                        minPartialCylinder: 0,
                        avgPartialCylinder: 0,
                        maxPartialCylinder: 0,
                        minEmptyCylinder: 0,
                        avgEmptyCylinder: 0,
                        maxEmptyCylinder: 0
                    };
                }
                if (rawReport.fillstatus === 'full') {
                    cylTypeReport.minFullCylinder = rawReport.mindwelltime;
                    cylTypeReport.avgFullCylinder = rawReport.avgdwelltime;
                    cylTypeReport.maxFullCylinder = rawReport.maxdwelltime;
                } else if (rawReport.fillstatus === 'partial') {
                    cylTypeReport.minPartialCylinder = rawReport.mindwelltime;
                    cylTypeReport.avgPartialCylinder = rawReport.avgdwelltime;
                    cylTypeReport.maxPartialCylinder = rawReport.maxdwelltime;
                } else if (rawReport.fillstatus === 'empty') {
                    cylTypeReport.minEmptyCylinder = rawReport.mindwelltime;
                    cylTypeReport.avgEmptyCylinder = rawReport.avgdwelltime;
                    cylTypeReport.maxEmptyCylinder = rawReport.maxdwelltime;
                }
                locReport[rawReport.cylindertype] = cylTypeReport;
                processedReport[rawReport.location] = locReport;
            });
            this.dwellTimeReport = _.map(processedReport, function (lv, lk) {
                return {
                    location: lk,
                    cylTypeCounter: [],
                    report: _.map(lv, function (cv, ck) {
                        return {cylinderType: ck, report: cv};
                    })
                };
            });
        }).bind(this));
};

HospitalAdminCylinderDwellTimeReport.$inject = ["$rootScope", "$scope", "ReportService", "hospitalReport", "toastr", "gettextCatalog", "setupDateRangePickerBySelector"];