export function ButtonLowBatterBannerController($scope, ButtonsFeatureService, ButtonsListService, gettextCatalog) {
    var self = this;
    var buttonsEnabled = ButtonsFeatureService.isEnabled();
    var buttonsWithLowBattery = [];

    if (buttonsEnabled) {
        ButtonsListService.getButtonsWithLowBattery()
            .then(function (lowBattButtons) {
                _.forEach(lowBattButtons, function (btn) {
                    btn.displayText = gettextCatalog.getString('{{name}} at {{location}} for {{task}}', {
                        name: btn.name,
                        location: btn.location,
                        task: btn.buttonTaskType
                    });
                });
                buttonsWithLowBattery = lowBattButtons;
            })
            .catch(function (err) {
                console.error(err);
                buttonsWithLowBattery = [];
            });
    }

    self.shouldShow = function () {
        return buttonsEnabled && buttonsWithLowBattery && buttonsWithLowBattery.length > 0;
    };

    self.buttonsWithLowBattery = function () {
        return buttonsWithLowBattery;
    };
}

ButtonLowBatterBannerController.$inject = ["$scope", "ButtonsFeatureService", "ButtonsListService", "gettextCatalog"];