export function ActionRequest($rootScope, $scope, $q, $uibModal, Common, cylinderRefreshIntervalMillis, SessionService, CylinderListService, OrderListService, $window, LocationListService, RefreshService, faultyCylinderSummary, gettext, Roles, CylinderOperatingModes, porterHospitalActionRequest, NotificationsService) {
    if (!SessionService.isCurrentRoleAnAdminRole() && !SessionService.verifyCurrentRole([Roles.PORTER])) {
        return;
    }
    $rootScope.navbarType = 'PORTER';

    var vm = this;

    vm.gettext = gettext;
    vm.CylinderListService = CylinderListService;
    vm.OrderListService = OrderListService;
    vm.CylinderOperatingModes = CylinderOperatingModes;

    vm.LocationListService = LocationListService;

    vm.cylinderRefreshIntervalMillis = cylinderRefreshIntervalMillis;
    vm.scope = $scope;
    vm.rootScope = $rootScope;
    vm.q = $q;
    vm.animationsEnabled = true;

    vm.faultyCylinderSummary = faultyCylinderSummary;

    vm.porterHospitalActionRequest = null;
    vm.hasReportToDisplay = null;
    vm.NotificationsService = NotificationsService;

    function updatePorterHospitalActionRequest(ActionRequest) {
        vm.porterHospitalActionRequest = ActionRequest;
        vm.hasReportToDisplay = vm.porterHospitalActionRequest.cylinderTypes;
        if (vm.hasReportToDisplay) {
            vm.numberOfColumnInATable = vm.porterHospitalActionRequest.cylinderTypes.length + 1;
        }
    }

    $(document).ready(function () {
        vm._setupTooltips();
    });

    updatePorterHospitalActionRequest(porterHospitalActionRequest);

    vm.updatePorterHospitalActionRequest = updatePorterHospitalActionRequest;

    function refresh() {
        return vm.CylinderListService.faultyCylinderSummary()
            .then(function (faultyCylinderSummary) {
                vm.faultyCylinderSummary = faultyCylinderSummary;
                return vm.OrderListService.newOrderSummary();
            });
    }

    vm.updateOrderState = updateOrderState;
    vm.resetNotifications = resetNotifications;
    vm.openOrderRejection = openOrderRejectionModal;

    vm.oneAtATime = false;
    vm.overviewTotals = Common.summariseOverview(vm.expectations);
    vm.status = {};
    vm.porterSummary = Common.generatePorterSummary(vm.locations, vm.cylinderTypes, vm.cylinders, vm.orders);
    vm.orderTypes = [];
    vm.showOrderHistory = false;
    vm.showOrderSummary = true;
    vm.currentTab = 'overview';
    vm.isDemo = $window.demo;
    vm.RefreshService = RefreshService;

    function refreshCylinders() {
        return vm.CylinderListService.findAll().then(function (cylinders) {
            vm.cylinders = Common.processCylinders(cylinders, vm.cylinderTypes, vm.locations, vm.hubs);
            vm.expectations = Common.summariseExpectations(vm.locations, vm.cylinders);
            vm.overviewTotals = Common.summariseOverview(vm.expectations);

            return vm.cylinders;
        });
    }

    vm.RefreshService.start(vm.cylinderRefreshIntervalMillis, refresh);
    $scope.$on('$destroy', function () {
        vm.RefreshService.stop();
    });

    function updateOrderState(location, order, newState, msg) {
        var removed = _.remove(vm.orders[location][order.status], order);
        order.status = newState;
        if (newState === 'Cancelled') {
            order.message = msg;
        }

        if (vm.orders[location][newState]) {
            vm.orders[location][newState].push(order);
        } else {
            vm.orders[location][newState] = [order];
        }

        return OrderListService.save(order).then(function () {
            Common.enrichOrder(order, vm.locations, vm.cylinderTypes);
            vm.porterSummary = Common.generatePorterSummary(vm.locations, vm.cylinderTypes, vm.cylinders, vm.orders);
        });
    }

    function resetNotifications(orders) {
        orders.notifications = 0;
    }

    function openOrderRejectionModal(location, order) {
        var modalInstance = $uibModal.open({
            animation: vm.animationsEnabled,
            templateUrl: 'orderRejectionModalContent.html', // TODO - this template doesn't exist!!
            controller: 'OrderRejectionModalController',
            controllerAs: 'rejectionCtrl',
            size: 'lg'
        });

        return modalInstance.result.then(function (msg) {
            return updateOrderState(location, order, 'cancelled', msg);
        });
    }
}

ActionRequest.prototype.commaSeparatedAnd = function (array) {
    if (_.isArray(array)) {
        return [array.slice(0, -1).join(', '), array.slice(-1)[0]].join(array.length < 2 ? '' : ' and ');
    }

    return '';
};

ActionRequest.prototype.emptyCylinders = function () {
    // TODO refactor this and erorAndWarnings()
    var self = this;
    var returnValue = _.map(self.locations, function (location) {
        return {
            name: location.name,
            cylinderTypes: _.map(self.cylinderTypes, function (cylType) {
                return {
                    gas: cylType.gas,
                    valve: cylType.valve,
                    size: cylType.size,
                    tableHeader: [cylType.gas.gasType, cylType.valve.valveType, cylType.size.sizeCode].join(' '),
                    noOfCylinders: _.filter(self.cylinders, function (cylinder) {
                        return cylinder.operatingMode !== self.CylinderOperatingModes.FAULT &&
                            cylinder.lastKnownLocationId === location.id &&
                            cylinder.cylinderTypeId === cylType.id &&
                            cylinder.isEmpty;
                    }).length
                };
            })
        };
    }).filter(function (location) {
        var sumOfFaultyCylindersAtThisLocation = _.sumBy(location.cylinderTypes, function (cylType) {
            return cylType.noOfCylinders;
        });
        return sumOfFaultyCylindersAtThisLocation > 0;
    });

    if (angular.equals(self.previousEmptyCylinders, returnValue)) {
        return self.previousEmptyCylinders;
    }
    self.previousEmptyCylinders = returnValue;
    return returnValue;
};


ActionRequest.prototype.cylinderFaults = function () {
    var self = this;
    var returnValue = _.map(self.locations, function (location) {
        return {
            name: location.name,
            cylinderTypes: _.map(self.cylinderTypes, function (cylType) {
                return {
                    gas: cylType.gas,
                    valve: cylType.valve,
                    size: cylType.size,
                    tableHeader: [cylType.gas.gasType, cylType.valve.valveType, cylType.size.sizeCode].join(' '),
                    noOfCylinders: _.filter(self.cylinders, function (cylinder) {
                        return cylinder.lastKnownLocationId === location.id &&
                            cylinder.cylinderTypeId === cylType.id &&
                            cylinder.operatingMode === self.CylinderOperatingModes.FAULT;
                    }).length
                };
            })
        };
    }).filter(function (location) {
        var sumOfFaultyCylindersAtThisLocation = _.sumBy(location.cylinderTypes, function (cylType) {
            return cylType.noOfCylinders;
        });
        return sumOfFaultyCylindersAtThisLocation > 0;
    });

    if (angular.equals(self.previousCylinderFaults, returnValue)) {
        return self.previousCylinderFaults;
    }
    self.previousCylinderFaults = returnValue;
    return returnValue;
};

ActionRequest.prototype.hasCylinderFaults = function () {
    return this.faultyCylinderSummary.totalCount > 0;
};

ActionRequest.prototype.orderRequests = function () {
    var self = this;

    var returnValue = self.getOrdersThatAre('New');


    if (angular.equals(self.previousOrderRequests, returnValue)) {
        return self.previousOrderRequests;
    }
    self.previousOrderRequests = returnValue;
    return returnValue;
};

ActionRequest.prototype.totalOrderRequests = function () {
    return _.sumBy(this.orderRequests(), function (orderRequest) {
        return _.sumBy(orderRequest.cylinderTypes, function (cylType) {
            return cylType.noOfOrders;
        });
    });
};

ActionRequest.prototype.pendingDeliveries = function () {
    var self = this;

    var returnValue = self.getOrdersThatAre('Acknowledged');

    if (angular.equals(self.previousPendingDeliveries, returnValue)) {
        return self.previousPendingDeliveries;
    }
    self.previousPendingDeliveries = returnValue;
    return returnValue;
};

ActionRequest.prototype.totalPendingDeliveries = function () {
    return _.sumBy(this.pendingDeliveries(), function (orderRequest) {
        return _.sumBy(orderRequest.cylinderTypes, function (cylType) {
            return cylType.noOfOrders;
        });
    });
};


ActionRequest.prototype.getOrdersThatAre = function (orderStatus) { // 'acknowledged'
    var self = this;
    return _.map(self.locations, function (location) {
        return {
            id: location.id,
            name: location.name,
            cylinderTypes: _.sortBy(_.map(self.cylinderTypes, function (cylType) {
                return {
                    gas: cylType.gas,
                    valve: cylType.valve,
                    size: cylType.size,
                    tableHeader: [cylType.gas.gasType, cylType.valve.valveType, cylType.size.sizeCode].join(' '),
                    noOfOrders: _.filter(self.orderList, function (order) {
                        return order.locationId === location.id &&
                            order.status === orderStatus &&
                            (order.cylinderTypeId === cylType.id);
                    }).length
                };
            }), ['gasType', 'sizeCode'])
        };
    }).filter(function (location) {
        var totalCylindersPendingDelivery = _.sumBy(location.cylinderTypes, function (cylType) {
            return cylType.noOfOrders;
        });
        return totalCylindersPendingDelivery > 0;
    });
};

ActionRequest.prototype.gasHeaderNamesAndColSpans = function (cylinderTypes) {
    var returnValue = [];

    _.forEach(cylinderTypes, function (cylType) {
        var done = false;
        _.forEach(returnValue, function (thDet) {
            if (cylType.gas.gasType === thDet.gas.gasType) {
                thDet.span++;
                done = true;
            }
        });
        if (!done) {
            returnValue.push({
                gas: cylType.gas,
                span: 1
            });
        }
    });

    if (angular.equals(this.previousGasHeaderNamesAndColSpans, returnValue)) {
        return this.previousGasHeaderNamesAndColSpans;
    }
    this.previousGasHeaderNamesAndColSpans = returnValue;
    return returnValue;
};

ActionRequest.prototype.acceptOrders = function (locationId) {
    var self = this;
    self.OrderListService.acceptAllOrders(locationId)
        .then(function () {
            self.refresh();
        });
};

ActionRequest.prototype.cancelOrders = function (currentStatus, locationId) {
    var self = this;
    self.OrderListService.cancelAllOrders(currentStatus, locationId, self.gettext('order cancelled by porter')) // ATTENTION: It's only stored into the database and it's never displayed, no need to localise
        .then(function () {
            self.refresh();
        });
};

ActionRequest.prototype.deliverOrders = function (locationId) {
    var self = this;
    self.OrderListService.fulfillAllOrders(locationId)
        .then(function () {
            self.refresh();
        });
};

ActionRequest.prototype.updateAllOrderState = function (location, currentState, newState) {
    var self = this;
    _.filter(self.orderList, function (order) {
        if (location === 'all-locations') return order.status === currentState;
        return order.locationId === location.id && order.status === currentState;
    }).map(function (order) {
        var theLocation = order.location;
        var theCylinderType = order.cylinderType;
        delete order.location;
        delete order.cylinderType;
        order.status = newState;
        self.OrderListService.save(order, {location: theLocation}, {cylinderType: theCylinderType});
    });
};

ActionRequest.prototype.refresh = function () {
    console.log('Now Refreshing');
    var self = this;
    return self.CylinderListService.faultyCylinderSummary()
        .then(function (faultyCylinderSummary) {
            self.faultyCylinderSummary = faultyCylinderSummary;
            return self.OrderListService.newOrderSummary();
        });
};

ActionRequest.prototype.regenerateActionRequest = function () {
    var self = this;
    self.LocationListService.regenHospitalActionRequest()
        .then(function (result) {
            self.updatePorterHospitalActionRequest(result);
        });
};

ActionRequest.prototype.checkIfOverstockNotificationsEnabled = function () {
    var self = this;
    return self.NotificationsService.areOverStockNotificationsEnabled();
};

ActionRequest.prototype._setupTooltips = function () {
    $('[data-toggle="tooltip"]').tooltip('destroy');
    $('[data-toggle="tooltip"]').tooltip();
};

ActionRequest.$inject = ["$rootScope", "$scope", "$q", "$uibModal", "Common", "cylinderRefreshIntervalMillis", "SessionService", "CylinderListService", "OrderListService", "$window", "LocationListService", "RefreshService", "faultyCylinderSummary", "gettext", "Roles", "CylinderOperatingModes", "porterHospitalActionRequest" , "NotificationsService"];
