let images,
    currentImage,
    currentCountryStats,
    baseUrl,
    defaultCountryCode;

defaultCountryCode = 'default';
baseUrl = '../resources/img/Loginphotos/';

export function LoginPhotoService() {
  images = _.reduce(this.regionToImageMapping, function (flattened, country, countryCode) {
    return flattened.concat(_.map(country.images, function (image) {
      image.countryCode = countryCode;
      return image;
    }));
  }, []);
}

function getCurrentImageWithBaseUrlAndCountryCode() {
    return getImageWithBaseUrlAndCountryCode(currentImage, currentCountryStats);
}

function getImageWithBaseUrlAndCountryCode(image, countryStats) {
    if (!image.url) {
        image.url = baseUrl + image.name;
    }
    if (!image.countryCode) {
        image.countryCode = countryStats.countryCode;
    }
    return image;
}

LoginPhotoService.prototype.setRegionStats = function (regionStats) {
    var self = this;

    self.hasStats = true;
    self.globalStats = _.find(regionStats, {name: 'Global'});

    if (!regionStats || regionStats.length === 0 || !self.globalStats || !self.globalStats.cylindersCount) {
        self.hasStats = false;
        return;
    }

    self.countryStats = _.filter(regionStats, function (region) {
        return region.cylindersCount > 0 && region.name !== 'Global';
    });
    var availableCountries = _.map(self.countryStats, function (country) {
        return country.countryCode;
    });

    _.remove(images, function (image) {
        return !_.includes(availableCountries, image.countryCode);
    });

    if ((self.globalStats.cylindersCount * 1) > 0) {
        currentCountryStats = _.sample(self.countryStats);

        var countryCode = self.regionToImageMapping[currentCountryStats.countryCode] ? currentCountryStats.countryCode : defaultCountryCode;
        currentImage = _.sample(self.regionToImageMapping[countryCode].images);
        currentImage.countryCode = countryCode;
    }
};

LoginPhotoService.prototype.getCurrentStats = function () {
    var toReturn = {
        hasStats: this.hasStats
    };

    if (this.hasStats) {
        toReturn.globalStats = this.globalStats;
        toReturn.countryStats = currentCountryStats;
        toReturn.image = getCurrentImageWithBaseUrlAndCountryCode();
    }

    return toReturn;
};

LoginPhotoService.prototype.getCurrentIndex = function () {
    return _.findIndex(images, {name: currentImage.name});
};

LoginPhotoService.prototype.getNextByOffset = function (offset) {
    var newIndex = this.getCurrentIndex() + offset;
    if (newIndex >= images.length) {
        newIndex -= images.length;
    } else if (newIndex < 0) {
        newIndex = images.length - 2 - newIndex;
    }

    currentImage = images[newIndex];
    currentCountryStats = _.find(this.countryStats, {countryCode: currentImage.countryCode});

    return this.getCurrentStats();
};

LoginPhotoService.prototype.getNext = function () {
    return this.getNextByOffset(1);
};

LoginPhotoService.prototype.getPrevious = function () {
    return this.getNextByOffset(-1);
};

LoginPhotoService.prototype.getDefault = function () {
    return getImageWithBaseUrlAndCountryCode(this.regionToImageMapping.default.images[0], {countryCode: ''});
};

LoginPhotoService.prototype.regionToImageMapping = {
    default: {
        images: [{
            name: 'Transport.jpg',
            credit: '',
            link: ''
        }]
    },
    DE: {
        images: [{
            name: 'DE_Munich_Marienplatz_Stefan Jurca_Flickr.jpg',
            credit: 'CC Image by Stefan Jurca on Flickr',
            link: 'https://flic.kr/p/rNKBKK'
        },
            {
                name: 'DE_Berlin_Reichstag_Reinhard Link_Flickr.jpg',
                credit: 'CC Image by Reinhard Link on Flickr',
                link: 'https://flic.kr/p/pTVJqy'
            },
            {
                name: 'DE_MunichSkyline_Achim Lammerts_Flickr.jpg',
                credit: 'CC Image by Achim Lammerts on Flickr',
                link: 'https://flic.kr/p/mitctk'
            },
            {
                name: 'DE_Berlin_BrandenbergGate2_Tom Godber_Flickr.jpg',
                credit: 'CC Image by Tom Godber on Flickr',
                link: 'https://flic.kr/p/eaotDG'
            },
            {
                name: 'DE_Berlin_Jorg Schubert_Flickr.jpg',
                credit: 'CC Image by Jörg Schubert on Flickr',
                link: 'https://flic.kr/p/mgVHQT'
            }]
    },
    ES: {
        images: [{
            name: 'ES_Barcelona_skyline_Rob Gillies_Flickr.jpg',
            credit: 'CC Image by Rob Gillies on Flickr',
            link: 'https://flic.kr/p/61ScJW'
        },
            {
                name: 'ES_Madrid_Skyline_Barcex_Flickr.jpg',
                credit: 'CC Image by Barcex on Flickr',
                link: 'https://flic.kr/p/mfMprF'
            },
            {
                name: 'ES_Madrid_GranVia_Felipe Gabaldon_Flickr.jpg',
                credit: 'CC Image by Felipe Gabaldón on Flickr',
                link: 'https://flic.kr/p/5QXEN6'
            }]
    },
    HK: {
        images: [{
            name: 'HK_HongKong_Junk_KittyKaht_Flickr.jpg',
            credit: 'CC Image by KittyKaht on Flickr',
            link: 'https://flic.kr/p/aDqqQf'
        },
            {
                name: 'HK_HongKong_Skyline_Daxis_Flickr.jpg',
                credit: 'CC Image by Daxis on Flickr',
                link: 'https://flic.kr/p/Bgygv5'
            }]
    },
    NL: {
        images: [{
            name: 'NL_Amsterdam_Javier Vieras_Flickr.jpg',
            credit: 'CC Image by Javier Vieras on Flickr',
            link: 'https://flic.kr/p/e6k1z7'
        }]
    },
    NO: {
        images: [{
            name: 'NO_Eigeroy_Sten Dueland_Flickr.jpg',
            credit: 'CC Image by Sten Dueland on Flickr',
            link: 'https://flic.kr/p/pqPvC5'
        },
            {
                name: 'NO_Norwegian sunset near Tromso_Diana Robinson_Flickr.jpg',
                credit: 'CC Image by Diana Robinson on Flickr',
                link: 'https://flic.kr/p/eogcDX'
            },
            {
                name: 'NO_Oslo_oseberg ship_mararie_Flickr.jpg',
                credit: 'CC Image by mararie on Flickr',
                link: 'https://flic.kr/p/8VCw7k'
            }]
    },
    SE: {
        images: [{
            name: 'SE_Stockholm_skyline_Thomas Fabian_Flickr.jpg',
            credit: 'CC Image by Thomas Fabian on Flickr',
            link: 'https://flic.kr/p/CkStEY'
        },
            {
                name: 'SE_Stockholm_GamlaStan_2benny_Flickr.jpg',
                credit: 'CC Image by 2benny on Flickr',
                link: 'https://flic.kr/p/e2aBhh'
            }]
    },
    UK: {
        images: [{
            name: 'UK_England_FalkirkViaduct_Micheal_Flickr.jpg',
            credit: 'CC Image by Micheal on Flickr',
            link: 'https://flic.kr/p/tD937h'
        },
            {
                name: 'UK_LondonUnderground_Ian Muttoo_Flickr.jpg',
                credit: 'CC Image by Ian Muttoo on Flickr',
                link: 'https://flic.kr/p/4euYUC'
            },
            {
                name: 'UK_Big Ben_Hernan Pinera_Flickr.jpg',
                credit: 'CC Image by Hernán Piñera on Flickr',
                link: 'https://flic.kr/p/dD5P2R'
            },
            {
                name: 'UK_Westminster_Hernan Pinera_Flickr.jpg',
                credit: 'CC Image by Hernán Piñera on Flickr',
                link: 'https://flic.kr/p/egmRk6'
            }]
    },
    US: {
        images: [{
            name: 'US_AtlantaSunset_Steve Hardy_Flickr.jpg',
            credit: 'CC Image by Steve Hardy on Flickr',
            link: 'https://flic.kr/p/5gZbaB'
        },
            {
                name: 'US_ChicagoBean_Steve Wright Jr._Flickr.jpg',
                credit: 'CC Image by Steve Wright Jr. on Flickr',
                link: 'https://flic.kr/p/adUqtZ'
            },
            {
                name: 'US_GrandCanyon_Grand Canyon National Park_Flickr.jpg',
                credit: 'CC Image by Grand Canyon National Park on Flickr',
                link: 'https://flic.kr/p/e1pNmi'
            },
            {
                name: 'US_SanFranCisco_Nicolas Raymond_Flickr.jpg',
                credit: 'CC Image by Nicolas Raymond on Flickr',
                link: 'https://flic.kr/p/iQ8pKS'
            }]
    },
    CH: {
        images: [{
            name: 'CH_Sankt_Moritz_Dorf_Canton_of_Graubunden_Switzerland_Flickr.jpg',
            credit: 'CC Image by Dennis Jarvis on Flickr',
            link: 'https://flic.kr/p/zZbNrn'
        }]
    },
    LU: {
        images: [{
            name: 'LU_Mamer_Luxemburg_Luxembourg_Flickr.jpg',
            credit: 'CC Image by Tristan Schmurr on Flickr',
            link: 'https://flic.kr/p/Eh1UpQ'
        }]
    }
};