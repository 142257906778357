// ATTENTION: Keep it in synch with the liv-iq-api/common/models/locationTypes.js or make it one
const STANDARD_LOCATION = 'Standard Location';
const CENTRAL_STORE = 'Central Store';
const DEPARTMENTAL_STORE = 'Departmental Store';
const DEPARTMENTAL_LOCATION = 'Departmental Location';

export const locationTypes = {
    // ATTENTION: Keep it in synch with the liv-iq-api/common/models/locationTypes.js or make it one
    STANDARD_LOCATION: gettext(STANDARD_LOCATION),
    CENTRAL_STORE: gettext(CENTRAL_STORE),
    DEPARTMENTAL_STORE: gettext(DEPARTMENTAL_STORE),
    DEPARTMENTAL_LOCATION: gettext(DEPARTMENTAL_LOCATION)
};

export const locationTypesNoLocale = {
    // ATTENTION: Keep it in synch with the liv-iq-api/common/models/locationTypes.js or make it one
    STANDARD_LOCATION: STANDARD_LOCATION,
    CENTRAL_STORE: CENTRAL_STORE,
    DEPARTMENTAL_STORE: DEPARTMENTAL_STORE,
    DEPARTMENTAL_LOCATION: DEPARTMENTAL_LOCATION
};

/**
 * Does nothing, it's only needed to extract strings to localise
 *
 * @param text
 * @returns {*}
 */
function gettext(text) {
    return text;
}