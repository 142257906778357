export function ButtonStatsDashboardPanelCtrl($scope, ReportService, CommonTranslations, gettextCatalog) {
    var self = this;

    clearDisplayStats();

    self.timeUnits = gettextCatalog.getString('hours');

    $scope.$watch('ctrl.timeIntervalKey', function (timeInterval) {
        return ReportService.buttonStatsReport(timeInterval)
            .then(function (results) {
                var overallStats = _.find(results, function (r) {
                    return r.locationid === null && r.buttontasktypeid === null;
                });

                if (overallStats) {
                    setDisplayStats(overallStats.pushcount, overallStats.avgacktime, overallStats.avgfulfilltime);
                } else {
                    clearDisplayStats();
                }
            })
            .catch(function (err) {
                console.error(err);
                clearDisplayStats();
            });
    });

    $(document)
        .ready(function () {
            $('[data-toggle="tooltip"]')
                .tooltip('destroy');
            $('[data-toggle="tooltip"]')
                .tooltip();
        });

    function clearDisplayStats() {
        setDisplayStats();
    }

    function setDisplayStats(pushCount, avgAckTime, avgFulfillTime) {
        self.displayStats = {
            pushCount: pushCount !== undefined ? pushCount : CommonTranslations.NOT_AVAILABLE_ABBREVIATION,
            avgAckTime: avgAckTime ? moment.duration(avgAckTime, 'seconds')
                .humanize() : CommonTranslations.NOT_AVAILABLE_ABBREVIATION,
            avgFulfillTime: avgFulfillTime ? moment.duration(avgFulfillTime, 'seconds')
                .humanize() : CommonTranslations.NOT_AVAILABLE_ABBREVIATION
        };
    }
}

ButtonStatsDashboardPanelCtrl.$inject = ['$scope', 'ReportService', 'CommonTranslations', 'gettextCatalog'];