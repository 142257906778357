import "jquery-ui/slider";

export function slider() {
  return {
    restrict: 'A',
    scope: {
      config: '=config',
      value: '=model'
    },
    link: function (scope, elem, attrs) {
      $(elem).slider({
        range: false,
        min: scope.config.min,
        max: scope.config.max,
        step: scope.config.step,
        start: scope.config.start,
        stop: scope.config.stop,
        slide: function (event, ui) {
          scope.$apply(function () {
            scope.value = ui.value;
          });
        }
      });
      scope.value = scope.value || scope.config.defaultValue || 0;
      $(elem).slider('value', scope.value);
    }
  };
}
