import {CommonTranslations} from "./CommonTranslations";
import {CommonFactory} from "./common";
import {DATE_FORMAT_FOR_DATE_RANGE_PICKER_INPUT} from "./DATE_FORMAT_FOR_DATE_RANGE_PICKER_INPUT";
import {formatForDateRangePickerInputFactory} from "./formatForDateRangePickerInput";
import {setupDateRangePickerBySelectorFactory} from "./setupDateRangePickerBySelector";
import {AccessDisabled} from "./accessDisabled/accessDisabled";
import {FilteringAndPaginationApplicatorFactory} from "./angular-smart-table/FilteringAndPaginationApplicator";
import {SmartTableClientAdapterFactory} from "./angular-smart-table/SmartTableClientAdapter";
import {SmartTableServerAdapterFactory} from "./angular-smart-table/smartTableServerAdapter";

export const SHARED_MODULE = 'shared';

angular.module(SHARED_MODULE, []);

angular.module(SHARED_MODULE)
    .constant('DATE_FORMAT_FOR_DATE_RANGE_PICKER_INPUT', DATE_FORMAT_FOR_DATE_RANGE_PICKER_INPUT);

angular.module(SHARED_MODULE)
    .controller('AccessDisabled', AccessDisabled);

angular.module(SHARED_MODULE)
    .factory('Common', CommonFactory)
    .factory('formatForDateRangePickerInput', formatForDateRangePickerInputFactory)
    .factory('setupDateRangePickerBySelector', setupDateRangePickerBySelectorFactory);

angular.module(SHARED_MODULE)
    .service('CommonTranslations', CommonTranslations)
    .service('FilteringAndPaginationApplicatorFactory', FilteringAndPaginationApplicatorFactory)
    .service('SmartTableClientAdapter', SmartTableClientAdapterFactory)
    .service('SmartTableServerAdapter', SmartTableServerAdapterFactory);