export function NotificationToggleController($scope, PushNotificationsService, ButtonsFeatureService, gettextCatalog) {
    var ready = false;

    var self = this;
    self.isBusy = false;

    self.shouldShow = function () {
        return ButtonsFeatureService.isEnabled() && PushNotificationsService.notificationsSupported && ready;
    };

    self.subscribedToNotifications = function () {
        return PushNotificationsService.subscribed;
    };

    self.areNotificationsDenied = PushNotificationsService.areNotificationsDenied;

    self.toggleNotificationSubscription = function () {
        self.isBusy = true;

        var action;
        if (self.subscribedToNotifications()) {
            action = PushNotificationsService.unsubscribe();
        } else {
            action = PushNotificationsService.subscribe();
        }

        var onFinishedAction = function () {
            self.isBusy = false;
            $scope.$apply();
        };

        return action
            .then(onFinishedAction)
            .catch(function (err) {
                console.error(err);
                onFinishedAction();
            });
    };

    PushNotificationsService.setupAction
        .then(function () {
            ready = true;
        });
}

NotificationToggleController.$inject = ["$scope", "PushNotificationsService", "ButtonsFeatureService", "gettextCatalog"];