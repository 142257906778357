export function PorterOrderHistory($rootScope, $uibModal, SessionService, orderRefreshIntervalMillis, OrderListService, SmartTableServerAdapter, gettext, Roles, ButtonsOrdersService, ButtonsFeatureService) {
    var self = this;

    if (!SessionService.isCurrentRoleAnAdminRole() && !SessionService.verifyCurrentRole([Roles.PORTER, Roles.NURSE])) {
        return;
    }

    self.cylinderFeatureEnabled = $rootScope.hospital === null || !!$rootScope.hospital.features.cylinder;

    $rootScope.navbarType = 'PORTER';
    self.buttonsOrdersService = ButtonsOrdersService;
    self.buttonsEnabled = ButtonsFeatureService.isEnabled();
    self.orderRefreshIntervalMillis = orderRefreshIntervalMillis;
    self.ordersGrid = new SmartTableServerAdapter(OrderListService);
    self.buttonsOtherOrdersGrid = new SmartTableServerAdapter(self.buttonsOrdersService, function () {
        return {
            buttonTaskTypeId: {
                regexp: '/OT.*/i'
            }
        };
    });
    self.buttonsCylinderOrdersGrid = new SmartTableServerAdapter(self.buttonsOrdersService, function () {
        return {
            buttonTaskTypeId: {
                regexp: '/CT.*/i'
            }
        };
    });

    self.$modal = $uibModal;
    self.OrderListService = OrderListService;
    self.gettext = gettext;
}

PorterOrderHistory.prototype.openOrderRejection = function (order) {
    var self = this;
    var modalInstance = self.$modal.open({
        animation: self.animationsEnabled,
        template: require('./orderRejectionModal/orderRejectionModal.tpl.html'),
        controller: 'OrderRejectionModalController',
        controllerAs: 'rejectionCtrl',
        size: 'lg'
    });

    return modalInstance.result.then(function (msg) {
        return self.cancel(order, msg);
    });
};

PorterOrderHistory.prototype.cancel = function (order, msg) {
    var self = this;
    order.cancelledTime = new Date();
    order.message = msg || self.gettext('cancelled by requester'); // ATTENTION: It's only stored into the database and it's never displayed, no need to localise
    return self.OrderListService.save(order)
        .then(function () {
            self.ordersGrid.refresh();
        });
};

PorterOrderHistory.$inject = ["$rootScope", "$uibModal", "SessionService", "orderRefreshIntervalMillis", "OrderListService", "SmartTableServerAdapter", "gettext", "Roles", "ButtonsOrdersService", "ButtonsFeatureService"];