import * as angular from "angular";

import "jquery";
import "ng-password-strength";
import "angular-numeraljs";
import "angular-ui-bootstrap";
import "angular-ui-slider";
import "angular-hotkeys";
import "angular-toastr";
import "angular-bootstrap-show-errors";
import "ui-select";
import "angular-sanitize";
import "ng-csv";
import "bootstrap/dist/js/bootstrap.min";
import "jquery-ui";
import "daterangepicker";

import angularFilter from "angular-filter";
import angularRoute from "angular-route";
import angularResource from "angular-resource";
import angularMoment from "angular-moment";
import ngMessages from "angular-messages";
import blockUI from "angular-block-ui";

import * as smartTable from 'angular-smart-table';
import * as dynamicLocale from 'angular-dynamic-locale';

import "../i18n/liv-iq-hospital_de_DE.po";
import "../i18n/liv-iq-hospital_en_GB.po";
import "../i18n/liv-iq-hospital_en_US.po";
import "../i18n/liv-iq-hospital_eo.po";
import "../i18n/liv-iq-hospital_es_ES.po";
import "../i18n/liv-iq-hospital_fr_FR.po";
import "../i18n/liv-iq-hospital_nl_NL.po";
import "../i18n/liv-iq-hospital_pl_PL.po";
import "../i18n/liv-iq-hospital_sv_SE.po";

import "../i18n/additional-translation-keys";
import "../i18n/angular-locale_eo";
import "../i18n/jquery.ui.datepicker-eo";
import "../i18n/jquery.ui.datepicker-en_GB";
import "../i18n/jquery.ui.datepicker-es_ES";
import "../i18n/jquery.ui.datepicker-de_DE";
import "../i18n/jquery.ui.datepicker-sv_SE";
import "../i18n/jquery.ui.datepicker-nl_NL";
import "../i18n/jquery.ui.datepicker-fr_FR";
import "../node_modules/jquery-ui/"

import "angular-i18n/angular-locale_de-de";
import "angular-i18n/angular-locale_en-gb";
import "angular-i18n/angular-locale_en-us";
import "angular-i18n/angular-locale_eo";
import "angular-i18n/angular-locale_es-es";
import "angular-i18n/angular-locale_fr-fr";
import "angular-i18n/angular-locale_nl-nl";
import "angular-i18n/angular-locale_pl-pl";
import "angular-i18n/angular-locale_sv-se";

import "./main-navbar";
import "./serviceWorkers/serviceWorker";

const importAll = r => r.keys().map(r);
importAll(require.context('./resources/img', false, /\.(gif|png|jpg|svg)$/));
importAll(require.context('./resources/flags', false, /\.png$/));
importAll(require.context('./resources/icons', false, /\.svg$/));

import './favicon.ico';

import "./json/timezones";
import "./json/lang";
import "./json/country";

import "./layout/navbar/empty.tpl-lazy.html";
import "./layout/navbar/admin.tpl-lazy.html";
import "./layout/navbar/hospitaladmin.tpl-lazy.html";
import "./layout/navbar/superadmin.tpl-lazy.html";
import "./layout/navbar/nurse.tpl-lazy.html";
import "./layout/navbar/porter.tpl-lazy.html";

import "./layout/navbar/navbar.changelanguage.tpl.html";
import "./layout/navbar/navbar.changeuser.porter.tpl.html";
import "./layout/navbar/navbar.changeuser.nurse.tpl.html";
import "./layout/navbar/navbar.changeuser.tpl.html";

import "./directives/liv-iq-pagination.custom.tpl.html";

import "./styles.less";

import lbServices from '../api-client/js/services/lb-services.js';

import {AUDITLOGS_MODULE} from "./services/auditLogs/auditLogs.module";
import {BUTTONS_MODULE} from "./services/buttons/buttons.module";
import {COLOURMAP_MODULE} from "./services/colourmap/colourmap.module";
import {CONFIG_PARAMETERS_MODULE} from "./services/configParameters/configParameters.module";
import {USERS_MODULE} from "./services/users/users.module";
import {COUNTRY_MODULE} from "./services/country/country.module";
import {CYLINDERS_MODULE} from "./services/cylinders/cylinders.module";
import {DASHBOARD_REPORTS_MODULE} from "./services/dashboardReport/dashboard-reports.module";
import {EVENT_LOGS_MODULE} from "./services/eventLog/EventLogService.module";
import {FILL_PRESSURE_PLAN_MODULE} from "./services/fillpressureplan/fillpressureplan.module";
import {HOSPITAL_FEATURES_MODULE} from "./services/hospitalFeatures/hospitalFeatures.module";
import {HOSPITALS_MODULE} from "./services/hospitals/hospitals.module";
import {HUB_CONNECTIVITY_MODULE} from "./services/hubconnectivity/hubconnectivity.module";
import {HUBS_MODULE} from "./services/hubs/hubs.module";
import {LOCATIONS_MODULE} from "./services/locations/locations.module";
import {LOCATION_TYPES_MODULE} from "./services/locationTypes/locationTypes.module";
import {NOTIFICATIONS_MODULE} from "./services/notifications/notifications.module";
import {ORDERS_MODULE} from "./services/orders/orders.module";
import {I18N_MODULE} from "./services/locale/locale.module";
import {PUSH_NOTIFICATIONS_MODULE} from "./services/pushNotifications/pushNotifications.module";
import {REFRESH_MODULE} from "./services/refresh/refresh.module";
import {REPORTS_MODULE} from "./services/reports/reports.module";
import {ROLES_MODULE} from "./services/roles/roles.module";
import {SERVICE_LEVELS_MODULES} from "./services/serviceLevels/serviceLevels.module";
import {USAGE_LOGS_MODULE} from "./services/usageLogs/usageLogs.module";
import {SHARED_MODULE} from "./shared/shared.module";
import {HOSPITAL_ADMIN_MODULE} from "./hospital/admin/admin.module";
import {HOSPITAL_ADMIN_AUDIT_LOGS_MODULE} from "./hospital/admin/auditLogs/auditLogs.module";
import {HOSPITAL_ADMIN_BUTTONS_MODULE} from "./hospital/admin/buttons/buttons.module";
import {HOSPITAL_ADMIN_CONFIGURATION_MODULE} from "./hospital/admin/configuration/configuration.module";
import {HOSPITAL_ADMIN_CYLINDERS_MODULE} from "./hospital/admin/cylinders/cylinders.module";
import {HOSPITAL_ADMIN_CYLINDER_VARIABLES_MODULE} from "./hospital/admin/cylinderVariables/cylinderVariables.module";
import {HOSPITAL_ADMIN_CYLINDER_TYPES_MODULE} from "./hospital/admin/cylinderTypes/cylinderTypes.module";
import {HOSPITAL_ADMIN_CYLINDER_USAGE_MODULE} from "./hospital/admin/cylinderUsage/cylinderUsage.module";
import {HOSPITAL_ADMIN_DASHBOARD_MODULE} from "./hospital/admin/dashboard/dashboard.module";
import {HOSPITAL_ADMIN_LOCATIONS_MODULE} from "./hospital/admin/locations/locations.module";
import {HOSPITAL_ADMIN_LOST_CYLINDERS_MODULE} from "./hospital/admin/lostCylinders/lost-cylinders.module";
import {HOSPITAL_ADMIN_HUBS_MODULE} from "./hospital/admin/hubs/hubs.module";
import {HOSPITAL_ADMIN_REPORTS_BILL_SPLIT_MODULE} from "./hospital/admin/reports/bill-split/bill-split.module";
import {HOSPITAL_ADMIN_REPORTS_CYLINDER_ALARM_MODULE} from "./hospital/admin/reports/cylinder-alarm/report-cylinder-alarm.module";
import {HOSPITAL_ADMIN_REPORTS_CYLINDER_DWELL_TIME} from "./hospital/admin/reports/cylinder-dwelltime/report-cylinder-dwelltime.module";
import {HOSPITAL_ADMIN_REPORTS_CYLINDER_FIRST_USE} from "./hospital/admin/reports/cylinder-first-use/report-cylinder-first-use.module";
import {HOSPITAL_ADMIN_REPORTS_CYLINDER_EMPTY_PRESSURE_MODULE} from "./hospital/admin/reports/cylinder-empty-pressure/report-cylinder-empty-pressure.module";
import {HOSPITAL_ADMIN_REPORTS_CYLINDER_EMPTY_REPLACEMENT_TIME_MODULE} from "./hospital/admin/reports/cylinder-empty-replacement/report-cylinder-empty-replacement-time.module";
import {HOSPITAL_ADMIN_REPORTS_CYLINDER_EMPTY_TO_RETURN_MODULE} from "./hospital/admin/reports/cylinder-empty-to-return/report-cylinder-empty-to-return.module";
import {HOSPITAL_ADMIN_REPORTS_CYLINDER_TREATMENT_MODULE} from "./hospital/admin/reports/cylinder-treatment/report-cylinder-treatment.module";
import {HOSPITAL_ADMIN_REPORTS_OXYGEN_STORED_MODULE} from "./hospital/admin/reports/oxygen-stored/report-oxygen-stored.module";
import {HOSPITAL_ADMIN_REPORTS_OXYGEN_USED_MODULE} from "./hospital/admin/reports/oxygen-used/report-oxygen-used.module";
import {HOSPITAL_ADMIN_REPORTS_STOCK_MODULE} from "./hospital/admin/reports/stock/report-stock.module";
import {HOSPITAL_ADMIN_REPORTS_BUTTONS_MODULE} from "./hospital/admin/reports/buttons/report-buttons.module";
import {HOSPITAL_ADMIN_USAGE_LOGS_MODULE} from "./hospital/admin/usageLogs/usageLogs.module";
import {HOSPITAL_ADMIN_USERS_MODULE} from "./hospital/admin/users/users.module";
import {HOSPITAL_ADMIN_SETTINGS_MODULE} from "./hospital/admin/settings/settings.module";
import {HOSPITAL_ADMIN_ABOUT_MODULE} from "./hospital/admin/about/about.module";
import {NURSE_MODULE} from "./hospital/nurse/nurse.module";
import {NURSE_DASHBOARD_MODULE} from "./hospital/nurse/dashboard/dashboard.module";
import {NURSE_ORDER_HISTORY_MODULE} from "./hospital/nurse/orderHistory/orderHistory.module";
import {NURSE_SETTINGS_MODULE} from "./hospital/nurse/settings/settings.module";
import {PORTER_MODULE} from "./hospital/porter/porter.module";
import {PORTER_DASHBOARD_MODULE} from "./hospital/porter/dashboard/dashboard.module";
import {PORTER_ORDER_HISTORY_MODULE} from "./hospital/porter/orderHistory/orderHistory.module";
import {PORTER_SETTINGS_MODULE} from "./hospital/porter/settings/settings.module";
import {PORTER_HOSPITAL_ACTION_REQUEST_MODULE} from "./hospital/porter/hospitalActionRequest/hospitalActionRequest.module";
import {ADMIN_MODULE} from "./admin/admin.module";
import {ADMIN_USERS_MODULE} from "./admin/users/users.module";
import {ADMIN_HOSPITALS_MODULE} from "./admin/hospitals/hospitals.module";
import {ADMIN_REPORTS_FILL_PLANT_MODULE} from "./admin/reports/report-fill-plant/report-fill-plant.module";
import {ADMIN_SETTINGS_MODULE} from "./admin/settings/settings.module";
import {SUPERADMIN_MODULE} from "./superadmin/superadmin.module";
import {SUPERADMIN_EVENT_LOGS_MODULE} from "./superadmin/event-logs/event-logs.module";
import {SUPERADMIN_USERS_MODULE} from "./superadmin/users/users.module";
import {SUPERADMIN_HOSPITALS_MODULE} from "./superadmin/hospitals/hospitals.module";
import {SUPERADMIN_SERVICE_LEVELS} from "./superadmin/serviceLevels/serviceLevels.module";
import {SUPERADMIN_SETTINGS_MODULE} from "./superadmin/settings/settings.module";
import {HOSPITAL_FEATURES_MODAL_MODULE} from "./hospitalFeaturesModal/hospitalFeatures.module";
import {FIRST_JOURNEY_MODULE} from "./firstJourney/firstJourney.module";
import {SIGN_IN_MODULE} from "./signin/signin.module";
import {COOKIE_POLICY_MODULE} from "./cookiePolicy/cookiePolicy.module";
import {LAYOUT_MODULE} from "./layout/layout.module";

import {AuthService} from "./signin/authentication.service";
import {LoginPhotoService} from "./signin/loginPhoto.service";
import {SessionService} from "./signin/session.service";
import {focusMe} from './directives/focusMe';
import {LivIqButtonTypeSelector} from "./directives/liv-iq-button-type-selector.directive";
import {ButtonTypeSelectorCtrl} from "./directives/liv-iq-button-type-selector.controller";
import {ButtonsOrderHistoryDirective} from "./directives/liv-iq-buttons-order-history";
import {ButtonsOrdersDirective} from "./directives/liv-iq-buttons-orders";
import {livIqConfigParameters} from "./directives/liv-iq-config-parameters.controller";
import {ConfigParametersDirective} from "./directives/liv-iq-config-parameters.directive";
import {CylindersDirective} from "./directives/liv-iq-cylinders.directive";
import {HospitalEditDirective} from "./directives/liv-iq-hospital-edit.directive";
import {HospitalsDirective} from "./directives/liv-iq-hospitals.directive";
import {OrdersDirective} from "./directives/liv-iq-orders.directive";
import {pageIdDirective} from "./directives/liv-iq-page-id.directive";
import {RepeatPasswordDirective} from "./directives/liv-iq-repeat-password.directive";
import {slider} from "./directives/liv-iq-slider.directive";
import {UserEditDirective} from "./directives/liv-iq-user-edit.directive";
import {UsersDirective} from "./directives/liv-iq-users.directive";
import {VirtualButtonsDirective} from "./directives/liv-iq-virtual-buttons.directive";
import {VirtualButtonsController} from "./directives/liv-iq-virtual-buttons.controller";
import {NotificationToggleDirective} from "./directives/notification-toggle.directive";
import {NotificationToggleController} from "./directives/notification-toggle.controller";
import {stAutoRefresh} from "./directives/st-auto-refresh";
import {stPersist} from "./directives/st-persist";
import {stResetSearch} from "./directives/st-reset-search";
import {stSearchDate} from "./directives/st-search-date";
import {stSortIcon} from "./directives/st-sort-icon";
import {validMacAddress} from "./directives/valid-mac-address";
import {livIqHumanizeAdviceToNurseFactory} from "./filters/livIqHumanizeAdviceToNurse";
import {livIqPercentageWhenFalsyOrNaNFactory} from "./filters/livIqPercentageWhenFalsyOrNaN";
import {livIqWhenFalsyOrNaNFactory} from "./filters/livIqWhenFalsyOrNaN";
import {preventTemplateCacheConfig, preventTemplateCacheFactory} from "./filters/preventCache";
import {CylinderFactory} from "./models/CylinderModel";
import {LocationFactory} from "./models/LocationModel";
import {ModelFactory} from "./models/Model";
import {HubFactory} from "./models/PegasusModel";
import {ENVIRONMENT_VARIABLES_MODULE} from "./environmentVariables";
import {MainController} from "./main.controller";

export const MAIN_MODULE = 'liv-iq-hospital';

const app = angular.module(MAIN_MODULE, [
    angularRoute,
    angularResource,
    'ngPasswordStrength',
    'ngNumeraljs',
    'ui.bootstrap',
    'ui.slider',
    angularFilter,

    SHARED_MODULE,

    lbServices,

    AUDITLOGS_MODULE,
    BUTTONS_MODULE,
    COLOURMAP_MODULE,
    CONFIG_PARAMETERS_MODULE,
    COUNTRY_MODULE,
    CYLINDERS_MODULE,
    DASHBOARD_REPORTS_MODULE,
    EVENT_LOGS_MODULE,
    FILL_PRESSURE_PLAN_MODULE,
    HOSPITAL_FEATURES_MODULE,
    HOSPITALS_MODULE,
    HUB_CONNECTIVITY_MODULE,
    HUBS_MODULE,
    LOCATIONS_MODULE,
    LOCATION_TYPES_MODULE,
    NOTIFICATIONS_MODULE,
    ORDERS_MODULE,
    PUSH_NOTIFICATIONS_MODULE,
    REFRESH_MODULE,
    REPORTS_MODULE,
    ROLES_MODULE,
    SERVICE_LEVELS_MODULES,
    USAGE_LOGS_MODULE,
    USERS_MODULE,

    HOSPITAL_ADMIN_MODULE,
    HOSPITAL_ADMIN_AUDIT_LOGS_MODULE,
    HOSPITAL_ADMIN_BUTTONS_MODULE,
    HOSPITAL_ADMIN_CONFIGURATION_MODULE,
    HOSPITAL_ADMIN_CYLINDERS_MODULE,
    HOSPITAL_ADMIN_CYLINDER_VARIABLES_MODULE,
    HOSPITAL_ADMIN_CYLINDER_TYPES_MODULE,
    HOSPITAL_ADMIN_CYLINDER_USAGE_MODULE,
    HOSPITAL_ADMIN_DASHBOARD_MODULE,
    HOSPITAL_ADMIN_LOCATIONS_MODULE,
    HOSPITAL_ADMIN_LOST_CYLINDERS_MODULE,
    HOSPITAL_ADMIN_HUBS_MODULE,
    HOSPITAL_ADMIN_REPORTS_BILL_SPLIT_MODULE,
    HOSPITAL_ADMIN_REPORTS_CYLINDER_ALARM_MODULE,
    HOSPITAL_ADMIN_REPORTS_CYLINDER_DWELL_TIME,
    HOSPITAL_ADMIN_REPORTS_CYLINDER_FIRST_USE,
    HOSPITAL_ADMIN_REPORTS_CYLINDER_EMPTY_PRESSURE_MODULE,
    HOSPITAL_ADMIN_REPORTS_CYLINDER_EMPTY_REPLACEMENT_TIME_MODULE,
    HOSPITAL_ADMIN_REPORTS_CYLINDER_EMPTY_TO_RETURN_MODULE,
    HOSPITAL_ADMIN_REPORTS_CYLINDER_TREATMENT_MODULE,
    HOSPITAL_ADMIN_REPORTS_OXYGEN_STORED_MODULE,
    HOSPITAL_ADMIN_REPORTS_OXYGEN_USED_MODULE,
    HOSPITAL_ADMIN_REPORTS_STOCK_MODULE,
    HOSPITAL_ADMIN_REPORTS_BUTTONS_MODULE,
    HOSPITAL_ADMIN_USAGE_LOGS_MODULE,
    HOSPITAL_ADMIN_USERS_MODULE,
    HOSPITAL_ADMIN_SETTINGS_MODULE,

    HOSPITAL_ADMIN_ABOUT_MODULE,

    NURSE_MODULE,
    NURSE_DASHBOARD_MODULE,
    NURSE_ORDER_HISTORY_MODULE,
    NURSE_SETTINGS_MODULE,

    PORTER_MODULE,
    PORTER_DASHBOARD_MODULE,
    PORTER_ORDER_HISTORY_MODULE,
    PORTER_SETTINGS_MODULE,
    PORTER_HOSPITAL_ACTION_REQUEST_MODULE,

    ADMIN_MODULE,
    ADMIN_USERS_MODULE,
    ADMIN_HOSPITALS_MODULE,
    ADMIN_REPORTS_FILL_PLANT_MODULE,
    ADMIN_SETTINGS_MODULE,

    SUPERADMIN_MODULE,
    SUPERADMIN_EVENT_LOGS_MODULE,
    SUPERADMIN_USERS_MODULE,
    SUPERADMIN_HOSPITALS_MODULE,
    SUPERADMIN_SERVICE_LEVELS,
    SUPERADMIN_SETTINGS_MODULE,
    HOSPITAL_FEATURES_MODAL_MODULE,
    FIRST_JOURNEY_MODULE,

    SIGN_IN_MODULE,
    COOKIE_POLICY_MODULE,
    LAYOUT_MODULE,
    I18N_MODULE,

    'cfp.hotkeys',
    angularMoment,
    'toastr',
    ngMessages,
    'ui.bootstrap.showErrors',
    smartTable,
    blockUI,
    dynamicLocale,
    'ui.select',
    'ngSanitize',
    'ngCsv',
    ENVIRONMENT_VARIABLES_MODULE
]);

app.constant('api_adm_url', '/hap_api/adm');
app.constant('api_svc_url', '/hap_api/svc');
app.constant('cylinderRefreshIntervalMillis', 60000);
app.constant('buttonsRefreshIntervalMillis', 60000);
app.constant('buttonsTasksRefreshIntervalMillis', 10000);
app.constant('buttonsOrdersRefreshIntervalMillis', 10000);
app.constant('orderRefreshIntervalMillis', 10000);
app.constant('hubKeepAliveIntervalMillis', 150000);

app.directive('devModeOnly', function () {
    return {
        restrict: 'A',
        transclude: false,
        link: function (scope, element) {
            scope.$watch(
                function () {
                    return window.devModeActivated;
                },
                function (newValue) {
                    if (newValue) {
                        console.info('dev mode activated');
                        $(element).show();
                    }
                }
            );
            $(element).hide();
        }
    };
});

app.config(toastrConfig);

function toastrConfig(toastrConfig) {
    angular.extend(toastrConfig, {
        closeButton: true,
        closeHtml: '<button>&times;</button>',
        iconClasses: {
            error: 'toast-error',
            info: 'toast-info',
            success: 'toast-success',
            warning: 'toast-warning'
        },
        tapToDismiss: true,
        timeOut: 2500
    });
}

toastrConfig.$inject = ["toastrConfig"];

app.config(httpConfig);

function httpConfig($httpProvider) {
    // By default LoopBack enables Cross-origin resource sharing (CORS) using the cors package.
    // Must also enable CORS on the client side.
    $httpProvider.defaults.useXDomain = true;
    delete $httpProvider.defaults.headers.common['X-Requested-With'];

    $httpProvider.interceptors.push(interceptor);

    function interceptor($q, toastr, $localStorage, $injector) {
        return {
            responseError: function (response) {
                if (response.status === 401) {
                    // Unauthorized so token must have expired
                    delete $localStorage.user;
                }
                if (response.status === 500) {
                    // Handle 500 error code
                }

                if (response.status === 422) {
                    console.error(response);

                    var gettextCatalog = $injector.get('gettextCatalog');

                    if (response.data && response.data.error.details && response.data.error.details.messages) {
                        var errorMessages = response.data.error.details.messages;
                        for (var key in errorMessages) {
                            if (errorMessages.hasOwnProperty(key)) {
                                for (var i = 0; i < errorMessages[key].length; i++) {
                                    toastr.error(gettextCatalog.getString(errorMessages[key][i]), gettextCatalog.getString(key), {timeOut: 0});
                                }
                            }
                        }
                    }
                }

                // Always reject (or resolve) the deferred you're given
                return $q.reject(response);
            }
        };
    }

    interceptor.$inject = ['$q', 'toastr', '$localStorage', '$injector'];
}

httpConfig.$inject = ["$httpProvider"];

app.config(tmhDynamicLocaleProviderConfig);

function tmhDynamicLocaleProviderConfig(tmhDynamicLocaleProvider) {
    tmhDynamicLocaleProvider.localeLocationPattern('vendor/angular-i18n/angular-locale_{{locale}}.js');
}

tmhDynamicLocaleProviderConfig.$inject = ["tmhDynamicLocaleProvider"];

/* blockUI configuration */
app.config(blockUIConfig);

function blockUIConfig(blockUIConfig) {
    blockUIConfig.requestFilter = function (config) {
        function blockReload(config) {
            var theUrl = config.url;
            return theUrl.match(/.*flatButtonOrders.*/) ||
                theUrl.match(/.*flatCylinders\/count.*/) ||
                theUrl.match(/.*locationSummaries\/getPage.*/) ||
                theUrl.match(/.*flatCylinders\/getPage.*/);
        }

        return !blockReload(config);
    };
}

blockUIConfig.$inject = ['blockUIConfig'];

app.config(uiSelectConfig);

function uiSelectConfig(uiSelectConfig) {
    uiSelectConfig.theme = 'bootstrap';
}

uiSelectConfig.$inject = ['uiSelectConfig'];

/* added in order to remove the default ! hash prefix. See: https://docs.angularjs.org/guide/migration */
app.config(locationProviderConfig);

function locationProviderConfig($locationProvider) {
    $locationProvider.hashPrefix('');
}

locationProviderConfig.$inject = ['$locationProvider'];

angular.module(MAIN_MODULE)
    .config(preventTemplateCacheConfig);

angular.module(MAIN_MODULE)
    .controller('livIqButtonTypeSelector', ButtonTypeSelectorCtrl)
    .controller('livIqConfigParameters', livIqConfigParameters)
    .controller('VirtualButtonsController', VirtualButtonsController)
    .controller('NotificationToggleController', NotificationToggleController)
    .controller('MainController', MainController);

angular.module(MAIN_MODULE)
    .directive('focusMe', focusMe)
    .directive('livIqButtonTypeSelector', LivIqButtonTypeSelector)
    .directive('livIqButtonsOrderHistory', ButtonsOrderHistoryDirective)
    .directive('livIqButtonsOrders', ButtonsOrdersDirective)
    .directive('livIqConfigParameters', ConfigParametersDirective)
    .directive('livIqCylinders', CylindersDirective)
    .directive('livIqHospitalEdit', HospitalEditDirective)
    .directive('livIqHospitals', HospitalsDirective)
    .directive('livIqOrders', OrdersDirective)
    .directive('livIqPageId', pageIdDirective)
    .directive('livIqPorterCylinders', CylindersDirective)
    .directive('livIqRepeatPassword', RepeatPasswordDirective)
    .directive('livIqSlider', slider)
    .directive('livIqUserEdit', UserEditDirective)
    .directive('livIqUsers', UsersDirective)
    .directive('livIqVirtualButtons', VirtualButtonsDirective)
    .directive('notificationToggle', NotificationToggleDirective)
    .directive('stAutoRefresh', stAutoRefresh)
    .directive('stPersist', stPersist)
    .directive('stResetSearch', stResetSearch)
    .directive('validMacAddress', validMacAddress);

angular.module(MAIN_MODULE)
    .factory('AuthService', AuthService)
    .factory('preventTemplateCache', preventTemplateCacheFactory)
    .factory('CylinderModel', CylinderFactory)
    .factory('LocationModel', LocationFactory)
    .factory('Model', ModelFactory)
    .factory('HubModel', HubFactory);

angular.module(MAIN_MODULE)
    .filter('livIqHumanizeAdviceToNurse', livIqHumanizeAdviceToNurseFactory)
    .filter('livIqPercentageWhenFalsyOrNaN', livIqPercentageWhenFalsyOrNaNFactory)
    .filter('livIqWhenFalsyOrNaN', livIqWhenFalsyOrNaNFactory);

angular.module(MAIN_MODULE)
    .service('LoginPhotoService', LoginPhotoService)
    .service('SessionService', SessionService);

angular.module('smart-table')
    .config(stSearchDate)
    .config(stSortIcon);
