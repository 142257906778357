export function HubListService($rootScope, $q, Hub, FlatHub, FlatHubConnectivity, hubKeepAliveIntervalMillis) {
    this.rootScope = $rootScope;
    this.Hub = Hub;
    this.FlatHub = FlatHub;
    this.FlatHubConnectivity = FlatHubConnectivity;
    this.q = $q;
}

HubListService.prototype.getBaseFilter = function (hospital) {
    return _.cloneDeep({
        filter: {
            where: {
                hospitalId: hospital.id,
                deleted: 0
            }
        }
    });
};

HubListService.prototype.add = function (hub) {
    var newHub = new this.Hub(hub);

    // add isNew attribute so backend audit logging can determine if is new hub
    // this is necessary because all hub have to be initialised with a user determined id
    if (hub) {
        delete newHub.isNew;
    } else {
        newHub.isNew = true;
    }

    // We don't have to wait for appReady because the "add" button appears after the app is ready
    newHub.hospitalId = this.rootScope.hospital.id;
    return newHub;
};

HubListService.prototype.find = function (name) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var filter = self.getBaseFilter(hospital);
        filter.filter.include = ['location', 'cylinders'];
        filter.where = filter.where || {};
        filter.where.name = name;
        return self.Hub.find(filter).$promise;
    });
};

HubListService.prototype.findById = function (id) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var options = self.getBaseFilter(hospital);
        options.id = id;
        options.filter.include = ['location'];
        return self.Hub.findById(options).$promise;
    });
};

HubListService.prototype.getPage = function (applyFilteringAndPagination) {
    var self = this;

    return this.rootScope.appReady.promise.then(function (hospital) {
        var filter = self.getBaseFilter(hospital);

        applyFilteringAndPagination(filter.filter);

        var toReturn;

        return self.FlatHub.getPage(filter).$promise.then(function (data) {
            toReturn = {
                items: _.map(data.items, function (flatHub) {
                    return new self.FlatHub(flatHub);
                }),
                totalCount: data.totalCount
            };
            var hubIds = _.map(data.items, function (hub) {
                return hub.id;
            });
            return self.FlatHubConnectivity.getStats({
                hospitalId: hospital.id,
                hubIds: hubIds
            }).$promise;
        })
            .then(function (connectivityData) {
                _.forEach(toReturn.items, function (hub) {
                    hub.missedStat = connectivityData.missedStats[hub.id];
                    var hubLostConnectivityThresholdMs = hospital.hubLostConnectivityThresholdMinutes * 60000;
                    var now = new Date();
                    var lastSeen = new Date(hub.lastSeen);
                    var latest = new Date(now - hubLostConnectivityThresholdMs);
                    if (lastSeen < latest) {
                        hub.possibleFaultCondition = true;
                    }
                });
                return self.q.resolve(toReturn);
            });
    });
};

HubListService.prototype.findAll = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var filter = self.getBaseFilter(hospital);
        filter.filter.include = ['location', 'cylinders'];
        return self.Hub.find(filter).$promise;
    });
};

HubListService.prototype.save = function (hub) {
    if (hub) {
        if (hub.location) {
            hub.locationId = hub.location.id;
            delete hub.location;
        }
        hub.deleted = 0;
        return hub.$save();
    }
    return false;
};

HubListService.prototype.del = function (hub) {
    if (hub) {
        hub.deleted = new Date().getTime();
        var realHub = this.add(hub);
        return realHub.$save();
    }
    return false;
};

HubListService.$inject = ["$rootScope", "$q", "Hub", "FlatHub", "FlatHubConnectivity", "hubKeepAliveIntervalMillis"];