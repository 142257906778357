import angularRoute from "angular-route";
import "ngstorage";

import {cookiePolicyConfig} from "./config.route";
import {CookiePolicyController} from "./cookiePolicy";

export const COOKIE_POLICY_MODULE = 'cookiePolicy';

angular.module(COOKIE_POLICY_MODULE, ['ngStorage', angularRoute]);

angular.module(COOKIE_POLICY_MODULE)
    .config(cookiePolicyConfig);

angular.module(COOKIE_POLICY_MODULE)
    .controller('CookiePolicyController', CookiePolicyController)
