// CommonJS package manager support
if (typeof module !== 'undefined' && typeof exports !== 'undefined' &&
  module.exports === exports) {
  // Export the *name* of this Angular module
  // Sample usage:
  //
  //   import lbServices from './lb-services';
  //   angular.module('app', [lbServices]);
  //
  module.exports = "lbServices";
}

(function(window, angular, undefined) {
  'use strict';

  var urlBase = "/api";
  var authHeader = 'authorization';

  function getHost(url) {
    var m = url.match(/^(?:https?:)?\/\/([^\/]+)/);
    return m ? m[1] : null;
  }

  var urlBaseHost = getHost(urlBase) || location.host;

/**
 * @ngdoc overview
 * @name lbServices
 * @module
 * @description
 *
 * The `lbServices` module provides services for interacting with
 * the models exposed by the LoopBack server via the REST API.
 *
 */
  var module = angular.module("lbServices", ['ngResource']);

/**
 * @ngdoc object
 * @name lbServices.Role
 * @header lbServices.Role
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Role` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Role",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/Roles/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Role#findById
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/Roles/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#find
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/Roles",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#findOne
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Role` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/Roles/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Role#count
             * @methodOf lbServices.Role
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/Roles/count",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.Role#modelName
        * @propertyOf lbServices.Role
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Role`.
        */
        R.modelName = "Role";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Appuser
 * @header lbServices.Appuser
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Appuser` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Appuser",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/appusers/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Appuser#upsert
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Appuser` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/appusers",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#findById
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Appuser` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/appusers/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#find
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Appuser` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/appusers",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#findOne
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Appuser` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/appusers/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#count
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/appusers/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#login
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Login a user with username/email and password.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `include` – `{string=}` - Related objects to include in the response. See the description of return value for more details.
             *   Default value: `user`.
             *
             *  - `rememberMe` - `boolean` - Whether the authentication credentials
             *     should be remembered in localStorage across app/browser restarts.
             *     Default: `true`.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The response body contains properties of the AccessToken created on login.
             * Depending on the value of `include` parameter, the body may contain additional properties:
             *   - `user` - `{User}` - Data of the currently logged in user. (`include=user`)
             *
             */
            "login": {
              params: {
                include: 'user',
              },
              interceptor: {
                response: function(response) {
                  var accessToken = response.data;
                  LoopBackAuth.setUser(
                    accessToken.id, accessToken.userId, accessToken.user);
                  LoopBackAuth.rememberMe =
                    response.config.params.rememberMe !== false;
                  LoopBackAuth.save();
                  return response.resource;
                },
              },
              url: urlBase + "/appusers/login",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#logout
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Logout a user with access token.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `access_token` – `{string}` - Do not supply this argument, it is automatically extracted from request headers.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "logout": {
              interceptor: {
                response: function(response) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return responseError.resource;
                },
              },
              url: urlBase + "/appusers/logout",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#prototype$addToRole
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Add a user to a role
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - User id
             *
             *  - `roleId` – `{number}` - Id of the role to add.
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$addToRole": {
              params: {
                'roleId': '@roleId',
              },
              url: urlBase + "/appusers/:id/roles/:roleId",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#prototype$removeFromRole
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Remove a user from a role
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - User id
             *
             *  - `roleId` – `{number}` - Id of the role to remove.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$removeFromRole": {
              params: {
                'roleId': '@roleId',
              },
              url: urlBase + "/appusers/:id/roles/:roleId",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#prototype$acceptTerms
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Accept Terms and Conditions
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - User id
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `acceptedTCsOn` – `{number=}` -
             */
            "prototype$acceptTerms": {
              url: urlBase + "/appusers/:id/acceptTerms",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#getSelf
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Gets a sub-set of the current user's details
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Appuser` object.)
             * </em>
             */
            "getSelf": {
              url: urlBase + "/appusers/self",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#createOAuthAccessToken
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Create an access token suitable for OAuth
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `clientId` – `{string}` - The Oauth Client ID
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Appuser` object.)
             * </em>
             */
            "createOAuthAccessToken": {
              url: urlBase + "/appusers/oauthaccesstokens",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#hasOAuthAccessTokenForClient
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Checks if an access token exists for the OAuth client for the current user
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `clientId` – `{string}` - The Oauth Client ID
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Appuser` object.)
             * </em>
             */
            "hasOAuthAccessTokenForClient": {
              url: urlBase + "/appusers/oauthaccesstokens/exists",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#forceResetPassword
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * User Force Resets Password
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Appuser` object.)
             * </em>
             */
            "forceResetPassword": {
              url: urlBase + "/appusers/forceResetPassword",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#updatePassword
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Update Password
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Appuser` object.)
             * </em>
             */
            "updatePassword": {
              url: urlBase + "/appusers/updatePassword",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#requestPasswordRecovery
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Request Password Recovery
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Appuser` object.)
             * </em>
             */
            "requestPasswordRecovery": {
              url: urlBase + "/appusers/requestPasswordRecovery",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#userRequestPending
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Request Password Recovery
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Appuser` object.)
             * </em>
             */
            "userRequestPending": {
              url: urlBase + "/appusers/userRequestPending",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#activateNewUser
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Verify and activate new user using the verification token
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Appuser` object.)
             * </em>
             */
            "activateNewUser": {
              url: urlBase + "/appusers/activateNewUser",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#recoverPasswordUsingEmail
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Recover password with emailed link to the user
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Appuser` object.)
             * </em>
             */
            "recoverPasswordUsingEmail": {
              url: urlBase + "/appusers/recoverPasswordUsingEmail",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Appuser#getCurrent
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Get data of the currently logged user. Fail with HTTP result 401
             * when there is no user logged in.
             *
             * @param {function(Object,Object)=} successCb
             *    Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *    `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             */
            'getCurrent': {
              url: urlBase + "/appusers" + '/:id',
              method: 'GET',
              params: {
                id: function() {
                  var id = LoopBackAuth.currentUserId;
                  if (id == null) id = '__anonymous__';
                  return id;
                },
              },
              interceptor: {
                response: function(response) {
                  LoopBackAuth.currentUserData = response.data;
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return $q.reject(responseError);
                },
              },
              __isGetCurrentUser__: true,
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Appuser#updateOrCreate
             * @methodOf lbServices.Appuser
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Appuser` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

        /**
         * @ngdoc method
         * @name lbServices.Appuser#getCachedCurrent
         * @methodOf lbServices.Appuser
         *
         * @description
         *
         * Get data of the currently logged user that was returned by the last
         * call to {@link lbServices.Appuser#login} or
         * {@link lbServices.Appuser#getCurrent}. Return null when there
         * is no user logged in or the data of the current user were not fetched
         * yet.
         *
         * @returns {Object} A Appuser instance.
         */
        R.getCachedCurrent = function() {
          var data = LoopBackAuth.currentUserData;
          return data ? new R(data) : null;
        };

        /**
         * @ngdoc method
         * @name lbServices.Appuser#isAuthenticated
         * @methodOf lbServices.Appuser
         *
         * @returns {boolean} True if the current user is authenticated (logged in).
         */
        R.isAuthenticated = function() {
          return this.getCurrentId() != null;
        };

        /**
         * @ngdoc method
         * @name lbServices.Appuser#getCurrentId
         * @methodOf lbServices.Appuser
         *
         * @returns {Object} Id of the currently logged-in user or null.
         */
        R.getCurrentId = function() {
          return LoopBackAuth.currentUserId;
        };

        /**
        * @ngdoc property
        * @name lbServices.Appuser#modelName
        * @propertyOf lbServices.Appuser
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Appuser`.
        */
        R.modelName = "Appuser";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Gas
 * @header lbServices.Gas
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Gas` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Gas",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/gases/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Gas#upsert
             * @methodOf lbServices.Gas
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Gas` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/gases",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Gas#findById
             * @methodOf lbServices.Gas
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Gas` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/gases/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Gas#find
             * @methodOf lbServices.Gas
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Gas` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/gases",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Gas#findOne
             * @methodOf lbServices.Gas
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Gas` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/gases/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Gas#count
             * @methodOf lbServices.Gas
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/gases/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Gas#updateOrCreate
             * @methodOf lbServices.Gas
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Gas` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];


        /**
        * @ngdoc property
        * @name lbServices.Gas#modelName
        * @propertyOf lbServices.Gas
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Gas`.
        */
        R.modelName = "Gas";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Valve
 * @header lbServices.Valve
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Valve` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Valve",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/valves/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Valve#upsert
             * @methodOf lbServices.Valve
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Valve` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/valves",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Valve#findById
             * @methodOf lbServices.Valve
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Valve` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/valves/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Valve#find
             * @methodOf lbServices.Valve
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Valve` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/valves",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Valve#findOne
             * @methodOf lbServices.Valve
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Valve` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/valves/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Valve#count
             * @methodOf lbServices.Valve
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/valves/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Valve#updateOrCreate
             * @methodOf lbServices.Valve
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Valve` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];


        /**
        * @ngdoc property
        * @name lbServices.Valve#modelName
        * @propertyOf lbServices.Valve
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Valve`.
        */
        R.modelName = "Valve";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Size
 * @header lbServices.Size
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Size` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Size",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/sizes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Size#upsert
             * @methodOf lbServices.Size
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Size` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/sizes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Size#findById
             * @methodOf lbServices.Size
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Size` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/sizes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Size#find
             * @methodOf lbServices.Size
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Size` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/sizes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Size#findOne
             * @methodOf lbServices.Size
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Size` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/sizes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Size#count
             * @methodOf lbServices.Size
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/sizes/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Size#updateOrCreate
             * @methodOf lbServices.Size
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Size` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];


        /**
        * @ngdoc property
        * @name lbServices.Size#modelName
        * @propertyOf lbServices.Size
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Size`.
        */
        R.modelName = "Size";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.CylinderType
 * @header lbServices.CylinderType
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `CylinderType` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "CylinderType",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/cylinderTypes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.CylinderType#upsert
             * @methodOf lbServices.CylinderType
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CylinderType` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/cylinderTypes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.CylinderType#findById
             * @methodOf lbServices.CylinderType
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CylinderType` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/cylinderTypes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CylinderType#find
             * @methodOf lbServices.CylinderType
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CylinderType` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/cylinderTypes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CylinderType#findOne
             * @methodOf lbServices.CylinderType
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CylinderType` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/cylinderTypes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CylinderType#count
             * @methodOf lbServices.CylinderType
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/cylinderTypes/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.CylinderType#updateOrCreate
             * @methodOf lbServices.CylinderType
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CylinderType` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];


        /**
        * @ngdoc property
        * @name lbServices.CylinderType#modelName
        * @propertyOf lbServices.CylinderType
        * @description
        * The name of the model represented by this $resource,
        * i.e. `CylinderType`.
        */
        R.modelName = "CylinderType";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Hospital
 * @header lbServices.Hospital
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Hospital` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Hospital",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/hospitals/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Hospital#upsert
             * @methodOf lbServices.Hospital
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Hospital` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/hospitals",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Hospital#findById
             * @methodOf lbServices.Hospital
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Hospital` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/hospitals/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Hospital#find
             * @methodOf lbServices.Hospital
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Hospital` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/hospitals",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Hospital#findOne
             * @methodOf lbServices.Hospital
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Hospital` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/hospitals/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Hospital#count
             * @methodOf lbServices.Hospital
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/hospitals/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Hospital#getAllTimeZones
             * @methodOf lbServices.Hospital
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Hospital` object.)
             * </em>
             */
            "getAllTimeZones": {
              isArray: true,
              url: urlBase + "/hospitals/getAllTimeZones",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Hospital#getPlatform
             * @methodOf lbServices.Hospital
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Hospital` object.)
             * </em>
             */
            "getPlatform": {
              url: urlBase + "/hospitals/getPlatform",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Hospital#updateOrCreate
             * @methodOf lbServices.Hospital
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Hospital` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];


        /**
        * @ngdoc property
        * @name lbServices.Hospital#modelName
        * @propertyOf lbServices.Hospital
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Hospital`.
        */
        R.modelName = "Hospital";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ServiceLevel
 * @header lbServices.ServiceLevel
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ServiceLevel` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ServiceLevel",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/serviceLevels/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.ServiceLevel#upsert
             * @methodOf lbServices.ServiceLevel
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ServiceLevel` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/serviceLevels",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ServiceLevel#findById
             * @methodOf lbServices.ServiceLevel
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ServiceLevel` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/serviceLevels/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ServiceLevel#find
             * @methodOf lbServices.ServiceLevel
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ServiceLevel` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/serviceLevels",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ServiceLevel#findOne
             * @methodOf lbServices.ServiceLevel
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ServiceLevel` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/serviceLevels/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ServiceLevel#count
             * @methodOf lbServices.ServiceLevel
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/serviceLevels/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ServiceLevel#getFeatureDefinitions
             * @methodOf lbServices.ServiceLevel
             *
             * @description
             *
             * Get the property names and labels for feature selects
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `featureDefinitions` – `{*=}` -
             */
            "getFeatureDefinitions": {
              url: urlBase + "/serviceLevels/getFeatureDefinitions",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ServiceLevel#updateOrCreate
             * @methodOf lbServices.ServiceLevel
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ServiceLevel` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];


        /**
        * @ngdoc property
        * @name lbServices.ServiceLevel#modelName
        * @propertyOf lbServices.ServiceLevel
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ServiceLevel`.
        */
        R.modelName = "ServiceLevel";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Stock
 * @header lbServices.Stock
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Stock` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Stock",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/stocks/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Stock#upsert
             * @methodOf lbServices.Stock
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Stock` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/stocks",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Stock#findById
             * @methodOf lbServices.Stock
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Stock` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/stocks/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Stock#find
             * @methodOf lbServices.Stock
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Stock` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/stocks",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Stock#findOne
             * @methodOf lbServices.Stock
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Stock` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/stocks/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Stock#count
             * @methodOf lbServices.Stock
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/stocks/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Stock#updateOrCreate
             * @methodOf lbServices.Stock
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Stock` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];


        /**
        * @ngdoc property
        * @name lbServices.Stock#modelName
        * @propertyOf lbServices.Stock
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Stock`.
        */
        R.modelName = "Stock";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatAuditEvent
 * @header lbServices.FlatAuditEvent
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatAuditEvent` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatAuditEvent",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatAuditEvents/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatAuditEvent#getPage
             * @methodOf lbServices.FlatAuditEvent
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `t` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `items` – `{object=}` -
             *
             *  - `totalCount` – `{number=}` -
             */
            "getPage": {
              url: urlBase + "/flatAuditEvents/getPage",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatAuditEvent#modelName
        * @propertyOf lbServices.FlatAuditEvent
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatAuditEvent`.
        */
        R.modelName = "FlatAuditEvent";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatCylinderActionRequest
 * @header lbServices.FlatCylinderActionRequest
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatCylinderActionRequest` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatCylinderActionRequest",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatCylinderActionRequests/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinderActionRequest#find
             * @methodOf lbServices.FlatCylinderActionRequest
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatCylinderActionRequest` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/flatCylinderActionRequests",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinderActionRequest#getPage
             * @methodOf lbServices.FlatCylinderActionRequest
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `t` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `items` – `{object=}` -
             *
             *  - `totalCount` – `{number=}` -
             */
            "getPage": {
              url: urlBase + "/flatCylinderActionRequests/getPage",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatCylinderActionRequest#modelName
        * @propertyOf lbServices.FlatCylinderActionRequest
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatCylinderActionRequest`.
        */
        R.modelName = "FlatCylinderActionRequest";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.HospitalFeatures
 * @header lbServices.HospitalFeatures
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `HospitalFeatures` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "HospitalFeatures",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/hospitalFeatures/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.HospitalFeatures#upsert
             * @methodOf lbServices.HospitalFeatures
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HospitalFeatures` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/hospitalFeatures",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.HospitalFeatures#findById
             * @methodOf lbServices.HospitalFeatures
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HospitalFeatures` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/hospitalFeatures/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HospitalFeatures#find
             * @methodOf lbServices.HospitalFeatures
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HospitalFeatures` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/hospitalFeatures",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HospitalFeatures#findOne
             * @methodOf lbServices.HospitalFeatures
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HospitalFeatures` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/hospitalFeatures/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HospitalFeatures#count
             * @methodOf lbServices.HospitalFeatures
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/hospitalFeatures/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.HospitalFeatures#updateOrCreate
             * @methodOf lbServices.HospitalFeatures
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HospitalFeatures` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];


        /**
        * @ngdoc property
        * @name lbServices.HospitalFeatures#modelName
        * @propertyOf lbServices.HospitalFeatures
        * @description
        * The name of the model represented by this $resource,
        * i.e. `HospitalFeatures`.
        */
        R.modelName = "HospitalFeatures";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Location
 * @header lbServices.Location
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Location` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Location",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/locations/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Location#upsert
             * @methodOf lbServices.Location
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Location` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/locations",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Location#findById
             * @methodOf lbServices.Location
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Location` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/locations/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Location#find
             * @methodOf lbServices.Location
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Location` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/locations",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Location#findOne
             * @methodOf lbServices.Location
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Location` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/locations/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Location#count
             * @methodOf lbServices.Location
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/locations/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Location#byHospital
             * @methodOf lbServices.Location
             *
             * @description
             *
             * Returns locations for the specified hospital
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number}` - Id of the hospital whose locations to return.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Location` object.)
             * </em>
             */
            "byHospital": {
              isArray: true,
              url: urlBase + "/locations/byHospital/:hospitalId",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Location#getPage
             * @methodOf lbServices.Location
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `t` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `items` – `{object=}` -
             *
             *  - `totalCount` – `{number=}` -
             */
            "getPage": {
              url: urlBase + "/locations/getPage",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Location#updateOrCreate
             * @methodOf lbServices.Location
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Location` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];


        /**
        * @ngdoc property
        * @name lbServices.Location#modelName
        * @propertyOf lbServices.Location
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Location`.
        */
        R.modelName = "Location";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.LocationSummary
 * @header lbServices.LocationSummary
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `LocationSummary` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "LocationSummary",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/locationSummaries/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.LocationSummary#findById
             * @methodOf lbServices.LocationSummary
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationSummary` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/locationSummaries/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationSummary#find
             * @methodOf lbServices.LocationSummary
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationSummary` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/locationSummaries",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationSummary#findOne
             * @methodOf lbServices.LocationSummary
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationSummary` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/locationSummaries/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationSummary#getPage
             * @methodOf lbServices.LocationSummary
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `t` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `items` – `{object=}` -
             *
             *  - `totalCount` – `{number=}` -
             */
            "getPage": {
              url: urlBase + "/locationSummaries/getPage",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationSummary#regenHospitalActionRequest
             * @methodOf lbServices.LocationSummary
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             *  - `ctx` – `{context=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationSummary` object.)
             * </em>
             */
            "regenHospitalActionRequest": {
              url: urlBase + "/locationSummaries/regenHospitalActionRequest",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.LocationSummary#modelName
        * @propertyOf lbServices.LocationSummary
        * @description
        * The name of the model represented by this $resource,
        * i.e. `LocationSummary`.
        */
        R.modelName = "LocationSummary";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatLocationSummary
 * @header lbServices.FlatLocationSummary
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatLocationSummary` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatLocationSummary",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatLocationSummaries/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatLocationSummary#findById
             * @methodOf lbServices.FlatLocationSummary
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatLocationSummary` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/flatLocationSummaries/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatLocationSummary#find
             * @methodOf lbServices.FlatLocationSummary
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatLocationSummary` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/flatLocationSummaries",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatLocationSummary#findOne
             * @methodOf lbServices.FlatLocationSummary
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatLocationSummary` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/flatLocationSummaries/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatLocationSummary#getPage
             * @methodOf lbServices.FlatLocationSummary
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `t` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `items` – `{object=}` -
             *
             *  - `totalCount` – `{number=}` -
             */
            "getPage": {
              url: urlBase + "/flatLocationSummaries/getPage",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatLocationSummary#modelName
        * @propertyOf lbServices.FlatLocationSummary
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatLocationSummary`.
        */
        R.modelName = "FlatLocationSummary";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.CylinderTypeSummary
 * @header lbServices.CylinderTypeSummary
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `CylinderTypeSummary` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "CylinderTypeSummary",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/cylinderTypeSummaries/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.CylinderTypeSummary#findById
             * @methodOf lbServices.CylinderTypeSummary
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CylinderTypeSummary` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/cylinderTypeSummaries/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CylinderTypeSummary#find
             * @methodOf lbServices.CylinderTypeSummary
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CylinderTypeSummary` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/cylinderTypeSummaries",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CylinderTypeSummary#findOne
             * @methodOf lbServices.CylinderTypeSummary
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CylinderTypeSummary` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/cylinderTypeSummaries/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CylinderTypeSummary#getPage
             * @methodOf lbServices.CylinderTypeSummary
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `t` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `items` – `{object=}` -
             *
             *  - `totalCount` – `{number=}` -
             */
            "getPage": {
              url: urlBase + "/cylinderTypeSummaries/getPage",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.CylinderTypeSummary#modelName
        * @propertyOf lbServices.CylinderTypeSummary
        * @description
        * The name of the model represented by this $resource,
        * i.e. `CylinderTypeSummary`.
        */
        R.modelName = "CylinderTypeSummary";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.LocationType
 * @header lbServices.LocationType
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `LocationType` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "LocationType",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/locationTypes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.LocationType#findById
             * @methodOf lbServices.LocationType
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationType` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/locationTypes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationType#find
             * @methodOf lbServices.LocationType
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationType` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/locationTypes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationType#findOne
             * @methodOf lbServices.LocationType
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationType` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/locationTypes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationType#count
             * @methodOf lbServices.LocationType
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/locationTypes/count",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.LocationType#modelName
        * @propertyOf lbServices.LocationType
        * @description
        * The name of the model represented by this $resource,
        * i.e. `LocationType`.
        */
        R.modelName = "LocationType";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.HubConnection
 * @header lbServices.HubConnection
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `HubConnection` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "HubConnection",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/hubConnections/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.HubConnection#missedPacketsLast24Hrs
             * @methodOf lbServices.HubConnection
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `hubId` – `{string}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `missedPackets` – `{number=}` -
             */
            "missedPacketsLast24Hrs": {
              url: urlBase + "/hubConnections/missedPacketsLast24Hrs",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.HubConnection#modelName
        * @propertyOf lbServices.HubConnection
        * @description
        * The name of the model represented by this $resource,
        * i.e. `HubConnection`.
        */
        R.modelName = "HubConnection";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Hub
 * @header lbServices.Hub
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Hub` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Hub",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/hubs/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Hub#upsert
             * @methodOf lbServices.Hub
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Hub` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/hubs",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Hub#findById
             * @methodOf lbServices.Hub
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Hub` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/hubs/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Hub#find
             * @methodOf lbServices.Hub
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Hub` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/hubs",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Hub#findOne
             * @methodOf lbServices.Hub
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Hub` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/hubs/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Hub#count
             * @methodOf lbServices.Hub
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/hubs/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Hub#prototype$updateAttributes
             * @methodOf lbServices.Hub
             *
             * @description
             *
             * Update attributes for a model instance and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Hub` object.)
             * </em>
             */
            "prototype$updateAttributes": {
              url: urlBase + "/hubs/:id",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Hub#getToken
             * @methodOf lbServices.Hub
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Hub` object.)
             * </em>
             */
            "getToken": {
              url: urlBase + "/hubs/getToken",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Hub#updateOrCreate
             * @methodOf lbServices.Hub
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Hub` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];


        /**
        * @ngdoc property
        * @name lbServices.Hub#modelName
        * @propertyOf lbServices.Hub
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Hub`.
        */
        R.modelName = "Hub";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Cylinder
 * @header lbServices.Cylinder
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Cylinder` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Cylinder",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/cylinders/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Cylinder#upsert
             * @methodOf lbServices.Cylinder
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cylinder` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/cylinders",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cylinder#findById
             * @methodOf lbServices.Cylinder
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cylinder` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/cylinders/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cylinder#find
             * @methodOf lbServices.Cylinder
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cylinder` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/cylinders",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cylinder#findOne
             * @methodOf lbServices.Cylinder
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cylinder` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/cylinders/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cylinder#count
             * @methodOf lbServices.Cylinder
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/cylinders/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Cylinder#forceInsightsUpdate
             * @methodOf lbServices.Cylinder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `updatedCylinderCount` – `{number=}` -
             */
            "forceInsightsUpdate": {
              url: urlBase + "/cylinders/forceInsightsUpdate",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Cylinder#updateOrCreate
             * @methodOf lbServices.Cylinder
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Cylinder` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];


        /**
        * @ngdoc property
        * @name lbServices.Cylinder#modelName
        * @propertyOf lbServices.Cylinder
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Cylinder`.
        */
        R.modelName = "Cylinder";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatAppuser
 * @header lbServices.FlatAppuser
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatAppuser` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatAppuser",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatAppusers/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatAppuser#getPage
             * @methodOf lbServices.FlatAppuser
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `t` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `items` – `{object=}` -
             *
             *  - `totalCount` – `{number=}` -
             */
            "getPage": {
              url: urlBase + "/flatAppusers/getPage",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatAppuser#getCurrent
             * @methodOf lbServices.FlatAppuser
             *
             * @description
             *
             * Get data of the currently logged user. Fail with HTTP result 401
             * when there is no user logged in.
             *
             * @param {function(Object,Object)=} successCb
             *    Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *    `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             */
            'getCurrent': {
              url: urlBase + "/flatAppusers" + '/:id',
              method: 'GET',
              params: {
                id: function() {
                  var id = LoopBackAuth.currentUserId;
                  if (id == null) id = '__anonymous__';
                  return id;
                },
              },
              interceptor: {
                response: function(response) {
                  LoopBackAuth.currentUserData = response.data;
                  return response.resource;
                },
                responseError: function(responseError) {
                  LoopBackAuth.clearUser();
                  LoopBackAuth.clearStorage();
                  return $q.reject(responseError);
                },
              },
              __isGetCurrentUser__: true,
            },
          }
        );



        /**
         * @ngdoc method
         * @name lbServices.FlatAppuser#getCachedCurrent
         * @methodOf lbServices.FlatAppuser
         *
         * @description
         *
         * Get data of the currently logged user that was returned by the last
         * call to {@link lbServices.FlatAppuser#login} or
         * {@link lbServices.FlatAppuser#getCurrent}. Return null when there
         * is no user logged in or the data of the current user were not fetched
         * yet.
         *
         * @returns {Object} A FlatAppuser instance.
         */
        R.getCachedCurrent = function() {
          var data = LoopBackAuth.currentUserData;
          return data ? new R(data) : null;
        };

        /**
         * @ngdoc method
         * @name lbServices.FlatAppuser#isAuthenticated
         * @methodOf lbServices.FlatAppuser
         *
         * @returns {boolean} True if the current user is authenticated (logged in).
         */
        R.isAuthenticated = function() {
          return this.getCurrentId() != null;
        };

        /**
         * @ngdoc method
         * @name lbServices.FlatAppuser#getCurrentId
         * @methodOf lbServices.FlatAppuser
         *
         * @returns {Object} Id of the currently logged-in user or null.
         */
        R.getCurrentId = function() {
          return LoopBackAuth.currentUserId;
        };

        /**
        * @ngdoc property
        * @name lbServices.FlatAppuser#modelName
        * @propertyOf lbServices.FlatAppuser
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatAppuser`.
        */
        R.modelName = "FlatAppuser";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatCylinder
 * @header lbServices.FlatCylinder
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatCylinder` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatCylinder",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatCylinders/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinder#count
             * @methodOf lbServices.FlatCylinder
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/flatCylinders/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinder#getPage
             * @methodOf lbServices.FlatCylinder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `t` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `items` – `{object=}` -
             *
             *  - `totalCount` – `{number=}` -
             */
            "getPage": {
              url: urlBase + "/flatCylinders/getPage",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinder#dwellTimeSummary
             * @methodOf lbServices.FlatCylinder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `query` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatCylinder` object.)
             * </em>
             */
            "dwellTimeSummary": {
              isArray: true,
              url: urlBase + "/flatCylinders/dwelltimesummary",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinder#averageEmptyPressureAtLocation
             * @methodOf lbServices.FlatCylinder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `query` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatCylinder` object.)
             * </em>
             */
            "averageEmptyPressureAtLocation": {
              isArray: true,
              url: urlBase + "/flatCylinders/averageemptypressureatlocation",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinder#averageReturnPressure
             * @methodOf lbServices.FlatCylinder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `query` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatCylinder` object.)
             * </em>
             */
            "averageReturnPressure": {
              isArray: true,
              url: urlBase + "/flatCylinders/averagereturnpressure",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinder#emptyToReturnFromLocation
             * @methodOf lbServices.FlatCylinder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `query` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatCylinder` object.)
             * </em>
             */
            "emptyToReturnFromLocation": {
              isArray: true,
              url: urlBase + "/flatCylinders/emptytoreturnfromlocation",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinder#emptyToReturnInHospital
             * @methodOf lbServices.FlatCylinder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `query` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatCylinder` object.)
             * </em>
             */
            "emptyToReturnInHospital": {
              isArray: true,
              url: urlBase + "/flatCylinders/emptytoreturninhospital",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinder#hospitalOxygenUsage
             * @methodOf lbServices.FlatCylinder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `query` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatCylinder` object.)
             * </em>
             */
            "hospitalOxygenUsage": {
              isArray: true,
              url: urlBase + "/flatCylinders/hospitaloxygenusage",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinder#hospitalCylinderFirstUsed
             * @methodOf lbServices.FlatCylinder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `query` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatCylinder` object.)
             * </em>
             */
            "hospitalCylinderFirstUsed": {
              isArray: true,
              url: urlBase + "/flatCylinders/hospitalcylinderfirstused",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinder#locationCylinderFirstUsed
             * @methodOf lbServices.FlatCylinder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `query` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatCylinder` object.)
             * </em>
             */
            "locationCylinderFirstUsed": {
              isArray: true,
              url: urlBase + "/flatCylinders/locationcylinderfirstused",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinder#hospitalCylinderAlarm
             * @methodOf lbServices.FlatCylinder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `query` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatCylinder` object.)
             * </em>
             */
            "hospitalCylinderAlarm": {
              isArray: true,
              url: urlBase + "/flatCylinders/hospitalcylinderalarm",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinder#locationCylinderAlarm
             * @methodOf lbServices.FlatCylinder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `query` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatCylinder` object.)
             * </em>
             */
            "locationCylinderAlarm": {
              isArray: true,
              url: urlBase + "/flatCylinders/locationcylinderalarm",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinder#hospitalCylinderTreatment
             * @methodOf lbServices.FlatCylinder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `query` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatCylinder` object.)
             * </em>
             */
            "hospitalCylinderTreatment": {
              isArray: true,
              url: urlBase + "/flatCylinders/hospitalcylindertreatment",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinder#locationCylinderTreatment
             * @methodOf lbServices.FlatCylinder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `query` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatCylinder` object.)
             * </em>
             */
            "locationCylinderTreatment": {
              isArray: true,
              url: urlBase + "/flatCylinders/locationcylindertreatment",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatCylinder#modelName
        * @propertyOf lbServices.FlatCylinder
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatCylinder`.
        */
        R.modelName = "FlatCylinder";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatCylinderOrder
 * @header lbServices.FlatCylinderOrder
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatCylinderOrder` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatCylinderOrder",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatCylinderOrders/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinderOrder#find
             * @methodOf lbServices.FlatCylinderOrder
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatCylinderOrder` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/flatCylinderOrders",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinderOrder#getPage
             * @methodOf lbServices.FlatCylinderOrder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `t` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `items` – `{object=}` -
             *
             *  - `totalCount` – `{number=}` -
             */
            "getPage": {
              url: urlBase + "/flatCylinderOrders/getPage",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinderOrder#emptyCylinderReplacementTimeAtLocation
             * @methodOf lbServices.FlatCylinderOrder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `query` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatCylinderOrder` object.)
             * </em>
             */
            "emptyCylinderReplacementTimeAtLocation": {
              isArray: true,
              url: urlBase + "/flatCylinderOrders/emptycylinderreplacetimeatlocation",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinderOrder#emptyCylinderReplacementTimeInHospital
             * @methodOf lbServices.FlatCylinderOrder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `query` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatCylinderOrder` object.)
             * </em>
             */
            "emptyCylinderReplacementTimeInHospital": {
              isArray: true,
              url: urlBase + "/flatCylinderOrders/emptycylinderreplacetimeinhospital",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatCylinderOrder#modelName
        * @propertyOf lbServices.FlatCylinderOrder
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatCylinderOrder`.
        */
        R.modelName = "FlatCylinderOrder";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatCylinderUsageRecord
 * @header lbServices.FlatCylinderUsageRecord
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatCylinderUsageRecord` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatCylinderUsageRecord",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatCylinderUsageRecords/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinderUsageRecord#getPage
             * @methodOf lbServices.FlatCylinderUsageRecord
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `t` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `items` – `{object=}` -
             *
             *  - `totalCount` – `{number=}` -
             */
            "getPage": {
              url: urlBase + "/flatCylinderUsageRecords/getPage",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatCylinderUsageRecord#modelName
        * @propertyOf lbServices.FlatCylinderUsageRecord
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatCylinderUsageRecord`.
        */
        R.modelName = "FlatCylinderUsageRecord";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatHub
 * @header lbServices.FlatHub
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatHub` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatHub",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatHubs/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatHub#getPage
             * @methodOf lbServices.FlatHub
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `t` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `items` – `{object=}` -
             *
             *  - `totalCount` – `{number=}` -
             */
            "getPage": {
              url: urlBase + "/flatHubs/getPage",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatHub#modelName
        * @propertyOf lbServices.FlatHub
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatHub`.
        */
        R.modelName = "FlatHub";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatHubConnectivity
 * @header lbServices.FlatHubConnectivity
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatHubConnectivity` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatHubConnectivity",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatHubConnectivity/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatHubConnectivity#getStats
             * @methodOf lbServices.FlatHubConnectivity
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `missedStats` – `{*=}` -
             */
            "getStats": {
              url: urlBase + "/flatHubConnectivity/getStats",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatHubConnectivity#modelName
        * @propertyOf lbServices.FlatHubConnectivity
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatHubConnectivity`.
        */
        R.modelName = "FlatHubConnectivity";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Order
 * @header lbServices.Order
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Order` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Order",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/orders/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Order#upsert
             * @methodOf lbServices.Order
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Order` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/orders",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Order#findById
             * @methodOf lbServices.Order
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Order` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/orders/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Order#find
             * @methodOf lbServices.Order
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Order` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/orders",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Order#findOne
             * @methodOf lbServices.Order
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Order` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/orders/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Order#updateAll
             * @methodOf lbServices.Order
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
            "updateAll": {
              url: urlBase + "/orders/update",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Order#count
             * @methodOf lbServices.Order
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/orders/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Order#usage
             * @methodOf lbServices.Order
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `date` – `{date=}` -
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Order` object.)
             * </em>
             */
            "usage": {
              url: urlBase + "/orders/usage",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Order#updateOrCreate
             * @methodOf lbServices.Order
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Order` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];

            /**
             * @ngdoc method
             * @name lbServices.Order#update
             * @methodOf lbServices.Order
             *
             * @description
             *
             * Update instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * The number of instances updated
             */
        R["update"] = R["updateAll"];


        /**
        * @ngdoc property
        * @name lbServices.Order#modelName
        * @propertyOf lbServices.Order
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Order`.
        */
        R.modelName = "Order";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ConfigParameters
 * @header lbServices.ConfigParameters
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ConfigParameters` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ConfigParameters",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/configParameters/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.ConfigParameters#upsert
             * @methodOf lbServices.ConfigParameters
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConfigParameters` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/configParameters",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConfigParameters#findById
             * @methodOf lbServices.ConfigParameters
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConfigParameters` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/configParameters/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConfigParameters#find
             * @methodOf lbServices.ConfigParameters
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConfigParameters` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/configParameters",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConfigParameters#findOne
             * @methodOf lbServices.ConfigParameters
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConfigParameters` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/configParameters/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ConfigParameters#count
             * @methodOf lbServices.ConfigParameters
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/configParameters/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ConfigParameters#updateOrCreate
             * @methodOf lbServices.ConfigParameters
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ConfigParameters` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];


        /**
        * @ngdoc property
        * @name lbServices.ConfigParameters#modelName
        * @propertyOf lbServices.ConfigParameters
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ConfigParameters`.
        */
        R.modelName = "ConfigParameters";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.AuditEvent
 * @header lbServices.AuditEvent
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `AuditEvent` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "AuditEvent",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/auditEvents/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.AuditEvent#create
             * @methodOf lbServices.AuditEvent
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AuditEvent` object.)
             * </em>
             */
            "create": {
              url: urlBase + "/auditEvents",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AuditEvent#createMany
             * @methodOf lbServices.AuditEvent
             *
             * @description
             *
             * Create a new instance of the model and persist it into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AuditEvent` object.)
             * </em>
             */
            "createMany": {
              isArray: true,
              url: urlBase + "/auditEvents",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.AuditEvent#findById
             * @methodOf lbServices.AuditEvent
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AuditEvent` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/auditEvents/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AuditEvent#find
             * @methodOf lbServices.AuditEvent
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AuditEvent` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/auditEvents",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AuditEvent#findOne
             * @methodOf lbServices.AuditEvent
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AuditEvent` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/auditEvents/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AuditEvent#count
             * @methodOf lbServices.AuditEvent
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/auditEvents/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.AuditEvent#usage
             * @methodOf lbServices.AuditEvent
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `options` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `AuditEvent` object.)
             * </em>
             */
            "usage": {
              url: urlBase + "/auditEvents/usage",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.AuditEvent#modelName
        * @propertyOf lbServices.AuditEvent
        * @description
        * The name of the model represented by this $resource,
        * i.e. `AuditEvent`.
        */
        R.modelName = "AuditEvent";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.CylinderCollectionOrder
 * @header lbServices.CylinderCollectionOrder
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `CylinderCollectionOrder` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "CylinderCollectionOrder",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/cylinderCollectionOrder/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.CylinderCollectionOrder#upsert
             * @methodOf lbServices.CylinderCollectionOrder
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CylinderCollectionOrder` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/cylinderCollectionOrder",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.CylinderCollectionOrder#findById
             * @methodOf lbServices.CylinderCollectionOrder
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CylinderCollectionOrder` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/cylinderCollectionOrder/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CylinderCollectionOrder#find
             * @methodOf lbServices.CylinderCollectionOrder
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CylinderCollectionOrder` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/cylinderCollectionOrder",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CylinderCollectionOrder#findOne
             * @methodOf lbServices.CylinderCollectionOrder
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CylinderCollectionOrder` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/cylinderCollectionOrder/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CylinderCollectionOrder#count
             * @methodOf lbServices.CylinderCollectionOrder
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/cylinderCollectionOrder/count",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.CylinderCollectionOrder#updateOrCreate
             * @methodOf lbServices.CylinderCollectionOrder
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CylinderCollectionOrder` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];


        /**
        * @ngdoc property
        * @name lbServices.CylinderCollectionOrder#modelName
        * @propertyOf lbServices.CylinderCollectionOrder
        * @description
        * The name of the model represented by this $resource,
        * i.e. `CylinderCollectionOrder`.
        */
        R.modelName = "CylinderCollectionOrder";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.CylinderUsageRecord
 * @header lbServices.CylinderUsageRecord
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `CylinderUsageRecord` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "CylinderUsageRecord",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/cylinderUsageRecords/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.CylinderUsageRecord#findById
             * @methodOf lbServices.CylinderUsageRecord
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CylinderUsageRecord` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/cylinderUsageRecords/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CylinderUsageRecord#find
             * @methodOf lbServices.CylinderUsageRecord
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CylinderUsageRecord` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/cylinderUsageRecords",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CylinderUsageRecord#findOne
             * @methodOf lbServices.CylinderUsageRecord
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `CylinderUsageRecord` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/cylinderUsageRecords/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.CylinderUsageRecord#count
             * @methodOf lbServices.CylinderUsageRecord
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/cylinderUsageRecords/count",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.CylinderUsageRecord#modelName
        * @propertyOf lbServices.CylinderUsageRecord
        * @description
        * The name of the model represented by this $resource,
        * i.e. `CylinderUsageRecord`.
        */
        R.modelName = "CylinderUsageRecord";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.RegionStat
 * @header lbServices.RegionStat
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `RegionStat` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "RegionStat",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/regionStats/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.RegionStat#find
             * @methodOf lbServices.RegionStat
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `RegionStat` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/regionStats",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.RegionStat#modelName
        * @propertyOf lbServices.RegionStat
        * @description
        * The name of the model represented by this $resource,
        * i.e. `RegionStat`.
        */
        R.modelName = "RegionStat";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatAcknowledgedOrderSummary
 * @header lbServices.FlatAcknowledgedOrderSummary
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatAcknowledgedOrderSummary` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatAcknowledgedOrderSummary",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatAcknowledgedOrderSummary/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatAcknowledgedOrderSummary#find
             * @methodOf lbServices.FlatAcknowledgedOrderSummary
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatAcknowledgedOrderSummary` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/flatAcknowledgedOrderSummary",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatAcknowledgedOrderSummary#modelName
        * @propertyOf lbServices.FlatAcknowledgedOrderSummary
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatAcknowledgedOrderSummary`.
        */
        R.modelName = "FlatAcknowledgedOrderSummary";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatNewOrderSummary
 * @header lbServices.FlatNewOrderSummary
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatNewOrderSummary` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatNewOrderSummary",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatNewOrderSummary/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatNewOrderSummary#find
             * @methodOf lbServices.FlatNewOrderSummary
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatNewOrderSummary` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/flatNewOrderSummary",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatNewOrderSummary#modelName
        * @propertyOf lbServices.FlatNewOrderSummary
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatNewOrderSummary`.
        */
        R.modelName = "FlatNewOrderSummary";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatEmptyCylinderSummary
 * @header lbServices.FlatEmptyCylinderSummary
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatEmptyCylinderSummary` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatEmptyCylinderSummary",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatEmptyCylinderSummary/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatEmptyCylinderSummary#find
             * @methodOf lbServices.FlatEmptyCylinderSummary
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatEmptyCylinderSummary` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/flatEmptyCylinderSummary",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatEmptyCylinderSummary#modelName
        * @propertyOf lbServices.FlatEmptyCylinderSummary
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatEmptyCylinderSummary`.
        */
        R.modelName = "FlatEmptyCylinderSummary";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatFaultyCylinderSummary
 * @header lbServices.FlatFaultyCylinderSummary
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatFaultyCylinderSummary` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatFaultyCylinderSummary",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatFaultyCylinderSummary/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatFaultyCylinderSummary#find
             * @methodOf lbServices.FlatFaultyCylinderSummary
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatFaultyCylinderSummary` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/flatFaultyCylinderSummary",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatFaultyCylinderSummary#modelName
        * @propertyOf lbServices.FlatFaultyCylinderSummary
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatFaultyCylinderSummary`.
        */
        R.modelName = "FlatFaultyCylinderSummary";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.StockHistory
 * @header lbServices.StockHistory
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `StockHistory` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "StockHistory",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/stockHistory/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.StockHistory#locationSummary
             * @methodOf lbServices.StockHistory
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `query` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `StockHistory` object.)
             * </em>
             */
            "locationSummary": {
              isArray: true,
              url: urlBase + "/stockHistory/locationsummary",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.StockHistory#hospitalSummary
             * @methodOf lbServices.StockHistory
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `query` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `StockHistory` object.)
             * </em>
             */
            "hospitalSummary": {
              isArray: true,
              url: urlBase + "/stockHistory/hospitalsummary",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.StockHistory#modelName
        * @propertyOf lbServices.StockHistory
        * @description
        * The name of the model represented by this $resource,
        * i.e. `StockHistory`.
        */
        R.modelName = "StockHistory";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatCylinderType
 * @header lbServices.FlatCylinderType
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatCylinderType` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatCylinderType",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatCylinderTypes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinderType#find
             * @methodOf lbServices.FlatCylinderType
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatCylinderType` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/flatCylinderTypes",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatCylinderType#modelName
        * @propertyOf lbServices.FlatCylinderType
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatCylinderType`.
        */
        R.modelName = "FlatCylinderType";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Insights
 * @header lbServices.Insights
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Insights` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Insights",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/insights/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Insights#hospitalInfo
             * @methodOf lbServices.Insights
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `query` – `{object=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insights` object.)
             * </em>
             */
            "hospitalInfo": {
              isArray: true,
              url: urlBase + "/insights/hospitalInfo",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insights#cylinderInfo
             * @methodOf lbServices.Insights
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `query` – `{object=}` -
             *
             *  - `ctx` – `{context=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insights` object.)
             * </em>
             */
            "cylinderInfo": {
              url: urlBase + "/insights/cylinderInfo",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Insights#hubInfo
             * @methodOf lbServices.Insights
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `query` – `{object=}` -
             *
             *  - `ctx` – `{context=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Insights` object.)
             * </em>
             */
            "hubInfo": {
              url: urlBase + "/insights/hubInfo",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.Insights#modelName
        * @propertyOf lbServices.Insights
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Insights`.
        */
        R.modelName = "Insights";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatCylinderJourney
 * @header lbServices.FlatCylinderJourney
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatCylinderJourney` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatCylinderJourney",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatCylinderJourneys/:id",
          { 'id': '@id' },
          {
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatCylinderJourney#modelName
        * @propertyOf lbServices.FlatCylinderJourney
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatCylinderJourney`.
        */
        R.modelName = "FlatCylinderJourney";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatCylinderHistory
 * @header lbServices.FlatCylinderHistory
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatCylinderHistory` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatCylinderHistory",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatCylinderHistories/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatCylinderHistory#fillPlantReport
             * @methodOf lbServices.FlatCylinderHistory
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatCylinderHistory` object.)
             * </em>
             */
            "fillPlantReport": {
              url: urlBase + "/flatCylinderHistories/fillplantreport",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatCylinderHistory#modelName
        * @propertyOf lbServices.FlatCylinderHistory
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatCylinderHistory`.
        */
        R.modelName = "FlatCylinderHistory";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatStock
 * @header lbServices.FlatStock
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatStock` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatStock",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatStocks/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatStock#findById
             * @methodOf lbServices.FlatStock
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatStock` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/flatStocks/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatStock#find
             * @methodOf lbServices.FlatStock
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatStock` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/flatStocks",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatStock#findOne
             * @methodOf lbServices.FlatStock
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatStock` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/flatStocks/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatStock#count
             * @methodOf lbServices.FlatStock
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/flatStocks/count",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatStock#modelName
        * @propertyOf lbServices.FlatStock
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatStock`.
        */
        R.modelName = "FlatStock";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.DashboardReport
 * @header lbServices.DashboardReport
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `DashboardReport` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "DashboardReport",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/dashboardReports/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.DashboardReport#getDisplaySettings
             * @methodOf lbServices.DashboardReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{Object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DashboardReport` object.)
             * </em>
             */
            "getDisplaySettings": {
              url: urlBase + "/dashboardReports/getDisplaySettings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DashboardReport#setDisplaySettings
             * @methodOf lbServices.DashboardReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{Object=}` -
             *
             *  - `updates` – `{Object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DashboardReport` object.)
             * </em>
             */
            "setDisplaySettings": {
              url: urlBase + "/dashboardReports/setDisplaySettings",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DashboardReport#yesterday
             * @methodOf lbServices.DashboardReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DashboardReport` object.)
             * </em>
             */
            "yesterday": {
              url: urlBase + "/dashboardReports/yesterday",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DashboardReport#last7Days
             * @methodOf lbServices.DashboardReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DashboardReport` object.)
             * </em>
             */
            "last7Days": {
              url: urlBase + "/dashboardReports/last7Days",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DashboardReport#last30Days
             * @methodOf lbServices.DashboardReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DashboardReport` object.)
             * </em>
             */
            "last30Days": {
              url: urlBase + "/dashboardReports/last30Days",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DashboardReport#last3Months
             * @methodOf lbServices.DashboardReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DashboardReport` object.)
             * </em>
             */
            "last3Months": {
              url: urlBase + "/dashboardReports/last3Months",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DashboardReport#last12Months
             * @methodOf lbServices.DashboardReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DashboardReport` object.)
             * </em>
             */
            "last12Months": {
              url: urlBase + "/dashboardReports/last12Months",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.DashboardReport#last24Months
             * @methodOf lbServices.DashboardReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `DashboardReport` object.)
             * </em>
             */
            "last24Months": {
              url: urlBase + "/dashboardReports/last24Months",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.DashboardReport#modelName
        * @propertyOf lbServices.DashboardReport
        * @description
        * The name of the model represented by this $resource,
        * i.e. `DashboardReport`.
        */
        R.modelName = "DashboardReport";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.LocationReport
 * @header lbServices.LocationReport
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `LocationReport` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "LocationReport",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/locationReports/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.LocationReport#yesterday
             * @methodOf lbServices.LocationReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             *  - `locationId` – `{number=}` -
             *
             *  - `ctx` – `{context=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationReport` object.)
             * </em>
             */
            "yesterday": {
              url: urlBase + "/locationReports/yesterday",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationReport#last7Days
             * @methodOf lbServices.LocationReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             *  - `locationId` – `{number=}` -
             *
             *  - `ctx` – `{context=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationReport` object.)
             * </em>
             */
            "last7Days": {
              url: urlBase + "/locationReports/last7Days",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationReport#last30Days
             * @methodOf lbServices.LocationReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             *  - `locationId` – `{number=}` -
             *
             *  - `ctx` – `{context=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationReport` object.)
             * </em>
             */
            "last30Days": {
              url: urlBase + "/locationReports/last30Days",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationReport#last3Months
             * @methodOf lbServices.LocationReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             *  - `locationId` – `{number=}` -
             *
             *  - `ctx` – `{context=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationReport` object.)
             * </em>
             */
            "last3Months": {
              url: urlBase + "/locationReports/last3Months",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationReport#last12Months
             * @methodOf lbServices.LocationReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             *  - `locationId` – `{number=}` -
             *
             *  - `ctx` – `{context=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationReport` object.)
             * </em>
             */
            "last12Months": {
              url: urlBase + "/locationReports/last12Months",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.LocationReport#last24Months
             * @methodOf lbServices.LocationReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             *  - `locationId` – `{number=}` -
             *
             *  - `ctx` – `{context=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `LocationReport` object.)
             * </em>
             */
            "last24Months": {
              url: urlBase + "/locationReports/last24Months",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.LocationReport#modelName
        * @propertyOf lbServices.LocationReport
        * @description
        * The name of the model represented by this $resource,
        * i.e. `LocationReport`.
        */
        R.modelName = "LocationReport";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.HospitalReport
 * @header lbServices.HospitalReport
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `HospitalReport` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "HospitalReport",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/hospitalReports/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.HospitalReport#oxygenUsagePeriod
             * @methodOf lbServices.HospitalReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             *  - `ctx` – `{context=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HospitalReport` object.)
             * </em>
             */
            "oxygenUsagePeriod": {
              url: urlBase + "/hospitalReports/oxygenUsagePeriod",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HospitalReport#hospitalActionRequest
             * @methodOf lbServices.HospitalReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             *  - `ctx` – `{context=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HospitalReport` object.)
             * </em>
             */
            "hospitalActionRequest": {
              url: urlBase + "/hospitalReports/hospitalActionRequest",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HospitalReport#yesterday
             * @methodOf lbServices.HospitalReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             *  - `ctx` – `{context=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HospitalReport` object.)
             * </em>
             */
            "yesterday": {
              url: urlBase + "/hospitalReports/yesterday",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HospitalReport#last7Days
             * @methodOf lbServices.HospitalReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             *  - `ctx` – `{context=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HospitalReport` object.)
             * </em>
             */
            "last7Days": {
              url: urlBase + "/hospitalReports/last7Days",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HospitalReport#last30Days
             * @methodOf lbServices.HospitalReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             *  - `ctx` – `{context=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HospitalReport` object.)
             * </em>
             */
            "last30Days": {
              url: urlBase + "/hospitalReports/last30Days",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HospitalReport#last3Months
             * @methodOf lbServices.HospitalReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             *  - `ctx` – `{context=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HospitalReport` object.)
             * </em>
             */
            "last3Months": {
              url: urlBase + "/hospitalReports/last3Months",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HospitalReport#last12Months
             * @methodOf lbServices.HospitalReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             *  - `ctx` – `{context=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HospitalReport` object.)
             * </em>
             */
            "last12Months": {
              url: urlBase + "/hospitalReports/last12Months",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HospitalReport#last24Months
             * @methodOf lbServices.HospitalReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HospitalReport` object.)
             * </em>
             */
            "last24Months": {
              url: urlBase + "/hospitalReports/last24Months",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.HospitalReport#modelName
        * @propertyOf lbServices.HospitalReport
        * @description
        * The name of the model represented by this $resource,
        * i.e. `HospitalReport`.
        */
        R.modelName = "HospitalReport";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Logs
 * @header lbServices.Logs
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Logs` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Logs",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/logs/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Logs#archivedEventLogList
             * @methodOf lbServices.Logs
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Logs` object.)
             * </em>
             */
            "archivedEventLogList": {
              isArray: true,
              url: urlBase + "/logs/archivedEventLogList",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Logs#getArchivedEventLogFile
             * @methodOf lbServices.Logs
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filename` – `{string}` -
             *
             *  - `res` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `` – `{undefined=}` -
             */
            "getArchivedEventLogFile": {
              url: urlBase + "/logs/getArchivedEventLogFile/:filename",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.Logs#modelName
        * @propertyOf lbServices.Logs
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Logs`.
        */
        R.modelName = "Logs";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ButtonTaskType
 * @header lbServices.ButtonTaskType
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ButtonTaskType` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ButtonTaskType",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/buttonTaskTypes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.ButtonTaskType#find
             * @methodOf lbServices.ButtonTaskType
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonTaskType` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/buttonTaskTypes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonTaskType#byHospital
             * @methodOf lbServices.ButtonTaskType
             *
             * @description
             *
             * Returns button task types for the specified hospital
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number}` - Id of the hospital whose button task types to return.
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonTaskType` object.)
             * </em>
             */
            "byHospital": {
              isArray: true,
              url: urlBase + "/buttonTaskTypes/byHospital/:hospitalId",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.ButtonTaskType#modelName
        * @propertyOf lbServices.ButtonTaskType
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ButtonTaskType`.
        */
        R.modelName = "ButtonTaskType";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.HwButton
 * @header lbServices.HwButton
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `HwButton` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "HwButton",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/hwButtons/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.HwButton#upsert
             * @methodOf lbServices.HwButton
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HwButton` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/hwButtons",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.HwButton#findById
             * @methodOf lbServices.HwButton
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HwButton` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/hwButtons/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HwButton#find
             * @methodOf lbServices.HwButton
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HwButton` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/hwButtons",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HwButton#findOne
             * @methodOf lbServices.HwButton
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HwButton` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/hwButtons/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HwButton#count
             * @methodOf lbServices.HwButton
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/hwButtons/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.HwButton#getPage
             * @methodOf lbServices.HwButton
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `t` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `items` – `{object=}` -
             *
             *  - `totalCount` – `{number=}` -
             */
            "getPage": {
              url: urlBase + "/hwButtons/getPage",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.HwButton#updateOrCreate
             * @methodOf lbServices.HwButton
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `HwButton` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];


        /**
        * @ngdoc property
        * @name lbServices.HwButton#modelName
        * @propertyOf lbServices.HwButton
        * @description
        * The name of the model represented by this $resource,
        * i.e. `HwButton`.
        */
        R.modelName = "HwButton";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ButtonOrder
 * @header lbServices.ButtonOrder
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ButtonOrder` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ButtonOrder",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/buttonOrders/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.ButtonOrder#findById
             * @methodOf lbServices.ButtonOrder
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonOrder` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/buttonOrders/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonOrder#find
             * @methodOf lbServices.ButtonOrder
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonOrder` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/buttonOrders",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonOrder#findOne
             * @methodOf lbServices.ButtonOrder
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonOrder` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/buttonOrders/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonOrder#count
             * @methodOf lbServices.ButtonOrder
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/buttonOrders/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonOrder#prototype$acknowledge
             * @methodOf lbServices.ButtonOrder
             *
             * @description
             *
             * Acknowledge a new order
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonOrder` object.)
             * </em>
             */
            "prototype$acknowledge": {
              url: urlBase + "/buttonOrders/:id/acknowledge",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonOrder#prototype$fulfill
             * @methodOf lbServices.ButtonOrder
             *
             * @description
             *
             * Fulfill a new/acknowledged order
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonOrder` object.)
             * </em>
             */
            "prototype$fulfill": {
              url: urlBase + "/buttonOrders/:id/fulfill",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonOrder#prototype$cancel
             * @methodOf lbServices.ButtonOrder
             *
             * @description
             *
             * Cancel an order
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonOrder` object.)
             * </em>
             */
            "prototype$cancel": {
              url: urlBase + "/buttonOrders/:id/cancel",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonOrder#request
             * @methodOf lbServices.ButtonOrder
             *
             * @description
             *
             * Request an order
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "request": {
              url: urlBase + "/buttonOrders/request",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonOrder#getPage
             * @methodOf lbServices.ButtonOrder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `t` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `items` – `{object=}` -
             *
             *  - `totalCount` – `{number=}` -
             */
            "getPage": {
              url: urlBase + "/buttonOrders/getPage",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.ButtonOrder#modelName
        * @propertyOf lbServices.ButtonOrder
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ButtonOrder`.
        */
        R.modelName = "ButtonOrder";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatButtonOrder
 * @header lbServices.FlatButtonOrder
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatButtonOrder` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatButtonOrder",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatButtonOrders/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatButtonOrder#findById
             * @methodOf lbServices.FlatButtonOrder
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatButtonOrder` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/flatButtonOrders/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatButtonOrder#find
             * @methodOf lbServices.FlatButtonOrder
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatButtonOrder` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/flatButtonOrders",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatButtonOrder#findOne
             * @methodOf lbServices.FlatButtonOrder
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatButtonOrder` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/flatButtonOrders/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatButtonOrder#count
             * @methodOf lbServices.FlatButtonOrder
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/flatButtonOrders/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatButtonOrder#getPage
             * @methodOf lbServices.FlatButtonOrder
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `t` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `items` – `{object=}` -
             *
             *  - `totalCount` – `{number=}` -
             */
            "getPage": {
              url: urlBase + "/flatButtonOrders/getPage",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatButtonOrder#countByType
             * @methodOf lbServices.FlatButtonOrder
             *
             * @description
             *
             * Returns a count per task type of orders
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number}` - Only orders in this hospital will be considered when counting
             *
             *  - `location` – `{string}` - Only orders for this location will be considered when counting
             *
             *  - `status` – `{number}` - Only orders in this state will be considered when counting
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatButtonOrder` object.)
             * </em>
             */
            "countByType": {
              isArray: true,
              url: urlBase + "/flatButtonOrders/countByType",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatButtonOrder#modelName
        * @propertyOf lbServices.FlatButtonOrder
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatButtonOrder`.
        */
        R.modelName = "FlatButtonOrder";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatHwButton
 * @header lbServices.FlatHwButton
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatHwButton` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatHwButton",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatHwButtons/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatHwButton#findById
             * @methodOf lbServices.FlatHwButton
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatHwButton` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/flatHwButtons/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatHwButton#find
             * @methodOf lbServices.FlatHwButton
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatHwButton` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/flatHwButtons",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatHwButton#findOne
             * @methodOf lbServices.FlatHwButton
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatHwButton` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/flatHwButtons/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatHwButton#count
             * @methodOf lbServices.FlatHwButton
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/flatHwButtons/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatHwButton#getPage
             * @methodOf lbServices.FlatHwButton
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `t` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `items` – `{object=}` -
             *
             *  - `totalCount` – `{number=}` -
             */
            "getPage": {
              url: urlBase + "/flatHwButtons/getPage",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatHwButton#history
             * @methodOf lbServices.FlatHwButton
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `hospitalId` – `{number}` -
             *
             *  - `mac` – `{string}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatHwButton` object.)
             * </em>
             */
            "history": {
              isArray: true,
              url: urlBase + "/flatHwButtons/history",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatHwButton#modelName
        * @propertyOf lbServices.FlatHwButton
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatHwButton`.
        */
        R.modelName = "FlatHwButton";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ButtonOtherTaskType
 * @header lbServices.ButtonOtherTaskType
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ButtonOtherTaskType` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ButtonOtherTaskType",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/buttonOtherTaskTypes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.ButtonOtherTaskType#upsert
             * @methodOf lbServices.ButtonOtherTaskType
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonOtherTaskType` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/buttonOtherTaskTypes",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonOtherTaskType#findById
             * @methodOf lbServices.ButtonOtherTaskType
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonOtherTaskType` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/buttonOtherTaskTypes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonOtherTaskType#find
             * @methodOf lbServices.ButtonOtherTaskType
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonOtherTaskType` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/buttonOtherTaskTypes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonOtherTaskType#findOne
             * @methodOf lbServices.ButtonOtherTaskType
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonOtherTaskType` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/buttonOtherTaskTypes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonOtherTaskType#count
             * @methodOf lbServices.ButtonOtherTaskType
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/buttonOtherTaskTypes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonOtherTaskType#getPage
             * @methodOf lbServices.ButtonOtherTaskType
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `t` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `items` – `{object=}` -
             *
             *  - `totalCount` – `{number=}` -
             */
            "getPage": {
              url: urlBase + "/buttonOtherTaskTypes/getPage",
              method: "POST",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.ButtonOtherTaskType#updateOrCreate
             * @methodOf lbServices.ButtonOtherTaskType
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonOtherTaskType` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];


        /**
        * @ngdoc property
        * @name lbServices.ButtonOtherTaskType#modelName
        * @propertyOf lbServices.ButtonOtherTaskType
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ButtonOtherTaskType`.
        */
        R.modelName = "ButtonOtherTaskType";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatButtonOtherTaskType
 * @header lbServices.FlatButtonOtherTaskType
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatButtonOtherTaskType` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatButtonOtherTaskType",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatButtonOtherTaskTypes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatButtonOtherTaskType#findById
             * @methodOf lbServices.FlatButtonOtherTaskType
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatButtonOtherTaskType` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/flatButtonOtherTaskTypes/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatButtonOtherTaskType#find
             * @methodOf lbServices.FlatButtonOtherTaskType
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatButtonOtherTaskType` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/flatButtonOtherTaskTypes",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatButtonOtherTaskType#findOne
             * @methodOf lbServices.FlatButtonOtherTaskType
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatButtonOtherTaskType` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/flatButtonOtherTaskTypes/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatButtonOtherTaskType#count
             * @methodOf lbServices.FlatButtonOtherTaskType
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/flatButtonOtherTaskTypes/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatButtonOtherTaskType#getPage
             * @methodOf lbServices.FlatButtonOtherTaskType
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             *  - `ctx` – `{object=}` -
             *
             *  - `t` – `{object=}` -
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `items` – `{object=}` -
             *
             *  - `totalCount` – `{number=}` -
             */
            "getPage": {
              url: urlBase + "/flatButtonOtherTaskTypes/getPage",
              method: "POST",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatButtonOtherTaskType#modelName
        * @propertyOf lbServices.FlatButtonOtherTaskType
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatButtonOtherTaskType`.
        */
        R.modelName = "FlatButtonOtherTaskType";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.NotificationSubscription
 * @header lbServices.NotificationSubscription
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `NotificationSubscription` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "NotificationSubscription",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/notificationSubscriptions/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.NotificationSubscription#subscribe
             * @methodOf lbServices.NotificationSubscription
             *
             * @description
             *
             * Subscribes to notifications
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationSubscription` object.)
             * </em>
             */
            "subscribe": {
              url: urlBase + "/notificationSubscriptions/subscribe",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationSubscription#unsubscribe
             * @methodOf lbServices.NotificationSubscription
             *
             * @description
             *
             * Unsubscribes from notifications
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "unsubscribe": {
              url: urlBase + "/notificationSubscriptions/unsubscribe",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.NotificationSubscription#publickey
             * @methodOf lbServices.NotificationSubscription
             *
             * @description
             *
             * Gets the Vapid public key for subscriptions
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `NotificationSubscription` object.)
             * </em>
             */
            "publickey": {
              url: urlBase + "/notificationSubscriptions/publickey",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.NotificationSubscription#modelName
        * @propertyOf lbServices.NotificationSubscription
        * @description
        * The name of the model represented by this $resource,
        * i.e. `NotificationSubscription`.
        */
        R.modelName = "NotificationSubscription";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ButtonOrderType
 * @header lbServices.ButtonOrderType
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ButtonOrderType` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ButtonOrderType",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/buttonOrderTypes/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.ButtonOrderType#find
             * @methodOf lbServices.ButtonOrderType
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonOrderType` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/buttonOrderTypes",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.ButtonOrderType#modelName
        * @propertyOf lbServices.ButtonOrderType
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ButtonOrderType`.
        */
        R.modelName = "ButtonOrderType";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.SwButton
 * @header lbServices.SwButton
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `SwButton` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "SwButton",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/swButtons/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.SwButton#upsert
             * @methodOf lbServices.SwButton
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SwButton` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/swButtons",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.SwButton#findById
             * @methodOf lbServices.SwButton
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SwButton` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/swButtons/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SwButton#find
             * @methodOf lbServices.SwButton
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SwButton` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/swButtons",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SwButton#findOne
             * @methodOf lbServices.SwButton
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SwButton` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/swButtons/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SwButton#count
             * @methodOf lbServices.SwButton
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/swButtons/count",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.SwButton#prototype$removeButton
             * @methodOf lbServices.SwButton
             *
             * @description
             *
             * Delete a SW Button
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$removeButton": {
              url: urlBase + "/swButtons/:id/remove",
              method: "PUT",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.SwButton#updateOrCreate
             * @methodOf lbServices.SwButton
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `SwButton` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];


        /**
        * @ngdoc property
        * @name lbServices.SwButton#modelName
        * @propertyOf lbServices.SwButton
        * @description
        * The name of the model represented by this $resource,
        * i.e. `SwButton`.
        */
        R.modelName = "SwButton";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatSwButton
 * @header lbServices.FlatSwButton
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatSwButton` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatSwButton",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatSwButtons/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatSwButton#findById
             * @methodOf lbServices.FlatSwButton
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatSwButton` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/flatSwButtons/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatSwButton#find
             * @methodOf lbServices.FlatSwButton
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatSwButton` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/flatSwButtons",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatSwButton#findOne
             * @methodOf lbServices.FlatSwButton
             *
             * @description
             *
             * Find first instance of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatSwButton` object.)
             * </em>
             */
            "findOne": {
              url: urlBase + "/flatSwButtons/findOne",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatSwButton#count
             * @methodOf lbServices.FlatSwButton
             *
             * @description
             *
             * Count instances of the model matched by where from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `where` – `{object=}` - Criteria to match model instances
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * Data properties:
             *
             *  - `count` – `{number=}` -
             */
            "count": {
              url: urlBase + "/flatSwButtons/count",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatSwButton#modelName
        * @propertyOf lbServices.FlatSwButton
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatSwButton`.
        */
        R.modelName = "FlatSwButton";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.Notifyee
 * @header lbServices.Notifyee
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `Notifyee` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "Notifyee",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/notifyees/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.Notifyee#upsert
             * @methodOf lbServices.Notifyee
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifyee` object.)
             * </em>
             */
            "upsert": {
              url: urlBase + "/notifyees",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifyee#findById
             * @methodOf lbServices.Notifyee
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifyee` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/notifyees/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifyee#find
             * @methodOf lbServices.Notifyee
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifyee` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/notifyees",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifyee#prototype$removeNotifyee
             * @methodOf lbServices.Notifyee
             *
             * @description
             *
             * Delete a Notifyee
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$removeNotifyee": {
              url: urlBase + "/notifyees/:id/remove",
              method: "PUT",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifyee#prototype$addLocation
             * @methodOf lbServices.Notifyee
             *
             * @description
             *
             * Add a location to the notifyee
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `locationId` – `{number}` - The Id of the location to add
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifyee` object.)
             * </em>
             */
            "prototype$addLocation": {
              url: urlBase + "/notifyees/:id/addlocation",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifyee#prototype$addNotifyeeToLocation
             * @methodOf lbServices.Notifyee
             *
             * @description
             *
             * Add a LocationNotifyee entry that links a notifyee to a given location
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `locationId` – `{number}` - The Id of the location to add
             *
             * @param {Object} postData Request data.
             *
             * This method does not accept any data. Supply an empty object.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifyee` object.)
             * </em>
             */
            "prototype$addNotifyeeToLocation": {
              url: urlBase + "/notifyees/:id/addlocationnotifyee",
              method: "POST",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifyee#prototype$removeLocation
             * @methodOf lbServices.Notifyee
             *
             * @description
             *
             * Removes a location from the notifyee
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `locationNotifyeeId` – `{number=}` - The Id of the locationNotifyee to remove
             *
             *  - `locationId` – `{number=}` - The Id of the location to remove
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$removeLocation": {
              url: urlBase + "/notifyees/:id/removelocation",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifyee#prototype$removeNotifyeeFromAllLocations
             * @methodOf lbServices.Notifyee
             *
             * @description
             *
             * Removes all LocationNotifyee entries that link a notifyee to hospital locations
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - PersistedModel id
             *
             *  - `locationNotifyeeId` – `{number=}` - The Id of the locationNotifyee to remove
             *
             *  - `locationId` – `{number=}` - The Id of the location to remove
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * This method returns no data.
             */
            "prototype$removeNotifyeeFromAllLocations": {
              url: urlBase + "/notifyees/:id/removelocationnotifyees",
              method: "DELETE",
            },

            /**
             * @ngdoc method
             * @name lbServices.Notifyee#getWithLocations
             * @methodOf lbServices.Notifyee
             *
             * @description
             *
             * Gets the notifyees with all their locations
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - The filter to apply to the Notifyee
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifyee` object.)
             * </em>
             */
            "getWithLocations": {
              isArray: true,
              url: urlBase + "/notifyees/withlocations",
              method: "GET",
            },
          }
        );



            /**
             * @ngdoc method
             * @name lbServices.Notifyee#updateOrCreate
             * @methodOf lbServices.Notifyee
             *
             * @description
             *
             * Update an existing model instance or insert a new one into the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *   This method does not accept any parameters.
             *   Supply an empty object or omit this argument altogether.
             *
             * @param {Object} postData Request data.
             *
             * This method expects a subset of model properties as request parameters.
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `Notifyee` object.)
             * </em>
             */
        R["updateOrCreate"] = R["upsert"];


        /**
        * @ngdoc property
        * @name lbServices.Notifyee#modelName
        * @propertyOf lbServices.Notifyee
        * @description
        * The name of the model represented by this $resource,
        * i.e. `Notifyee`.
        */
        R.modelName = "Notifyee";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.FlatLocationNotifyee
 * @header lbServices.FlatLocationNotifyee
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `FlatLocationNotifyee` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "FlatLocationNotifyee",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/flatLocationNotifyees/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.FlatLocationNotifyee#findById
             * @methodOf lbServices.FlatLocationNotifyee
             *
             * @description
             *
             * Find a model instance by id from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `id` – `{*}` - Model id
             *
             *  - `filter` – `{object=}` - Filter defining fields and include
             *
             * @param {function(Object,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Object} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatLocationNotifyee` object.)
             * </em>
             */
            "findById": {
              url: urlBase + "/flatLocationNotifyees/:id",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.FlatLocationNotifyee#find
             * @methodOf lbServices.FlatLocationNotifyee
             *
             * @description
             *
             * Find all instances of the model matched by filter from the data source.
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `FlatLocationNotifyee` object.)
             * </em>
             */
            "find": {
              isArray: true,
              url: urlBase + "/flatLocationNotifyees",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.FlatLocationNotifyee#modelName
        * @propertyOf lbServices.FlatLocationNotifyee
        * @description
        * The name of the model represented by this $resource,
        * i.e. `FlatLocationNotifyee`.
        */
        R.modelName = "FlatLocationNotifyee";



        return R;
      }]);

/**
 * @ngdoc object
 * @name lbServices.ButtonsReport
 * @header lbServices.ButtonsReport
 * @object
 *
 * @description
 *
 * A $resource object for interacting with the `ButtonsReport` model.
 *
 * ## Example
 *
 * See
 * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
 * for an example of using this object.
 *
 */
  module.factory(
    "ButtonsReport",
    [
      'LoopBackResource', 'LoopBackAuth', '$injector', '$q',
      function(LoopBackResource, LoopBackAuth, $injector, $q) {
        var R = LoopBackResource(
        urlBase + "/buttonsReports/:id",
          { 'id': '@id' },
          {

            /**
             * @ngdoc method
             * @name lbServices.ButtonsReport#yesterday
             * @methodOf lbServices.ButtonsReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number}` -
             *
             *  - `type` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonsReport` object.)
             * </em>
             */
            "yesterday": {
              isArray: true,
              url: urlBase + "/buttonsReports/yesterday",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonsReport#last7Days
             * @methodOf lbServices.ButtonsReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number}` -
             *
             *  - `type` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonsReport` object.)
             * </em>
             */
            "last7Days": {
              isArray: true,
              url: urlBase + "/buttonsReports/last7Days",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonsReport#last30Days
             * @methodOf lbServices.ButtonsReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number}` -
             *
             *  - `type` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonsReport` object.)
             * </em>
             */
            "last30Days": {
              isArray: true,
              url: urlBase + "/buttonsReports/last30Days",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonsReport#last3Months
             * @methodOf lbServices.ButtonsReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number}` -
             *
             *  - `type` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonsReport` object.)
             * </em>
             */
            "last3Months": {
              isArray: true,
              url: urlBase + "/buttonsReports/last3Months",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonsReport#last12Months
             * @methodOf lbServices.ButtonsReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number}` -
             *
             *  - `type` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonsReport` object.)
             * </em>
             */
            "last12Months": {
              isArray: true,
              url: urlBase + "/buttonsReports/last12Months",
              method: "GET",
            },

            /**
             * @ngdoc method
             * @name lbServices.ButtonsReport#last24Months
             * @methodOf lbServices.ButtonsReport
             *
             * @description
             *
             * <em>
             * (The remote method definition does not provide any description.)
             * </em>
             *
             * @param {Object=} parameters Request parameters.
             *
             *  - `hospitalId` – `{number}` -
             *
             *  - `type` – `{string=}` -
             *
             * @param {function(Array.<Object>,Object)=} successCb
             *   Success callback with two arguments: `value`, `responseHeaders`.
             *
             * @param {function(Object)=} errorCb Error callback with one argument:
             *   `httpResponse`.
             *
             * @returns {Array.<Object>} An empty reference that will be
             *   populated with the actual data once the response is returned
             *   from the server.
             *
             * <em>
             * (The remote method definition does not provide any description.
             * This usually means the response is a `ButtonsReport` object.)
             * </em>
             */
            "last24Months": {
              isArray: true,
              url: urlBase + "/buttonsReports/last24Months",
              method: "GET",
            },
          }
        );




        /**
        * @ngdoc property
        * @name lbServices.ButtonsReport#modelName
        * @propertyOf lbServices.ButtonsReport
        * @description
        * The name of the model represented by this $resource,
        * i.e. `ButtonsReport`.
        */
        R.modelName = "ButtonsReport";



        return R;
      }]);


  module
  .factory('LoopBackAuth', function() {
    var props = ['accessTokenId', 'currentUserId', 'rememberMe'];
    var propsPrefix = '$LoopBack$';

    function LoopBackAuth() {
      var self = this;
      props.forEach(function(name) {
        self[name] = load(name);
      });
      this.currentUserData = null;
    }

    LoopBackAuth.prototype.save = function() {
      var self = this;
      var storage = this.rememberMe ? localStorage : sessionStorage;
      props.forEach(function(name) {
        save(storage, name, self[name]);
      });
    };

    LoopBackAuth.prototype.setUser = function(accessTokenId, userId, userData) {
      this.accessTokenId = accessTokenId;
      this.currentUserId = userId;
      this.currentUserData = userData;
    };

    LoopBackAuth.prototype.clearUser = function() {
      this.accessTokenId = null;
      this.currentUserId = null;
      this.currentUserData = null;
    };

    LoopBackAuth.prototype.clearStorage = function() {
      props.forEach(function(name) {
        save(sessionStorage, name, null);
        save(localStorage, name, null);
      });
    };

    return new LoopBackAuth();

    // Note: LocalStorage converts the value to string
    // We are using empty string as a marker for null/undefined values.
    function save(storage, name, value) {
      try {
        var key = propsPrefix + name;
        if (value == null) value = '';
        storage[key] = value;
      } catch (err) {
        console.log('Cannot access local/session storage:', err);
      }
    }

    function load(name) {
      var key = propsPrefix + name;
      return localStorage[key] || sessionStorage[key] || null;
    }
  })
  .config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('LoopBackAuthRequestInterceptor');
  }])
  .factory('LoopBackAuthRequestInterceptor', ['$q', 'LoopBackAuth',
    function($q, LoopBackAuth) {
      return {
        'request': function(config) {
          // filter out external requests
          var host = getHost(config.url);
          if (host && host !== urlBaseHost) {
            return config;
          }

          if (LoopBackAuth.accessTokenId) {
            config.headers[authHeader] = LoopBackAuth.accessTokenId;
          } else if (config.__isGetCurrentUser__) {
            // Return a stub 401 error for User.getCurrent() when
            // there is no user logged in
            var res = {
              body: { error: { status: 401 }},
              status: 401,
              config: config,
              headers: function() { return undefined; },
            };
            return $q.reject(res);
          }
          return config || $q.when(config);
        },
      };
    }])

  /**
   * @ngdoc object
   * @name lbServices.LoopBackResourceProvider
   * @header lbServices.LoopBackResourceProvider
   * @description
   * Use `LoopBackResourceProvider` to change the global configuration
   * settings used by all models. Note that the provider is available
   * to Configuration Blocks only, see
   * {@link https://docs.angularjs.org/guide/module#module-loading-dependencies Module Loading & Dependencies}
   * for more details.
   *
   * ## Example
   *
   * ```js
   * angular.module('app')
   *  .config(function(LoopBackResourceProvider) {
   *     LoopBackResourceProvider.setAuthHeader('X-Access-Token');
   *  });
   * ```
   */
  .provider('LoopBackResource', function LoopBackResourceProvider() {
    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#setAuthHeader
     * @methodOf lbServices.LoopBackResourceProvider
     * @param {string} header The header name to use, e.g. `X-Access-Token`
     * @description
     * Configure the REST transport to use a different header for sending
     * the authentication token. It is sent in the `Authorization` header
     * by default.
     */
    this.setAuthHeader = function(header) {
      authHeader = header;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#getAuthHeader
     * @methodOf lbServices.LoopBackResourceProvider
     * @description
     * Get the header name that is used for sending the authentication token.
     */
    this.getAuthHeader = function() {
      return authHeader;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#setUrlBase
     * @methodOf lbServices.LoopBackResourceProvider
     * @param {string} url The URL to use, e.g. `/api` or `//example.com/api`.
     * @description
     * Change the URL of the REST API server. By default, the URL provided
     * to the code generator (`lb-ng` or `grunt-loopback-sdk-angular`) is used.
     */
    this.setUrlBase = function(url) {
      urlBase = url;
      urlBaseHost = getHost(urlBase) || location.host;
    };

    /**
     * @ngdoc method
     * @name lbServices.LoopBackResourceProvider#getUrlBase
     * @methodOf lbServices.LoopBackResourceProvider
     * @description
     * Get the URL of the REST API server. The URL provided
     * to the code generator (`lb-ng` or `grunt-loopback-sdk-angular`) is used.
     */
    this.getUrlBase = function() {
      return urlBase;
    };

    this.$get = ['$resource', function($resource) {
      var LoopBackResource = function(url, params, actions) {
        var resource = $resource(url, params, actions);

        // Angular always calls POST on $save()
        // This hack is based on
        // http://kirkbushell.me/angular-js-using-ng-resource-in-a-more-restful-manner/
        resource.prototype.$save = function(success, error) {
          // Fortunately, LoopBack provides a convenient `upsert` method
          // that exactly fits our needs.
          var result = resource.upsert.call(this, {}, this, success, error);
          return result.$promise || result;
        };
        return resource;
      };

      LoopBackResource.getUrlBase = function() {
        return urlBase;
      };

      LoopBackResource.getAuthHeader = function() {
        return authHeader;
      };

      return LoopBackResource;
    }];
  });
})(window, window.angular);
