import {CONFIG_PARAMETERS_MODULE} from "../../services/configParameters/configParameters.module";
import {HOSPITALS_MODULE} from "../../services/hospitals/hospitals.module";
import {USERS_MODULE} from "../../services/users/users.module";

import {SuperAdminHospitals} from "./hospitals";

export const SUPERADMIN_HOSPITALS_MODULE = 'superadmin.hospitals';

angular.module(SUPERADMIN_HOSPITALS_MODULE, [CONFIG_PARAMETERS_MODULE, HOSPITALS_MODULE, USERS_MODULE]);

angular.module(SUPERADMIN_HOSPITALS_MODULE)
    .controller('SuperAdminHospitals', SuperAdminHospitals);