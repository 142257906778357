const routeBase = 'hospital/nurse';

export function nurseConfig($routeProvider) {
    $routeProvider.when('/' + routeBase + '/accessDisabled', {
        template: require('../../shared/accessDisabled/accessDisabled.tpl.html'),
        controller: 'AccessDisabled',
        controllerAs: 'accessDisabledCtrl',
        resolve: {
            disabledFor: ['Roles', function (Roles) {
                return Roles.NURSE;
            }],
        }
    }).when('/' + routeBase + '/dashboard', {
        template: require('./dashboard/dashboard.tpl.html'),
        controller: 'NurseDashboard',
        controllerAs: 'nurseDashCtrl',
        resolve: {
            cylinderTypes: ['CylinderTypeListService', function (CylinderTypeListService) {
                return CylinderTypeListService.findAll();
            }]
        }
    }).when('/' + routeBase + '/orderHistory', {
        template: require('./orderHistory/orderHistory.tpl.html'),
        controller: 'NurseOrderHistory',
        controllerAs: 'nurseOrderHistoryCtrl',
        resolve: {
            cylinderList: ['CylinderListService', function (CylinderListService) {
                return CylinderListService.findAll();
            }],
            cylinderTypes: ['CylinderTypeListService', function (CylinderTypeListService) {
                return CylinderTypeListService.findAll();
            }],
            hubList: ['HubListService', function (HubListService) {
                return HubListService.findAll();
            }],
            locationList: ['LocationListService', function (LocationListService) {
                return LocationListService.findAll();
            }],
        }
    }).when('/' + routeBase + '/settings', {
        template: require('./settings/settings.tpl.html'),
        controller: 'NurseSettings',
        controllerAs: 'nurseSettingsCtrl',
        resolve: {
            locationList: ['LocationListService', function (LocationListService) {
                return LocationListService.findAll();
            }]
        }
    });
}

function gettext(text) {
    return text;
}

nurseConfig.$inject = ["$routeProvider"];
