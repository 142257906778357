export const AuditLogEntities = {
        /**
         * ATTENTION: Keep it in synch with the
         * liv-iq-api/common/models/audit-event-operations.js
         * liv-iq-api/common/models/audit-event-entities.js
         * or make it one
         */
        USER: gettext('User'),
        CONFIG_PARAMETERS: gettext('ConfigParameters'),
        CYLINDER_TYPE: gettext('CylinderType'),
        CYLINDER: gettext('Cylinder'),
        STOCK: gettext('Stock'),
        GAS: gettext('Gas'),
        HOSPITAL: gettext('Hospital'),
        HUB: gettext('Hub'),
        LOCATION: gettext('Location'),
        LOCATION_TYPE: gettext('LocationType'),
        ORDER: gettext('Order'),
        SIZE: gettext('Size'),
        VALVE: gettext('Valve'),
        LOGIN: gettext('Login'),
        LOGOUT: gettext('Logout'),
        UPDATED: gettext('Updated'),
        DELETED: gettext('Deleted'),
        ADDED: gettext('Added')
    };

/**
 * Does nothing, it's only needed to extract strings to localise
 *
 * @param text
 * @returns {*}
 */
function gettext(text) {
    return text;
}
