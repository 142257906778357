export function NotificationsService($q, $rootScope, ButtonsFeatureService, Notifyee) {
    let dummyPromise = $q(function (resolve) {
        resolve();
    });

    let self = this;

    self.notificationTypes = {
        lowStock: 0,
        lostHubs: 1,
        buttonPush: 2,
        buttonLowBattery: 3,
        actionRequest: 4,
        overStock: 5
    };

    self.areAnyNotificationsEnabled = function () {
        return self.areLowStockNotificationsEnabled() ||
               self.areOverStockNotificationsEnabled() ||
               self.areLostHubNotificationsEnabled() ||
               self.areButtonNotificationsEnabled() ||
               self.areHospitalActionRequestGenerationNotificationsEnabled;
    };

    self.areButtonNotificationsEnabled = function () {
        return ButtonsFeatureService.isEnabled();
    };

    self.areLowStockNotificationsEnabled = function () {
        return $rootScope.hospital === null || $rootScope.hospital.features.lowStockEmailNotification;
    };

    self.areOverStockNotificationsEnabled = function () {
        return $rootScope.hospital === null || $rootScope.hospital.features.overStockEmailNotification;
    };

    self.areLostHubNotificationsEnabled = function () {
        return $rootScope.hospital === null || $rootScope.hospital.features.lostHubEmailNotification;
    };

    self.areHospitalActionRequestGenerationNotificationsEnabled = function () {
        return $rootScope.hospital === null || $rootScope.hospital.features.actionRequestGeneration;
    };

    self.getNotifyees = function (notificationType, includeLocations) {
        return getBaseFilter(notificationType)
            .then(function (filter) {
                if (includeLocations) {
                    return Notifyee.getWithLocations(filter).$promise;
                } else {
                    return Notifyee.find(filter).$promise;
                }
            });
    };

    self.addNotifyee = function (notifyee) {
        if (!self.areAnyNotificationsEnabled()) return dummyPromise;

        return getHospital()
            .then(function (hospital) {
                notifyee.hospitalId = hospital.id;
                return notifyee;
            })
            .then(function (updatedNotifyee) {
                return Notifyee.upsert(updatedNotifyee).$promise;
            });
    };

    self.removeNotifyee = function (notifyee) {
        if (!self.areAnyNotificationsEnabled()) return dummyPromise;

        return new Notifyee(notifyee).$prototype$removeNotifyee();
    };

    self.addNotifyeeLocation = function (notifyee, locationId) {
        if (!self.areAnyNotificationsEnabled()) return dummyPromise;

        return new Notifyee(notifyee).$prototype$addLocation({locationId: locationId});
    };

    self.removeNotifyeeLocation = function (notifyee, locationId) {
        if (!self.areAnyNotificationsEnabled()) return dummyPromise;

        return new Notifyee(notifyee).$prototype$removeLocation({locationId: locationId});
    };

    self.addNotifyeeToLocation = function (notifyee, locationId) {
        if (!self.areAnyNotificationsEnabled()) return dummyPromise;

        return new Notifyee(notifyee).$prototype$addNotifyeeToLocation({locationId: locationId});
    };

    self.removeNotifyeeFromAllLocations = function (notifyee, locationId) {
        if (!self.areAnyNotificationsEnabled()) return dummyPromise;

        return new Notifyee(notifyee).$prototype$removeNotifyeeFromAllLocations({locationId: locationId});
    };

    function getHospital() {
        return $rootScope.appReady.promise;
    }

    function getBaseFilter(notificationType) {
        return getHospital()
            .then(function (hospital) {
                return {
                    filter: {
                        where: {
                            hospitalId: hospital ? hospital.id : null,
                            notificationType: notificationType
                        }
                    }
                };
            });
    }
}

NotificationsService.$inject = ['$q', '$rootScope', 'ButtonsFeatureService', 'Notifyee'];