export function ConfigParametersHelpersService($rootScope, FillPressurePlanService, toastr, gettextCatalog) {
    const self = this;
    self.rootScope = $rootScope;
    this.validateConfigParameters = function (configParameters) {

        function validateRange(value, minInMinutes, maxInMinutes, propName) {
            const minutes = 60000;
            if (typeof value === 'number' && (value < minInMinutes * minutes || value > maxInMinutes * minutes)) {
                toastr.error(
                    gettextCatalog.getString('The {{propName}} value was outside the range {{min}} to {{max}} minutes.', {
                        propName: propName,
                        min: minInMinutes,
                        max: maxInMinutes
                    }),
                    gettextCatalog.getString('Value out of range')
                );
                return false;
            }
            return true;
        }

        const cylinderEnabled = self.rootScope.hospital.features.cylinder;
        if (cylinderEnabled) {
            if (!validateRange(
                configParameters.cylinderDetectionTimeWindow,
                5,
                30,
                gettextCatalog.getString('Cylinder Detection Time Window'))) return false;

            if (!validateRange(
                configParameters.lostCylinderDetectionTimeWindow,
                16,
                360,
                gettextCatalog.getString('Lost Cylinder Time Window'))) return false;

            if (!validateRange(
                configParameters.fromStoreTimeWindow,
                5,
                500,
                gettextCatalog.getString('Delivery Time Window'))) return false;

            if (!validateRange(
                configParameters.cylinderDwellTimeForOrder,
                5,
                60,
                gettextCatalog.getString('Cylinder Dwell Time'))) return false;

            if (configParameters.emptyLevelForFillPressure >= configParameters.usableFullLevelForFillPressure) {
                toastr.error(
                    gettextCatalog.getString('The Empty level should be below the Usably Full level.'),
                    gettextCatalog.getString('Invalid fill pressures')
                );
                return false;
            }
        }

        return true;
    };

    this.updatePressures = function (configParameters) {
        const cylinderEnabled = self.rootScope.hospital.features.cylinder;
        if (cylinderEnabled) {
            configParameters.emptyPressureCB = FillPressurePlanService.pressureCBForLevelInCurrentHospital(configParameters.emptyLevelForFillPressure);
            configParameters.usableFullPressureCB = FillPressurePlanService.pressureCBForLevelInCurrentHospital(configParameters.usableFullLevelForFillPressure);
        }
    };
}

ConfigParametersHelpersService.$inject = ["$rootScope", "FillPressurePlanService", "toastr", "gettextCatalog"];