export function NotifyeeListController($q, NotificationsService, LocationListService, gettextCatalog, toastr) {
    const self = this;
    self.notifyees = [];
    self.locationsById = {};

    self.$onInit = $onInit;
    self.addNotifyee = addNotifyee;
    self.allLocationsSelected = allLocationsSelected;
    self.anyLocationsSelected = anyLocationsSelected;
    self.editNotifyee = editNotifyee;
    self.removeNotifyee = removeNotifyee;
    self.selectedNotifyeeLocations = selectedNotifyeeLocations;
    self.toggleSelectLocation = toggleSelectLocation;
    self.toggleSelectAllLocations = toggleSelectAllLocations;
    self.updateNotifyee = updateNotifyee;


    function isButtonPushNotification() {
        return self.notificationType === NotificationsService.notificationTypes.buttonPush;
    }

    function $onInit() {
        if (isButtonPushNotification()) {
            LocationListService.findAll()
                .then(locations => {
                    self.locations = locations;
                    self.usesLocations = self.locations.length > 0;
                })
                .then(() => {
                    initialiseNotifyees();
                });
        } else {
            initialiseNotifyees();
        }
    }

    function initialiseNotifyees() {
        initialiseNewNotifyee();
        getNotifyees();
        _.forEach(self.locations, loc => {
            self.locationsById[loc.id] = loc;
        });
    }

    function editNotifyee(notifyee) {
        notifyee.edit = true;
    }

    function removeNotifyee(notifyee) {
        NotificationsService.removeNotifyee(notifyee)
            .then((removedNotifyee) => {
                toastr.success(
                    gettextCatalog.getString('Notify {{email}} removed', {
                        email: notifyee.email
                    }),
                    gettextCatalog.getString('Notifyee Removed')
                );
                return removedNotifyee;
            })
            .then(removedNotifyee => {
                return NotificationsService.removeNotifyeeFromAllLocations(removedNotifyee, 127);
            })
            .then(getNotifyees)
            .catch(showError);
    }

    function addNotifyee() {
        if (validateNewNotifyee()) {
            NotificationsService.addNotifyee(self.newNotifyee)
                .then(function (savedNotifyee) {
                    toastr.success(
                        gettextCatalog.getString('{{email}} was added as a new notifyee', {
                            email: self.newNotifyee.email
                        }),
                        gettextCatalog.getString('Notifyee Added')
                    );
                    return savedNotifyee;
                })
                .then(savedNotifyee => {
                    LocationListService.LocationListService.findAll()
                        .then(locations => {
                            let toBeAddedLocationNotifyees = _.map(locations, location => {
                                return NotificationsService.addNotifyeeToLocation(savedNotifyee, location.id);
                            });
                            return $q.all(toBeAddedLocationNotifyees);
                        })
                })
                .then(getNotifyees)
                .then(initialiseNewNotifyee)
                .catch(showError);
        }
    }

    function updateNotifyee(notifyee) {
        if (validateNotifyee(notifyee)) {
            NotificationsService.addNotifyee(notifyee)
                .then(function (savedNotifyee) {
                    toastr.success(
                        gettextCatalog.getString('{{email}} was updated as a new notifyee', {
                            email: self.newNotifyee.email
                        }),
                        gettextCatalog.getString('Notifyee Updated')
                    );
                })
                .then(getNotifyees)
                .catch(showError);
        }
    }

    function toggleSelectLocation(notifyee, notifyeeLocation) {
        return selectLocationCore(notifyee, notifyeeLocation, !notifyeeLocation.selected)
            .then(function (result) {
                var successMessage;
                var successTitle;

                var messageParams = {
                    email: notifyee.email,
                    location: notifyeeLocation.locationName
                };

                if (result.newState) {
                    successMessage = gettextCatalog.getString('{{email}} will be notified for {{location}}', messageParams);
                    successTitle = gettextCatalog.getString('Location Assigned');
                } else {
                    successMessage = gettextCatalog.getString('{{email}} will no longer be notified for {{location}}', messageParams);
                    successTitle = gettextCatalog.getString('Location Unassigned');
                }

                toastr.success(
                    successMessage,
                    successTitle
                );
            })
            .then(getNotifyees)
            .catch(showError);
    }

    function toggleSelectAllLocations(notifyee) {
        var allSelected = self.allLocationsSelected(notifyee);

        const promises = _.map(notifyee.locations, loc => selectLocationCore(notifyee, loc, !allSelected));

        $q.all(promises)
            .then(function () {
                var successMessage;
                var successTitle;

                var messageParams = {email: notifyee.email};

                if (allSelected) {
                    successMessage = gettextCatalog.getString('{{email}} will no longer be notified for any locations', messageParams);
                    successTitle = gettextCatalog.getString('All Locations Unassigned');
                } else {
                    successMessage = gettextCatalog.getString('{{email}} will be notified for all locations', messageParams);
                    successTitle = gettextCatalog.getString('All Locations Assigned');
                }

                toastr.success(
                    successMessage,
                    successTitle
                );
            })
            .then(getNotifyees)
            .catch(showError);
    }

    function anyLocationsSelected(notifyee) {
        return self.selectedNotifyeeLocations(notifyee).length > 0;
    }

    function allLocationsSelected(notifyee) {
        return _.every(notifyee.locations, loc => loc.selected);
    }

    function selectedNotifyeeLocations(notifyee) {
        return _.filter(notifyee.locations, loc => loc.selected);
    }

    function validateNewNotifyee() {
        return validateNotifyee(self.newNotifyee);
    }

    function validateNotifyee(notifyee) {
        var errorMessage = '';

        if (!notifyee.name) {
            errorMessage += gettextCatalog.getString('Please enter a name.') + '</br>';
        }

        if (!notifyee.email) {
            errorMessage += gettextCatalog.getString('Please enter an email address.') + '</br>';
        }

        if (!errorMessage.length) return true;

        alert(errorMessage, gettextCatalog.getString('Invalid notifyee'));
        return false;
    }

    function showError(err) {
        // Unprocessable Entity / Unauthorized errors are handled by $httpProvider interceptors set up in the main module
        // Also the interceptor will log Unprocessable Entity errors to the console
        if (err.status !== 422 && err.status !== 401) {
            console.error(err);

            var errorMessage;
            const innerErrorMessage = _.get(err, 'data.error.message');
            if (innerErrorMessage) {
                errorMessage = gettextCatalog.getString('Unknown error: {{msg}}', {
                    msg: innerErrorMessage
                });
            } else {
                errorMessage = gettextCatalog.getString('Unknown error');
            }

            alert(errorMessage);
        } else if (err.status === 401) {
            console.error(err);
        }
    }

    function alert(message, title) {
        toastr.error(
            message,
            title || gettextCatalog.getString('Error'),
            {allowHtml: true}
        );
    }

    function initialiseNewNotifyee() {
        self.newNotifyee = {
            notificationType: self.notificationType
        };
    }

    function getNotifyees() {
        return NotificationsService.getNotifyees(self.notificationType, self.usesLocations)
            .then(function (notifyees) {
                _.forEach(notifyees, fillInLocations);
                self.notifyees = notifyees;
            })
            .catch(function (err) {
                console.error(err);
            });
    }

    function fillInLocations(notifyee) {
        if (notifyee.locations === undefined) notifyee.locations = [];

        // Fill in the location names (and flag the fact they are selected) of locations that are already selected
        _.forEach(notifyee.locations, function (notifyeeLoc) {
            var matchingLoc = self.locationsById[notifyeeLoc.locationId];
            notifyeeLoc.locationName = matchingLoc ? matchingLoc.name : 'Anon. ' + notifyeeLoc.locationId;
            notifyeeLoc.selected = true;
        });

        // Build up a lookup of currently selected locations
        var notifyeeLocationsById = {};
        _.forEach(notifyee.locations, function (notifyeeLoc) {
            notifyeeLocationsById[notifyeeLoc.locationId] = notifyeeLoc;
        });

        // Add any locations that haven't already been selected
        var unselectedLocations = _.filter(self.locationsById, function (loc) {
            return !notifyeeLocationsById[loc.id];
        });
        _.forEach(unselectedLocations, function (loc) {
            notifyee.locations.push({
                notifyeeId: notifyee.id,
                locationId: loc.id,
                locationName: loc.name,
                selected: false
            });
        });

        // And finally sort by location name
        notifyee.locations = _.sortBy(notifyee.locations, function (loc) {
            return loc.locationName;
        });
    }

    function selectLocationCore(notifyee, notifyeeLocation, newState) {
        var promise;
        var wasUpdated;
        if (newState && !notifyeeLocation.selected) {
            wasUpdated = true;
            promise = NotificationsService.addNotifyeeLocation(notifyee, notifyeeLocation.locationId);
        } else if (!newState && notifyeeLocation.selected) {
            wasUpdated = true;
            promise = NotificationsService.removeNotifyeeLocation(notifyee, notifyeeLocation.locationId);
        } else { // Don't bother doing anything if selection state is already the desired one
            wasUpdated = false;
            promise = $q(function (resolve) {
                resolve();
            }); // Just return a dummy promise
        }

        return promise.then(function () {
            return {
                newState: newState,
                wasUpdated: wasUpdated
            };
        });
    }
}

NotifyeeListController.$inject = ["$q", "NotificationsService", "LocationListService", "gettextCatalog", "toastr"];