export function PorterSettings($rootScope, $scope, SessionService, LocationListService, UserListService, Roles, toastr, gettextCatalog) {
    if (!SessionService.isCurrentRoleAnAdminRole() && !SessionService.verifyCurrentRole([Roles.PORTER])) {
        return;
    }
    $rootScope.navbarType = 'PORTER';

    var self = this;

    self.rootScope = $rootScope;
    self.scope = $scope;
    self.LocationListService = LocationListService;
    self.UserListService = UserListService;
    self.toastr = toastr;
    self.gettextCatalog = gettextCatalog;

    self.scope.disableSubmission = function () {
        return !(
            self.scope.currentPassword && self.scope.newPassword && self.scope.newPassword.length >= 8 &&
            self.scope.confirmNewPassword && self.scope.newPassword === self.scope.confirmNewPassword &&
            self.scope.newPassword !== self.scope.currentPassword
        ) || self.scope.shouldDisable();
    };

    self.scope.shouldDisable = function () {
        return self.rootScope.currentRole.name !== 'Porter';
    };

    self.scope.submitPasswordChangeRequest = function () {
        self.UserListService.updatePassword(self.scope.currentPassword, self.scope.newPassword)
            .then(function () {
                console.log('Password Changed');
                self.scope.currentPassword = '';
                self.scope.newPassword = '';
                self.scope.confirmNewPassword = '';
                self.toastr.success(self.gettextCatalog.getString('Password Successfully updated'));
            })
            .catch(function (err) {
                console.log(err);
                self.scope.currentPassword = '';
                self.scope.newPassword = '';
                self.scope.confirmNewPassword = '';
                self.toastr.error(self.gettextCatalog.getString('Failed to update password'));
            });
    };
}

PorterSettings.$inject = ["$rootScope", "$scope", "SessionService", "LocationListService", "UserListService", "Roles", "toastr", "gettextCatalog"];