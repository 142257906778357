import moment from 'moment';

export function ReportButtonsStatsLocationCtrl($scope, $timeout, ReportService, ColourMapService, CommonTranslations, gettextCatalog) {
    const self = this;
    self.scope = $scope;
    self.timeUnits = gettextCatalog.getString('hours');
    self.ReportService = ReportService;
    self.ColourMapService = ColourMapService;
    self.CommonTranslations = CommonTranslations;
    self.gettextCatalog = gettextCatalog;

    let isForWholeHospital = false;
    self.location = $scope.$parent.loc;
    isForWholeHospital = !self.location;
    self.title = getTitle();

    self.canvasId = 'stats-chart-' + (isForWholeHospital ? 'h' : self.location.id);

    self.dataAvailable = true; // Avoid showing the 'No data' indication initially

    self.totalOrders = 0;
    self.ordersPerDay = 0;
    self.totalAcked = 0;
    self.totalFulfilled = 0;
    self.avgAckTime = displayStringForTime();
    self.avgFulfillTime = displayStringForTime();


    let statsChart;

    $scope.$watch('ctrl.stats', function (newStats) {
        if (!newStats || !newStats.length) {
            self.dataAvailable = false;
            return;
        }

        const statsForLocation = _.filter(newStats, isStatForLocation);

        const overallStats = _.find(statsForLocation, function (stat) {
            return stat.buttontasktypeid === null;
        });
        self.totalOrders = overallStats.pushcount;
        self.ordersPerDay = self.totalOrders / self.timeInterval.duration.asDays();
        self.totalAcked = overallStats.ackcount;
        self.totalFulfilled = overallStats.fulfillcount;
        self.avgAckTime = displayStringForTime(overallStats.avgacktime);
        self.avgFulfillTime = displayStringForTime(overallStats.avgfulfilltime);

        // We are only interested in showing task specific stats if there have been some button pushs for that task
        const unsortedOrdersByTask = _.filter(statsForLocation, function (stat) {
            return stat.buttontasktypeid !== null && stat.pushcount > 0;
        });
        const statsByTask = _.sortBy(unsortedOrdersByTask, function (stat) {
            return stat.buttontasktype;
        });
        self.dataAvailable = !!statsByTask.length;

        updateChart(statsByTask);
    });

    function getTitle() {
        if (isForWholeHospital) {
            return gettextCatalog.getString('Hospital (Total)');
        } else {
            return self.location.name;
        }
    }

    function isStatForLocation(stat) {
        if (isForWholeHospital) {
            return stat.locationid === null;
        } else {
            return stat.locationid === self.location.id;
        }
    }

    function displayStringForTime(secondsDuration) {
        return secondsDuration ? moment.duration(secondsDuration, 'seconds')
            .humanize() : CommonTranslations.NOT_AVAILABLE_ABBREVIATION;
    }

    function updateChart(statsByTask) {
        ColourMapService.paint(statsByTask, 0.8, 1);

        const labels = _.map(statsByTask, function (stat) {
            return stat.buttontasktype;
        });
        const data = _.map(statsByTask, function (stat) {
            return stat.pushcount;
        });
        const bgColours = _.map(statsByTask, function (stat) {
            return stat.colour.light;
        });
        const hoverBgColours = _.map(statsByTask, function (stat) {
            return stat.colour.medium;
        });

        if (!statsChart) {
            const chartCtx = document.getElementById(self.canvasId);
            if (!chartCtx) {
                return;
            }
            statsChart = new Chart(chartCtx, {
                type: 'doughnut',
                data: {
                    labels: labels,
                    datasets: [{
                        data: data,
                        backgroundColor: bgColours,
                        hoverBackgroundColor: hoverBgColours,
                        borderColor: 'rgb(255,255,255)',
                        borderWidth: 1
                    }]
                },
                options: {
                    cutoutPercentage: 50,
                    legend: {
                        position: 'bottom',
                        labels: {
                            boxWidth: 10,
                            fontColor: '#333333',
                            fontFamily: '\'LindeDaxPowerPoint\', \'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif',
                            fontStyle: 'normal',
                            fontSize: 14,
                            generateLabels: function (data) {
                                const series = _.zip(data.labels, data.datasets[0].data, data.datasets[0].backgroundColor);
                                return _.map(series, function (s) {
                                    return {
                                        text: s[0] + ' - ' + s[1],
                                        fillStyle: s[2],
                                        lineWidth: 0,
                                        strokeStyle: s[2]
                                    };
                                });
                            }
                        }
                    }
                }
            });
        } else {
            statsChart.data.labels = labels;
            statsChart.data.datasets[0].data = data;
            statsChart.data.datasets[0].backgroundColor = bgColours;
            statsChart.data.datasets[0].hoverBackgroundColor = hoverBgColours;
            statsChart.update();
        }
    }
}

ReportButtonsStatsLocationCtrl.$inject = ["$scope", "$timeout", "ReportService", "ColourMapService", "CommonTranslations", "gettextCatalog"];