export function SessionService($rootScope, $window, $location, $q, $localStorage, AuthService, FillPressurePlanService, HospitalListService, LocaleService, gettextCatalog, Roles) {
    var self = this;
    self.window = $window;
    self.gettextCatalog = gettextCatalog;
    self.q = $q;
    self.localStorage = $localStorage;
    self.rootScope = $rootScope;
    self.AuthService = AuthService;
    self.FillPressurePlanService = FillPressurePlanService;
    self.HospitalListService = HospitalListService;
    self.rootScope.appReady = $q.defer();
    self.location = $location;
    window.devModeActivated = false;
    self.LocaleService = LocaleService;
    self.Roles = Roles;
    this._addScopeEventListeners();
}

SessionService.prototype._addScopeEventListeners = function () {
    this.rootScope.$on(this.LocaleService.EVENT_LOCALE_CHANGED, (function () {
        this._setHospitalNameOnRootScope();
    }).bind(this));
};

SessionService.prototype.restoreSession = function () {
    var self = this;

    self.rootScope.appReady = self.q.defer();
    var sessionVariables = self.AuthService.getSessionVariables();
    self.rootScope.currentUser = sessionVariables.user;
    self.rootScope.currentRole = sessionVariables.role;
    self.rootScope.configParameters = sessionVariables.configParameters;
    self.rootScope.hospital = sessionVariables.hospital;
    if (sessionVariables.hospital) {
        self.FillPressurePlanService.loadCurrentHospitalAndFillPressurePlan();
    }
    self._setHospitalNameOnRootScope();
    if (self.rootScope.currentUser) {
        self.LocaleService.applyPersistedLocale(self.rootScope.currentUser, self.rootScope.hospital);
        self.LocaleService.loadListOfLocale();
    } else {
        self.LocaleService.applyApplicationDefaultLocale();
    }
    self.rootScope.appReady.resolve(sessionVariables.hospital);
    return true;
};

SessionService.prototype._setHospitalNameOnRootScope = function () {
    var sessionVariables = this.AuthService.getSessionVariables();
    if (sessionVariables.hospital) {
        this.rootScope.hospitalName = sessionVariables.hospital.name;
    } else if (this.rootScope.currentRole && this.rootScope.currentRole.name === this.Roles.LINDE_ADMINISTRATOR) {
        this.rootScope.hospitalName = this.gettextCatalog.getString('Linde Administration');
    } else if (this.rootScope.currentRole && this.rootScope.currentRole.name === this.Roles.LINDE_SUPER_ADMINISTRATOR) {
        this.rootScope.hospitalName = this.gettextCatalog.getString('Linde Super Administration');
    }
};

SessionService.prototype.isCurrentRoleAnAdminRole = function () {
    return this.isCurrentRoleIn([
        this.Roles.LINDE_SUPER_ADMINISTRATOR,
        this.Roles.LINDE_ADMINISTRATOR,
        this.Roles.HOSPITAL_SERVICE,
        this.Roles.HOSPITAL_ADMINISTRATOR
    ]);
};

SessionService.prototype.isCurrentRoleALindeAdminRole = function () {
    return this.isCurrentRoleIn([
        this.Roles.LINDE_SUPER_ADMINISTRATOR,
        this.Roles.LINDE_ADMINISTRATOR
    ]);
};

function isRoleIn(currentRole, rolesToCheck) {
    var allowed = false;
    if (currentRole) {
        _.forEach(rolesToCheck, function (name) {
            if (name === currentRole.name) {
                allowed = true;
            }
        });
    }
    return allowed;
}

SessionService.prototype.isCurrentRoleIn = function (roleNames) {
    return isRoleIn(this.rootScope.currentRole, roleNames);
};

SessionService.prototype.verifyCurrentRole = function (roleNames) {
    if (isRoleIn(this.rootScope.currentRole, roleNames)) {
        return true;
    }

    this.location.path('/signin');
    return false;
};

SessionService.prototype.enterHospital = function (hospital) {
    this.localStorage.savedSession = JSON.stringify(this.AuthService.getSessionVariables());
    this.localStorage.hospital = hospital;
    this.localStorage.configParameters = hospital ? hospital.configParameters : null;
    this.localStorage.user.hospitalId = hospital ? hospital.id : null;
    this.restoreSession();
};

SessionService.prototype.redirectAfterLogin = function () {
    this.restoreSession();

    var redirectPath = this.location.search().onlogin;
    if (redirectPath) {
        this.location.path(redirectPath);
        return;
    }
    switch (this.rootScope.currentRole.name) {
        case this.Roles.HOSPITAL_SERVICE:
        case this.Roles.HOSPITAL_ADMINISTRATOR:
            var hospitalFeatures = this.rootScope.hospital.features;
            if (hospitalFeatures.dashboard && hospitalFeatures.cylinder) {
                this.location.path('/hospital/admin/dashboard');
            } else if (hospitalFeatures.dashboard) {
                this.location.path('/hospital/admin/button-dashboard');
            } else {
                this.location.path('/hospital/admin/users');
            }
            break;
        case this.Roles.NURSE:
            this.location.path('/hospital/nurse/dashboard');
            break;
        case this.Roles.PORTER:
            this.location.path('/hospital/porter/dashboard');
            break;
        case this.Roles.LINDE_SUPER_ADMINISTRATOR:
            this.location.path('/superadmin/hospitals');
            break;
        case this.Roles.LINDE_ADMINISTRATOR:
            this.location.path('/admin/hospitals');
            break;
        default:
            this.location.path('/signin');
            break;
    }
};

SessionService.prototype.isValid = function () {
    var sessionVariables = this.AuthService.getSessionVariables();
    return sessionVariables.user;
};

SessionService.prototype.logout = function (redirectOnLoginPath) {
    var vm = this;
    return vm.AuthService.logout().then(function () {
        vm.restoreSession();
        var signinPath = '/signin';

        if (redirectOnLoginPath === signinPath) {
            // Will happen when navigating to the sign-in page - we don't want to get
            // stuck in an endless redirect loop!
            redirectOnLoginPath = vm.location.search().onlogin;
        }
        var loc;
        if (redirectOnLoginPath) {
            loc = vm.location.path(signinPath).search('onlogin', redirectOnLoginPath);
        } else {
            loc = vm.location.path(signinPath);
        }

        return loc;
    });
};

SessionService.$inject = ['$rootScope', '$window', '$location', '$q', '$localStorage', 'AuthService', 'FillPressurePlanService', 'HospitalListService', 'LocaleService', 'gettextCatalog', 'Roles'];