import moment from 'moment';

export function HospitalAdminCylinderUsage($rootScope, $scope, SmartTableServerAdapter, SessionService, UsageList, CylinderUsageService, toastr) {
    if (!SessionService.isCurrentRoleAnAdminRole()) {
        return;
    }
    $rootScope.navbarType = 'HOSPITALADMIN';

    const self = this;
    self.toastr = toastr;
    self.scope = $scope;
    self.usageList = UsageList;
    self.CylinderUsageService = CylinderUsageService;

    self.cylinderUsageGrid = new SmartTableServerAdapter(self.CylinderUsageService);
}

HospitalAdminCylinderUsage.prototype.duration = (start, end) => moment.duration(end - start).humanize();

HospitalAdminCylinderUsage.$inject = ["$rootScope", "$scope", "SmartTableServerAdapter", "SessionService", "UsageList", "CylinderUsageService", "toastr"];