export function RecoverPasswordWithEmailController($rootScope, $routeParams, $scope, $q, Common, UserListService, SessionService, toastr) {
    var self = this;

    self.rootScope = $rootScope;
    self.routeParams = $routeParams;
    self.scope = $scope;
    self.q = $q;
    self.Common = Common;
    self.UserListService = UserListService;
    self.SessionService = SessionService;
    self.toastr = toastr;
    self.rcoveryAttempted = false;
    self.rcoverySuccessful = false;
    self.rcoveryFailed = false;

    self.scope.roles = [
        {index: 1, name: 'HospitalService'},
        {index: 2, name: 'HospitalAdministrator'},
        {index: 3, name: 'Porter'},
        {index: 4, name: 'Nurse'}
    ];

    if (!self.routeParams.token || !self.routeParams.id) {
        self.SessionService.logout();
    }

    self.scope.showActivationForm = function () {
        return !self.rcoveryAttempted;
    };

    self.scope.showActivationSuccess = function () {
        return self.rcoveryAttempted && self.rcoverySuccessful;
    };

    self.scope.showActivationFailure = function () {
        return self.rcoveryAttempted && self.rcoveryFailed;
    };
}

RecoverPasswordWithEmailController.prototype.enableActivationButton = function () {
    return this.scope.selectedRole &&
        this.scope.username &&
        this.scope.newPassword &&
        this.scope.newPassword.length >= 8 &&
        this.scope.confirmNewPassword &&
        this.scope.newPassword === this.scope.confirmNewPassword;
};

RecoverPasswordWithEmailController.prototype.recoverPassword = function () {
    var self = this;
    self.rcoveryAttempted = true;
    return self.UserListService.recoverPasswordUsingEmail(self.routeParams.id, self.scope.username, self.routeParams.token, self.scope.newPassword, self.scope.selectedRole.name)
        .then(function () {
            self.rcoverySuccessful = true;
        })
        .catch(function (err) {
            console.log('Password Recovery Error:', err);
            self.toastr.error(self.Common.interpretHttpStatus(err.status));
            self.rcoveryFailed = true;
        });
};

RecoverPasswordWithEmailController.prototype.gotoSignInPage = function () {
    return this.SessionService.logout();
};

RecoverPasswordWithEmailController.$inject = ["$rootScope", "$routeParams", "$scope", "$q", "Common", "UserListService", "SessionService", "toastr"];