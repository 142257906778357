export function HospitalAdminButtonsList($rootScope, $location, ButtonsListService, buttonsRefreshIntervalMillis, SmartTableServerAdapter, SessionService) {
  if (!SessionService.isCurrentRoleAnAdminRole()) {
    return;
  }
  $rootScope.navbarType = 'HOSPITALADMIN';

  var self = this;

  self.buttonsRefreshIntervalMillis = buttonsRefreshIntervalMillis;

  self.buttonsGrid = new SmartTableServerAdapter(ButtonsListService);
  self.location = $location;

  self.showPaginationControl = function () {
    return self.buttonsGrid.totalItems > 5;
  };

  self.enterButton = function (button) {
    var self = this;
    if (button.pushCount > 0) {
      var path = '/hospital/admin/button/' + button.mac + '/history';
      self.location.path(path);
    }
  };
}

HospitalAdminButtonsList.$inject = ["$rootScope", "$location", "ButtonsListService", "buttonsRefreshIntervalMillis", "SmartTableServerAdapter", "SessionService"];