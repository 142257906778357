export function HospitalAdminLocations($rootScope, $scope, $uibModal, $q, $timeout, SessionService, ConfigParametersListService, LocationListService, StockListService, locations, locationTypes, hubs, cylinderTypes, toastr, gettextCatalog, LocationTypes, NotificationsService) {
    this.rootScope = $rootScope;
    if (!SessionService.isCurrentRoleAnAdminRole()) {
        return;
    }
    $rootScope.navbarType = 'HOSPITALADMIN';

    this.gettextCatalog = gettextCatalog;
    this.toastr = toastr;
    this.scope = $scope;
    this.scope.readOnly = !(_.includes($rootScope.currentRole.name, 'Service') || _.includes($rootScope.currentRole.name, 'Linde'));
    this.modal = $uibModal;
    this.q = $q;
    this.timeout = $timeout;
    this.ConfigParametersListService = ConfigParametersListService;
    this.LocationListService = LocationListService;
    this.StockListService = StockListService;
    this.LocationTypes = LocationTypes;
    this.locations = locations;
    this.locationTypes = locationTypes;
    this.cylinderTypes = cylinderTypes;
    this.allHubs = hubs;
    this.is3DMapEnabled = $rootScope.hospital.features['3DMap'];
    this.notificationsService = NotificationsService;
    this.reset();
    this.setCylinderTypesForStockLevels();
    this.countCylindersForLocations();

    this.openConfigParameters = function () {
        var vm = this;
        var modalInstance = vm.modal.open({
            animation: vm.animationsEnabled,
            template: require('./configParametersModal/configParametersModal.tpl.html'),
            controller: 'ConfigParametersController',
            controllerAs: 'configurationCtrl',
            size: 'lg',
            resolve: {
                configParameters: function () {
                    if (vm.selection.configParametersId) {
                        return vm.ConfigParametersListService.findById(vm.selection.configParametersId);
                    }
                    return vm.ConfigParametersListService.add({hospitalId: vm.selection.hospitalId});
                },
                hospitalConfigParameters: function () {
                    return vm.ConfigParametersListService.findById(vm.rootScope.hospital.configParametersId);
                },
                location: function () {
                    return vm.selection;
                },
                readOnly: this.scope.readOnly
            }
        });

        return modalInstance.result.then(function (result) {
            if (result.hasOwnProperty('newValue')) {
                vm.selection.configParameters = result.newValue;
                if (result.newValue) {
                    vm.selection.configParametersId = result.newValue.id;
                }
            }
            return true;
        });
    };
}

HospitalAdminLocations.prototype.countCylindersForLocations = function () {
    var vm = this;
    _.forEach(vm.locations, function (location) {
        location.cylinderCount = _.reduce(location.hubs, function (total, hub) {
            var realHub = _.find(vm.allHubs, ['id', hub.id]);
            var pegCylinderCount = (realHub) ? realHub.cylinders.length : 0;
            return total + pegCylinderCount;
        }, 0);
    });
};


HospitalAdminLocations.prototype.getGases = function () {
    return Object.keys(this.groupCylinderTypes());
};

HospitalAdminLocations.prototype.getValves = function (gas) {
    return Object.keys(this.groupCylinderTypes()[gas]);
};

HospitalAdminLocations.prototype.getSizes = function (gas, valve) {
    return Object.keys(this.groupCylinderTypes()[gas][valve]);
};

HospitalAdminLocations.prototype.setCylinderTypesForStockLevels = function () {
    var self = this;
    _.forEach(self.locations, function (location) {
        _.forEach(location.stocks, function (stock) {
            stock.cylinderTypeDef = _.find(self.cylinderTypes, ['id', stock.cylinderType]);
        });
    });
};

HospitalAdminLocations.prototype.reset = function () {
    this.scope.initialFocus = false;
    this.selection = null;
};

HospitalAdminLocations.prototype.openEditPane = function (focusOnFieldId, existingLocation) {
    var self = this;
    self.scope.initialFocus = false;
    self.existingLocation = existingLocation;
    self.timeout(function () {
        self.scope.initialFocus = focusOnFieldId;
        self.selection = self.LocationListService.add(existingLocation);
        self.stockSettingsByCylinderType = _.map(self.cylinderTypes, function (cylinderType) {
            return self.getOrCreateStockSettingsForCylinderType(cylinderType);
        });
    });
};

HospitalAdminLocations.prototype.closeEditPane = function () {
    this.scope.initialFocus = false;
};

HospitalAdminLocations.prototype.getOrCreateStockSettingsForCylinderType = function (cylinderType) {
    console.log("'cylinderType' is: ");
    console.log(cylinderType);
    var self = this;
    var stock = _.find(self.selection.stocks, {cylinderTypeId: cylinderType.id});
    console.log("'stock' found is: ");
    console.log(stock);

    stock = self.StockListService.add(stock || {
        cylinderTypeId: cylinderType.id,
        min: null,
        max: null
    });
    console.log("Latest 'stock' value is:");
    console.log(stock);
    console.log("'self.StockListService' is:");
    console.log(self.StockListService);

    return {
        cylinderType: cylinderType,
        stock: stock
    };
};

HospitalAdminLocations.prototype.add = function () {
    this.openEditPane('inputLocationName');
};

HospitalAdminLocations.prototype.select = function (locationSummary) {
    var self = this;
    self.LocationListService.findById(locationSummary.id)
        .then(function (location) {
            if (location) {
                self.openEditPane('inputDescription', location);
            } else {
                self.reset();
            }
        });
};

HospitalAdminLocations.prototype.current = function () {
    return this.selection;
};

HospitalAdminLocations.prototype.locationTypesSelect = function () {
    var self = this;
    if (self.areCylindersEnabled()) {
        return self.locationTypes;
    } else {
        return _.filter(self.locationTypes, function (item) {
            return item.name !== self.LocationTypes.DEPARTMENTAL_STORE &&
                item.name !== self.LocationTypes.CENTRAL_STORE;
        });
    }
};

HospitalAdminLocations.prototype.areCylindersEnabled = function () {
    return this.rootScope.hospital === null || this.rootScope.hospital.features.cylinder;
};

HospitalAdminLocations.prototype.refreshLocations = function () {
    var self = this;
    return self.LocationListService.findAll().then(function (locations) {
        self.locations = locations;
        return locations;
    });
};

HospitalAdminLocations.prototype.saveStockSettings = function (savedLocation) {
    let self = this;
    console.log("'self.locationTypes' is:")
    console.log(self.locationTypes);
    
    let savedLocationType = _.find(self.locationTypes, ['id', savedLocation.locationTypeId]);
    console.log("'savedLocationType' is:");
    console.log(savedLocationType)
    let stockSettingsToSave = _.map(self.stockSettingsByCylinderType, function (value) {
        console.log("'value.stock' before modification is:");
        console.log(value.stock);
        value.stock.locationId = savedLocation.id;
        value.stock.hospitalId = savedLocation.hospitalId;
        if (savedLocationType.name === self.LocationTypes.DEPARTMENTAL_LOCATION ||
            savedLocationType.name === self.LocationTypes.STANDARD_LOCATION) {
            value.stock.min = null;
            value.stock.max = null;
            value.stock.overstock = null;
        }
        console.log("'value.stock' after modification is:");
        console.log(value.stock);
        return value.stock;
    });

    console.log("'stockstockSettingsToSave' is: ");
    console.log(stockSettingsToSave);

    self.StockListService.save(stockSettingsToSave);
};

HospitalAdminLocations.prototype.save = function () {
    var self = this;
    var errorMsg = this.validateLocation();
    if (!errorMsg.length) {
        if (self.selection) {
            if (self.selection !== self.existingLocation) {
                // Only save if the item has actually changed
                self.LocationListService.save(self.selection)
                    .then(function (savedLocation) {
                        console.log("'savedLocation' is: ");
                        console.log(savedLocation);
                        self.saveStockSettings(savedLocation);
                    })
                    .then(function () {
                        self.refreshLocations();
                        self.toastr.success(self.gettextCatalog.getString('Location has been saved'), self.gettextCatalog.getString('Saved'));
                    });
            }
        }
        self.reset();
    } else {
        window.alert(errorMsg);
    }
};

HospitalAdminLocations.prototype.del = function () {
    var self = this;
    if (self.selection) {
        if (self.existingLocation) {
            self.LocationListService.del(self.existingLocation).then(function () {
                self.toastr.error(self.gettextCatalog.getString('Location has been deleted'), self.gettextCatalog.getString('Deleted'), {timeOut: 0});
                self.refreshLocations();
            });
        }
    }
    self.reset();
};

HospitalAdminLocations.prototype.validateLocation = function () {
    var self = this;
    var errorMsg = '';
    if (!self.selection.name) {
        errorMsg += self.gettextCatalog.getString('Please enter a valid location name') + '\r\n';
    }

    if (errorMsg.length > 0) {
        return errorMsg;
    }
    return true;
};

HospitalAdminLocations.prototype.totalHubs = function () {
    var self = this;
    var total = 0;
    _.forEach(self.locations, function (location) {
        if (location) {
            total += Number(location.hubCount);
        }
    });
    return total;
};

HospitalAdminLocations.prototype.totalCylinders = function () {
    var self = this;
    var total = 0;
    _.forEach(self.locations, function (location) {
        total += Number(location.cylindersCount);
    });
    return total;
};

HospitalAdminLocations.prototype.areCylindersEnabled = function () {
    return this.rootScope.hospital === null || this.rootScope.hospital.features.cylinder;
};
HospitalAdminLocations.prototype.areOverstockNotificationsEnabled = function () {
    return this.notificationsService != null && this.notificationsService.areOverStockNotificationsEnabled();
};
HospitalAdminLocations.$inject = ["$rootScope", "$scope", "$uibModal", "$q", "$timeout", "SessionService", "ConfigParametersListService", "LocationListService", "StockListService", "locations", "locationTypes", "hubs", "cylinderTypes", "toastr", "gettextCatalog", "LocationTypes", "NotificationsService"];