import {LIST_HELPERS_MODULE} from "../listHelpers/listHelpers.module";

import {ButtonHistoryService} from "./buttonHistory.service";
import {ButtonOrdersSummaryService} from "./buttonOrdersSummary.service";
import {ButtonsFeatureService} from "./buttonsFeature.service";
import {ButtonsListService} from "./buttonsList.service";
import {ButtonsOrdersService} from "./buttonsOrders.service";
import {ButtonsOrdersListService} from "./buttonsOrdersList.service";
import {ButtonsOrderTypesService} from "./buttonsOrderTypes.service";
import {ButtonsTasksListService} from "./buttonsTasksList.service";
import {SwButtonsService} from "./swbuttons.service";

export const BUTTONS_MODULE = 'services.buttons';

angular
    .module(BUTTONS_MODULE, [LIST_HELPERS_MODULE]);

angular.module(BUTTONS_MODULE)
    .service('ButtonHistoryService', ButtonHistoryService)
    .service('ButtonOrdersSummaryService', ButtonOrdersSummaryService)
    .service('ButtonsFeatureService', ButtonsFeatureService)
    .service('ButtonsListService', ButtonsListService)
    .service('ButtonsOrdersService', ButtonsOrdersService)
    .service('ButtonsOrdersListService', ButtonsOrdersListService)
    .service('ButtonsOrderTypesService', ButtonsOrderTypesService)
    .service('ButtonsTasksListService', ButtonsTasksListService)
    .service('SwButtonsService', SwButtonsService);
