export function CylinderUsageService($rootScope, $q, CylinderUsageRecord, FlatCylinderUsageRecord) {
    // console.log ('in CylinderUsageService (); Cylinder: ', Cylinder);
    this.rootScope = $rootScope;
    this.CylinderUsageRecord = CylinderUsageRecord;
    this.FlatCylinderUsageRecord = FlatCylinderUsageRecord;
    this.q = $q;
}

CylinderUsageService.prototype.getBaseFilter = function (hospital) {
    return _.cloneDeep({
        filter: {
            where: {
                hospitalId: hospital.id,
                endTime: {
                    neq: null
                }
            },
            include: 'cylinder'
        }
    });
};

CylinderUsageService.prototype.findClosedUsages = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        // console.log ('CylinderUsageService.findAll (); hospital:', hospital);
        return self.CylinderUsageRecord.find(self.getBaseFilter(hospital)).$promise;
    });
};

CylinderUsageService.prototype.getPage = function (applyFilteringAndPagination) {
    var self = this;

    return this.rootScope.appReady.promise.then(function (hospital) {
        var filter = self.getBaseFilter(hospital);

        applyFilteringAndPagination(filter.filter);

        return self.FlatCylinderUsageRecord.getPage(filter).$promise.then(function (data) {
            return self.q.resolve({
                items: _.map(data.items, function (flatCylinder) {
                    return new self.FlatCylinderUsageRecord(flatCylinder);
                }),
                totalCount: data.totalCount
            });
        });
    });
};

CylinderUsageService.$inject = ["$rootScope", "$q", "CylinderUsageRecord", "FlatCylinderUsageRecord"];