export function livIqPercentageWhenFalsyOrNaNFactory() {
    function livIqPercentageWhenFalsyOrNaN(value, placeholderToUseWhenFalsyOrNaN) {
        placeholderToUseWhenFalsyOrNaN = placeholderToUseWhenFalsyOrNaN === undefined ? '' : placeholderToUseWhenFalsyOrNaN;
        if (!value || isNaN(value)) {
            return placeholderToUseWhenFalsyOrNaN;
        }
        return value + '%';
    }

    return livIqPercentageWhenFalsyOrNaN;
}