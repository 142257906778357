export function HospitalAdminCylinderEmptyReplacementTimeReport($rootScope, $scope, ReportService, emptyReplacementReport, locations, toastr, gettextCatalog, setupDateRangePickerBySelector) {
    $rootScope.navbarType = 'HOSPITALADMIN';

    this.gettextCatalog = gettextCatalog;
    this.setupDateRangePickerBySelector = setupDateRangePickerBySelector;
    this.rootScope = $rootScope;
    this.scope = $scope;
    this.ReportService = ReportService;
    this.emptyReplacementReport = emptyReplacementReport;
    this.locations = locations;
    this.locations.unshift({name: this.gettextCatalog.getString('Hospital'), id: null});
    this.selectedLocation = _.first(this.locations);
    this.toastr = toastr;
    this.timeInterval = this.ReportService.defaultInterval();
    this.reportName = this.gettextCatalog.getString('Hospital');

    this._addScopeEventListeners();
    this._setupDateRangePicker();
}

HospitalAdminCylinderEmptyReplacementTimeReport.prototype._addScopeEventListeners = function () {
    this.scope.$on(this.ReportService.LABELS_LOCALIZED, this._setupDateRangePicker.bind(this));
};

HospitalAdminCylinderEmptyReplacementTimeReport.prototype._setupDateRangePicker = function () {
    this.setupDateRangePickerBySelector(
        '#cylinder-empty-replacement-report-time-interval',
        this.timeInterval,
        this.refresh.bind(this)
    );
};

HospitalAdminCylinderEmptyReplacementTimeReport.prototype.refresh = function () {
    var self = this;

    if (self.selectedLocation && self.selectedLocation.id) {
        self.reportName = self.selectedLocation.name;
        self.ReportService.cylinderEmptyReplacementTimeAtLocation(self.selectedLocation.id, self.timeInterval.from, self.timeInterval.to)
            .then(function (emptyReplacementReport) {
                self.emptyReplacementReport = emptyReplacementReport;
            });
    } else {
        self.reportName = self.gettextCatalog.getString('Hospital');
        self.ReportService.cylinderEmptyReplacementTimeInHospital(self.timeInterval.from, self.timeInterval.to)
            .then(function (emptyReplacementReport) {
                self.emptyReplacementReport = emptyReplacementReport;
            });
    }
};

HospitalAdminCylinderEmptyReplacementTimeReport.$inject = ["$rootScope", "$scope", "ReportService", "emptyReplacementReport", "locations", "toastr", "gettextCatalog", "setupDateRangePickerBySelector"];