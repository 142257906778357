export function OrderListService($rootScope, $q, Order, FlatCylinderOrder, FlatNewOrderSummary, FlatAcknowledgedOrderSummary, CylinderTypeService) {
    var self = this;
    this.rootScope = $rootScope;
    this.Order = Order;
    this.FlatCylinderOrder = FlatCylinderOrder;
    this.FlatNewOrderSummary = FlatNewOrderSummary;
    this.FlatAcknowledgedOrderSummary = FlatAcknowledgedOrderSummary;
    this.q = $q;
    this.CylinderTypeService = CylinderTypeService;


    Object.defineProperty(this.FlatCylinderOrder.prototype, 'cylinderTypeDesc', {
        get: function () {
            return self.CylinderTypeService.translateCylinderType(this.cylinderTypeName);
        }
    });
}

OrderListService.prototype.add = function (order) {
    var newOrder = new this.Order(order);
    // We don't have to wait for appReady because the "add" button appears after the app is ready
    newOrder.hospitalId = this.rootScope.hospital.id;
    return newOrder;
};

OrderListService.prototype.getPage = function (applyFilteringAndPagination) {
    var self = this;

    return this.rootScope.appReady.promise.then(function (hospital) {
        var filter = {
            filter: {
                where: {
                    hospitalId: hospital.id,
                    deleted: 0
                },
                order: 'raisedTime DESC'
            }
        };

        applyFilteringAndPagination(filter.filter);

        return self.FlatCylinderOrder.getPage(filter).$promise.then(function (data) {
            return self.q.resolve({
                items: _.map(data.items, function (flatItem) {
                    return new self.FlatCylinderOrder(flatItem);
                }),
                totalCount: data.totalCount
            });
        });
    });
};

OrderListService.prototype.findAll = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        // console.log ('OrderListService.findAll (); hospital:', hospital);
        return self.FlatCylinderOrder.find({
            filter: {
                where: {
                    hospitalId: hospital.id,
                    deleted: 0
                }
            }
        }).$promise;
    });
};

OrderListService.prototype.findByDateRange = function (from, to) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        // console.log ('OrderListService.findAll (); hospital:', hospital);
        return self.Order.find({
            filter: {
                where: {
                    hospitalId: hospital.id,
                    deleted: 0,
                    raisedTime: {between: [from, to]}
                },
                include: [
                    'location',
                    'cylinderType'
                ]
            }
        }).$promise;
    });
};

OrderListService.prototype.acknowledgeAllOrders = function (optionalMessage) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var where = {
            hospitalId: hospital.id,
            deleted: 0,
            raisedTime: {neq: null},
            acknowledgedTime: null,
            fulfilledTime: null,
            cancelledTime: null
        };

        var values = {
            acknowledgedTime: new Date()
        };

        if (optionalMessage) {
            values.message = optionalMessage;
        }

        return self.Order.updateAll({where: where}, values).$promise;
    });
};

OrderListService.prototype.deliverAllOrders = function (optionalMessage) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var where = {
            hospitalId: hospital.id,
            deleted: 0,
            raisedTime: {neq: null},
            acknowledgedTime: {neq: null},
            fulfilledTime: null,
            cancelledTime: null
        };

        var values = {
            fulfilledTime: new Date()
        };

        if (optionalMessage) {
            values.message = optionalMessage;
        }

        return self.Order.updateAll({where: where}, values).$promise;
    });
};

OrderListService.prototype.cancelAllOrders = function (currentStatus, locationId, optionalMessage) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var where = {
            hospitalId: hospital.id,
            deleted: 0,
            raisedTime: {neq: null},
            fulfilledTime: null,
            cancelledTime: null
        };

        if (locationId) {
            where.locationId = locationId;
        }

        if (currentStatus === 'new') {
            where.acknowledgedTime = null;
        } else {
            where.acknowledgedTime = {neq: null};
        }

        var values = {
            cancelledTime: new Date()
        };

        if (optionalMessage) {
            values.message = optionalMessage;
        }

        return self.Order.updateAll({where: where}, values).$promise;
    });
};

OrderListService.prototype.acceptAllOrders = function (locationId, optionalMessage) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var where = {
            hospitalId: hospital.id,
            deleted: 0,
            raisedTime: {neq: null},
            fulfilledTime: null,
            acknowledgedTime: null,
            cancelledTime: null
        };

        if (locationId) {
            where.locationId = locationId;
        }

        var values = {
            acknowledgedTime: new Date()
        };

        if (optionalMessage) {
            values.message = optionalMessage;
        }

        return self.Order.updateAll({where: where}, values).$promise;
    });
};

OrderListService.prototype.fulfillAllOrders = function (locationId, optionalMessage) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var where = {
            hospitalId: hospital.id,
            deleted: 0,
            raisedTime: {neq: null},
            fulfilledTime: null,
            acknowledgedTime: {neq: null},
            cancelledTime: null
        };

        if (locationId) {
            where.locationId = locationId;
        }

        var values = {
            fulfilledTime: new Date()
        };

        if (optionalMessage) {
            values.message = optionalMessage;
        }

        return self.Order.updateAll({where: where}, values).$promise;
    });
};


OrderListService.prototype.save = function (order, param1, param2) {
    var self = this;

    order = self.add(order);
    return self.Order.upsert({}, order).$promise;
};

OrderListService.prototype.del = function (order) {
    if (order) {
        order.deleted = new Date().getTime();
        return order.$save();
    }
    return false;
};

OrderListService.prototype.getOrderStats = function (date) {
    var self = this;

    return this.rootScope.appReady.promise.then(function () {
        return self.Order.usage({
            date: date
        }).$promise;
    });
};

OrderListService.prototype.groupByLocationGasTypeAndSize = function (rawSummaryData) {
    var toReturn = {
        summary: {},
        locations: [],
        cylinderTypes: [],
        countBySize: {},
        countByType: {},
        sizeCount: 0,
        typeCount: 0,
        totalCount: 0
    };
    _.forEach(rawSummaryData, function (data) {
        if (!toReturn.summary[data.location]) {
            toReturn.summary[data.location] = {};
            toReturn.summary[data.location].id = data.locationId;
            toReturn.locations.push(data.location);
        }
        if (!toReturn.summary[data.location][data.cylinderType]) {
            toReturn.summary[data.location][data.cylinderType] = Number(data.count);
            if (!_.includes(toReturn.cylinderTypes, data.cylinderType)) {
                toReturn.cylinderTypes.push(data.cylinderType);
            }
        }
        if (toReturn.countBySize[data.sizeCode]) {
            toReturn.countBySize[data.sizeCode] += Number(data.count);
        } else if (Number(data.count) > 0) {
            toReturn.countBySize[data.sizeCode] = Number(data.count);
            toReturn.sizeCount++;
        }
        if (toReturn.countByType[data.cylinderType]) {
            toReturn.countByType[data.cylinderType] += Number(data.count);
        } else if (Number(data.count) > 0) {
            toReturn.countByType[data.cylinderType] = Number(data.count);
            toReturn.typeCount++;
        }
        toReturn.totalCount += Number(data.count);
    });
    return toReturn;
};

OrderListService.prototype.newOrderSummary = function () {
    var self = this;
    return self.rootScope.appReady.promise.then(function (hospital) {
        var filter = _.cloneDeep({
            filter: {
                where: {
                    hospitalId: hospital.id
                }
            }
        });

        return self.FlatNewOrderSummary.find(filter).$promise
            .then(function (rawFaultyCylinderSummary) {
                return self.q(function (resolve) {
                    resolve(self.groupByLocationGasTypeAndSize(rawFaultyCylinderSummary));
                });
            });
    });
};

OrderListService.prototype.acknowledgedOrderSummary = function () {
    var self = this;
    return self.rootScope.appReady.promise.then(function (hospital) {
        var filter = _.cloneDeep({
            filter: {
                where: {
                    hospitalId: hospital.id
                }
            }
        });

        return self.FlatAcknowledgedOrderSummary.find(filter).$promise
            .then(function (rawAcknowledgedOrderSummary) {
                return self.q(function (resolve) {
                    resolve(self.groupByLocationGasTypeAndSize(rawAcknowledgedOrderSummary));
                });
            });
    });
};

OrderListService.$inject = ["$rootScope", "$q", "Order", "FlatCylinderOrder", "FlatNewOrderSummary", "FlatAcknowledgedOrderSummary", "CylinderTypeService"];