import {CYLINDERS_MODULE} from "../../../services/cylinders/cylinders.module";
import * as smartTable from 'angular-smart-table';

import {HospitalAdminDashboard} from "./dashboard";
import {HospitalAdminButtonDashboard} from "./button-dashboard";
import {ButtonLowBatterBannerController} from "../../../directives/liv-iq-button-low-battery-banner.controller";
import {ButtonLowBatteryBanner} from "../../../directives/liv-iq-button-low-battery-banner";
import {ButtonStatsDashboardPanelCtrl} from "../../../directives/liv-iq-button-stats-dashboard-panel.controller";
import {ButtonStatsDashboardPanel} from "../../../directives/liv-iq-button-stats-dashboard-panel.directive";

export const HOSPITAL_ADMIN_DASHBOARD_MODULE = 'hospital.admin.dashboard';

angular.module(HOSPITAL_ADMIN_DASHBOARD_MODULE, [CYLINDERS_MODULE, smartTable]);

angular.module(HOSPITAL_ADMIN_DASHBOARD_MODULE)
    .controller('HospitalAdminDashboard', HospitalAdminDashboard)
    .controller('HospitalAdminButtonDashboard', HospitalAdminButtonDashboard)
    .controller('livIqButtonLowBatteryBanner', ButtonLowBatterBannerController)
    .controller('livIqButtonStatsDashboardPanel', ButtonStatsDashboardPanelCtrl);

angular.module(HOSPITAL_ADMIN_DASHBOARD_MODULE)
    .directive('livIqButtonLowBatteryBanner', ButtonLowBatteryBanner)
    .directive('livIqButtonStatsDashboardPanel', ButtonStatsDashboardPanel);