export function ButtonsTasksListService($q, $rootScope, FlatButtonOtherTaskType, ButtonOtherTaskType, ButtonTaskType, ListHelpersService, ButtonsFeatureService) {

    var self = this;

    function getHospital() {
        return $rootScope.appReady.promise;
    }

    function getBaseFilter(hospital) {
        return {
            filter: {
                where: {
                    hospitalId: hospital ? hospital.id : null,
                    deleted: 0
                }
            }
        };
    }

    function updateTask(task, deleted) {
        if (task) {
            task.deleted = deleted;
            return task.$save();
        }
        return $q(function () {
            return false;
        });
    }

    self.getAllTaskTypes = function () {
        if (!ButtonsFeatureService.isEnabled()) {
            return $(function (resolve) {
                resolve([]);
            });
        }

        return getHospital()
            .then(getBaseFilter)
            .then(function (filter) {
                ListHelpersService.applyDefaultOrdering(filter.filter, [
                    {property: 'name'}
                ]);
                return ButtonTaskType.find(filter).$promise;
            });
    };

    self.getPage = function (applyFilteringAndPagination) {
        if (!ButtonsFeatureService.isEnabled()) {
            return ButtonsFeatureService.getEmptyPageResults();
        }

        return getHospital()
            .then(getBaseFilter)
            .then(function (filter) {
                applyFilteringAndPagination(filter.filter);

                return FlatButtonOtherTaskType.getPage(filter).$promise;
            })
            .then(function (data) {
                return {
                    items: _.map(data.items, function (task) {
                        return new FlatButtonOtherTaskType(task);
                    }),
                    totalCount: data.totalCount
                };
            });
    };

    self.save = function (task) {
        return updateTask(task, 0);
    };

    self.delete = function (task) {
        return updateTask(task, new Date().getTime());
    };

    self.makeEditableCopy = function (task) {
        return new ButtonOtherTaskType(task);
    };
}

ButtonsTasksListService.$inject = ["$q", "$rootScope", "FlatButtonOtherTaskType", "ButtonOtherTaskType", "ButtonTaskType", "ListHelpersService", "ButtonsFeatureService"];