function resizeHeaderOnScroll() {
  var distanceY = window.pageYOffset || document.documentElement.scrollTop,
  shrinkOn = 100,
  headerEl = document.getElementById('main-navbar');

  if (distanceY > shrinkOn) {
    headerEl.classList.add("compact");
  } else {
    headerEl.classList.remove("compact");
  }
}

window.addEventListener('scroll', resizeHeaderOnScroll);
