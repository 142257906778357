export function livIqHumanizeAdviceToNurseFactory(gettextCatalog, gettext) {
    var MESSAGE_CODE_TO_TEXT = {
        PRESS_MUTE_BUTTON: gettext('Press Mute button for 5 seconds to clear FP from display'),
        CHECK_CYLINDER: gettext('Check cylinder!'),
        CLOSE_VALVE: gettext('Close valve when finished'),
        CONNECT_CYLINDER: gettext('Connect a new cylinder!')
    };

    function livIqHumanizeAdviceToNurse(messageCode) {
        return gettextCatalog.getString(MESSAGE_CODE_TO_TEXT[messageCode]);
    }

    return livIqHumanizeAdviceToNurse;
}

livIqHumanizeAdviceToNurseFactory.$inject = ["gettextCatalog", "gettext"];