export function VirtualButtonsController($scope, $rootScope, ButtonTaskType, ButtonOrder, ButtonsTasksListService, SwButtonsService, toastr, CylinderTypeService, gettextCatalog) {
    var self = this;
    self.rootScope = $rootScope;
    self.gettextCatalog = gettextCatalog;
    self.CylinderTypeService = CylinderTypeService;
    var wrappedTaskTypes = [];
    var swButtonsTaskTypes = [];

    var swButtonsDisabledFlag = true;
    self.buttonsDisabled = function () {
        return swButtonsDisabledFlag;
    };

    function updateAndIndexSwButtons(buttons) {
        // Convert the SwButtons into pseudo task types
        swButtonsTaskTypes = _.map(buttons, function (sb) {
            return {
                hospitalId: sb.hospitalId,
                id: sb.buttonTaskTypeId,
                name: sb.buttonTaskType,
                description: decodeTask(sb.buttonTaskType)
            };
        });

        var indexedSwButtons = {};
        _.forEach(buttons, function (b) {
            indexedSwButtons[b.buttonTaskTypeId] = b;
        });

        return indexedSwButtons;
    }

    function decodeTask(taskName) {
        return self.CylinderTypeService.translateCylinderType(taskName);
    }

    function updateWrappedTaskTypes(indexedSwButtons) {
        _.forEach(wrappedTaskTypes, function (wtt) {
            wtt.swButton = indexedSwButtons[wtt.taskType.id] ? indexedSwButtons[wtt.taskType.id] : null;
        });
    }

    function refreshShortcuts(disableButtons) {
        if (disableButtons) swButtonsDisabledFlag = true;

        return SwButtonsService.getByLocation(self.location.id)
            .then(updateAndIndexSwButtons)
            .then(updateWrappedTaskTypes)
            .then(function () {
                swButtonsDisabledFlag = false;
            })
            .catch(function (err) {
                console.error('Error getting shortcuts:', err);
                updateWrappedTaskTypes({});
                swButtonsDisabledFlag = true;
            });
    }

    // Doing this is enough for this control to update periodically since
    // Whenever the nurse dashboard refreshes it also sets the location
    // We always want to refresh the buttons even if the location has changed since there may be
    // new 'most recently ordered' items
    $scope.$watch('ctrl.location', function (newValue, oldValue) {
        var locationHasChanged = newValue.id !== oldValue.id;
        return refreshShortcuts(locationHasChanged);
    });

    ButtonsTasksListService.getAllTaskTypes()
        .then(function (taskTypes) {
            wrappedTaskTypes = _.map(taskTypes, function (tt) {
                Object.defineProperty(tt, 'description', {
                    get: function () {
                        return decodeTask(this.name);
                    }
                });
                return {taskType: tt};
            });
            updateWrappedTaskTypes({});
        })
        .catch(function (err) {
            console.error('Error getting task types:', err);
        });

    self.taskShortcuts = function () {
        return swButtonsTaskTypes;
    };

    self.cylinderTaskTypes = function () {
        return _.filter(wrappedTaskTypes, function (tt) {
            return tt.taskType.isCylinderType;
        });
    };

    self.otherTaskTypes = function () {
        return _.filter(wrappedTaskTypes, function (tt) {
            return !tt.taskType.isCylinderType;
        });
    };

    function isTaskTypeAShortcut(wrappedTaskType) {
        return !!wrappedTaskType.swButton;
    }

    self.toggleShortcutText = function (wrappedTaskType) {
        if (isTaskTypeAShortcut(wrappedTaskType)) {
            return self.gettextCatalog.getString('Remove Shortcut');
        } else {
            return self.gettextCatalog.getString('Add Shortcut');
        }
    };

    self.toggleShortcut = function (wrappedTaskType) {
        var promise;
        if (isTaskTypeAShortcut(wrappedTaskType)) {
            promise = SwButtonsService.delete(wrappedTaskType.swButton);
        } else {
            promise = SwButtonsService.create({
                hospitalId: wrappedTaskType.taskType.hospitalId,
                buttonTaskTypeId: wrappedTaskType.taskType.id,
                locationId: self.location.id
            });
        }

        return promise
            .catch(function (err) {
                console.error('Error toggling shortcut', err);
            })
            .finally(function () {
                refreshShortcuts(true);
            });
    };

    self.areCylindersEnabled = function () {
        return !!self.rootScope.hospital && self.rootScope.hospital.features.cylinder;
    };

    self.requestOrder = function (taskType) {
        return ButtonOrder.request({
            hospitalId: taskType.hospitalId,
            locationId: self.location.id,
            buttonTaskTypeId: taskType.id
        }).$promise
            .then(function (res) {
                return toastr.success(
                    self.gettextCatalog.getString('Request sent for {{taskType}}', {
                        taskType: taskType.name
                    }),
                    self.gettextCatalog.getString('Order request sent')
                );
            })
            .catch(function (err) {
                console.error('Error sending order request', err);
                return toastr.error(
                    err.message,
                    self.gettextCatalog.getString('Order request error')
                );
            });
    };
}

VirtualButtonsController.$inject = ["$scope", "$rootScope", "ButtonTaskType", "ButtonOrder", "ButtonsTasksListService", "SwButtonsService", "toastr", "CylinderTypeService", "gettextCatalog"];