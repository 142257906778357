import moment from 'moment';

export function UsersDirective() {
    return {
        restrict: 'E',
        scope: {
            tableAdapter: '=',
            selectUser: '&',
            addUser: '&',
            hideLocationColumn: '@',
            title: '@'
        },
        link: function (scope, element, attrs, controller) {
            scope.allowSelection = !!attrs.selectUser;
            scope.allowCreation = !!attrs.addUser;
            scope.formatDateTime = function (dateTime) {
                return moment(dateTime).fromNow();
            };
        },
        template: require('./liv-iq-users.tpl.html')
    };
}
