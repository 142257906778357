import angularRoute from "angular-route";
import {NURSE_DASHBOARD_MODULE} from "./dashboard/dashboard.module";
import {NURSE_ORDER_HISTORY_MODULE} from "./orderHistory/orderHistory.module";

import {nurseConfig} from "./config.route";

export const NURSE_MODULE = 'nurse';

angular.module(NURSE_MODULE, [angularRoute, NURSE_DASHBOARD_MODULE, NURSE_ORDER_HISTORY_MODULE]);

angular.module(NURSE_MODULE)
    .config(nurseConfig);