export function ButtonsOrdersListService($q, $timeout, buttonsOrdersRefreshIntervalMillis, ButtonsOrdersService, ButtonsFeatureService, SmartTableServerAdapter, ListHelpersService) {

    this.createNewOrdersController = (acceptedOrdersController, options) => {
        options = options || {};

        const additionalRefreshAction = options.additionalRefreshAction || function () {
        };

        function overallAdditionalRefreshAction() {
            //Whenever the new orders refreshes itself the accepted orders should be refreshed too
            acceptedOrdersController.refresh();
            additionalRefreshAction();
        }

        return createController(
            ButtonsOrdersService.validStatuses.new,
            !!options.hardLocationFiltering,
            !!options.sortOldestTop,
            overallAdditionalRefreshAction);
    };

    this.createAcceptedOrdersController = options => {
        options = options || {};

        return createController(
            ButtonsOrdersService.validStatuses.acknowledged,
            !!options.hardLocationFiltering,
            !!options.sortOldestTop,
            options.additionalRefreshAction);
    };

    function createController(desiredState, hardLocationFiltering, sortOldestTop, additionalRefreshAction) {
        // If hard location filtering is set then the filtering will always be done by selected location,
        // regardless of whether the grid's location column predicate is set or not.
        // Also hard location filtering will mean the tab counts will take the selected location into account.
        if (!additionalRefreshAction) {
            additionalRefreshAction = () => {
            };
        }

        let ctrl = {};
        ctrl.buttonsEnabled = ButtonsFeatureService.isEnabled;
        ctrl.selectedLocation = null;
        ctrl.ordersCount = 0;
        ctrl.refreshIntervalMillis = buttonsOrdersRefreshIntervalMillis;

        function getPageWrapper(applyFilteringAndPagination) {
            // Intercept the filtering to apply a default sort order
            function applyFilteringAndPaginationWrapper(filter) {
                applyFilteringAndPagination(filter);

                ListHelpersService.applyDefaultOrdering(filter, [
                    {
                        property: 'raisedTime',
                        direction: sortOldestTop ? 'ASC' : 'DESC'
                    }
                ]);
            }

            return ButtonsOrdersService.getPage(applyFilteringAndPaginationWrapper);
        }

        ctrl.groupItemsByLocationAndTask = (items, oldItems) => {
            const getKey = item => item.buttonTaskTypeId + '--' + item.locationId;

            const oldKeyedItems = _.keyBy(_.flatten(_.map(oldItems, group => group.items)), item => item.id);

            let groupedItems = {};
            _.forEach(items, item => {

                item.selected = oldKeyedItems[item.id] && oldKeyedItems[item.id].selected ? oldKeyedItems[item.id].selected : false;

                let key = getKey(item);
                if (groupedItems[key]) {
                    groupedItems[key].items.push(item);
                } else {
                    const groupSelected = oldItems && oldItems[key] && oldItems[key].groupSelected ? oldItems[key].groupSelected : false;
                    const groupVisible = oldItems && oldItems[key] && oldItems[key].groupVisible ? oldItems[key].groupVisible : false;
                    groupedItems[key] = {
                        groupSelected: groupSelected,
                        groupVisible: groupVisible,
                        items: [
                            item
                        ]
                    };
                }
            });
            return groupedItems;
        };

        ctrl.smartTable = new SmartTableServerAdapter(
            {getPage: getPageWrapper},
            () => {
                const filter = {status: desiredState};
                if (hardLocationFiltering && ctrl.selectedLocation) {
                    filter.location = ctrl.selectedLocation.name;
                }
                return filter;
            },
            {},
            ctrl.groupItemsByLocationAndTask);

        ctrl.raisedTimeDefaultSort = sortOldestTop ? true : 'reverse';

        ctrl.showPaginationControl = () => ctrl.smartTable.totalItems > 5;

      ctrl.isAnySelected = () =>
            _.some(_.flatten(_.map(ctrl.smartTable.displayedCollection, group => group.items)),
                item => item.selected);

      ctrl.areAllSelected = () => {
        if (!ctrl.isAnySelected()) return false; // Stops us returning true when no items in list
            return _.every(ctrl.smartTable.displayedCollection, group => group.groupSelected);
        };

        ctrl.toggleAllSelected = () => {
        const selectionStatus = !ctrl.areAllSelected();
            _.keysIn(ctrl.smartTable.displayedCollection)
                .forEach(key => {
                    ctrl.toggleGroupToSelectionStatus(key, selectionStatus);
                });
        };

        ctrl.acknowledge = order => {
            actionOne(order, ButtonsOrdersService.acknowledge, 'acknowledge');
        };

        ctrl.acknowledgeSelected = () => {
            actionSelected(ButtonsOrdersService.acknowledge, 'acknowledgeSelected');
        };

        ctrl.fulfill = order => {
            actionOne(order, ButtonsOrdersService.fulfill, 'fulfill');
        };

        ctrl.fulfillSelected = () => {
            actionSelected(ButtonsOrdersService.fulfill, 'fulfillSelected');
        };

        ctrl.cancel = order => {
            actionOne(order, ButtonsOrdersService.cancel, 'cancel');
        };

        ctrl.cancelSelected = () => {
            actionSelected(ButtonsOrdersService.cancel, 'cancelSelected');
        };

        ctrl.selectLocation = location => {
            if (!location) {
                // Deselect the location
                ctrl.selectedLocation = null;
                if (!hardLocationFiltering) clearLocationPredicate();
            } else {
                // Select the location
                ctrl.selectedLocation = location;
                if (!hardLocationFiltering) setLocationPredicate(location.name);
            }
        };

        ctrl.refresh = () => {
            ctrl.smartTable.refresh();
            return updateOrdersCount();
        };

      ctrl.isItemSelected = (key, id) => {
        const item = _.find(ctrl.smartTable.displayedCollection[key].items, item => item.id === id);
        return item.selected;
      };

        ctrl.toggleItemSelection = (key, id) => {
            const item = _.find(ctrl.smartTable.displayedCollection[key].items, item => item.id === id);
            item.selected = !item.selected;
            ctrl.updateGroupSelection(key);
        };

        ctrl.updateGroupSelection = key => {
            ctrl.smartTable.displayedCollection[key].groupSelected = !_.find(ctrl.smartTable.displayedCollection[key].items,
                item => item.selected === false);
        };

      ctrl.isGroupSelected = key => {
        return ctrl.smartTable.displayedCollection[key].groupSelected;
      };

        ctrl.toggleGroupSelection = key => {
            const newSelectionsStatus = !ctrl.smartTable.displayedCollection[key].groupSelected;
            ctrl.toggleGroupToSelectionStatus(key, newSelectionsStatus);
        };

        ctrl.toggleGroupToSelectionStatus = (key, selected) => {
            ctrl.updateItemsSelectionStatus(key, selected);
            ctrl.smartTable.displayedCollection[key].groupSelected = selected;
            ctrl.smartTable.displayedCollection[key].groupVisible = selected || ctrl.smartTable.displayedCollection[key].groupVisible;
        };

        ctrl.updateItemsSelectionStatus = (key, selected) => {
            ctrl.smartTable.displayedCollection[key].items.forEach(item => {
                item.selected = selected;
            });
        };

        ctrl.toggleGroupVisibility = key => ctrl.smartTable.displayedCollection[key].groupVisible = !ctrl.smartTable.displayedCollection[key].groupVisible;

        ctrl.clickRow = (key, $event) => {
            if ($event.target.getAttribute('id') === key) {
                return;
            }
            ctrl.toggleGroupVisibility(key);
        };

        ctrl.groupIsVisible = key => ctrl.smartTable.displayedCollection[key].groupVisible;

        ctrl.allGroupsAreVisible = () => _.every(ctrl.smartTable.displayedCollection,
            (value, key) => value.groupVisible || ctrl.isGroupOfOneItem(key));

        ctrl.shouldShowHideButton = () => {
            const allSizeOne = _.every(ctrl.smartTable.displayedCollection, (value, key) => ctrl.isGroupOfOneItem(key));
            return ctrl.allGroupsAreVisible() && !allSizeOne;
        };

        ctrl.toggleAllGroupsVisibility = () => {
            const isVisible = !ctrl.allGroupsAreVisible();
            _.keysIn(ctrl.smartTable.displayedCollection)
                .forEach(key => {
                    ctrl.smartTable.displayedCollection[key].groupVisible = isVisible;
                });
        };

        ctrl.showItemsInGroup = key => ctrl.groupIsVisible(key) && !ctrl.isGroupOfOneItem(key);

        ctrl.isGroupOfOneItem = key => ctrl.smartTable.displayedCollection[key].items.length === 1;

        function triggerRefresh() {
            // Used for internal calls to refresh
            // In this case other things outside this controller
            // may need refreshing as a result
            // ie the accepted orders when the new orders have changed
            ctrl.refresh();
            additionalRefreshAction();
        }

        function setLocationPredicate(name) {
            if (!ctrl.smartTable.tableState.search.predicateObject) {
                ctrl.smartTable.tableState.search.predicateObject = {};
            }
            ctrl.smartTable.tableState.search.predicateObject.location = name;
        }

        function clearLocationPredicate() {
            if (ctrl.smartTable.tableState.search.predicateObject) {
                delete ctrl.smartTable.tableState.search.predicateObject.location;
            }
        }

        function updateOrdersCount() {
            // If doing hard location filtering we want the counts to
            // take the location filtering into account
            let locationName = null;
            if (hardLocationFiltering && ctrl.selectedLocation) {
                locationName = ctrl.selectedLocation.name;
            }
            return ButtonsOrdersService.countOrdersWithStateAndLocation(desiredState, locationName)
                .then(function (count) {
                    ctrl.ordersCount = count;
                })
                .catch(function (err) {
                    console.error('updateOrdersCount failed: ', err);
                });
        }

        function actionOne(order, doAction, actionName) {
            doAction(order)
                .then(triggerRefresh)
                .catch(function (err) {
                    console.error(actionName + ' failed: ', err);
                });
        }

        function actionSelected(doAction, actionName) {
            const updates = _
                .filter(_.flatten(_.map(ctrl.smartTable.displayedCollection, function (group) {
                    return group.items;
                })), function (order) {
                    return order.selected;
                })
                .map(doAction);

            $q.all(updates)
                .then(triggerRefresh)
                .catch(function (err) {
                    console.error(actionName + ' failed: ', err);
                });
        }

        return ctrl;
    }
}

ButtonsOrdersListService.$inject = ["$q", "$timeout", "buttonsOrdersRefreshIntervalMillis", "ButtonsOrdersService", "ButtonsFeatureService", "SmartTableServerAdapter", "ListHelpersService"];