import {LocationTypeListService} from "./LocationTypeListService";
import {locationTypes, locationTypesNoLocale} from "./LocationTypes";

export const LOCATION_TYPES_MODULE = 'services.locationTypes';

angular.module(LOCATION_TYPES_MODULE, []);


angular.module(LOCATION_TYPES_MODULE)
    .constant('LocationTypes', locationTypes)
    .constant('LocationTypesNoLocale', locationTypesNoLocale);

angular.module(LOCATION_TYPES_MODULE)
    .service('LocationTypeListService', LocationTypeListService);