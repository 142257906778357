const LABELS_LOCALIZED = 'DashboardReportService.LABELS_LOCALIZED';

export function DashboardReportService($rootScope, $q, DashboardReport, LocaleService, gettextCatalog) {
    this.LABELS_LOCALIZED = LABELS_LOCALIZED;
    this.rootScope = $rootScope;
    this.q = $q;
    this.DashboardReport = DashboardReport;
    this.LocaleService = LocaleService;
    this.gettextCatalog = gettextCatalog;
    this._addScopeEventListeners();
    this._updateReportPeriodsLabels();
}

DashboardReportService.prototype._addScopeEventListeners = function () {
    this.rootScope.$on(this.LocaleService.EVENT_LOCALE_CHANGED, (function () {
        this._updateReportPeriodsLabels();
        this.rootScope.$broadcast(LABELS_LOCALIZED);
    }).bind(this));
};

/**
 * TODO: This should be invoked whenever the language selection is changed. Possible solutions:
 * - Listen on the $rootScope, attention on the memory leak problem
 */
DashboardReportService.prototype._updateReportPeriodsLabels = function () {
    var gettextCatalog = this.gettextCatalog;
    this.reportPeriods = [
        {
            name: gettextCatalog.getString('Yesterday'),
            id: '1',
            key: 'yesterday',
            text: gettextCatalog.getString('A day')
        },
        {
            name: gettextCatalog.getString('Last 7 Days'),
            id: '2',
            key: 'last7Days',
            text: gettextCatalog.getString('7 days')
        },
        {
            name: gettextCatalog.getString('Last 30 Days'),
            id: '3',
            key: 'last30Days',
            text: gettextCatalog.getString('30 days')
        },
        {
            name: gettextCatalog.getString('Last 3 Months'),
            id: '4',
            key: 'last3Months',
            text: gettextCatalog.getString('3 months')
        },
        {
            name: gettextCatalog.getString('Last 12 Months'),
            id: '5',
            key: 'last12Months',
            text: gettextCatalog.getString('12 months')
        },
        {
            name: gettextCatalog.getString('Last 24 Months'),
            id: '6',
            key: 'last24Months',
            text: gettextCatalog.getString('24 months')
        }
    ];
};

DashboardReportService.prototype.getTimePeriodForKey = function (key) {
    return _.find(this.reportPeriods, ['key', key]);
};

DashboardReportService.prototype.defaultDashboardReport = function () {
    return this.dashboardReport('last30Days');
};

DashboardReportService.prototype.dashboardReport = function (timePeriodKey) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var reportFn = self.DashboardReport[timePeriodKey];
        return reportFn({hospitalId: hospital.id}).$promise;
    });
};

DashboardReportService.prototype.getDisplaySettings = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.DashboardReport.getDisplaySettings({
            filter: {
                hospitalId: hospital.id,
                userId: self.rootScope.currentUser.id
            }
        }).$promise;
    });
};

DashboardReportService.prototype.getDisplaySettingsMenuOptions = function () {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.DashboardReport.getDisplaySettings({
            filter: {
                hospitalId: hospital.id,
                userId: self.rootScope.currentUser.id
            }
        }).$promise
            .then(function (rawSettings) {
                var formattedSettings = _.transform(rawSettings, function (result, v, k) {
                    if (k !== '$promise' && k !== '$resolved') {
                        result.push({name: k, value: v});
                    }
                }, []);
                return formattedSettings;
            });
    });
};

DashboardReportService.prototype.setDisplaySettings = function (updates) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        return self.DashboardReport.setDisplaySettings({
            filter: {
                hospitalId: hospital.id,
                userId: self.rootScope.currentUser.id
            }, updates: updates
        }).$promise;
    });
};

DashboardReportService.$inject = ["$rootScope", "$q", "DashboardReport", "LocaleService", "gettextCatalog"];