export function formatForDateRangePickerInputFactory(DATE_FORMAT_FOR_DATE_RANGE_PICKER_INPUT) {
    function formatForDateRangePickerInput(startDate, endDate) {
        return [
            startDate.format(DATE_FORMAT_FOR_DATE_RANGE_PICKER_INPUT),
            endDate.format(DATE_FORMAT_FOR_DATE_RANGE_PICKER_INPUT)
        ].join(' - ');
    }

    return formatForDateRangePickerInput;
}

formatForDateRangePickerInputFactory.$inject = ["DATE_FORMAT_FOR_DATE_RANGE_PICKER_INPUT"];