export function LocaleService($rootScope, $http, $timeout, gettextCatalog, UserListService, AuthService, HospitalListService, moment, tmhDynamicLocale) {
    var APPLICATION_DEFAULT_LOCALE = 'en_GB';
    var EVENT_LOCALE_CHANGED = 'LocaleService.EVENT_LOCALE_CHANGED';

    this.rootScope = $rootScope;
    this.http = $http;
    this.$timeout = $timeout;
    this.UserListService = UserListService;
    this.AuthService = AuthService;
    this.HospitalListService = HospitalListService;
    var self = this;

    var currentLocale = APPLICATION_DEFAULT_LOCALE;

    function getCurrentLocale() {
        return currentLocale;
    }

    function applyApplicationDefaultLocale() {
        return applyLocale(APPLICATION_DEFAULT_LOCALE);
    }

    function applyPersistedLocale(currentUser, hospital) {
        return applyLocale(currentUser.langSelect || (hospital && hospital.defaultLang) || APPLICATION_DEFAULT_LOCALE);
    }

    function persistAndApplyLocale(locale) {
        var hospitalId;
        var update = {id: self.rootScope.currentUser.id};

        if (self.rootScope.currentUser.hospitalId && self.rootScope.currentRole.name.match(/^Linde/)) {
            hospitalId = self.rootScope.currentUser.hospitalId;
        } else {
            update.hospitalId = self.rootScope.currentUser.hospitalId;
        }
        return UserListService.updateLanguage(update, locale)
            .then(function (updatedUser) {
                console.log('Updated:', updatedUser);
                self.rootScope.currentUser = updatedUser;
                if (hospitalId) {
                    self.rootScope.currentUser.hospitalId = hospitalId;
                }
                self.AuthService.updateUser(self.rootScope.currentUser);
                return applyLocale(self.rootScope.currentUser.langSelect);
            });
    }

    function applyLocale(locale) {
        tmhDynamicLocale.set(convertLocaleIntoNgFormat(locale));
        moment.locale(locale);
        gettextCatalog.setCurrentLanguage(locale);
        return gettextCatalog.loadRemote('i18n/liv-iq-hospital_' + locale + '.json').then(function () {
            currentLocale = locale;
            self.rootScope.$broadcast(EVENT_LOCALE_CHANGED, locale);
        });
    }

    function convertLocaleIntoNgFormat(locale) {
        return locale.replace('_', '-').toLowerCase();
    }

    function loadListOfLocale(session) {
        return self.HospitalListService.getPlatform()
            .then(function (platform) {
                self.isProd = platform.env === 'prod';
                return self.http.get('json/lang.json');
            })
            .then(function (result) {
                self.rootScope.languages = _.filter(result.data, function (locale) {
                    if (self.isProd) {
                        return locale.debug !== true;
                    }
                    return true;
                });
                var langId;
                if (session && session.user)
                    langId = session.user.langSelect;
                else if (self.rootScope && self.rootScope.currentUser)
                    langId = self.rootScope.currentUser.langSelect;
                self.rootScope.currentLang = _.find(self.rootScope.languages, function (lang) {
                    return lang.id === langId;
                });
                return true;
            }).catch(function (error) {
                console.error('unable to retrieve the list of locale', error);
            });
    }

    return {
        EVENT_LOCALE_CHANGED: EVENT_LOCALE_CHANGED,
        getCurrentLocale: getCurrentLocale,
        applyApplicationDefaultLocale: applyApplicationDefaultLocale,
        applyPersistedLocale: applyPersistedLocale,
        persistAndApplyLocale: persistAndApplyLocale,
        loadListOfLocale: loadListOfLocale
    };
}

LocaleService.$inject = ['$rootScope', '$http', '$timeout', 'gettextCatalog', 'UserListService', 'AuthService', 'HospitalListService', 'moment', 'tmhDynamicLocale'];