export function HubConnectivityListService($rootScope, $q, FlatHub, FlatHubConnectivity, hubKeepAliveIntervalMillis) {
    this.rootScope = $rootScope;
    this.FlatHub = FlatHub;
    this.FlatHubConnectivity = FlatHubConnectivity;
    this.q = $q;
}

HubConnectivityListService.prototype.getBaseFilter = function (hospital) {
    return _.cloneDeep({
        filter: {
            where: {
                hospitalId: hospital.id
            }
        }
    });
};

HubConnectivityListService.prototype.getStats = function (hubIds) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var query = {
            hospitalId: hospital.id,
            hubIds: hubIds
        };
        return self.FlatHubConnectivity.getStats(query).$promise;
    });
};

HubConnectivityListService.$inject = ["$rootScope", "$q", "FlatHub", "FlatHubConnectivity", "hubKeepAliveIntervalMillis"];