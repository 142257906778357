export function ButtonTypeSelectorCtrl(gettextCatalog, $timeout) {
    var self = this;

    self.buttonTypes = [
        {key: 'all', label: gettextCatalog.getString('All')},
        {key: 'hw', label: gettextCatalog.getString('REFRESH Buttons')},
        {key: 'sw', label: gettextCatalog.getString('Virtual Buttons')}
    ];
    self.selectedButtonType = self.buttonTypes[0];

    self.onChange = function () {
        console.debug('On change called:', self.selectedButtonType);
        if (self.onSelect) self.onSelect({newButtonType: self.selectedButtonType});
    };

    $timeout(self.onChange, 100);
}

ButtonTypeSelectorCtrl.$inject = ['gettextCatalog', '$timeout'];