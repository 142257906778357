import {USERS_MODULE} from "../users/users.module";
import lbServices from '../../../api-client/js/services/lb-services.js';

import {ConfigParametersListService} from "./ConfigParametersListService";
import {ConfigParametersHelpersService} from "./ConfigParametersHelpersService";
import {ConfigParametersController} from "../../hospital/admin/locations/configParametersModal/configParameters.controller";

export const CONFIG_PARAMETERS_MODULE = 'services.configParameters';

angular.module(CONFIG_PARAMETERS_MODULE, [lbServices, USERS_MODULE]);

angular.module(CONFIG_PARAMETERS_MODULE)
    .controller('ConfigParametersController', ConfigParametersController);

angular.module(CONFIG_PARAMETERS_MODULE)
    .service('ConfigParametersListService', ConfigParametersListService)
    .service('ConfigParametersHelpersService', ConfigParametersHelpersService);
