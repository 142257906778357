export function stAutoRefresh($interval) {
    function getRefreshInterval(intervalMs) {
        return (intervalMs === 'auto' || !intervalMs) ? 45000 : intervalMs;
    }

    return {
        require: 'stTable',
        link: function (scope, element, attr, ctrl) {
            var refreshInterval = getRefreshInterval(attr.stAutoRefresh);

            var autoRefreshTask = $interval(function () {
                ctrl.pipe();
                if (scope.onRefresh) {
                    scope.onRefresh();
                }
            }, refreshInterval);

            scope.$on('$destroy', function () {
                $interval.cancel(autoRefreshTask);
            });
        }
    };
}

stAutoRefresh.$inject = ["$interval"];