export function UserEditDirective($rootScope, gettextCatalog, Roles) {
    return {
        restrict: 'E',
        scope: {
            user: '=',
            hospital: '=',
            roles: '=',
            locations: '=',
            showDefaultLocationSelector: '=',
            isEditingItself: '=',
            readOnly: '=',
            languages: '=',
            save: '&',
            cancel: '&',
            deleteUser: '&delete'
        },
        link: function (scope) {
            scope.getCurrentAction = function () {
                return scope.user && scope.user.id ? gettextCatalog.getString('Edit') : gettextCatalog.getString('Add');
            };

            scope.isExistingUser = function () {
                return !!scope.user.id;
            };

            scope.passwordFieldShouldNotBeVisible = function () {
                return scope.hospital ?
                    (scope.hospital.features.userEmailAddressValidation && !scope.user.disableEmailNotifications) || (!scope.user.recoverPasswordRequest && scope.isExistingUser()) :
                    !scope.user.recoverPasswordRequest && scope.isExistingUser();
            };

            var ROLE_MAPPING = {};
            ROLE_MAPPING[Roles.LINDE_SUPER_ADMINISTRATOR] = [Roles.LINDE_SUPER_ADMINISTRATOR, Roles.LINDE_ADMINISTRATOR];
            ROLE_MAPPING[Roles.LINDE_ADMINISTRATOR] = [Roles.HOSPITAL_SERVICE, Roles.HOSPITAL_ADMINISTRATOR, Roles.PORTER, Roles.NURSE];
            ROLE_MAPPING[Roles.HOSPITAL_SERVICE] = [Roles.HOSPITAL_SERVICE, Roles.HOSPITAL_ADMINISTRATOR, Roles.PORTER, Roles.NURSE];
            ROLE_MAPPING[Roles.HOSPITAL_ADMINISTRATOR] = [Roles.HOSPITAL_ADMINISTRATOR, Roles.PORTER, Roles.NURSE];
            ROLE_MAPPING[Roles.PORTER] = [Roles.PORTER];
            ROLE_MAPPING[Roles.NURSE] = [Roles.NURSE];

            var currentRoleName = $rootScope.currentRole.name;
            var rolesNameToDisplayInOrder = ROLE_MAPPING[currentRoleName];
            var displayedRoles = scope.roles.concat([])
                .filter(function (role) {
                    return rolesNameToDisplayInOrder.indexOf(role.name) >= 0;
                });
            displayedRoles.sort(function (role1, role2) {
                return rolesNameToDisplayInOrder.indexOf(role1.name) - rolesNameToDisplayInOrder.indexOf(role2.name);
            });
            scope.displayedRoles = displayedRoles;
        },
        template: require('./liv-iq-user-edit.tpl.html')
    };
}

UserEditDirective.$inject = ["$rootScope", "gettextCatalog", "Roles"];