export function UserListService($rootScope, $q, Appuser, FlatAppuser, gettextCatalog) {
    this.rootScope = $rootScope;
    this.Appuser = Appuser;
    this.FlatAppuser = FlatAppuser;
    this.q = $q;
    this.gettextCatalog = gettextCatalog;
}

UserListService.prototype.getBaseFilter = function (hospital) {
    return _.cloneDeep({
        filter: {
            where: {
                hospitalId: hospital ? hospital.id : null,
                deleted: 0
            }
        }
    });
};

UserListService.prototype.acceptTCs = function (userId) {
    return this.Appuser.prototype$acceptTerms({id: userId}).$promise;
};

UserListService.prototype.add = function (user) {
    var newUser = new this.Appuser(user);
    // We don't have to wait for appReady because the "add" button appears after the app is ready
    if (!newUser.hospitalId && this.rootScope.hospital) {
        newUser.hospitalId = this.rootScope.hospital.id;
        newUser.langSelect = this.rootScope.hospital.defaultLang;
    }
    return newUser;
};

UserListService.prototype.findOneByEmail = function (email) {
    var self = this;
    return this.rootScope.appReady.promise.then(function (hospital) {
        var filter = self.getBaseFilter(hospital);
        filter.filter.where.email = email;
        filter.filter.include = ['defaultLocation', 'role'];
        return self.Appuser.findOne(filter).$promise;
    });
};

UserListService.prototype.getPage = function (applyFilteringAndPagination) {
    var self = this;

    return this.rootScope.appReady.promise.then(function (hospital) {
        var filter = self.getBaseFilter(hospital);

        applyFilteringAndPagination(filter.filter);

        return self.FlatAppuser.getPage(filter).$promise.then(function (data) {
            return self.q.resolve({
                items: _.map(data.items, function (flatItem) {
                    return new self.FlatAppuser(flatItem);
                }),
                totalCount: data.totalCount
            });
        });
    });
};

UserListService.prototype.findAll = function () {
    var self = this;
    return self.rootScope.appReady.promise.then(function (hospital) {
        // console.log ('UserListService.findAll (); hospital:', hospital);
        var filter = self.getBaseFilter(hospital);
        filter.filter.include = ['defaultLocation', 'role'];
        return self.Appuser.find(filter).$promise;
    });
};

UserListService.prototype.findOneOnRoleInHospital = function (userInfo) {
    var self = this;
    var filter = {
        filter: {
            where: {
                hospitalId: userInfo.hospitalId,
                roleId: userInfo.roleId,
                email: userInfo.email,
                deleted: 0
            }
        }
    };
    return self.Appuser.find(filter).$promise
        .then(function (users) {
            if (users.length) {
                return users[0];
            }
            return null;
        });
};

UserListService.prototype.save = function (user) {
    var self = this;

    if (user) {
        if (user.password && user.password.length < 8) {
            var e = new Error(self.gettextCatalog.getString('Password should be at least 8 characters long.'));
            window.alert(e.message);
            throw e;
        }
        var oldRoleId = null;
        var newRoleId = null;
        if (user.role && user.role.id) {
            oldRoleId = user.roleId;
            user.roleId = user.role.id;
            newRoleId = user.roleId;
        }
        if (user.defaultLocation && user.defaultLocation.id) {
            user.defaultLocationId = user.defaultLocation.id;
        }
        var userPromise = self.add(user).$save();

        return userPromise.then(function (user) {
            if (oldRoleId !== newRoleId) {
                return self.Appuser.prototype$addToRole({id: user.id, roleId: newRoleId}).$promise.then(
                    function () {
                        if (oldRoleId) {
                            return self.Appuser.prototype$removeFromRole({
                                id: user.id,
                                roleId: oldRoleId
                            }).$promise.then(function () {
                                return userPromise;
                            });
                        }
                        return userPromise;
                    }
                );
            }
            return userPromise;
        });
    }
    return false;
};

UserListService.prototype.updateLanguage = function (user, lang) {
    var self = this;
    user.langSelect = lang;
    var updatingUser = new this.Appuser(user);
    return updatingUser.$save();
};

UserListService.prototype.updatePassword = function (currentPassword, newPassword) {
    var self = this;
    return self.Appuser.updatePassword({currentPassword: currentPassword, newPassword: newPassword}).$promise;
};

UserListService.prototype.forceResetPassword = function (newPassword) {
    var self = this;
    return self.Appuser.forceResetPassword({newPassword: newPassword}).$promise;
};

UserListService.prototype.requestPasswordRecovery = function (email) {
    var self = this;
    return self.Appuser.requestPasswordRecovery({email: email}).$promise;
};

UserListService.prototype.userRequestPending = function (hospitalId) {
    var self = this;
    return self.Appuser.userRequestPending({hospitalId: hospitalId}).$promise;
};

UserListService.prototype.activateNewUser = function (userId, username, validationToken, password) {
    var self = this;
    return self.Appuser.activateNewUser({
        id: userId,
        email: username,
        token: validationToken,
        password: password
    }).$promise;
};

UserListService.prototype.recoverPasswordUsingEmail = function (userId, username, token, newPassword, roleName) {
    var self = this;
    return self.Appuser.recoverPasswordUsingEmail({
        id: userId,
        email: username,
        token: token,
        newPassword: newPassword,
        roleName: roleName
    }).$promise;
};

UserListService.prototype.del = function (user) {
    if (user) {
        user.deleted = new Date().getTime();
        user.password = new Date().getTime().toString();
        user.username = user.email;
        user.email = new Date().getTime().toString();
        return user.$save();
    }
    return false;
};

UserListService.$inject = ['$rootScope', '$q', 'Appuser', 'FlatAppuser', 'gettextCatalog'];