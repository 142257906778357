export function SuperAdminEventLogs($rootScope, $scope, $uibModal, $q, $timeout, toastr, EventLogService, archivedFiles) {
    $rootScope.navbarType = 'SUPERADMIN';
    this.scope = $scope;
    this.modal = $uibModal;
    this.toastr = toastr;
    this.q = $q;
    this.timeout = $timeout;
    this.EventLogService = EventLogService;
    this.archivedFiles = archivedFiles;
}

function download(filename, content) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:application/octet-stream;base64,' + content);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

SuperAdminEventLogs.prototype.getArchivedFile = function (filename) {
    return this.EventLogService.getArchivedFile({filename: filename})
        .then(function (content) {
            download(filename, content);
        });
};

SuperAdminEventLogs.$inject = ["$rootScope", "$scope", "$uibModal", "$q", "$timeout", "toastr", "EventLogService", "archivedFiles"];