import {CONFIG_PARAMETERS_MODULE} from "../../../services/configParameters/configParameters.module";
import {STOCKS_MODULE} from "../../../services/stocks/stocks.module";
import {LOCATIONS_MODULE} from "../../../services/locations/locations.module";

import {HospitalAdminLocations} from "./locations";

export const HOSPITAL_ADMIN_LOCATIONS_MODULE = 'hospital.admin.locations';

angular.module(HOSPITAL_ADMIN_LOCATIONS_MODULE, [CONFIG_PARAMETERS_MODULE, LOCATIONS_MODULE, STOCKS_MODULE]);

angular.module(HOSPITAL_ADMIN_LOCATIONS_MODULE)
    .controller('HospitalAdminLocations', HospitalAdminLocations);