import {CYLINDERS_MODULE} from "../../../services/cylinders/cylinders.module";
import {ORDERS_MODULE} from "../../../services/orders/orders.module";
import {LOCATIONS_MODULE} from "../../../services/locations/locations.module";
import {LAYOUT_MODULE} from "../../../layout/layout.module";

import {PorterOrderHistory} from "./orderHistory";
import {OrderRejectionModalController} from "./orderRejectionModal/orderRejectionModal.controller";

export const PORTER_ORDER_HISTORY_MODULE = 'porter.orderHistory';

angular.module(PORTER_ORDER_HISTORY_MODULE, [CYLINDERS_MODULE, ORDERS_MODULE, LOCATIONS_MODULE, LAYOUT_MODULE]);

angular.module(PORTER_ORDER_HISTORY_MODULE)
    .controller('PorterOrderHistory', PorterOrderHistory)
    .controller('OrderRejectionModalController', OrderRejectionModalController);