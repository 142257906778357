import {LAYOUT_MODULE} from "../../../layout/layout.module";
import {ORDERS_MODULE} from "../../../services/orders/orders.module";
import {CYLINDERS_MODULE} from "../../../services/cylinders/cylinders.module";

import {ActionRequest} from "./hospitalActionRequest";

export const PORTER_HOSPITAL_ACTION_REQUEST_MODULE = 'porter.hospitalActionRequest';

angular.module(PORTER_HOSPITAL_ACTION_REQUEST_MODULE, [CYLINDERS_MODULE, ORDERS_MODULE, LAYOUT_MODULE]);

angular.module(PORTER_HOSPITAL_ACTION_REQUEST_MODULE)
    .controller('ActionRequest', ActionRequest);