export function superAdminConfig($routeProvider) {
    $routeProvider.when('/superadmin/servicelevels', {
        template: require('./serviceLevels/serviceLevels.tpl.html'),
        controller: 'SuperAdminServiceLevels',
        controllerAs: 'serviceLevelsCtrl',
        resolve: {
            serviceLevels: ['ServiceLevelListService', function (ServiceLevelListService) {
                return ServiceLevelListService.findAll();
            }],
            featureDefinitions: ['ServiceLevelListService', function (ServiceLevelListService) {
                return ServiceLevelListService.getFeatureDefinitions();
            }]
        }
    }).when('/superadmin/hospitals', {
        template: require('./hospitals/hospitals.tpl.html'),
        controller: 'SuperAdminHospitals',
        controllerAs: 'hospitalListCtrl',
        resolve: {
            hospitals: ['HospitalListService',function (HospitalListService) {
                return HospitalListService.findAll();
            }],
            serviceLevels: ['ServiceLevelListService', function (ServiceLevelListService) {
                return ServiceLevelListService.findAll();
            }],
            fillPressurePlans: ['FillPressurePlanService', function (FillPressurePlanService) {
                return FillPressurePlanService.fillPressurePlans;
            }],
            countries: ['CountryService', function (CountryService) {
                return CountryService.countries();
            }]
        }
    }).when('/superadmin/users', {
        template: require('./users/users.tpl.html'),
        controller: 'SuperadminUsers',
        controllerAs: 'userListCtrl',
        resolve: {
            users: ['UserListService',function (UserListService) {
                return UserListService.findAll().catch(function (err) {
                    return []; // Ignore errors - might be due to start-up condition
                })
            }],
            roles: ['RoleListService', function (RoleListService) {
                return RoleListService.findAll(true).catch(function (err) {
                    return []; // Ignore errors - might be due to start-up condition
                });
            }]
        }
    }).when('/superadmin/eventLogs', {
        template: require('./event-logs/event-logs.tpl.html'),
        controller: 'SuperAdminEventLogs',
        controllerAs: 'eventLogsCtrl',
        resolve: {
            archivedFiles: ['EventLogService', function (EventLogService) {
                return EventLogService.listOrArchivedFiles().catch(function (err) {
                    return []; // Ignore errors - might be due to start-up condition
                });
            }]
        }
    }).when('/superadmin/settings', {
        template: require('./settings/settings.tpl.html'),
        controller: 'LindeSuperAdminSettings',
        controllerAs: 'lindeSuperAdminSettingsCtrl'

    }).when('/superadmin/about', {
        template: require('./about/about.tpl.html'),
    });
}

superAdminConfig.$inject = ['$routeProvider'];
