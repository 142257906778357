import THREE from "threejs";

export function CylinderFactory(Model, Cylinder, CylinderOperatingModes) {
    var superclass = Model;

    function CylinderModel(cylinderJSON, hub) {
        superclass.call(this, cylinderJSON);
        if (this.deleted === undefined) this.deleted = 0;
        this.hub = hub;
    }

    CylinderModel.FAULT_COLOR = new THREE.Color(0xFF8400);
    CylinderModel.OK_COLOR = new THREE.Color(0x7fcc3f);
    CylinderModel.EMPTY_COLOR = new THREE.Color(0xff173b);
    CylinderModel.DELIVERY_COLOR = new THREE.Color(0x00ACFF);

    CylinderModel.findAll = function () { // TODO: Make specific to hospital
        return Cylinder.find({
            filter: {
                where: {
                    deleted: 0
                },
                include: [
                    'lastKnownLocation',
                    'hub'
                ]
            }
        }).$promise.then(function (cylinders) {
            return _.map(cylinders, function (cylinder) {
                return new CylinderModel(cylinder);
            });
        });
    };

    CylinderModel.prototype.statusColor = function () {
        if (this.operatingMode === CylinderOperatingModes.FAULT || (this.operatingMode === CylinderOperatingModes.GAS_DELIVERY && this.isEmpty)) {
            return CylinderModel.FAULT_COLOR;
        } else if (this.isEmpty) {
            return CylinderModel.EMPTY_COLOR;
        }
        return CylinderModel.OK_COLOR;
    };

    return CylinderModel;
}

CylinderFactory.$inject = ["Model", "Cylinder", "CylinderOperatingModes"];